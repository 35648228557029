import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { useFormProdutoPrincipalEditValidation } from './form-produto-principal-edit-validation';
// import { OrigemMercadoriaMock } from '../../../../../../data/mocks/origem-mercadoria-mock';
// import { IndEscalaMock } from '../../../../../../data/mocks/ind-escala-mock';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { AutocompleteNcmsGestao } from '../../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { AutocompleteProdutoCategorias } from '../../../../controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { stringNumeros } from 'utils/string-numeros';
import { retornoAutoComplete } from '../../../../controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useConfirm } from 'material-ui-confirm';
import { BalancaMock } from 'data/mocks/balanca-mock';
import { usePostProdutoMedida } from 'data/api/gestao/produto/produto-medida/post-produto-medida';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { AutocompleteMedidas } from '../../../../controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { guidEmpty } from 'utils/guid-empty';
import { ProdutoPrincipalEditFormModel } from 'model/app/forms/produto/produto-edit/produto-principal-edit-form-model';
import { useSessaoAtual } from 'services/app';
import {
  isPlanoFiscal,
  isPlanoComEstoque,
  isPlanoControleProducao,
  isPlanoFarmaceutico
} from 'utils/plano-utils';
import { AutocompleteGrupoImpostos } from 'views/components/controles/autocompletes/autocomplete-grupo-impostos/autocomplete-grupo-impostos';
import { ProdutoFiscalModel } from 'model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { EditarIcon, InformacaoIcon, NovoIcon } from 'views/components/icons';
import { ProdutoVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-variacao';
import { AutocompleteProdutoSetor } from 'views/components/controles/autocompletes/autocomplete-setores/autocomplete-setores';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import {
  ProdutoEstoque,
  ProdutoEstoqueRefs
} from 'views/components/modals/produto/components/produto-edit/components/produto-estoque/produto-estoque';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useStyles } from './form-produto-principal-edit-styles';
import { AutocompleteMarcas } from 'views/components/controles/autocompletes/autocomplete-marcas/autocomplete-marcas';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useMarca } from 'services/app/hooks/marca';
import { Box, Button, Grid, Tooltip } from 'views/design-system';

export interface ProdutoPrincipalEditRefs
  extends DefaultFormRefs<ProdutoPrincipalEditFormModel> {
  fecharEstoque: () => void;
  isAberto: () => boolean;
}

export interface FormProdutoPrincipalEditProps
  extends DefaultFormProps<ProdutoPrincipalEditFormModel> {
  setCarregandoExterno(carregando: boolean): void;
  contratoId: string;
  empresaId: string;
  id: string;
  variacaoPadrao: ProdutoVariacaoModel;
  openDialogCodigo: (codigo?: string) => void;
  atualizarVariacao: () => void;
  tipo: EnumTipoProduto;
}

export const FormProdutoPrincipalEdit = forwardRef<
  ProdutoPrincipalEditRefs,
  FormProdutoPrincipalEditProps
>(
  (
    {
      setCarregandoExterno,
      loading,
      id,
      variacaoPadrao,
      openDialogCodigo,
      atualizarVariacao,
      ...props
    }: FormProdutoPrincipalEditProps,
    ref
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const refInputValorVenda = useRef<HTMLInputElement>(null);
    const refEstoque = useRef<ProdutoEstoqueRefs>(null);
    const [modelForm, setModelForm] = useState<ProdutoPrincipalEditFormModel>(
      new ProdutoPrincipalEditFormModel()
    );

    const [abrirEstoque, setAbrirEstoque] = useState<boolean>(false);

    const { FormProdutoPrincipalEditYupValidation } =
      useFormProdutoPrincipalEditValidation();
    const { postProdutoMedida } = usePostProdutoMedida();
    const { callEvent } = useEventTools();
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();
    const { plano } = useSessaoAtual();
    const { getEmpresaAtual } = useGestaoToken();
    const planoFiscal = isPlanoFiscal(plano?.plano);
    const planoComEstoque = isPlanoComEstoque(plano?.plano);
    const isPlanoFarmacia = isPlanoFarmaceutico(plano?.plano);
    const planoControleProducao = isPlanoControleProducao(plano?.plano);
    const { isMobile } = useThemeQueries();
    const { addNovaMarca, carregando } = useMarca();

    const empresaFiscal = getEmpresaAtual()?.isFiscal;
    const classes = useStyles();

    const labelMarca = props.tipo === EnumTipoProduto.Medicamento ? 'Laboratório' : 'Marca'

    const showConfirm = useCallback(
      (campo: string) => {
        return confirm({
          title: `Campo "${campo}" sem valor!`,
          description: `Deseja manter o campo "${campo}" sem valor?`,
          cancellationText: 'Não',
          confirmationText: 'Sim'
        });
      },
      [confirm]
    );

    const calcularValorReal = useCallback((vPreco: number, vCompra: number) => {
      const pLucro = ((vPreco - vCompra) / vCompra) * 100;

      return toDecimalString(isNaN(pLucro) ? 0 : pLucro);
    }, []);

    const calcularValorFinal = useCallback(
      (vCompra: number, pLucro: number) => {
        return toDecimalString((vCompra * pLucro) / 100 + vCompra);
      },
      []
    );

    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      setValue,
      reset,
      setError
    } = useForm<ProdutoPrincipalEditFormModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormProdutoPrincipalEditYupValidation),
      criteriaMode: 'all',
      mode: 'onSubmit'
    });

    const onSubmit = async (values: ProdutoPrincipalEditFormModel) => {
      const model = picker<ProdutoPrincipalEditFormModel>(
        values,
        new ProdutoPrincipalEditFormModel()
      );

      const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/;
      if (regexCaracEspeciais.test(model.codigo)) {
        setError('codigo', {
          type: 'error',
          message: 'Não pode conter caracteres especiais.'
        });
        return;
      }
      model.cnpjFab = stringNumeros(model.cnpjFab);
      model.medidaEntrada = model.medidaSaida;
      model.medidaEntradaId = model.medidaSaidaId;

      let confirmVPreco = true;

      if (model.vPreco.length === 0 || isNaN(toDecimal(model.vPreco))) {
        model.vPreco = '0';
      }
      if (model.vCompra.length === 0 || isNaN(toDecimal(model.vCompra))) {
        model.vCompra = '0';
      }

      if (toDecimal(model.vPreco) === 0) {
        await showConfirm('Valor Venda').catch(() => {
          confirmVPreco = false;
          if (!isMobile) refInputValorVenda.current?.focus();
          return;
        });
        if (!confirmVPreco) {
          return;
        }
      }

      props.onSubmit(model, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: ProdutoPrincipalEditFormModel) => {
        setModelForm({
          ...model,
          vPreco: toDecimalString(model.vPreco),
          vCompra: toDecimalString(model.vCompra),
          precoFabricacao: model.precoFabricacao,
          precoMaximoConsumidor: model.precoMaximoConsumidor
        });
        reset({
          ...model,
          vPreco: toDecimalString(model.vPreco),
          vCompra: toDecimalString(model.vCompra),
          precoFabricacao: model.precoFabricacao,
          precoMaximoConsumidor: model.precoMaximoConsumidor
        });
        //senão o focus n funfa
        setTimeout(() => {
          if (!isMobile) refInputNome.current?.focus();
        }, 500);
      },
      fecharEstoque: () => {
        refEstoque.current?.close();
      },
      isAberto: () => abrirEstoque
    }));

    const handleModalEstoque = useCallback(() => {
      setAbrirEstoque(true);
    }, []);

    const onChangeCategoria = useCallback(
      async (retorno: retornoAutoComplete | null) => {
        const ultimaCategoria = getValues('nomeCategoria');
        if (!retorno) {
          setValue('nomeCategoria', ultimaCategoria)
          return
        }
        if (!retorno.isString) {
          let categoria = picker<ProdutoCategoriaModel>(
            retorno.value,
            new ProdutoCategoriaModel()
          );
          setValue('categoriaId', categoria.id);
          setValue('nomeCategoria', categoria.nome);
        }
      }, [getValues, setValue]);

    const addNovaMedida = useCallback(
      async (value: string, context: 'Entrada' | 'Saida') => {
        setCarregandoExterno(true);

        const novaMedida = new ProdutoMedidaModel(
          guidEmpty(),
          props.contratoId,
          props.empresaId,
          value,
          value
        );
        const ret = await postProdutoMedida(novaMedida);
        if (ret.erro) {
          throw ret.erro;
        }
        const medidaRet = ret.resultado?.data as ProdutoMedidaModel;

        setValue(`medida${context}Id`, medidaRet.id);
        setValue(`medida${context}`, medidaRet.sigla);
        setCarregandoExterno(false);
        callEvent(AppEventEnum.reloadActions, {
          medida: medidaRet
        });
      },
      [
        setCarregandoExterno,
        props.contratoId,
        props.empresaId,
        postProdutoMedida,
        setValue,
        callEvent
      ]
    );

    const onChangeSetor = useCallback(
      async (retorno: retornoAutoComplete) => {
        let setor = picker<SetoresModel>(retorno.value, new SetoresModel());
        setValue(
          'setorId',
          setor.id === '1' || setor.id === guidEmpty() ? null : setor.id
        );
        setValue(
          'nomeSetor',
          setor.id === '1' || setor.id === guidEmpty()
            ? 'Sem Setor'
            : setor.descricao
        );
      },
      [setValue]
    );

    const onChangeMedidaWrapper = useCallback(
      async (retorno: retornoAutoComplete, context: 'Entrada' | 'Saida') => {
        if (retorno.isNewVal) {
          const ultimaMedida = getValues(`medida${context}`)
          try {
            if (retorno.value !== ultimaMedida) {
              setValue(`medida${context}`, retorno.value);
              await addNovaMedida(retorno.value, context);
            } else {
              showToast('error', 'Medida já existente');
            }
          } catch (e: any) {
            setValue(`medida${context}`, ultimaMedida);
            setCarregandoExterno(false);
            showToast(
              'error',
              'Erro ao cadastrar a medida. Tente novamente em alguns instantes. Detalhe: ' +
              e.message
            );
          }
        } else if (!retorno.isString) {
          const medida = picker<ProdutoMedidaModel>(
            retorno.value,
            new ProdutoMedidaModel()
          );

          setValue(`medida${context}Id`, medida.id);
          setValue(`medida${context}`, medida.sigla);
        }
      },
      [addNovaMedida, getValues, setCarregandoExterno, setValue, showToast]
    );

    const calcularValores = useCallback(
      (event: any) => {
        setValue(event.target.name, event.target.value);
        const vCompra = toDecimal(
          event.target.name === 'vCompra'
            ? event.target.value
            : getValues('vCompra')
        );
        const vPreco = toDecimal(
          event.target.name === 'vPreco'
            ? event.target.value
            : getValues('vPreco')
        );
        const pLucro = toDecimal(
          event.target.name === 'pLucro'
            ? event.target.value
            : getValues('pLucro')
        );

        if (event.target.name === 'pLucro') {
          setValue('vPreco', calcularValorFinal(vCompra, pLucro));
        } else {
          setValue('pLucro', calcularValorReal(vPreco, vCompra));
        }
      },
      [setValue, getValues, calcularValorFinal, calcularValorReal]
    );

    const validarCodigo = useCallback(
      (ev: any) => {
        const nome: string = ev.target.value
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/;
        if (regexCaracEspeciais.test(nome)) {
          setError('codigo', {
            type: 'error',
            message: 'Não pode conter caracteres especiais.'
          });
        }

        setValue('codigo', nome);
      },
      [setError, setValue]
    );

    const cadastrarNovoLaboratorio = async (val: string) => {
      const ret = await addNovaMarca(new MarcaModel(guidEmpty(), '', val, ''))
      if (ret) {
        setValue('marcaId', ret.id)
        setValue('marca', ret.nome)
      }
    }

    const isFiscal = planoFiscal && empresaFiscal;

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {(loading && props.showLoading) || carregando ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2}>
                {variacaoPadrao.codigos.length <= 1 && (
                  <>
                    <Grid item xs={7} md={(isFiscal || (!isFiscal && !planoComEstoque)) ? 4 : 9}>
                      <Controller
                        name="codigo"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            disabled={loading}
                            fullWidth
                            autoComplete="new-password"
                            variant="outlined"
                            label="Código"
                            endAdornmentButton={
                              variacaoPadrao.codigos.length > 0 ? (
                                <Box
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    openDialogCodigo(getValues('codigo'))
                                  }
                                >
                                  <EditarIcon tipo="INPUT" />
                                </Box>
                              ) : undefined
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            error={Boolean(
                              errors.codigo && errors.codigo.message
                            )}
                            helperText={
                              errors.codigo ? errors.codigo?.message : undefined
                            }
                            {...field}
                            onBlur={validarCodigo}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5} md={(isFiscal || (!isFiscal && !planoComEstoque)) ? 2 : 3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{ height: '100%' }}
                        onClick={() => openDialogCodigo()}
                      >
                        <NovoIcon tipo="BUTTON" />
                        Novo
                      </Button>
                    </Grid>
                  </>
                )}
                {planoComEstoque && (
                  <Grid item xs={7} md={(isFiscal && variacaoPadrao.codigos.length > 1) ? 9 : 3}>
                    <Controller
                      name="qSaldoTotal"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          fullWidth
                          disabled={loading}
                          label="Estoque"
                          readOnly
                          variant="outlined"
                          error={Boolean(
                            errors.qSaldoTotal && errors.qSaldoTotal.message
                          )}
                          helperText={
                            errors.qSaldoTotal
                              ? errors.qSaldoTotal?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                {planoComEstoque && (
                  <Grid item xs={5} md={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={handleModalEstoque}
                    >
                      <EditarIcon tipo="BUTTON" />
                      Ajustar
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Controller
                    name="marca"
                    control={control}
                    render={({ field }) => {
                      return (
                        <AutocompleteMarcas
                          disabled={loading}
                          propId={id}
                          label={labelMarca}
                          permiteAdicionar
                          {...field}
                          onChange={(e) => {
                            if (e.isNewVal) {
                              if (e.isString) {
                                cadastrarNovoLaboratorio(e.value)
                              }
                              return
                            }
                            if (!e.isString) {
                              setValue('marcaId', e.value.id);
                              setValue('marca', e.value.nome);
                            }
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={planoComEstoque || (!planoComEstoque && !isFiscal) || (variacaoPadrao.codigos.length > 1 && !planoComEstoque) ? 6 : 12}>
                  <Controller
                    name="nomeCategoria"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutoCategorias
                        permiteAdicionar
                        disabled={loading}
                        propId={id}
                        modo="categoria"
                        placeholder="Ex: Eletrônicos"
                        loadingExterno={loading}
                        label="Categoria"
                        error={Boolean(
                          errors.nomeCategoria && errors.nomeCategoria.message
                        )}
                        helperText={
                          errors.nomeCategoria
                            ? errors.nomeCategoria?.message
                            : undefined
                        }
                        {...field}
                        onChange={onChangeCategoria}
                      />
                    )}
                  />
                </Grid>
                {isFiscal && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="imposto"
                        control={control}
                        render={({ field }) => (
                          <AutocompleteGrupoImpostos
                            allowSubmit
                            loadingExterno={loading}
                            label="Grupo de Imposto"
                            propId={id}
                            placeholder="-Selecione-"
                            error={Boolean(
                              errors.imposto && errors.imposto.message
                            )}
                            helperText={
                              errors.imposto
                                ? errors.imposto?.message
                                : undefined
                            }
                            {...field}
                            onChange={(retorno) => {
                              if (!retorno.isString) {
                                const imposto = picker<ProdutoFiscalModel>(
                                  retorno.value,
                                  new ProdutoFiscalModel()
                                );
                                setValue('imposto', imposto.descricao);
                                setValue('impostoId', imposto.id);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {isFiscal && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="codigoNcm"
                        control={control}
                        render={({ field }) => (
                          <AutocompleteNcmsGestao
                            modo="campoFormatado"
                            loadingExterno={loading}
                            label="Código NCM"
                            useCache
                            propId={id}
                            placeholder="NCM:"
                            error={Boolean(
                              errors.codigoNcm && errors.codigoNcm.message
                            )}
                            helperText={
                              errors.codigoNcm
                                ? errors.codigoNcm?.message
                                : undefined
                            }
                            {...field}
                            onChange={(retorno) => {
                              if (!retorno.isString) {
                                const ncm = picker<NcmModel>(
                                  retorno.value,
                                  new NcmModel()
                                );

                                setValue('codigoNcm', ncm.codigo);
                                setValue('ncmId', ncm.ncmId);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6} md={
                  (variacaoPadrao.codigos.length > 1 && !isFiscal && !planoComEstoque) ? 12 :
                    isFiscal ? 4 : 6
                }>
                  <Controller
                    name="medidaSaida"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteMedidas
                        loadingExterno={loading}
                        label="Medida de Venda"
                        propId={id}
                        error={Boolean(
                          errors.medidaSaida && errors.medidaSaida.message
                        )}
                        helperText={
                          errors.medidaSaida
                            ? errors.medidaSaida?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) =>
                          onChangeMedidaWrapper(retorno, 'Saida')
                        }
                        permiteAdicionar
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="vCompra"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputValorVenda}
                        tipo="DECIMAL"
                        manterMascara
                        autoComplete="new-password"
                        disabled={loading}
                        fullWidth
                        variant="outlined"
                        showStartAdornment
                        label='Valor de Compra'
                        endAdornmentButton={
                          props.tipo === EnumTipoProduto.Medicamento ? (
                            <Tooltip
                              title={`
                                  Preço Fabricação:
                                  ${getValues('precoFabricacao') ? (
                                  ' R$ ' + toDecimalString(
                                    getValues('precoFabricacao')
                                  )
                                ) : ' Não encontrado'}
                                   `
                              }
                            >
                              <div>
                                <InformacaoIcon
                                  tipo="GERAL"
                                  class={classes.infoIcon}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder=""
                        error={Boolean(
                          errors.vCompra && errors.vCompra.message
                        )}
                        helperText={
                          errors.vCompra ? errors.vCompra?.message : undefined
                        }
                        {...field}
                        onChange={(event) => calcularValores(event)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="pLucro"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="DECIMAL"
                        manterMascara
                        autoComplete="new-password"
                        disabled={loading}
                        fullWidth
                        variant="outlined"
                        casasDecimais={getValues('pLucro').length === 3 ? 1 : 2}
                        label='Margem de Lucro (%)'
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder=""
                        error={Boolean(errors.pLucro && errors.pLucro.message)}
                        helperText={
                          errors.pLucro ? errors.pLucro?.message : undefined
                        }
                        {...field}
                        onChange={(event) => calcularValores(event)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="vPreco"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputValorVenda}
                        tipo="DECIMAL"
                        manterMascara
                        autoComplete="new-password"
                        disabled={loading}
                        fullWidth
                        max={8}
                        showStartAdornment
                        endAdornmentButton={
                          props.tipo === EnumTipoProduto.Medicamento ? (
                            <Tooltip
                              title={`
                                  Preço Máximo Consumidor:
                                  ${getValues('precoMaximoConsumidor') ? (
                                  'R$ ' + toDecimalString(
                                    getValues('precoMaximoConsumidor')
                                  )
                                ) : ' Não encontrado'}`
                              }
                            >
                              <div>
                                <InformacaoIcon
                                  tipo="GERAL"
                                  class={classes.infoIcon}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                        variant="outlined"
                        label='Valor de Venda'
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{ maxLength: 12 }}
                        error={Boolean(errors.vPreco && errors.vPreco.message)}
                        helperText={
                          errors.vPreco ? errors.vPreco?.message : undefined
                        }
                        {...field}
                        onChange={(event) => calcularValores(event)}
                      />
                    )}
                  />
                </Grid>

                {planoFiscal && props.tipo !== EnumTipoProduto.Medicamento && (
                  <Grid
                    item
                    xs={planoControleProducao && !isPlanoFarmacia ? 6 : 12}
                  >
                    <Controller
                      name="balanca"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          allowSubmit
                          label="Modo de Balança"
                          variant="outlined"
                          fullWidth
                          disabled={loading}
                          conteudo={BalancaMock}
                          {...field}
                          onChange={(event) => {
                            const item = BalancaMock.filter(
                              (item) => item.Key === event.target.value
                            )[0]?.Key;
                            if (item !== undefined) {
                              setValue('balanca', item);
                            }
                          }}
                          value={getValues('balanca')}
                        />
                      )}
                    />
                  </Grid>
                )}
                {planoControleProducao && !isPlanoFarmacia && (
                  <Grid item xs={props.tipo === EnumTipoProduto.Medicamento ? 12 : planoFiscal ? 6 : 12}>
                    <Controller
                      name="nomeSetor"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteProdutoSetor
                          disabled={loading}
                          modo="setor"
                          propId={id}
                          placeholder="Ex: Bar"
                          idSetor={getValues('setorId')}
                          loadingExterno={loading}
                          label="Setor"
                          {...field}
                          onChange={onChangeSetor}
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* <Grid item xs={planoControleMesasComandas ? 4 : 6}>
                  <Controller
                    name="tipo"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        label="Tipo Produto"
                        conteudo={TpProdutoMock}
                        fullWidth={true}
                        variant="outlined"
                        {...field}
                        onChange={ev => {
                          const item = TpProdutoMock.filter(x => x.Key === ev.target.value)
                          setValue('tipo', item[0].Key)
                        }}
                        value={getValues('tipo')}
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
        {abrirEstoque && (
          <ProdutoEstoque
            atualizarVariacao={atualizarVariacao}
            id={id}
            variacaoPadraoId={variacaoPadrao.id}
            onClose={() => setAbrirEstoque(false)}
            ref={refEstoque}
          />
        )}
      </>
    );
  }
);