export enum EnumTipoVale {
    ValeTroca,
    ValeTroco,
    ValeDesconto,
    ValeVasilhame,
}

export enum EnumStatusVale {
    Pendente,
    Confirmado,
    Utilizado,
    Cancelado
}