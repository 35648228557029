import { isEmpty } from 'lodash';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { ComandasCadastro } from './comanda-modal/comandas-cadastro/comandas-cadastro';
import { ComandasEdit } from './comanda-modal/comanda-edit/comandas-edit';
import { ComandasCadastroRfid } from './comanda-modal/comandas-cadastro-rfid/comandas-cadastro-rfid';

interface ComandaModalProps extends ModalProps {
  rfid?: boolean;
  rfidECod?: boolean;
  atualizarLista: boolean
  handleAtualizarLista: (value: boolean) => void
}

export const ComandasModal = ({ ...props }: ComandaModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {(props.openned && isEmpty(props.id) && !props.rfid) &&
        <ComandasCadastro
          atualizarLista={props.atualizarLista}
          handleAtualizarLista={props.handleAtualizarLista}
        />
      }
      {(props.openned && !isEmpty(props.id) && !props.rfid) &&
        <ComandasEdit
          atualizarLista={props.atualizarLista}
          handleAtualizarLista={props.handleAtualizarLista}
          id={props.id}
        />
      }
      {(props.openned && props.rfid) &&
        <ComandasCadastroRfid
          atualizarLista={props.atualizarLista}
          handleAtualizarLista={props.handleAtualizarLista}
          cadastrarCodigo={props.rfidECod}
        />
      }
    </DefaultModal>
  );
};
