import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { PontosVendaCompletoModel } from "model/api/gestao/pontos-venda/pontos-venda-completo-model";

export function useGetPontosVendaByIdCompleto() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPontosVendaByIdCompleto = useCallback(
    (empresaId: string, id: string) =>
      invocarApi<PontosVendaCompletoModel>({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${id}/completo`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPontosVendaByIdCompleto,
  };
}
