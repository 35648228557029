import { ValeModel } from "model/api/gestao/troca/vale-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useMovSimples } from "services/app/hooks/mov-simples";
import { DialogReiprimirVale } from "views/components/modals/dialog-reimprimir-vale/dialog-reimprimir-vale";

export const ReimprimirValeDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const { imprimirVale } = useMovSimples();
    const [modalState, setModalState] = useState({
        aberto: false,
    });

    const modalAlterado = useCallback(({ aberto }: any) => {
        setModalState({
            aberto,
        });
    }, [])

    //to colocando essa função aqui porque a impressão HTML não funciona com dialog aberto =(
    const imprimirVales = useCallback((vales: ValeModel[]) => {
        setModalState({ aberto: false });
        setTimeout(async () => {
            for (let vale of vales) {
                await imprimirVale(vale);
            }
        }, 500)
    }, [imprimirVale])

    useEffect(() => {
        addHandler(AppEventEnum.ReiprimirValeDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ReiprimirValeDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.aberto) {
        return null
    }

    return (
        <DialogReiprimirVale
            openned={modalState.aberto}
            imprimirVales={imprimirVales}
        />
    )
}