import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    celulaGrid: {
        marginLeft: theme.spacing(2),
        minWidth: 0,
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    cardContent: {
        "& span": {
            fontSize: "0.7em",
        },
        "& p": {
            fontSize: "1.0em",
        },
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    contentFinal: {
        justifyContent: 'flex-end', display: 'flex'
    },
    codigo: {
        minWidth: 120,
        maxWidth: 120
    },
    overflowText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '14px',
        color: theme.palette.common.black,
    },
    imgContainer: {
        padding: theme.spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    prodImg: {
        objectFit: 'contain'
    },
    bold: {
        fontWeight: 700
    },
    ncm: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    tagProdutos: {
        cursor: 'pointer',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagCodigoAnvisa: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
    },
}))