import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus,
} from '../../../../../../components/tabs/tabs-saurus';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from '../../../../../../../services/app/hooks/toast-saurus';
import { useHistory, useLocation } from 'react-router';
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useSessaoAtual } from 'services/app';
import { CircularLoading, useThemeQueries } from 'views';
import { useStyles } from './components/list-styles';
import { useGetComandas } from 'data/api/gestao/comandas/get-comandas';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { ComandasListData } from './components/comandas-list-data';
import { ComandaAddIcon } from 'views/components/icons/comanda-add-icon';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { useGetMesas } from 'data/api/gestao/mesas/get-mesas';
import { MesasListData } from './components/mesas-list-data';
import { useGetSaloes } from 'data/api/gestao/saloes/get-saloes';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { ButtonFabMenuModel } from 'model/app/components/button-fab-menu/button-fab-menu';
import { ButtonFabMenu } from 'views/components/controles/buttons/button-fab-menu';
import { MenuVerticalIcon } from 'views/components/icons/menu-vertical-icon';
import { ConfiguracaoIcon } from 'views/components/icons';
import { AdicionarMesaIcon } from 'views/components/icons/adicionar-mesa-icon';
import { isEmpty } from 'lodash';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { ButtonFab } from 'views/components/controles';
import { useNFC } from 'services/app/hooks/nfc';
import { SelecionarCadastroComanda } from './components/selecionar-cadastro-comanda/selecionar-cadastro-comanda';
import { CadastroFacilitadoComanda } from './components/cadastro-facilitado-comanda/cadastro-facilitado-comanda';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { Grid } from 'views/design-system';
import { CardControleCotaMesasComQrcode } from 'views/components/cards/card-controle-cota-mesas-com-qrcode';
// import { useTourSaurus } from 'services/app/hooks/tour-saurus';
// import { EnumTour } from 'model/enums/enum-tour';

interface MesaListProps {
  listMesas: MesasModel[];
}

interface ComandasListProps {
  listComandas: ComandasModel[]
}

export const MesasComandasTabs = () => {
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const isFarmaceutico = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

  // rotas
  const rotaMesas = '/gerenciar/mesas';
  const rotaComandas = '/gerenciar/comandas';

  const retornaAba = useCallback((path: string) => {
    if (isFarmaceutico) {
      return 1;
    }
    return path.indexOf(rotaComandas) > -1 ? 1 : 0;
  }, [isFarmaceutico]);

  // AUX
  const { replace, push } = useHistory();
  const location = useLocation()
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();
  const { setRegistro } = useGestaoStorage();
  const { verificarNFC, temNFC } = useNFC();
  const { callTour } = useTourSaurus();
  const atualizarCotas = useRef<boolean>(true)

  // STATES E REFS
  const [queryStatusMesas, setQueryStatusMesas] = useState<MesaListProps>({
    listMesas: [],
  });

  const [queryStatusComandas, setQueryStatusComandas] = useState<ComandasListProps>({
    listComandas: [],
  });

  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );
  const [salaoId, setSalaoId] = useState<string>('')
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCloseMenu, setOpenCloseMenu] = useState<boolean>(false);
  const [saloesMock, setSaloesMock] = useState<KeyValueModel[]>([]);
  const [finalizouTourMesas, setFinalizouTourMesas] = useState<boolean>(false)
  let finalizou = useRef<boolean>(true)
  let finalizouPrimeiroPasso = useRef<boolean>(true)
  let finalizouTodooTour = useRef<boolean>(false)
  const [opcoesCadastro, setOpcoesCadastro] = useState<boolean>(false);

  const handleOpenClose = useCallback(() => {
    setOpenCloseMenu(prev => !prev);
  }, [])

  const handleOpen = () => {
    setOpenMenu(true)
  }
  const handleClose = () => {
    setOpenMenu(false)
  }
  // chamadas API
  const { getMesas, carregando: carregandoMesas } = useGetMesas();
  const { getComandas, carregando: carregandoComanda } = useGetComandas();
  const { getSaloes, carregando: carregandoSaloes } = useGetSaloes();

  // providers
  const {
    abrirCadastroComandas,
    abrirCadastroMesas,
  } = useCadastros();

  const carregando = carregandoMesas || carregandoComanda || carregandoSaloes;

  const handleSaloes = useCallback(async () => {
    const query = 'pageSize=0';
    if (saloesMock.length > 0) return
    try {
      const res = await getSaloes(
        query,
        getEmpresaSelecionada()!.Id,
      );
      if (res.erro) throw res.erro;

      const response = res.resultado?.data?.list as SaloesModel[]

      if (!response || response.length === 0) {
        showToast('error', 'Você não possui salões cadastrados. Cadastre um salão para poder gerenciar mesas e comandas.')
        push('/saloes')
        return
      }

      const keyValueArray = response.map(x => new KeyValueModel(x.id, x.descricao))
      setSaloesMock(keyValueArray);
      setSalaoId(keyValueArray[0].Key);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEmpresaSelecionada, getSaloes, push, saloesMock.length, showToast])

  // Mesas
  const fillResultMesas = useCallback(
    async (listMesas: Array<MesasModel>) => {
      setQueryStatusMesas({
        listMesas: listMesas,
      });
    },
    [],
  );

  const handleMesas = useCallback(
    async () => {
      try {
        if (!salaoId) return
        const query = `pageSize=0&salaoId=${salaoId}`;
        const res = await getMesas(
          query,
          getEmpresaSelecionada()!.Id,
        );
        if (res.erro) throw res.erro;

        fillResultMesas(res.resultado?.data.list);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fillResultMesas, getEmpresaSelecionada, getMesas, salaoId, showToast],
  );

  const onCardMesasSelected = useCallback((id: string) => {
    const idSalao = (saloesMock.length === 1 && isEmpty(salaoId)) ? saloesMock[0].Key : salaoId;

    const temMesa = queryStatusMesas.listMesas.length > 0;

    setRegistro(GestaoStorageKeys.ModoCadastroMesa, { modo: 1, salaoId: idSalao, temMesa: temMesa }, false);
    abrirCadastroMesas(id, location.pathname, true);
  }, [abrirCadastroMesas, location.pathname, queryStatusMesas.listMesas.length, salaoId, saloesMock, setRegistro]);

  const onCardMesasChecked = (id: string) => { };

  const handleClickConfigurarMesas = useCallback(() => {
    const temMesa = queryStatusMesas.listMesas.length > 0;

    setRegistro(GestaoStorageKeys.ModoCadastroMesa, { modo: 1, salaoId: salaoId, temMesa: temMesa }, false)
    abrirCadastroMesas('', '', true)
  }, [abrirCadastroMesas, queryStatusMesas.listMesas.length, salaoId, setRegistro]);

  const handleOpenNovaMesa = useCallback(() => {
    const temMesa = queryStatusMesas.listMesas.length > 0;

    setRegistro(GestaoStorageKeys.ModoCadastroMesa, { modo: 2, salaoId: salaoId, temMesa: temMesa }, false)
    abrirCadastroMesas('', '', true)
  }, [abrirCadastroMesas, queryStatusMesas.listMesas.length, salaoId, setRegistro]);

  // Comandas
  const fillResultComandas = useCallback(
    async (listComandas: Array<ComandasModel>) => {
      setQueryStatusComandas({
        listComandas: listComandas,
      });
    },
    [],
  );

  const handleComandas = useCallback(
    async () => {
      const query = 'pageSize=0';
      try {
        const res = await getComandas(query, getEmpresaSelecionada()!.Id);
        if (res.erro) throw res.erro;

        fillResultComandas(res.resultado?.data.list);

        if (res.resultado?.data.list.length === 0 && temNFC) {
          setOpcoesCadastro(true)
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getComandas, getEmpresaSelecionada, fillResultComandas, temNFC, showToast],
  );

  const onCardComandaSelected = useCallback((id: string) => {
    abrirCadastroComandas(id, location.pathname, true);
  }, [abrirCadastroComandas, location.pathname]);;

  const onCardComandaChecked = (id: string) => { };

  const handleOpenOrCloseGerarComanda = useCallback((rfid?: boolean) => {
    abrirCadastroComandas('', '', true, rfid);
  }, [abrirCadastroComandas]);

  const tabChange = useCallback((index: number) => {
    setAbaSelecionada(index)
    if (isFarmaceutico) {
      replace(rotaComandas);
      return
    };

    if (index === 0) {
      replace(rotaMesas);
    } else {
      replace(rotaComandas);
    }
  }, [isFarmaceutico, replace]);

  const buscarMesasSaloes = useCallback(async () => {
    await handleSaloes()
    await handleMesas();
  }, [handleMesas, handleSaloes])

  const handleGetAbaSelecionada = useCallback(async () => {
    if (abaSelecionada === 0) {
      await buscarMesasSaloes()
    } else {
      await handleComandas();
    }
  }, [abaSelecionada, buscarMesasSaloes, handleComandas])

  useEffect(() => {
    handleGetAbaSelecionada()
  }, [handleGetAbaSelecionada]);

  const modalMesaEdit = useCallback(({ openned, atualizarLista }: any) => {
    if (!openned && atualizarLista) {
      handleMesas()
      atualizarCotas.current = true
    }
  }, [handleMesas])

  const modalComandaEdit = useCallback(({ openned, atualizarLista }: any) => {
    if (!openned && atualizarLista) {
      handleComandas()
    }
  }, [handleComandas])

  useEffect(() => {
    addHandler(AppEventEnum.MesasModal, modalMesaEdit)
    addHandler(AppEventEnum.ComandasModal, modalComandaEdit)

    return () => {
      removeHandler(AppEventEnum.MesasModal, modalMesaEdit)
      removeHandler(AppEventEnum.ComandasModal, modalComandaEdit)
    }
  }, [addHandler, modalComandaEdit, modalMesaEdit, removeHandler])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ConfiguracaoIcon tipo='BUTTON_FAB' />, 'Reconfigurar Mesas', () => handleClickConfigurarMesas()));
    itens.push(new ButtonFabMenuModel(<AdicionarMesaIcon tipo='BUTTON_FAB' />, 'Adicionar Mesa', () => handleOpenNovaMesa()));

    return itens;
  }, [handleClickConfigurarMesas, handleOpenNovaMesa])

  const MenuItensComandas = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ComandaAddIcon tipo='BUTTON_FAB' />, 'Cadastro Manual', () => handleOpenOrCloseGerarComanda()));
    itens.push(new ButtonFabMenuModel(<ConfiguracaoIcon tipo='BUTTON_FAB' />, 'Opções de Cadastro', () => setOpcoesCadastro(true)));

    return itens;
  }, [handleOpenOrCloseGerarComanda])

  const validarButtonFab = useMemo(() => {
    if (!isEmpty(salaoId) && (saloesMock.length === 1 || saloesMock.length > 1)) {
      return true;
    }

    return false;
  }, [salaoId, saloesMock.length])

  useEffect(() => {
    verificarNFC();
  }, [verificarNFC])

  const tabComandas = useMemo(() =>
    opcoesCadastro ? <SelecionarCadastroComanda
      showReturn={queryStatusComandas.listComandas.length > 0}
      goBack={() => setOpcoesCadastro(false)}
    /> : (
      <>
        <div className={classes.defaultContainer}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid container>
            <Grid item xs={12} className={classes.listContainer}>
              <CadastroFacilitadoComanda updateComandas={handleComandas} />
              <ComandasListData
                carregando={carregando}
                comandas={queryStatusComandas.listComandas}
                onCardChecked={onCardComandaChecked}
                onCardSelected={onCardComandaSelected}
                selectedList={[]}
              />
            </Grid>
          </Grid>
        </div>
        {temNFC ? (
          <ButtonFabMenu
            open={openMenu}
            onOpen={handleOpen}
            onClose={handleClose}
            values={MenuItensComandas}
            color={theme.palette.primary.main}
            colorItens={theme.palette.primary.main}
            icon={<MenuVerticalIcon tipo='BUTTON_FAB' fill={openMenu ? theme.palette.primary.main : '#FFF'} />}
            style={{
              zIndex: 0,
              position: 'fixed',
            }}
          />
        ) : (

          <ButtonFab
            tooltip="Adicionar Comanda"
            icon={<ComandaAddIcon fill={'#fff'} tipo="BUTTON_FAB" />}
            onClick={() => handleOpenOrCloseGerarComanda(false)}
            id='tour-CadComandas'
          />
        )}
      </>
    )
    , [MenuItensComandas, carregando, classes.defaultContainer, classes.listContainer, handleComandas, handleOpenOrCloseGerarComanda, onCardComandaSelected, opcoesCadastro, openMenu, queryStatusComandas.listComandas, temNFC, theme.palette.primary.main])

  const tabMesas = useMemo(() =>
    <>
      <div className={classes.defaultContainer} >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          {saloesMock.length > 1 && (
            <Grid item xs={12} style={{
              paddingTop: '16px',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingBottom: 16
            }}>
              <SelectSaurus
                label='Salões'
                conteudo={saloesMock}
                defaultValue={() => {
                  return saloesMock[0].Key
                }}
                onChange={(e) => {
                  setSalaoId(e.target.value);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.listContainer}>
            <MesasListData
              carregando={carregando}
              list={queryStatusMesas.listMesas}
              onCardChecked={onCardMesasChecked}
              onCardSelected={onCardMesasSelected}
              selectedList={[]}
              salao={saloesMock}
              salaoId={salaoId}
            ></MesasListData>
          </Grid>

        </Grid>

      </div>


      {validarButtonFab &&
        <ButtonFabMenu
          open={openCloseMenu}
          onClick={handleOpenClose}
          values={onClickMenuItem}
          color={theme.palette.primary.main}
          colorItens={theme.palette.primary.main}
          icon={<MenuVerticalIcon tipo='BUTTON_FAB' fill={openMenu ? theme.palette.primary.main : '#FFF'} />}
          style={{
            zIndex: 0,
            position: 'fixed',
          }}
          id='tour-CadMesas'
        />
      }
      <Grid className={classes.cardAviso} xs={10}>
        <CardControleCotaMesasComQrcode atualizarCotas={atualizarCotas} />
      </Grid>
    </>
    , [carregando, classes.cardAviso, classes.defaultContainer, classes.listContainer, handleOpenClose, onCardMesasSelected, onClickMenuItem, openCloseMenu, openMenu, queryStatusMesas.listMesas, salaoId, saloesMock, theme.palette.primary.main, validarButtonFab]);


  const acessouFormulario = useCallback((value: any) => {
    setFinalizouTourMesas(value.finalizado)
  }, [])

  useEffect(() => {
    if (location.pathname === '/gerenciar/mesas' && finalizou.current) {
      setOpenCloseMenu(true);
      setTimeout(() => { callTour(EnumTour.MESAS) }, 500)
      finalizou.current = false
    }
    if (location.pathname === '/gerenciar/comandas/') {
      setTimeout(() => { callTour(EnumTour.COMANDAS) }, 500)
      return
    }
    if (finalizouPrimeiroPasso.current && location.pathname === '/gerenciar/mesas/adicionar') {
      setTimeout(() => { callTour(EnumTour.MESASFORM) }, 1000)
      finalizouPrimeiroPasso.current = false
      return
    }
    if (finalizouTourMesas && location.pathname === '/gerenciar/mesas' && !finalizouTodooTour.current) {

      setTimeout(() => { callTour(EnumTour.RECONFIGMESAS) }, 500)
      setOpenCloseMenu(true);
      finalizouTodooTour.current = true
      return
    }
  }, [callTour, finalizouTourMesas, location.pathname])

  useEffect(() => {
    addHandler(AppEventEnum.TourFinish, acessouFormulario)
    return () => {
      removeHandler(AppEventEnum.TourFinish, acessouFormulario)
    }
  }, [acessouFormulario, addHandler, removeHandler])


  const tabs = useMemo(() => [
    new TabSaurusLabel('Mesas', 0),
    new TabSaurusLabel('Comandas', 1),
  ], [])

  const component = useMemo(() => <>
    <TabsSaurus
      selectedTabIndex={abaSelecionada}
      onChange={tabChange}
      tabsLabel={isFarmaceutico ? [] : tabs}
      hideTabs={isFarmaceutico}
      tabsContent={[
        new TabSaurusContent(
          0,
          (
            tabMesas
          ),
        ),
        new TabSaurusContent(
          1,
          (
            tabComandas
          ),
        ),
      ]}
    />
  </>, [abaSelecionada, isFarmaceutico, tabChange, tabComandas, tabMesas, tabs]);
  return component;
};
