import { isEmpty } from "lodash";
import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { PontosVendaCadastro } from "./components/pontos-venda-cadastro/pontos-venda-cadastro";
import { PontosVendaEdit } from "./components/pontos-venda-edit/pontos-venda-edit";

export interface PontosVendaModalProps extends ModalProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const PontosVendaModal = (props: PontosVendaModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) &&
        <PontosVendaCadastro handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      }
      {props.openned && !isEmpty(props.id) &&
        <PontosVendaEdit id={props.id} callbackUrl={props.aux ?? ''} handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      }
    </DefaultModal>
  );
};
