import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    height: "70px",
    boxShadow: theme.shadows[5],
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "10px",
    lineHeight: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "65px",
    },
  },
  title: {
    fontWeight: 400,
    textShadow: "2px 2px 1px rgba(0,0,0,0.05)",
    fontSize: "1.5rem",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
  },
  flexContent: {
    flex: 1,
    overflow: 'hidden',
  },
  flexFixed: {
    flex: 0,
    padding: '0!important'
  }
}));
