import { useStyles } from '../../controles/radio-list/radio-list-styles'
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { Controller, useForm } from "react-hook-form";
import { ProdutoCategoriaModel } from "model/api/gestao/produto/produto-categoria/produto-categoria-model";
import { guidEmpty } from "utils/guid-empty";
import { AutocompleteMarcas } from 'views/components/controles/autocompletes/autocomplete-marcas/autocomplete-marcas';
import { Box, Button } from 'views/design-system';

export interface PesquisaFabricanteFormModel {
    marca: string;
}

interface FormPesquisaGenericoProps {
    onSubmit: (model: PesquisaFabricanteFormModel) => void;
    text: string;
    label?: string;
    placeholder?: string;
}

export const FormPesquisaFabricante = ({
    onSubmit,
    text,
    label = 'Pesquisar Marca/Laboratório',
    placeholder = 'Digite aqui...',
}: FormPesquisaGenericoProps) => {

    const classes = useStyles();

    const {
        handleSubmit,
        control,
        setValue
    } = useForm<PesquisaFabricanteFormModel>({
        defaultValues: {
            marca: text
        },
        criteriaMode: 'all',
        mode: 'onChange'
    });

    return (
        <Box className={classes.container}>
            <form onSubmit={() => handleSubmit(onSubmit)()} className={classes.text}>
                <Controller
                    name="marca"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteMarcas
                            label={label}
                            placeholder={placeholder}
                            useCache={false}
                            allowSubmit
                            {...field}
                            onChange={(value) => {
                                if (!value?.isString) {
                                    const categoria = value?.value as ProdutoCategoriaModel | null
                                    if (categoria?.id === guidEmpty()) {
                                        setValue('marca', '')
                                        return
                                    }
                                    setValue('marca', categoria?.nome ?? '')
                                }
                            }}
                        />
                    )}
                />
            </form>
            <Box className={classes.button}>
                <Button fullWidth variant='contained' color='primary' onClick={() => handleSubmit(onSubmit)()}>
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Box>
        </Box>
    )
}