import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mov-importar-styles';
import { ProdutoIcon, VoltarIcon } from 'views/components/icons';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { IrAoCarrinhoIcon } from 'views/components/icons/ir-ao-carrinho-icon';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCadastros, useToastSaurus } from 'services/app';
import { useCallback, useState } from 'react';
import { CircularLoading } from 'views/components/utils';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { guidEmpty } from 'utils/guid-empty';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';

interface DialogMovImportarProps {
  pedido: PedidoModel;
  openned: boolean;
  handleImportar: (pedido: PedidoModel) => Promise<void>;
  dispatchEvent: AppEventEnum
}

export const DialogMovImportar = ({
  openned,
  handleImportar,
  pedido,
  dispatchEvent
}: DialogMovImportarProps) => {

  // HOOKS
  const { callEvent } = useEventTools();
  const { fecharImportarPedidorDialog, abrirDialogMovEmAndamento } = useCadastros();
  const { redirectCarrinho, redirectLanding, redirectLancadorPadrao } = useMovRota();
  const { showToast } = useToastSaurus()
  const { getMov, setPedidoExistente } = useMovAtual()

  // AUX
  const classes = useStyles();

  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false);

  const importar = useCallback(async (model: PedidoModel, goToCarrinho: boolean) => {
    try {
      setCarregandoInterno(true);

      await handleImportar(model);

      if (goToCarrinho) {
        await redirectCarrinho();
      }

      callEvent(dispatchEvent, pedido.id);
      fecharImportarPedidorDialog();

    } finally {
      setCarregandoInterno(false);
    }
  }, [callEvent, dispatchEvent, fecharImportarPedidorDialog, handleImportar, pedido.id, redirectCarrinho])

  const lancarItem = useCallback(async () => {
    try {
      const temProdutoNaMovAtual = getMov();

      if (!temProdutoNaMovAtual) {
        showToast('error', 'Não existe uma movimentação em andamento');
        redirectLanding()
        return
      }

      if (temProdutoNaMovAtual.produtos.length > 0) {
        abrirDialogMovEmAndamento(getMov()?.produtos.length ?? 0);
        fecharImportarPedidorDialog();
        return;
      }

      await setPedidoExistente(
        {
          pedidoId: pedido.id,
          codigoComanda: pedido.codigoComanda,
          codigoMesa: pedido.mesaCodigo,
          codigoPedido: pedido.codigoPedido,
          comandaId: pedido.comandaId === guidEmpty() ? '' : pedido.comandaId,
          identificador: pedido.identificador,
          mesaId: pedido.mesaId,
          salaoId: pedido.salaoId,
          clienteId: pedido.cliente.referenceId,
          isDelivery: false,
        }
      );

      fecharImportarPedidorDialog();
      await redirectLancadorPadrao();
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getMov, setPedidoExistente, pedido.id, pedido.comandaId, pedido.mesaId, pedido.salaoId, pedido.mesaCodigo, pedido.codigoComanda, pedido.codigoPedido, pedido.identificador, pedido.cliente.referenceId, fecharImportarPedidorDialog, redirectLancadorPadrao, showToast, redirectLanding, abrirDialogMovEmAndamento]);

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Importar Pedido"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        {carregandoInterno && <CircularLoading tipo='FULLSIZED' />}
        <Typography style={{ marginBottom: '16px' }}>
          Após a importação do pedido, não será mais possivel lançar produtos.
        </Typography>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => importar(pedido, false)}
              >
                <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                Importar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => importar(pedido, true)}
              >
                <IrAoCarrinhoIcon tipo="BUTTON_PRIMARY" />
                Importar e ir ao carrinho
              </Button>
            </Grid>

            {pedido.statusPedido.codigo !== EnumStatusPedido.FECHADO && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={lancarItem}
                >
                  <ProdutoIcon tipo="BUTTON_PRIMARY" />
                  Adicionar Itens
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              {fecharImportarPedidorDialog && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={fecharImportarPedidorDialog}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
