import { useCallback } from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { RetornoApiModel } from 'model';
import { isArray } from 'lodash';

export enum EnumDropCache {
    Categoria,
    Medida,
    Setor,
    Marca,
    Modificador,
    Subitem,
    Imposto,
    Ncm,
    Produto,
    Deposito,
    Impressora
}

interface AutocompleteResult<T = any> {
    propId: string;
    category: EnumDropCache;
    data: T;
    searchTerm: string; // Armazena o termo de pesquisa para futuras verificações
    timestamp: number; // Timestamp de criação para expiração
}


interface FetchDataProps<Params> {
    propId: string
    category: EnumDropCache
    searchTerm: string; // Novo parâmetro para identificar uma nova pesquisa
    params: Params
    useCache?: boolean
}

export interface DropCacheToUseProps {
    useCache?: boolean
    propId?: string
}

const cacheExpirationTime = 10 * 60 * 1000; // 10 minutos em milissegundos

export const useDropCache = <T = any>(
    fetchDropData?: (...params: any[]) => Promise<RetornoApiModel<T>>
) => {

    // Define `Params` como uma tupla dos parâmetros de `fetchAutocompleteData` quando ela existe, ou como `any[]` se não existir
    type Params = typeof fetchDropData extends (...args: infer P) => any ? P : any[];
    const { getRegistro, setRegistro, exists, delRegistro } = useGestaoStorage()

    const keyDropCache = GestaoStorageKeys.DropCache // Armazenando enum para uso geral

    // Função para buscar os dados do sessionStorage
    const getSessionData = useCallback((): AutocompleteResult<T>[] => {
        const sessionData = getRegistro(keyDropCache);
        return exists(keyDropCache) ? sessionData : [];
    }, [exists, getRegistro, keyDropCache]);

    // Função para salvar os dados no sessionStorage
    const setSessionData = useCallback((data: AutocompleteResult[]) => {
        setRegistro(keyDropCache, data);
    }, [keyDropCache, setRegistro]);

    const isCacheExpired = (timestamp: number): boolean => {
        return Date.now() - timestamp > cacheExpirationTime;
    };

    // Função para verificar o cache e fazer a requisição se necessário
    const fetchData = useCallback(
        async ({ propId, category, searchTerm = '', params, useCache = true }: FetchDataProps<Params>): Promise<AutocompleteResult<T> | undefined> => {
            // Retorna undefined e emite um aviso se fetchAutocompleteData não for fornecido
            if (!fetchDropData) {
                console.warn("fetchAutocompleteData não foi fornecido.");
                return undefined;

            } const normalizedSearchTerm = searchTerm.toLowerCase();
            const sessionData = getSessionData();

            // Checa o cache apenas se `useCache` for `true`
            if (useCache) {

                const cachedResult = sessionData.find(
                    (item) => item.propId === propId && item.category === category && item.searchTerm === normalizedSearchTerm
                );

                if (cachedResult && !isCacheExpired(cachedResult.timestamp)) {
                    return cachedResult; // Retorna o cache válido
                }
            }

            // Faz a requisição e armazena no cache se ainda não existe
            const res = await fetchDropData(...params);
            if (res.erro) throw res.erro

            if (res.resultado) {
                const data = res.resultado.data
                const newResult = {
                    propId,
                    category,
                    data,
                    searchTerm: normalizedSearchTerm,
                    timestamp: Date.now() // Armazena o timestamp de criação
                };
                if (useCache) {
                    const updatedSessionData = (prevData: AutocompleteResult<T>[]) => {
                        // Filtra os dados antigos para não incluir o item que será atualizado
                        const filteredData = prevData.filter(
                            (item) => !(item.propId === propId && item.category === category && item.searchTerm === normalizedSearchTerm)
                        );
                        return [...filteredData, newResult];
                    };
                    const currentSessionData = getSessionData();
                    setSessionData(updatedSessionData(currentSessionData));
                }
                return newResult; // Retorna o novo resultado após a requisição
            }
        },
        [fetchDropData, getSessionData, setSessionData]
    );

    // Função para limpar todo o cache
    const clearCache = () => {
        delRegistro(keyDropCache);
    };

    // Função para remover um item específico baseado em propId e category
    const removeCacheItem = (propId: string | string[], category: EnumDropCache) => {
        const sessionData = getSessionData();
        const propIdList = isArray(propId) ? propId : [propId];

        const filteredData = sessionData.filter(
            (item) => !(propIdList.includes(item.propId) && item.category === category)
        );
        setSessionData(filteredData);
    };

    // Função para remover todos os itens de uma categoria específica
    const removeCategoryCache = (category: EnumDropCache | EnumDropCache[]) => {
        const sessionData = getSessionData();
        const categoryList = isArray(category) ? category : [category]
        const filteredData = sessionData.filter((item) => !categoryList.includes(item.category));
        setSessionData(filteredData);
    };

    return {
        fetchData,
        clearCache,
        removeCacheItem,
        removeCategoryCache,
    };
};
