import { picker } from 'utils/picker';
import { PontosVendaModel } from 'model/api/gestao/pontos-venda/pontos-venda-model';
import { usePostPontosVenda } from 'data/api/gestao/pontos-venda';
import { PontosVendaCadastroFormModel } from 'model/app/forms/pontos-venda/pontos-venda-cadastro-form-model';
import { FormPontosVendaCadastro } from 'views/components/form/pontos-venda/form-pontos-venda-cadastro';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { SalvarEditarIcon } from '../../../../icons/salvar-editar-icon';
import { SalvarNovoIcon } from '../../../../icons/salvar-novo-icon';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { stringNumeros } from 'utils/string-numeros';
import { Button, Grid } from 'views/design-system';

export interface PontosVendaCadastroProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const PontosVendaCadastro = ({ atualizarLista, handleAtualizarLista }: PontosVendaCadastroProps) => {
  const { postPontosVenda, carregando } = usePostPontosVenda();
  const { fecharCadastroPontosVenda, abrirCadastroPontosVenda } =
    useCadastros();

  const classes = useModalStyles();
  const { getEmpresaSelecionada } = useSessaoAtual();

  const cadPontosVendaFormRef =
    useRef<DefaultFormRefs<PontosVendaCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const { showToast } = useToastSaurus();
  const saveNewPDV = useCallback(
    async (model: PontosVendaModel) => {
      try {
        let documento = stringNumeros(getEmpresaSelecionada()?.Documento || '');
        model.dominio = documento;
        const ret = await postPontosVenda(model);
        if (ret.erro) throw ret.erro;
        handleAtualizarLista(true)
        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroPontosVenda(ret.resultado?.data.id, '', true);
          }, 1);
          return
        }

        cadPontosVendaFormRef.current?.resetForm();
        showToast('success', 'PDV adicionado com sucesso!',);
      } catch (e: any) {
        showToast('error', e.message);
        cadPontosVendaFormRef.current?.fillForm(model);
      }
    },
    [
      postPontosVenda,
      showToast,
      abrirCadastroPontosVenda,
      getEmpresaSelecionada,
      handleAtualizarLista
    ],
  );

  const handleSubmit = useCallback(
    async (modelP: PontosVendaCadastroFormModel) => {
      const pdvToCreate = picker<PontosVendaModel>(
        modelP,
        new PontosVendaModel(),
      );
      pdvToCreate.empresaId = getEmpresaSelecionada()?.Id || '';
      pdvToCreate.contratoId = getEmpresaSelecionada()?.Id || '';
      pdvToCreate.depositoId = getEmpresaSelecionada()?.Id || '';
      saveNewPDV(pdvToCreate);
    },
    [getEmpresaSelecionada, saveNewPDV],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadPontosVendaFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadPontosVendaFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroPontosVenda('', atualizarLista);
    handleAtualizarLista(false)
  }, [atualizarLista, fecharCadastroPontosVenda, handleAtualizarLista]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Cadastro de PDV'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormPontosVendaCadastro
            ref={cadPontosVendaFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default PontosVendaCadastro;
