import { ValeModel } from 'model/api/gestao/troca/vale-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { ValeTrocoModal } from 'views/components/modals/vale-troco-modal/vale-troco-modal';

class ValeTrocoRenderModalProps {
    constructor(
        public aberto: boolean = false,
        public finalizar: (vale?: ValeModel) => void = () => {},
        public cancelar: () => void = () => {},
    ) { }
}

export const ValeTrocoRenderModal = () => {

    const { addHandler, removeHandler } = useEventTools();

    const [modalState, setModalState] = useState<ValeTrocoRenderModalProps>(new ValeTrocoRenderModalProps());

    const modalAlterado = useCallback(({ aberto, finalizar, cancelar }: ValeTrocoRenderModalProps) => {
        setModalState({
            aberto: aberto,
            finalizar,
            cancelar
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.ValeTrocoModal, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.ValeTrocoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <ValeTrocoModal
                    handleCancel={modalState.cancelar}
                    openned={modalState.aberto}
                    onSubmit={modalState.finalizar}
                />
            )}
        </>
    );
};