export enum AppEventEnum {
  EnvironmentConfig,

  ProdutoAlterado,
  MovAtualAlterada,
  MovAtualProdAlterado,
  MovAtualStatusAlterado,
  PermiteEntradaDigitada,
  Sincronizacao,
  SessaoPDV,
  SessaoSangria,
  SessaoDespesa,
  SessaoReforco,
  SessaoReceita,
  SemConexao,
  SincronizacaoGeral,
  AtualizarVendaOffiline,
  EmpresaSelecionada,
  ComandaVinculada,
  SuporteChamada,
  SetValueCam,
  EmpresaAtualAlterado,
  MenuAlterado,
  AlterarVersao,
  stopTimer,
  PDV,
  TourTrigger,
  AlterarMenuPDV,
  TabCategoria,
  TourSkip,
  MenuPDVFavorito,
  PagamentoEfetuado,
  AbrirConfiguracoesEmpresa,
  FinalizouWizard,

  //MODAIS
  PessoaModal,
  FinalizadoraModal,
  UploadCargaModal,
  CategoriaModal,
  RelatoriosAvancadosModal,
  NcmModal,
  ProdutoModal,
  ModalLimiteProdutoAtingido,
  DialogConversaoEmAndamento,
  ProdutoFiscalModal,
  ProdutoSelecionarModal,
  PontosVendaModal,
  PerfilModal,
  DialogUsuario,
  DialogVincularContratos,
  FaturasModal,
  SaloesModal,
  DocumentoFiscalModal,
  ComandasModal,
  DepositoModal,
  DialogBarCodeCam,
  CalculadoraModal,
  SetoresModal,
  EquipamentoModal,
  RecarregarPedidos,
  RecarregarPedidosComanda,
  ProdutoSubItemModal,
  AdicionarProdutoDialog,
  AdicionarProdutoGenericoDialog,
  AdicionarProdutoSemPrecoDialog,
  AdicionarProdutoBalancaDialog,
  AdicionarProdutoFiscaisDialog,
  ModalLimiteVendaAtingido,
  AdicionarProdutoSubItemDialog,
  ModalPropagandaPlano,
  AvisoCobrancaDialog,
  NovoPDVDialog,
  MesasModal,
  DialogSelecaoProdutosCodigos,
  DialogEstoque,
  DialogCodigoProduto,
  EstrelaFavorito,
  EnviarSmsDialog,
  EnviarEmailDialog,
  SolicitarPermissao,
  ImprimirDialog,
  TrocarClienteDialog,
  CadastroProdutoFacilitado,
  ImportarDialog,
  FechamentoDialog,
  MovFechamento,
  VincularComandaDialog,
  DialogBarCodeCamComanda,
  DialogAlterarQuantidadeClientes,
  MovEmAndamento,
  IndicacaoDeDesperdicio,
  RefIndicacaoDeDesperdicio,
  DialogTaxaServico,
  DialogPedidoAcoes,
  DialogAlterarMesa,
  DialogPix,
  DialogCredenciarPix,
  DialogQrCode,
  DialogOpcoesProdutoCarrinho,
  ModificadorModal,
  AdicionarWebhookDialog,
  ContratoAtualizado,
  DialogFinalizacaoParcial,
  VerificarNFC,
  EnviarNFC,
  VerificarUSB,
  EnviarUSB,
  DialogPlanoCustomizado,
  DialogConfigResumoSessao,
  AdicionarProdutoGenericoPesavelDialog,
  DialogEditarGerenciamentoSessao,
  DialogConfirmarConferenciaSessao,
  ImagemLogin,
  AlterarDisplayKeybordPayment,
  Parcelamento,
  DialogAdicionarAcrescDesc,
  DialogAcrescimoDesconto,
  TermoPesquisa,
  TelaPesquisa,
  SubmitPesquisa,
  QtdProdutos,
  QtdMedicamentos,
  QtdPrincipioAtivo,
  QtdVarejo,
  QtdRevista,
  TextHeaderSearch,
  DialogFormasDePagamento,
  DialogMarca,
  AttTituloEdicaoProduto,
  EntradaModal,
  VolumeTransportadoModal,
  DocReferenciadoModal,
  TecladoFuncoes,
  PagamentoEditModal,
  Receita,
  Controlados,
  RastroMedicamentoModal,
  TourFinish,
  TourFinish2,
  TourFinish3,
  DialogInformacaoAdicional,
  DialogTaxaEntrega,
  DialogAlterarQuantidadeClientesVenda,
  DialogTaxaServicoCarrinho,
  AtualizarTaxa,
  DialogImpressaoNfe,
  DialogConfigIniciais,
  RecarregarpaginaRetiradaDocumentos,
  AtualizarTaxaDefinicoes,
  DefinicoesVenda,
  AtualizarMovPagamento,
  AtualizarMostrarItensCarrinho,
  AtualizarQuantidadeDePessoas,
  MarcaModal,
  DialogAviso,
  ComissaoModal,
  ConvenioModal,
  CartaoConvenioModal,
  PromocaoModal,
  PromocaoRegraModal,
  ConvenioResumoFaturasModal,
  DialogConvenioAutenticar,
  ConvenioResumoFaturas,
  DialogConvenioCartaoQrCode,
  DialogTermosDeUso,
  ComissaoComissionadosModal,
  PessoaSelecionarModal,
  ComissaoApuracaoModal,
  PedidoPagamentoAntecipado,
  ProdutoMotivoCancelamentoDialog,
  PagamentoRealizado,
  IdentificarComanda,
  InformacaoOperador,
  DialogCheckoutCartao,
  VencimentoPagamentoDialog,
  DownloadBoletoDialog,
  ValeTrocoModal,
  DialogAutenticarVale,
  ReiprimirValeDialog,

  // FILTROS MODAIS
  EntradaFiltroModal,
  CategoriaFiltroModal,
  NcmsFiltroModal,
  ImpostosFiltroModal,
  PDVFiltroModal,
  HistoricoVendaFiltroModal,
  ProdutoFiltroModal,
  PessoasFiltroModal,
  DocumentoFiscalFiltroModal,
  reloadActions,
  ConfirmarSituacaoManifesto,
  SituacaoManifestoModal,
  DialogConfirm,
  ConfirmEvent,
  CancelEvent,
  ManifestosFiltroModal,
  AttTermoPesquisa,
  ConvenioFiltroModal,
  ComissaoFiltroModal,
  PromocaoFiltroModal,
  PessoasFiltroStepModal,
  RelatoriosAvancadosFiltroModal,
  KeyNavigationIndex,
  BloquearTeclado,
}