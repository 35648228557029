import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { ImagemInput, InputColor, TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormCategoriaEditValidation } from './form-categoria-edit-validation';
import { SituacaoGeralMock } from 'data/mocks/situacao-geral-mock';
import { CategoriaEditFormModel } from 'model/app/forms/categoria/categoria-edit-form-model';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { AutocompleteProdutoCategorias } from '../../../controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias';
import { isCadastroInfinito, isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TipoExibicaoMock } from 'data/mocks/tipo-exibicao-mock';
import { EnumTipoExibicao } from 'model/enums/enum-tipo-exibicao';
import { Box, Button, Grid } from 'views/design-system';
export interface FormCategoriaEditProps
  extends DefaultFormProps<CategoriaEditFormModel> {
  categoriasList: Array<ProdutoCategoriaModel>;
}
export const FormCategoriaEdit = forwardRef<
  DefaultFormRefs<CategoriaEditFormModel>,
  FormCategoriaEditProps
>(({ loading, ...props }: FormCategoriaEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<CategoriaEditFormModel>(
    new CategoriaEditFormModel(),
  );
  const { FormCategoriaEditYupValidation } = useFormCategoriaEditValidation();
  const { isMobile } = useThemeQueries();
  const { plano } = useSessaoAtual();

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<CategoriaEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormCategoriaEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: CategoriaEditFormModel) => {
    const categoria = picker<CategoriaEditFormModel>(
      values,
      new CategoriaEditFormModel(),
    );

    props.onSubmit(categoria, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({
        ...modelForm,
      });
      if (!isMobile) {
        refInputNome.current?.focus();
      }
    },
    fillForm: (model: CategoriaEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) {
          refInputNome.current?.focus();
        }
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  name="foto"
                  control={control}
                  render={({ field }) => (
                    <ImagemInput
                      loadErrorImage={SemImagem}
                      imageRadius="5%"
                      width={isMobile ? '180px' : '200px'}
                      height={isMobile ? '180px' : '200px'}
                      accept="image/*"
                      semImagemRepresentante
                      error={Boolean(
                        errors.foto && errors.foto.message,
                      )}
                      helperText={
                        touchedFields.foto || errors.foto
                          ? errors.foto?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('foto')}
                      onChange={({ base64 }: any) => {
                        setValue('foto', base64);
                      }}
                      onBlur={() => {
                        setValue('foto', '');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      inputRef={refInputNome}
                      fullWidth
                      disabled={loading}
                      label="Nome da Categoria"
                      variant="outlined"
                      error={Boolean(errors.nome && errors.nome.message)}
                      helperText={
                        touchedFields.nome || errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      disabled={loading}
                      label="Descrição / Informativo"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              {isCadastroInfinito(plano?.plano) && (
                <Grid item xs={12} md={12}>
                  <Controller
                    name="breadCrumbs"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutoCategorias
                        modo="categoriaPai"
                        allowSubmit
                        useCache={false}
                        loadingExterno={loading}
                        label="Categoria Pai"
                        idCategoria={getValues('id')}
                        error={Boolean(
                          errors.breadCrumbs && errors.breadCrumbs.message,
                        )}
                        helperText={
                          touchedFields.breadCrumbs || errors.breadCrumbs
                            ? errors.breadCrumbs?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('breadCrumbs')}
                        onChange={(retorno) => {
                          if (!retorno) return
                          if (!retorno.isString) {
                            let categoriaPai = picker<ProdutoCategoriaModel>(
                              retorno.value,
                              new ProdutoCategoriaModel(),
                            );
                            setValue('categoriaPaiId', categoriaPai.id);
                            setValue('breadCrumbs', categoriaPai.breadCrumbs);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="cor"
                  control={control}
                  render={({ field }) => (
                    <InputColor
                      valor={getValues('cor')}
                      labelTxt={'Cor de Exibição'}
                      {...field}
                      onChange={(cor) => setValue('cor', cor)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="ativo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      label={'Situação Atual'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      error={Boolean(errors.ativo && errors.ativo.message)}
                      helperText={
                        touchedFields.ativo || errors.ativo
                          ? errors.ativo?.message
                          : undefined
                      }
                      {...field}
                      value={
                        SituacaoGeralMock.filter(
                          (item) => getValues('ativo') === item.Key,
                        )[0]?.Value
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="tipoExibicao"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      disabled={loading}
                      conteudo={TipoExibicaoMock.map(x => {
                        if (isFarmaceutico && x.Key === EnumTipoExibicao.Cardapio) {
                          x.Value = 'Vitrine'
                        }
                        return x
                      })}
                      {...field}
                      fullWidth
                      error={Boolean(errors.tipoExibicao && errors.tipoExibicao.message)}
                      helperText={
                        touchedFields.tipoExibicao || errors.tipoExibicao
                          ? errors.tipoExibicao?.message
                          : undefined
                      }
                      label="Tipo de Exibição"
                      onChange={(ev) => {
                        const item = TipoExibicaoMock.filter(x => x.Key === ev.target.value)

                        if (item.length > 0) {
                          setValue('tipoExibicao', item[0].Key)
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="ordem"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo='NUMERO'
                      inputRef={refInputNome}
                      fullWidth
                      disabled={loading}
                      label="Ordem da Categoria"
                      variant="outlined"
                      error={Boolean(errors.ordem && errors.ordem.message)}
                      helperText={
                        touchedFields.ordem || errors.ordem
                          ? errors.ordem?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('ordem')}

                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
