import { useGetMesaById } from "data/api/gestao/mesas/get-mesa-by-id";
import { usePutMesa } from "data/api/gestao/mesas/put-mesa";
import { isEqual } from "lodash";
import { MesasModel } from "model/api/gestao/mesa/mesa-model";
import { MesasNovaModel } from "model/api/gestao/mesa/mesa-nova-model";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { FormEditMesa } from "views/components/form/mesa/form-edit-mesa/form-mesa-gerar";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { ModalHeader } from "views/components/modals/components";
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { Button, Grid } from "views/design-system";

interface MesaEditProps {
    id: string;
    salaoId: string;
    atualizarLista: boolean
    handleAtualizarLista: (value: boolean) => void
}

export const MesasEdit = ({ atualizarLista, handleAtualizarLista, ...props }: MesaEditProps) => {
    //AUX
    const classes = useModalStyles();
    const { showToast } = useToastSaurus();

    //CHAMADAS DA API
    const { putMesa, carregando: carregandoPut } = usePutMesa();
    const { getMesaById, carregando: carregandoGet } = useGetMesaById();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

    const carregando = carregandoPut || carregandoSessaoAtual || carregandoGet;

    //STATES E REFS
    const [mesa, setMesa] = useState<MesasNovaModel>(new MesasNovaModel());
    const refEditForm = useRef<DefaultFormRefs<MesasNovaModel>>(null);

    //PROVIDERS
    const {
        fecharCadastroMesas,
    } = useCadastros();

    const onCloseClick = useCallback(() => {
        fecharCadastroMesas(atualizarLista);
        handleAtualizarLista(false)
    }, [atualizarLista, fecharCadastroMesas, handleAtualizarLista]);

    const recarregarForm = useCallback((model: MesasNovaModel) => {
        refEditForm.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(mesa);
    }, [mesa, recarregarForm]);

    const getMesaByIdWrapper = useCallback(async () => {
        try {
            const mesaId = props.id.replace('/', '');
            const res = await getMesaById(
                mesaId,
                getEmpresaSelecionada()?.Id!,
                props.salaoId,
            );

            if (res.erro)
                throw res.erro
            const response = res.resultado?.data as MesasModel
            const modelFormatado = {
                id: response.id,
                codigo: response.codigo,
                status: response.status.codigo,
                qrCodeNaMesa: response.qrCodeNaMesa
            } as MesasNovaModel

            return setMesa(modelFormatado);
        } catch (e: any) {
            showToast('error', e.message)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEmpresaSelecionada, getMesaById, props.id, props.salaoId])

    useEffect(() => {
        getMesaByIdWrapper();
    }, [getMesaByIdWrapper])

    const saveChangesMesa = useCallback(
        async (mesaModelForm: MesasNovaModel) => {
            const ret = await putMesa(
                mesaModelForm,
                getEmpresaSelecionada()!.Id,
                props.salaoId,
            );

            if (ret.erro) {
                throw ret.erro;
            }
            handleAtualizarLista(true)
        },
        [getEmpresaSelecionada, handleAtualizarLista, props.salaoId, putMesa],
    );

    const handleSubmit = useCallback(
        async (model: MesasNovaModel, beforeModel: MesasNovaModel) => {
            try {
                const mesaEqual = isEqual(model, beforeModel);
                if (mesaEqual) {
                    showToast('info', 'Nenhuma informação foi alterada');
                    return
                }
                await saveChangesMesa(model);
                showToast('success', 'Salao atualizado com Sucesso!');
            } catch (e: any) {
                showToast('error', e.message);
                refEditForm.current?.resetForm();
            }
        },
        [saveChangesMesa, showToast],
    );

    return (
        <div className={classes.root}>
            <ModalHeader
                title={'Editar Mesas'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <div className={classes.contentForms}>
                <FormEditMesa
                    ref={refEditForm}
                    showLoading={false}
                    model={mesa}
                    loading={carregando ?? false}
                    onSubmit={handleSubmit}
                />
            </div>
            <div className={classes.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            disabled={carregando}
                            onClick={() => {
                                refEditForm.current?.submitForm();
                            }}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}