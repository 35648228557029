import classNames from 'classnames';
import { DefaultCardProps } from './default-card-props';
import { useDefaultCardStyles } from './default-card-styles';
import { handleClickFeedbackTatil } from 'utils/feedback-tatil-fn';
import React from 'react';
import { LoadingOverlay } from 'views/design-system/loading-overlay/loading-overlay';

export const DefaultCard = ({
  onClick,
  onCheck,
  isSelected,
  children,
  disabled,
  className,
  hasTagStatus,
  notClickable,
  cardRef,
  ...props
}: DefaultCardProps) => {
  const classes = useDefaultCardStyles();
  // TODO: verificar o que fazer com o loading
  const [loading] = React.useState(false);

  return (
    <>
      <div
        ref={cardRef}
        {...props}
        onClick={async (e) => {
          if (onClick) {
            e.preventDefault();
            e.stopPropagation();
          }

          if (loading || disabled) return;
          await handleClickFeedbackTatil();
          if (onClick) {
            await onClick(e);
          }

        }}
        className={classNames(
          classes.cardContainer,
          'card',
          hasTagStatus === false ? classes.cardWithoutTag : undefined,
          isSelected ? classes.cardSelected : undefined,
          disabled ? classes.cardinativo : undefined,
          !notClickable ? classes.pointer : undefined,
          className,
        )}
      >
        {loading && (
          <LoadingOverlay variant='light' />
        )}
        {(hasTagStatus === true || hasTagStatus === undefined) && (
          <div className={classes.tagStatus} style={{ background: "#D9D9D9" }}></div>
        )}
        {children}
      </div>
    </>
  );
};
