import { picker } from "utils/picker";
import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect, useState } from "react";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { useToastSaurus, useCadastros } from "services/app";
import { SalvarEditarIcon, SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useHistory } from "react-router-dom";
import { NcmCadastroFormModel } from "model/app/forms/ncm/ncm-cadastro-form-model";
import { NcmModel } from "model/api/gestao/ncm/ncm-model";
import { usePostNcm } from "data/api/gestao/ncm/post-ncm";
import { FormNcmCadastro } from "../../../../form/ncm/form-ncm-cadastro/form-ncm-cadastro";
import { stringNumeros } from "utils/string-numeros";
import classNames from "classnames";
import { Button, Grid } from "views/design-system";
import { EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";

export const NcmCadastro = (props: { contratoID: string }) => {
  const { postNcm, carregando: carregandoPost } = usePostNcm();
  const { fecharCadastroNcm, abrirCadastroNcm } = useCadastros();
  const { removeCategoryCache } = useDropCache()
  const cadNcmFormRef = useRef<DefaultFormRefs<NcmCadastroFormModel>>(null);
  const [formInSubmit, setFormInSubmit] = useState(false);
  const redirect = useRef<boolean>(true);
  const classes = useModalStyles();

  const carregando = carregandoPost || formInSubmit;
  useEffect(() => {
    cadNcmFormRef.current?.fillForm(new NcmModel());
  }, []);

  const history = useHistory();
  const pathName = history.location.pathname;

  const { showToast } = useToastSaurus();
  const saveNewNcm = useCallback(
    async (model: NcmModel) => {
      try {
        const ret = await postNcm(model);
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          abrirCadastroNcm(ret.resultado?.data.id, pathName, true);
        } else {
          cadNcmFormRef.current?.resetForm();
        }
        if (!redirect.current) {
          showToast("success", "Ncm adicionada com sucesso!",);
        }
        removeCategoryCache(EnumDropCache.Ncm)
      } catch (e: any) {
        showToast("error", e.message);
        cadNcmFormRef.current?.resetForm();
      }
    },
    [postNcm, removeCategoryCache, abrirCadastroNcm, pathName, showToast]
  );
  const handleSubmit = useCallback(
    async (modelN: NcmCadastroFormModel) => {
      let ncmToCreate = picker<NcmModel>(modelN, new NcmModel());
      ncmToCreate.cest = stringNumeros(ncmToCreate.cest);
      ncmToCreate.codigo = stringNumeros(ncmToCreate.codigo);
      ncmToCreate.contratoId = props.contratoID;
      return saveNewNcm(ncmToCreate);
    },
    [props.contratoID, saveNewNcm]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadNcmFormRef.current?.submitForm();
  }, []);
  const onCloseClick = useCallback(() => {
    fecharCadastroNcm();
  }, [fecharCadastroNcm]);
  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Cadastro de NCM"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={classNames(classes.contentForms, carregando ? classes.contentFormsLoading : undefined)}>
          <FormNcmCadastro
            ref={cadNcmFormRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default NcmCadastro;
