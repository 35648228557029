import { useCallback, useEffect, useState, useRef } from "react";
import { VoltarIcon } from "views/components/icons";
import { isEqual } from "utils/is-equal";
import { usePutPontosVenda } from "data/api/gestao/pontos-venda";
import { PontosVendaEditFormModel } from "model/app/forms/pontos-venda/pontos-venda-edit-form-model";
import { PontosVendaEditConfiguracoesFormModel } from "model/app/forms/pontos-venda/pontos-venda-edit-configuracoes-model";
import { picker } from "utils/picker";
import { DefaultFormRefs } from "views/components/form/utils";
import { PontosVendaModel } from "model/api/gestao/pontos-venda/pontos-venda-model";
import { useCadastros, useToastSaurus } from "services/app";
import { ModalHeader } from "../../../components/modal-header/modal-header";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header/button-modal-header";
import { useModalStyles } from "../../../utils/modal-styles";
import { CircularLoading } from "../../../..";
import { NovoIcon } from "../../../../icons/novo-icon";
import { SalvarIcon } from "../../../../icons/salvar-icon";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import classNames from "classnames";
import { FormPontosVendaEdit } from "views/components/form/pontos-venda/form-pontos-venda-edit";
import { EnumIndstatusStatus } from "model/enums/enum-indstatus-status";
import { PutConfiguracoesPontosVendaProps, usePatchConfiguracoesPontosVenda } from "data/api/gestao/pontos-venda/put-configuracoes-pontos-venda";
import { usePDV } from "services/app/hooks/pdv";
import { useSessaoAtual } from 'services/app';
import { EnumPDVTpCaixa } from "model/enums/enum-pdv-tpcaixa";
import { Button, Grid } from "views/design-system";
import { useGetPontosVendaByIdCompleto } from "data/api/gestao/pontos-venda/get-pontos-venda-by-id-completo";
import { PontosVendaCompletoModel } from "model/api/gestao/pontos-venda/pontos-venda-completo-model";
import { isEmpty } from "lodash";

export interface PontosVendaEditProps {
  id: string,
  callbackUrl: string
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const PontosVendaEdit = (props: PontosVendaEditProps) => {
  // STATES E REFS
  const [pontosVendaFormState, setPontosVendaForm] = useState<PontosVendaEditFormModel>(
    new PontosVendaEditFormModel()
  );
  const refEditForm = useRef<DefaultFormRefs<PontosVendaEditFormModel>>(null);
  const refPontosVendaModel = useRef<PontosVendaCompletoModel>(new PontosVendaCompletoModel());

  // HOOKS
  const { abrirCadastroPontosVenda, fecharCadastroPontosVenda } = useCadastros();
  const { showToast } = useToastSaurus();
  const { invalidarConfiguracoes } = usePDV();

  // CALL API
  const { getPontosVendaByIdCompleto } = useGetPontosVendaByIdCompleto()
  const { putPontosVenda } = usePutPontosVenda();
  const { patchConfigPontosVenda } = usePatchConfiguracoesPontosVenda()

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();

  // AUX
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useModalStyles();


  const recarregarForm = useCallback((model: PontosVendaEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  const getPontosVendaByIdWrapper = useCallback(async () => {
    const res = await getPontosVendaByIdCompleto(getEmpresaSelecionada()?.Id || '', props.id);
    if (res.erro) {
      throw res.erro;
    }

    const ret = res.resultado?.data as PontosVendaCompletoModel;
    refPontosVendaModel.current = ret;
    const pontosVendaForm = picker<PontosVendaEditFormModel>(ret.caixa, new PontosVendaEditFormModel());

    pontosVendaForm.configuracoes = ret.configuracoes.map(config => {
      return picker<PontosVendaEditConfiguracoesFormModel>(config, new PontosVendaEditConfiguracoesFormModel());
    });

    const versaoSplit = !isEmpty(ret.versao) && !isEmpty(ret.versao.versao) ? ret.versao.versao.split('|') : []
    pontosVendaForm.versao = versaoSplit.length !== 0 ?
      `${versaoSplit?.[0]}, ${versaoSplit?.[1]}, ${versaoSplit?.[2]}` : 'A versão não foi gerada.'

    return pontosVendaForm;
  }, [getPontosVendaByIdCompleto, getEmpresaSelecionada, props.id]);


  const preencherTela = useCallback(async () => {
    setLoading(true)
    try {
      const pontosVenda = await getPontosVendaByIdWrapper();
      setPontosVendaForm(pontosVenda);
    } catch (e: any) {
      showToast("error", e.message);
    } finally {
      setLoading(false)
    }
  }, [getPontosVendaByIdWrapper, showToast]);

  useEffect(() => {
    recarregarForm(pontosVendaFormState);
  }, [pontosVendaFormState, recarregarForm]);

  useEffect(() => {
    (async () => {
      await preencherTela();
    })();
    return () => {
      //funcao de limpeza
      setPontosVendaForm(new PontosVendaEditFormModel());
    };
  }, [preencherTela]);

  const saveChangesPontosVenda = useCallback(
    async (pontosVendaModelForm: PontosVendaEditFormModel) => {
      let pontosVendaUpdate = picker<PontosVendaModel>(
        pontosVendaModelForm,
        refPontosVendaModel.current.caixa,
        false
      );
      pontosVendaUpdate.descricao = pontosVendaModelForm.descricao

      const ret = await putPontosVenda(pontosVendaUpdate);
      if (ret.erro) {
        throw ret.erro;
      }

      invalidarConfiguracoes();

    },
    [invalidarConfiguracoes, putPontosVenda]
  );

  const saveChangesConfiguracoes = useCallback(async (configuracoes: Array<PontosVendaEditConfiguracoesFormModel>, id: string) => {
    const ret = await patchConfigPontosVenda(configuracoes.map(x => new PutConfiguracoesPontosVendaProps(x.id, x.cod, x.vConfig)), getEmpresaSelecionada()?.Id || '', id)
    if (ret.erro) {
      throw ret.erro
    }
  }, [patchConfigPontosVenda, getEmpresaSelecionada])

  const changeStatus = useCallback(async () => {
    setLoading(true)
    try {
      let model = await getPontosVendaByIdWrapper();
      let newState = pontosVendaFormState.indStatus === EnumIndstatusStatus.ATIVADO ?
        EnumIndstatusStatus.DESATIVADO :
        EnumIndstatusStatus.ATIVADO

      model.indStatus = newState;

      await saveChangesPontosVenda(model);
      await preencherTela();
      showToast("success", "Situação alterada com Sucesso!",);
    } catch (e: any) {

      showToast("error", e.message);
    } finally {
      setLoading(false)
    }
  }, [getPontosVendaByIdWrapper, pontosVendaFormState.indStatus, saveChangesPontosVenda, preencherTela, showToast]);

  const isAtivoWebPDV =
    pontosVendaFormState.tpCaixa === EnumPDVTpCaixa.WEBPDV
    && pontosVendaFormState.indStatus === EnumIndstatusStatus.ATIVADO

  const createButtonRight = useCallback(() => {
    return (
      <MenuOptions
        options={[
          new MenuOptionsModel(
            `${pontosVendaFormState.indStatus === 0 ? "Desativar" : "Ativar"} PDV`,
            <></>,
            changeStatus,
          ),
        ]}
      />
    );
  }, [pontosVendaFormState.indStatus, changeStatus]);

  const redirectToCadastro = useCallback(() => {
    abrirCadastroPontosVenda("", "", true);
  }, [abrirCadastroPontosVenda]);

  const handleSubmit = useCallback(
    async (model: PontosVendaEditFormModel, beforeModel: PontosVendaEditFormModel) => {
      setLoading(true)
      try {
        const pontosVendaEqual = isEqual(model, beforeModel);
        const hasConfigEditado = model.configuracoes.filter(x => x.editado);

        if (pontosVendaEqual && hasConfigEditado.length === 0) {
          showToast("info", "Nenhuma informação foi alterada");
          return;
        }

        if (!pontosVendaEqual) {
          await saveChangesPontosVenda(model);
        }

        if (hasConfigEditado.length > 0) {
          await saveChangesConfiguracoes(hasConfigEditado, props.id)
        }
        props.handleAtualizarLista(true)
        await preencherTela();
        showToast("success", "Ponto de Venda atualizado com Sucesso!",);
      } catch (e: any) {
        showToast("error", e.message);
      } finally {
        setLoading(false)
      }
    },
    [preencherTela, props, saveChangesConfiguracoes, saveChangesPontosVenda, showToast]
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroPontosVenda(props.callbackUrl, props.atualizarLista);
    props.handleAtualizarLista(false)
  }, [fecharCadastroPontosVenda, props]);

  return (
    <>
      <div className={classes.root}>
        {loading ? <CircularLoading tipo="FULLSIZED" /> : null}
        <ModalHeader
          title={"Edição de PDV"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
          rightArea={(props.callbackUrl === '/pontos-de-venda/' && !isAtivoWebPDV) && createButtonRight()}
        />

        <div className={classes.content}>
          <div className={classNames(classes.contentForms, loading ? classes.contentFormsLoading : undefined)}>
            <FormPontosVendaEdit
              ref={refEditForm}
              onSubmit={handleSubmit}
              showLoading={false}
              loading={loading}
              setLoading={setLoading}
              pdvId={props.id}
            />
          </div>
          <div className={classes.acoes}>
            <Grid container spacing={2} item>
              {props.callbackUrl === '/pontos-de-venda/' && (
                <Grid item xs={12} md={6}>
                  <Button
                    disabled={loading}
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={redirectToCadastro}
                  >
                    <NovoIcon tipo="BUTTON" />
                    Novo
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} md={props.callbackUrl !== '/pontos-de-venda/' ? 12 : 6}>
                <Button
                  disabled={loading}
                  onClick={() => refEditForm.current?.submitForm()}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default PontosVendaEdit;