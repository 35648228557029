import { ModalProps } from '../utils/modal-props';
import { DefaultModal } from "../components"
import { isEmpty } from 'lodash';
import { MesasReconfigurar } from './mesas-modal/mesas-reconfigurar/mesas-reconfigurar';
import { MesasEdit } from './mesas-modal/meas-edit/mesas-edit';
import { useCallback } from 'react';
import { EnumCadastrosMesas } from 'model/enums/enum-cadastros-mesas';
import { MesasAdicionar } from './mesas-modal/mesas-adicionar/mesas-adicionar';
import { GestaoStorageKeys, useGestaoStorage } from 'services/app';

interface MesasModalProps extends ModalProps {
    atualizarLista: boolean
    handleAtualizarLista: (value: boolean) => void
}

export const MesasModal = (props: MesasModalProps) => {

    const { getRegistro } = useGestaoStorage();
    const valor = getRegistro(GestaoStorageKeys.ModoCadastroMesa, false);

    const switchModoCadastro = useCallback(() => {
        switch (valor.modo) {
            case EnumCadastrosMesas.Reconfigurar:
                return (
                    <MesasReconfigurar
                        atualizarLista={props.atualizarLista}
                        handleAtualizarLista={props.handleAtualizarLista}
                        temMesa={valor.temMesa}
                        salaoId={valor.salaoId}
                    />
                );
            case EnumCadastrosMesas.Adicionar:
                return (
                    <MesasAdicionar
                        atualizarLista={props.atualizarLista}
                        handleAtualizarLista={props.handleAtualizarLista}
                        salaoId={valor.salaoId}
                    />
                );
        }
    }, [props.atualizarLista, props.handleAtualizarLista, valor.modo, valor.salaoId, valor.temMesa])

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={'temporary'}
            anchor="right"
        >
            {props.openned && isEmpty(props.id) && switchModoCadastro()}
            {props.openned && !isEmpty(props.id) &&
                <MesasEdit
                    atualizarLista={props.atualizarLista}
                    handleAtualizarLista={props.handleAtualizarLista}
                    id={props.id}
                    salaoId={valor.salaoId}
                />}
        </DefaultModal>
    )
}