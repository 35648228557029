import { useCallback, FocusEvent, useState, useEffect } from "react";
import { ProdutoMedidaModel } from "model/api/gestao/produto/produto-medida/produto-medida-model";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";
import { useGetProdutoMedidas } from "data/api/gestao/produto/produto-medida/get-produto-medidas";
import { useToastSaurus } from "services/app";
import { useSessaoAtual } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { DropCacheToUseProps, EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";

export interface AutocompleteMedidasProps extends DropCacheToUseProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  permiteAdicionar?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  primeiroValor?: boolean;
  fetchOnLoad?: boolean;
}

const maxLengthMedida = 6;

export const AutocompleteMedidas = ({
  onChange,
  loadingExterno,
  fetchOnLoad = true,
  useCache = true,
  propId,
  ...props
}: AutocompleteMedidasProps) => {
  const [medidasState, setMedidasState] = useState<Array<ProdutoMedidaModel>>(
    new Array<ProdutoMedidaModel>()
  );
  const { getProdutoMedidas, carregando: carregandoMedidas } = useGetProdutoMedidas();
  const { fetchData, removeCacheItem } = useDropCache<ApiListModel<ProdutoMedidaModel>>(getProdutoMedidas)
  const { getEmpresaSelecionada } = useSessaoAtual()
  const { showToast } = useToastSaurus()
  const { addHandler, removeHandler } = useEventTools();

  const getMedidasWrapper = useCallback(async (termo?: string) => {
    try {
      const empresaId = getEmpresaSelecionada()?.Id || '';
      const query = termo ? `?sigla=${termo}` : ''
      const ret = await fetchData({
        propId: propId ?? '',
        category: EnumDropCache.Medida,
        params: [query, empresaId, 1, 100],
        useCache: useCache,
        searchTerm: termo ?? '',
      });
      let medidas: Array<ProdutoMedidaModel> = new Array<ProdutoMedidaModel>();
      const resultado = ret?.data?.list ?? []
      if (resultado?.length > 0) {
        medidas = resultado as Array<ProdutoMedidaModel>;
      }
      if (!props.value && medidas.length > 0 && props.primeiroValor) {
        const medidaUn = medidas.filter(x => x.sigla === 'UN')
        const medidaInicial = medidaUn.length > 0 ? medidaUn[0] : medidas[0]
        const valor: retornoAutoComplete = {
          isNewVal: false,
          isString: false,
          value: medidaInicial
        }
        if (onChange) {
          onChange(valor)
        }
      }
      setMedidasState(medidas)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [fetchData, getEmpresaSelecionada, onChange, propId, props.primeiroValor, props.value, showToast, useCache]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const carregando = carregandoMedidas || loadingExterno
  useEffect(() => {
    if (fetchOnLoad) {
      getMedidasWrapper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNovaMedida = useCallback(({ medida }: { medida: ProdutoMedidaModel }) => {
    setMedidasState(prev => ([...prev, medida]))
    removeCacheItem(propId ?? '', EnumDropCache.Medida)
  }, [propId, removeCacheItem])

  useEffect(() => {
    addHandler(AppEventEnum.reloadActions, addNovaMedida)

    return () => {
      removeHandler(AppEventEnum.reloadActions, addNovaMedida)
    }
  }, [addHandler, addNovaMedida, removeHandler])

  const onPesquisa = (termo: string) => fetchOnLoad ? {} : getMedidasWrapper(termo)


  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      opcoes={medidasState}
      optionLabelKey="sigla"
      optionValueKey="sigla"
      onBlur={props.onBlur}
      value={props.value}
      permiteNovo={props.permiteAdicionar}
      allowSubmit={props.allowSubmit}
      textoNovoItem="Adicionar: "
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      onPesquisa={onPesquisa}
      maxLength={maxLengthMedida}
    />
  );
};
