import { useCallback, useEffect, useState, useRef } from "react";
import { NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { isEqual } from "utils/is-equal";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DefaultFormRefs } from "views/components/form/utils";
import { useToastSaurus, useCadastros } from "services/app";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { NcmEditFormModel } from "model/app/forms/ncm/ncm-edit-form-model";
import { useGetNcmById } from "data/api/gestao/ncm/get-ncm-by-id";
import { usePutNcm } from "data/api/gestao/ncm/put-ncm";
import { NcmModel } from "model/api/gestao/ncm/ncm-model";
import { FormNcmEdit } from "../../../../form/ncm/form-ncm-edit/form-ncm-edit";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import { toDecimal } from "utils/to-decimal";
import classNames from "classnames";
import { Button, Grid } from "views/design-system";
import { EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";

export const NcmEdit = (props: { id: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const { removeCategoryCache } = useDropCache()
  const classes = useModalStyles();
  const { fecharCadastroNcm, abrirCadastroNcm } = useCadastros();
  const { getNcmById, carregando: carregandoGet } = useGetNcmById();
  const { putNcm, carregando: carregandoPut } = usePutNcm();
  const [formInSubmit, setFormInSubmit] = useState(false);
  const carregando = carregandoPut || carregandoGet || preenchendoTela || formInSubmit;
  const [ncmFormState, setNcmForm] = useState<NcmEditFormModel>(new NcmEditFormModel());

  const refEditForm = useRef<DefaultFormRefs<NcmEditFormModel>>(null);
  const refNcmModel = useRef<NcmModel>(new NcmModel());

  const recarregarForm = useCallback((model: NcmEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(ncmFormState);
  }, [ncmFormState, recarregarForm]);

  const getNcmByIdWrapper = useCallback(async () => {
    const res = await getNcmById(props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as NcmModel;
    refNcmModel.current = ret;
    const ncm = picker<NcmEditFormModel>(ret, new NcmEditFormModel());

    return ncm;
  }, [props.id, getNcmById]);

  useEffect(() => {
    (async () => {
      try {
        setPreenchendoTela(true);
        let ncm = await getNcmByIdWrapper();
        setNcmForm(ncm);
        setPreenchendoTela(false);
      } catch (e: any) {
        showToast("error", e.message);
        setPreenchendoTela(false);
      }
    })();
    return () => {
      //funcao de limpeza
      setNcmForm(new NcmEditFormModel());
    };
  }, [showToast, getNcmByIdWrapper, setNcmForm]);

  const saveChangesNcm = useCallback(
    async (NcmModelForm: NcmEditFormModel) => {
      let ncm = picker<NcmModel>(NcmModelForm, refNcmModel.current);
      try {
        ncm.pTribManual = ncm.pTribManual !== null ? toDecimal(ncm.pTribManual, 2) : null;
        const ret = await putNcm(ncm);

        if (ret.erro) {
          throw ret.erro;
        }
        removeCategoryCache(EnumDropCache.Ncm)
        showToast("success", "Registro de NCM atualizado com sucesso!",);
        setNcmForm(NcmModelForm);
      } catch (e: any) {
        showToast("error", e.message);
        refEditForm.current?.resetForm();
      }
    },
    [putNcm, removeCategoryCache, showToast]
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroNcm("", "", true);
  }, [abrirCadastroNcm]);

  const handleSubmit = useCallback(
    async (model: NcmEditFormModel, beforeModel: NcmEditFormModel) => {
      const ncmEqual = isEqual(model, beforeModel);
      if (!ncmEqual) {
        saveChangesNcm(model);
      }

      if (ncmEqual) {
        showToast("info", "Nenhuma informação foi alterada");
      }
    },
    [saveChangesNcm, showToast]
  );
  const onCloseClick = useCallback(() => {
    fecharCadastroNcm();
  }, [fecharCadastroNcm]);

  const changeStatus = useCallback(async () => {
    try {
      let model = await getNcmByIdWrapper();
      model.ativo = !model.ativo;
      saveChangesNcm(model);
    } catch (e: any) {
      showToast("error", e.message);
    }
  }, [getNcmByIdWrapper, saveChangesNcm, showToast]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Edição de NCM"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          <MenuOptions
            options={[
              new MenuOptionsModel(
                `${ncmFormState.ativo ? "Desabilitar s" : "Habilitar"} NCM`,
                <></>,
                changeStatus
              ),
            ]}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormNcmEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default NcmEdit;
