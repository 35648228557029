
import { isEmpty } from "lodash";

import { DefaultModal } from 'views/components/modals/components/default-modal';
import { ModalProps } from 'views/components/modals/utils/modal-props';
import FinalizadoraCadastro from "./components/finalizadora-cadastro/finalizadora-cadastro";
import FinalizadoraEdit from "./components/finalizadora-edit/finalizadora-edit";

export interface FinalizadoraModalProps extends ModalProps {
  atualizarDb?: boolean
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const FinalizadoraModal = (props: FinalizadoraModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) &&
        <FinalizadoraCadastro handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      }
      {props.openned && !isEmpty(props.id) &&
        <FinalizadoraEdit
          id={props.id}
          callbackUrl={props.aux ?? ''}
          atualizarDb={props.atualizarDb}
          atualizarLista={props.atualizarLista}
          handleAtualizarLista={props.handleAtualizarLista}
        />
      }
    </DefaultModal>
  );
};
