import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Grid } from 'views/design-system';
import { useStyles } from './novo-pagamento-list-styles';
import NovoPagamentoListData from './novo-pagamento-list-data';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import {
  CredenciamentoSafra,
  FinalizadoraModel
} from 'model/api/gestao/finalizadora/finalizadora-model';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useLocation } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useConfirm } from 'material-ui-confirm';
import { isEmpty } from 'lodash';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import PagamentoParcialEfetuarFragment from '../pagamento-parcial/pagamento-parcial-efetuar-fragment/pagamento-efetuar-fragment';
import { roundTo } from 'utils/round-to';
import { stringNumeros } from 'utils/string-numeros';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { ConvenioCartaoAutenticarModel } from 'model/api/gestao/convenio';
import { newGuid } from 'utils/new-guid';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { EnumTpTroco } from 'model/enums/enum-tp-troco';
import { validarPagamentoComposto } from 'utils/tp-pagamento-resolver';
export interface InserirPagamentoParamsProps {
  paymentMethod: FinalizadoraModel
  valorPaga?: number
  numeroParcelas?: number
  responseCredito?: ConvenioCartaoAutenticarModel
}

export const NovoPagamentoList = () => {
  // PROVIDERS
  const {
    getMov,
    carregando: carregandoMov,
    saveQtdPessoasPagamento,
    getQtdPessoasPagamento,
    iniciarPagamento,
  } = useMovAtual();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const { abrirParcelamento, abrirConvenioAutenticar } = useCadastros();
  const { redirectProcessarPagamento, avancarFluxoMov, redirectSelecionarCliente, redirectCarrinho } = useMovRota();
  const { getConsumidor } = useCadastroPadrao()

  // STATES E REFS
  const vDigitado = useRef<number>(0);
  const nroParcelas = useRef<number>(0);
  const payment = useRef<FinalizadoraModel>(new FinalizadoraModel());
  const valorDigitadoConfirmado = useRef<number>(0);
  const location = useLocation();
  const { pessoas } = getQtdPessoasPagamento();
  const [qtdePessoas, setQtdePessoas] = useState<number>(
    typeof pessoas === 'number' ? pessoas : 1
  );
  const confirm = useConfirm();
  const [modalEditAberto, setModalEditAberto] = useState(false);
  const digitado = useRef<number>(0);
  const { abrirCadastroFinalizadora, abrirPagamentoRealizado } = useCadastros();
  const { getConfigByCod } = useEmpresaAtual()
  const modeloAutenticacao = getConfigByCod(EnumEmpresaConfig.ModeloAutenticacao)

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: null as Array<FinalizadoraModel> | null
  });
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const mov = getMov();

  const vTotal = useMemo(() => {
    const movAtual = getMov();
    const pagAntecipado = movAtual?.informacoesGeraisPedido.pagAntecipado

    if (isEmpty(pagAntecipado)) {
      return movAtual?.vNF
    }

    return pagAntecipado.vTotal
  }, [getMov])

  const vPago = useCallback(() => {
    const movAtual = getMov();
    const pagAntecipado = movAtual?.informacoesGeraisPedido.pagAntecipado

    if (isEmpty(pagAntecipado)) {
      return movAtual?.vPago
    }
    return pagAntecipado.vPago
  }, [getMov])

  const valorAPagar = useRef<number>(
    valorDigitadoConfirmado.current === 0
      ? qtdePessoas > 1
        ? ((vTotal ?? 0) - (vPago() ?? 0)) / qtdePessoas
        : (vTotal ?? 0) - (vPago() ?? 0)
      : valorDigitadoConfirmado.current
  );


  // AUX
  const carregando = carregandoMov;
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);
    addHandler(AppEventEnum.AtualizarQuantidadeDePessoas, setQtdePessoas);
    addHandler(AppEventEnum.MovAtualAlterada, setQtdePessoas);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true)
      removeHandler(AppEventEnum.AtualizarQuantidadeDePessoas, setQtdePessoas);
    };
  }, [callEvent, addHandler, removeHandler]);

  //VALIDACAO DE ENTRADA NA TELA
  useEffect(() => {
    const informacoesPedido = mov?.informacoesGeraisPedido
    if (mov && (mov.vNF <= 0 || mov.vNF === undefined) && isEmpty(informacoesPedido?.pagAntecipado)) {
      showToast(
        'info',
        'Esta venda não possui valor para realizar o pagamento.'
      );
      avancarFluxoMov();
    }
  }, [avancarFluxoMov, getMov, mov, showToast]);

  const inserirPagamento = useCallback(
    async ({
      paymentMethod,
      valorPaga = 0,
      numeroParcelas = 0,
      responseCredito
    }: InserirPagamentoParamsProps) => {
      try {

        const pagamento: MovSimplesPagamentoModel = {
          adquirente: '',
          bandeira: '',
          nomeCartao: '',
          numCartao: '',
          tid: newGuid(),
          tpTransacao: paymentMethod.tpTransacao,
          modPagamento: paymentMethod.tpMod,
          nParcelas: numeroParcelas > 0 ? numeroParcelas : nroParcelas.current,
          dhTransacao: new Date(),
          cAut: '',
          codNsu: '',
          descontoAplicado: 0,
          acrescimoAplicado: 0,
          vPag: valorPaga === 0 ? valorAPagar.current : valorPaga,
          envioAPI: '',
          retornoAPI: '',
          status: 0,
          viaCliente: '',
          viaLojista: '',
          pagamentoId: paymentMethod.id,
          pagamentoNome: paymentMethod.descricao,
          vTroco: 0,
          tpTroco: EnumTpTroco.Fisico,
          cnpj: paymentMethod.cnpjCred,
          cancelado: false,
          tefId: '',
          pedidoId: '',
        };

        const credenciais = paymentMethod.credenciais
          ? JSON.parse(paymentMethod.credenciais.replaceAll("'", '"'))
          : new CredenciamentoSafra();

          const modComposto = validarPagamentoComposto(pagamento.modPagamento, pagamento.tpTransacao);

        if (paymentMethod.tpMod === EnumPagTpMod.CREDITO_LOJA && !modComposto) {
          if (modeloAutenticacao === EnumModeloAutenticacao.DocumentoCliente) {

            const pessoaId = mov?.cliente?.id ?? ''
            const consumidorPadrao = await getConsumidor()

            const dadosConvenio = {
              modoPagamento: pagamento,
              credenciais,
              credenciado: paymentMethod.credenciado,
              loginConvenio: {
                ...new ConvenioCartaoAutenticarModel(),
                pessoaId: pessoaId
              }
            }

            if (pessoaId === consumidorPadrao?.id) {
              await redirectSelecionarCliente({
                convenio: dadosConvenio
              })
              return
            }

            await redirectProcessarPagamento(dadosConvenio)
            return
          }
          abrirConvenioAutenticar('venda', {
            pagamento,
            credenciais
          })
          return
        }

        if (paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY) {
          await redirectProcessarPagamento({
            modoPagamento: pagamento,
            credenciais,
            credenciado: paymentMethod.credenciado,
          });
          return;
        }
        await iniciarPagamento({
          pagamento,
          naoAlteraTaxa: true,
          credenciamento: credenciais,
          credenciado: paymentMethod.credenciado
        });

        vDigitado.current = 0;
        valorDigitadoConfirmado.current = 0;
        nroParcelas.current = 0;

        const novaMov = getMov();

        if (novaMov!.vPago >= novaMov!.vNF) {
          setQtdePessoas(1);
          if (!isEmpty(novaMov?.informacoesGeraisPedido.pagAntecipado)) {
            abrirPagamentoRealizado()
            return
          }
          avancarFluxoMov();
        } else {
          let novaqtde = qtdePessoas;

          if (qtdePessoas - 1 > 0) {
            novaqtde = qtdePessoas - 1;
            setQtdePessoas(novaqtde);
            saveQtdPessoasPagamento(novaqtde);
          }

          let proximoPagamento = toDecimal(
            ((novaMov?.vNF ?? 0) - (novaMov?.vPago ?? 0)) / novaqtde,
            2
          );

          valorAPagar.current = proximoPagamento;
          showToast('success', `Pagamento parcial realizado!`);
        }
      } catch (err: any) {
        vDigitado.current = 0;
        valorDigitadoConfirmado.current = 0;
        nroParcelas.current = 0;
        showToast('error', err.message);
      }
    },
    [iniciarPagamento, getMov, modeloAutenticacao, abrirConvenioAutenticar, mov?.cliente?.id, getConsumidor, redirectProcessarPagamento, redirectSelecionarCliente, avancarFluxoMov, abrirPagamentoRealizado, qtdePessoas, showToast, saveQtdPessoasPagamento]
  );

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const search = useCallback(async () => {
    try {

      const res = await TouchoneDBPrimary.finalizadoras.toArray();
      fillResult(1, 1, res.length, res as FinalizadoraModel[]);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [showToast, fillResult]);

  const handleCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      try {
        // Parcelamento do pagamento
        if (valorAPagar.current === 0) {
          throw new Error(
            'Não é possível realizar um pagamento com valor zerado.'
          );
        }
        const arrayParcelamento = () => {
          const arrayParcelamento: number[] = [];
          for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
            arrayParcelamento.push(i);
          }
          return arrayParcelamento;
        };

        const arrayParc = arrayParcelamento().filter((item: number) => {
          return valorAPagar.current / item >= paymentMethod.vMinParc;
        });

        if (
          paymentMethod.qMaxParc > 1 &&
          paymentMethod.vMinParc < (vTotal ?? 0) - (vPago() ?? 0) &&
          arrayParc.length !== 1
        ) {
          const parapagar = valorAPagar.current;

          abrirParcelamento(
            async (parcelas: number) => {
              await inserirPagamento({
                paymentMethod,
                valorPaga: parapagar,
                numeroParcelas: parcelas
              });
              callEvent(AppEventEnum.AlterarDisplayKeybordPayment, { valor: stringNumeros(toDecimalString(valorAPagar.current)) });
            },
            qtdePessoas,
            parapagar,
            paymentMethod
          );
        } else {
          await inserirPagamento({ paymentMethod });
          callEvent(AppEventEnum.AlterarDisplayKeybordPayment, { valor: stringNumeros(toDecimalString(valorAPagar.current)) });
        }
      } catch (e: any) {
        showToast('error', e.message);
      } finally {
        callEvent(AppEventEnum.PagamentoEfetuado, true);
      }
    },
    [
      vTotal,
      vPago,
      abrirParcelamento,
      qtdePessoas,
      inserirPagamento,
      callEvent,
      showToast
    ]
  );

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      payment.current = paymentMethod;
      if (
        paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY &&
        VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR
      ) {
        const credenciais: CredenciamentoSafra | null =
          paymentMethod.credenciais
            ? JSON.parse(paymentMethod.credenciais)
            : null;

        if (
          !credenciais ||
          isEmpty(credenciais.merchantToken) ||
          isEmpty(credenciais.codigoAtivacao)
        ) {
          confirm({
            title: 'TEF Detectado',
            description:
              'Aparentemente você está com um dispositivo TEF conectado ao seu aparelho, porém a sua Forma de Pagamento não tem credenciais para usá-la, deseja configurar?',
            confirmationText: 'Configurar',
            cancellationText: 'Usar outra forma'
          }).then(() =>
            abrirCadastroFinalizadora(
              paymentMethod.id,
              location.pathname,
              false,
              true
            )
          );
          return;
        }
      }
      await handleCardClicked(paymentMethod);
    },
    [abrirCadastroFinalizadora, confirm, handleCardClicked, location.pathname]
  );

  const onCardChecked = useCallback(
    (model: FinalizadoraModel) => {
      const aux = [...selectedList];
      aux.push(model.id);
      setSelectedList(aux);
    },
    [selectedList]
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  const modalEditCredenciar = useCallback(({ aberto }: any) => {
    setModalEditAberto(aberto);
  }, []);

  const modalEditDesconto = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  const updateValor = useCallback(() => {
    valorAPagar.current =
      valorDigitadoConfirmado.current === 0
        ? qtdePessoas > 1
          ? ((vTotal ?? 0) - (vPago() ?? 0)) /
          qtdePessoas
          : (vTotal ?? 0) - (vPago() ?? 0)
        : valorDigitadoConfirmado.current;

    callEvent(AppEventEnum.AlterarDisplayKeybordPayment, { valor: stringNumeros(toDecimalString(valorAPagar.current)) });
  }, [callEvent, qtdePessoas, vPago, vTotal]);

  useEffect(() => {
    (async () => {
      if (!modalEditAberto) {
        await search();
      }
    })();
    addHandler(AppEventEnum.FinalizadoraModal, modalEdit);
    addHandler(AppEventEnum.DialogCredenciarPix, modalEditCredenciar);
    addHandler(AppEventEnum.DialogAcrescimoDesconto, modalEditDesconto);

    return () => {
      removeHandler(AppEventEnum.FinalizadoraModal, modalEdit);
      removeHandler(AppEventEnum.DialogCredenciarPix, modalEditCredenciar);
      removeHandler(AppEventEnum.DialogAcrescimoDesconto, modalEditDesconto);
    };
  }, [
    addHandler,
    modalEdit,
    modalEditAberto,
    modalEditCredenciar,
    modalEditDesconto,
    removeHandler,
    search
  ]);

  useEffect(() => {
    if (!modalEditAberto) {
      updateValor();
    }
  }, [modalEditAberto, updateValor]);

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        digitado.current = toDecimal(formattedText, 2);
        valorAPagar.current = toDecimal(formattedText, 2);
        return true;
      } catch (e: any) {
        showToast(
          'error',
          'Erro ao inserir o valor avulso. Detalhe: ' + e.message
        );
        return false;
      }
    },
    [showToast]
  );

  useEffect(() => {
    const redirect = async (e: any) => {
      e.preventDefault();
      await redirectCarrinho();
    }
    document.addEventListener('backbutton', redirect)
    return () => {
      document.removeEventListener('backbutton', redirect)
    }
  }, [redirectCarrinho])

  return (
    <>
      {(carregando) && <CircularLoading tipo="FULLSIZED" />}

      <Grid flex flexDirection="column" className={classes.root}>
        <Grid className={classes.keyboardArea} style={{ flex: 1 }}>
          <PagamentoParcialEfetuarFragment
            valorDigitadoConfirmadoRef={valorDigitadoConfirmado}
            vRestante={(vTotal ?? 0) - (vPago() ?? 0)}
            vPago={vPago() ?? 0}
            vPessoa={toDecimal(
              roundTo(((vTotal ?? 0) - (vPago() ?? 0)) / qtdePessoas, 2),
              2
            )}
            textChanged={textChanged}
          />
        </Grid>

        <Grid className={classes.buttonContainer}>
          <NovoPagamentoListData
            carregando={carregando}
            list={queryStatus.list}
            selectedList={selectedList}
            onCardClicked={onCardClicked}
            onCardChecked={onCardChecked}
            paymentScreen
          />
        </Grid>
      </Grid>
    </>
  );
};
