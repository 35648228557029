import { FormControlLabel } from '@material-ui/core';
import { Grid, Typography } from 'views/design-system';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useState } from 'react';
import { useStyles } from './card-produto-subitem-modificador-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { AddESub } from '../card-produto-subitem/components/add-e-sub/button-add-e-sub';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { MovSimplesModificadoresProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-modificadores-produto-model';
import { EnumTpCalculoModificador } from 'model/enums/enum-tpcalculo-modificador';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { DefaultCard } from '../components';
import { isEmpty } from 'lodash';

type CardSubitemModificadorProps = {
  produto: MovSimplesProdutoModel;
  handleClickAddOrSub: (
    tpProduto: EnumTpProduto,
    valorProdutoPai: number,
    produto: MovSimplesProdutoModel,
    qtd: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    prodGradeId?: string,
    fator?: 'add' | 'sub'
  ) => Promise<void>;
  verificar?: boolean;
  showValue?: boolean;
  edit?: boolean;
  qtdPai: number;
  modificador: MovSimplesModificadoresProdutoModel;
  qtdModificador: number;
  valorProdutoPai?: number;
  tpProdutoPai: EnumTpProduto;
  listaSubItens: MovSimplesProdutoModel[]
};

export const CardSubitemModificador = ({
  produto,
  handleClickAddOrSub,
  verificar,
  showValue,
  edit,
  qtdPai,
  modificador,
  qtdModificador,
  valorProdutoPai = 0,
  tpProdutoPai,
  listaSubItens
}: CardSubitemModificadorProps) => {
  // STATES E REFS
  const [qtd, setQtd] = useState<number>(produto.qCom);

  const isRadio = modificador.qMin === 1 && modificador.qMax === 1;
  const listaFiltrada = listaSubItens?.filter(x => x.qCom > 0)
  const retornaProdutoComMaiorValor = useCallback(() => {
    if (listaFiltrada.length > 0) {
      const produtoComMaiorValor = listaFiltrada.reduce((prev, current) => prev.vUnComOrig > current.vUnComOrig ? prev : current, produto)
      return produtoComMaiorValor
    }
    return produto
  }, [listaFiltrada, produto])
  const totalQtdSubitens = listaFiltrada.reduce((acc, atual) => toDecimal((atual.qCom / qtdModificador), 2) + acc, 0)
  const sobra = 1 - totalQtdSubitens


  // AUX
  const classes = useStyles();

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  if (edit && verificar && produto.qCom < 0) {
    return null;
  }

  const tipoDeProduto = (tipo: EnumTpProduto) => {
    const tipoModificado = tipo as unknown as EnumTipoProduto
    if (tipoModificado === EnumTipoProduto.Produto) {
      return EnumTpProduto.ProdutoComSubItem
    }
    return EnumTpProduto.Combo
  }
  const tpProduto = tipoDeProduto(tpProdutoPai)
  const retornaValor = (tipo: EnumTpProduto, produto: MovSimplesProdutoModel, modificador: MovSimplesModificadoresProdutoModel, radio: boolean) => {
    switch (tipo) {
      case EnumTpProduto.Combo:
        if (radio && produto.vUnCom - modificador.valorItemPadrao === 0) {
          return ''
        }
        return `Preço: R$ ${toDecimalString((radio ? produto.vUnComOrig - modificador.valorItemPadrao
          : produto.vUnComOrig) ?? 0, 2)}`;
      case EnumTpProduto.ProdutoComSubItem: return `Preço: R$ ${toDecimalString((produto.vUnComOrig) ?? 0, 2)}`
    }
  }
  const retornaSubTotalDeCadaProduto = (tipoDeCalculo: number | null) => {
    const possuiQtdPadrao = produto.infoSubItem!.qPadrao >= qtd

    const multiplicadorPadrao = possuiQtdPadrao ? qtd - produto.infoSubItem!.qPadrao : qtd;

    const qtdFracionada = produto.id === retornaProdutoComMaiorValor().id
      ? toDecimal((multiplicadorPadrao / qtdModificador), 2) + sobra
      : toDecimal((multiplicadorPadrao / qtdModificador), 2)
    const valorRateado = qtdFracionada * valorProdutoPai
    switch (tipoDeCalculo) {
      case EnumTpCalculoModificador.Maior:
        return `+ R$ ${toDecimalString(qtdFracionada * valorProdutoPai, 2)}`
      case EnumTpCalculoModificador.Rateia:
        return `+ R$ ${toDecimalString(valorRateado)}`
      default: return `+ R$ ${toDecimalString(multiplicadorPadrao * produto.vUnCom)}`
    }
  }
  const tpDeCalculoDoModificador = () => modificador.tpCalculo !== EnumTpCalculoModificador.Soma

  return (
    <DefaultCard>
      {/* {produto.subItens && produto.subItens.length > 0 && (
        <Typography
          variant="caption"
          className={classes.textInfoPersonalizavel}
        >
          Personalizável
        </Typography>
      )} */}

      <Grid className={classes.cardContainer}>
        <Grid style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {img(produto.imgUrl)}

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 4,
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: '600', fontSize: '14px' }}
            >
              {produto.xProd}
            </Typography>
            {!isEmpty(produto.infAdic) && <Typography
              variant="body1"
              style={{ fontWeight: 400, fontSize: '12px' }}
            >
              {produto.infAdic}
            </Typography>}
            <Grid container flexDirection="column">
              {!((produto?.infoSubItem?.qPadrao ?? 0) > 0) && <Typography
                variant="body1"
                style={{ fontWeight: '400', fontSize: '12px' }}
              >
                {retornaValor(tpProduto, produto, modificador, isRadio)} {produto.uCom}
              </Typography>}
              <Typography
                variant="body1"
                style={{ fontSize: '12px', height: '14.4px' }}
              >
                <span className={verificar && showValue ? '' : classes.invisible}>
                  {retornaSubTotalDeCadaProduto(modificador.tpCalculo)}
                </span>
                <span className={produto.infoSubItem!.qPadrao >= qtd || verificar ? classes.invisible : ''}>
                  {retornaSubTotalDeCadaProduto(modificador.tpCalculo)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isRadio ? (
          <FormControlLabel
            label=""
            checked={
              modificador.produtoSubGradeIdPadrao === produto.produtoGradeId
            }
            onClick={() => {
              handleClickAddOrSub(
                tpProdutoPai,
                valorProdutoPai,
                produto,
                1,
                modificador,
                produto?.produtoGradeId ?? ''
              );
            }}
            control={<SaurusRadio />}
          />
        ) : (
          <Grid style={{ display: 'flex', justifyContent: 'center' }}>
            <AddESub
              edit={edit}
              qtd={qtd}
              setQtd={setQtd}
              model={produto}
              alterarQtdeProduto={handleClickAddOrSub}
              verificar={verificar}
              modificador={modificador}
              qtdModificador={qtdModificador}
              valorProdutoPai={valorProdutoPai}
              tpProdutoPai={tpProdutoPai}
              valorFracionado={tpDeCalculoDoModificador()}
            />
          </Grid>
        )}
      </Grid>
    </DefaultCard>
  );
};
