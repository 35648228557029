import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: '100%'
  },
  textInfo: {
    marginBottom: theme.spacing(2)
  },
  buttonQuantidade: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonChanged: {
    width: '55px',
    height: '55px',
    padding: '0!important',
    borderRadius: '50%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMaisMenos: {
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(1)
  },
  textPerson: {
    fontSize: '35px',
    padding: 0,
    lineHeight: '10px',
    fontWeight: 700,
    textAlignLast: 'center',
    width: '100px',
  },
  cardImg: {
    display: "flex",
    objectFit: "cover",
    zIndex: 0,
    position: 'relative',
    background: theme.palette.common.white,
    width: "75px",
    height: "75px ",
    borderRadius: "5px",
  },
  cardImgInfo: {
    display: "flex",
    objectFit: "cover",
    zIndex: 0,
    position: 'relative',
    background: theme.palette.common.white,
    width: "40px",
    height: "40px ",
    borderRadius: "5px",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary}`,
  },
  titulo: {
    fontWeight: 700,
    fontSize: '1.25rem',
    marginBottom: theme.spacing(3)
  },
  badgeInfoModificador: {
    minWidth: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    background: theme.palette.primary.main,
    "& span": {
      fontSize: 11,
    }
  },
  containerSubItens: {
    background: theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  container: {
    overflow: 'hidden'
  },
  cardSubitensContainer: {
    flex: 1,
    height: '60%',
    overflowY: 'auto'
  },
  dadosDoProduto: {
    flex: 1
  },
  produtoText: {
    fontSize: '18px',
    marginLeft: 8,
    lineHeight: '18px',
    marginBottom: '8px'
  },
  produtoLabel:{
    fontSize: '10px',
    marginLeft: 8
  },
  productContent:{
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1
  }
}));
