import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { CardComanda } from 'views/components/cards/card-comanda/card-comanda';
export interface ComandasListDataProps {
  comandas: Array<ComandasModel>;
  carregando: boolean;
  selectedList: Array<ComandasModel>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const ComandasListData = (props: ComandasListDataProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.comandas.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Comanda encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.comandas.length > 0 &&
        props.comandas.map((comanda) => {
          const key = `comandas-list-${comanda.id}`
          return (
            <CardComanda
              model={comanda}
              key={key}
              onClick={onCardSelected}
              onCheck={onCardChecked}
              selected={false}
            />
          );
        })}
    </>
  );
};
