import classNames from "classnames"
import { GridProps } from "./grid-props";
import { useStyles } from "./grid-styles";
import './grid.css'
import { useFlexs } from "../styles/flex/flex";
import { forwardRef } from "react";
import { useThemeQueries } from "views/theme";

export const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    const styles = useStyles();
    const flex = useFlexs(props);
    const { theme } = useThemeQueries();
    const xs = props.xs === 'auto' ? styles.tamanhoXsAuto :
        props.xs === 1 ? styles.tamanhoXs1 :
            props.xs === 2 ? styles.tamanhoXs2 :
                props.xs === 3 ? styles.tamanhoXs3 :
                    props.xs === 4 ? styles.tamanhoXs4 :
                        props.xs === 5 ? styles.tamanhoXs5 :
                            props.xs === 6 ? styles.tamanhoXs6 :
                                props.xs === 7 ? styles.tamanhoXs7 :
                                    props.xs === 8 ? styles.tamanhoXs8 :
                                        props.xs === 9 ? styles.tamanhoXs9 :
                                            props.xs === 10 ? styles.tamanhoXs10 :
                                                props.xs === 11 ? styles.tamanhoXs11 :
                                                    props.xs === 12 ? styles.tamanhoXs12 :
                                                        props.xs ? styles.tamanhoXsTrue :
                                                            undefined;

    const sm = props.sm === 'auto' ? styles.tamanhoSmAuto :
        props.sm === 1 ? styles.tamanhoSm1 :
            props.sm === 2 ? styles.tamanhoSm2 :
                props.sm === 3 ? styles.tamanhoSm3 :
                    props.sm === 4 ? styles.tamanhoSm4 :
                        props.sm === 5 ? styles.tamanhoSm5 :
                            props.sm === 6 ? styles.tamanhoSm6 :
                                props.sm === 7 ? styles.tamanhoSm7 :
                                    props.sm === 8 ? styles.tamanhoSm8 :
                                        props.sm === 9 ? styles.tamanhoSm9 :
                                            props.sm === 10 ? styles.tamanhoSm10 :
                                                props.sm === 11 ? styles.tamanhoSm11 :
                                                    props.sm === 12 ? styles.tamanhoSm12 :
                                                        props.sm ? styles.tamanhoSmTrue :
                                                            undefined;

    const md = props.md === "auto" ? styles.tamanhoMdAuto :
        props.md === 1 ? styles.tamanhoMd1 :
            props.md === 2 ? styles.tamanhoMd2 :
                props.md === 3 ? styles.tamanhoMd3 :
                    props.md === 4 ? styles.tamanhoMd4 :
                        props.md === 5 ? styles.tamanhoMd5 :
                            props.md === 6 ? styles.tamanhoMd6 :
                                props.md === 7 ? styles.tamanhoMd7 :
                                    props.md === 8 ? styles.tamanhoMd8 :
                                        props.md === 9 ? styles.tamanhoMd9 :
                                            props.md === 10 ? styles.tamanhoMd10 :
                                                props.md === 11 ? styles.tamanhoMd11 :
                                                    props.md === 12 ? styles.tamanhoMd12 :
                                                        props.md ? styles.tamanhoMdTrue :
                                                            undefined;

    const lg = props.lg === "auto" ? styles.tamanhoLgAuto :
        props.lg === 1 ? styles.tamanhoLg1 :
            props.lg === 2 ? styles.tamanhoLg2 :
                props.lg === 3 ? styles.tamanhoLg3 :
                    props.lg === 4 ? styles.tamanhoLg4 :
                        props.lg === 5 ? styles.tamanhoLg5 :
                            props.lg === 6 ? styles.tamanhoLg6 :
                                props.lg === 7 ? styles.tamanhoLg7 :
                                    props.lg === 8 ? styles.tamanhoLg8 :
                                        props.lg === 9 ? styles.tamanhoLg9 :
                                            props.lg === 10 ? styles.tamanhoLg10 :
                                                props.lg === 11 ? styles.tamanhoLg11 :
                                                    props.lg === 12 ? styles.tamanhoLg12 :
                                                        props.lg ? styles.tamanhoLgTrue :
                                                            undefined;

    const xl = props.xl === "auto" ? styles.tamanhoXlAuto :
        props.xl === 1 ? styles.tamanhoXl1 :
            props.xl === 2 ? styles.tamanhoXl2 :
                props.xl === 3 ? styles.tamanhoXl3 :
                    props.xl === 4 ? styles.tamanhoXl4 :
                        props.xl === 5 ? styles.tamanhoXl5 :
                            props.xl === 6 ? styles.tamanhoXl6 :
                                props.xl === 7 ? styles.tamanhoXl7 :
                                    props.xl === 8 ? styles.tamanhoXl8 :
                                        props.xl === 9 ? styles.tamanhoXl9 :
                                            props.xl === 10 ? styles.tamanhoXl10 :
                                                props.xl === 11 ? styles.tamanhoXl11 :
                                                    props.xl === 12 ? styles.tamanhoXl12 :
                                                        props.xl ? styles.tamanhoXlTrue :
                                                            undefined;

    const spacing = props.container ?
        props.spacing === 1 ? styles.spacing1 :
            props.spacing === 2 ? styles.spacing2 :
                props.spacing === 3 ? styles.spacing3 :
                    props.spacing === 4 ? styles.spacing4 :
                        props.spacing === 5 ? styles.spacing5 :
                            props.spacing === 6 ? styles.spacing6 :
                                props.spacing === 7 ? styles.spacing7 :
                                    props.spacing === 8 ? styles.spacing8 :
                                        undefined : undefined;

    return <div ref={ref} className={classNames(
        props.container ? styles.container : undefined,
        props.item ? styles.item : undefined,
        props.item ? "grid-item" : undefined,
        flex.getFlex(),
        flex.getAlignItems(), flex.getFlexDirection(), flex.getFlexWrap(), flex.getJustifyContent(),
        xs, sm, md, lg, xl, spacing,
        props.className,
    )}

        style={{
            gap: props.gap ? theme.spacing(props.gap) : undefined,

            paddingTop: props.pt !== undefined ? theme.spacing(props.pt) : props.py !== undefined ? theme.spacing(props.py) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingRight: props.pr !== undefined ? theme.spacing(props.pr) : props.px !== undefined ? theme.spacing(props.px) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingBottom: props.pb !== undefined ? theme.spacing(props.pb) : props.py !== undefined ? theme.spacing(props.py) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingLeft: props.pl !== undefined ? theme.spacing(props.pl) : props.px !== undefined ? theme.spacing(props.px) : props.p !== undefined ? theme.spacing(props.p) : undefined,

            marginTop: props.mt !== undefined ? theme.spacing(props.mt) : props.my !== undefined ? theme.spacing(props.my) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginRight: props.mr !== undefined ? theme.spacing(props.mr) : props.mx !== undefined ? theme.spacing(props.mx) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginBottom: props.mb !== undefined ? theme.spacing(props.mb) : props.my !== undefined ? theme.spacing(props.my) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginLeft: props.ml !== undefined ? theme.spacing(props.ml) : props.mx !== undefined ? theme.spacing(props.mx) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            ...props.style
        }} id={props.id}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
        onTouchMove={props.onTouchMove}
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick} >
        {props.children}
    </div >


});