import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, ProcurarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "views/design-system";
import { isEmpty } from "lodash";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";

export const PontosHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { abrirPDVFiltroModal } = useFiltrosModais();

    const history = useHistory();
    const location = useLocation()

    const [openTextfield, setOpenTextfield] = useState<boolean>(true);

    const urlParams = new URLSearchParams(location.search)
    const filtros = {
        status: urlParams.get('status'),
        termo: urlParams.get('termo')
    }

    const leftArea = useCallback(() => (
        isMobile ?
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            /> :
            null
    ),
        [isMobile, abrirMenu]
    );

    const rightArea = useCallback(
        () => (
            <Box flex gap={2}>
                {!openTextfield && <ButtonPrivateHeader
                    icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
                    tooltip={'Abrir Pesquisa'}
                    onClick={() => setOpenTextfield(true)}
                ></ButtonPrivateHeader>}
                <ButtonPrivateHeader
                    icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
                    tooltip={'Filtros'}
                    onClick={() => {
                        abrirPDVFiltroModal({
                            status: !isEmpty(filtros.status) ? Number(filtros.status) : 0,
                            termo: filtros.termo || ''
                        })
                    }}
                ></ButtonPrivateHeader>
            </Box>
        ),
        [abrirPDVFiltroModal, filtros.status, filtros.termo, openTextfield]
    );

    const searchTextfield = (
        <FormPesquisaHeader
            text={filtros.termo || ''}
            closeSearch={() => setOpenTextfield(false)}
            onSubmit={(model) => {
                const searchs: Array<string | null> = [
                    isEmpty(filtros.status) ? '' : `status=${filtros.status}`,
                    model.generico.length > 0 ? `generico=${model.generico}` : null,
                ]

                let query = ''

                searchs
                    .filter(x => x)
                    .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

                history.push({
                    pathname: '/pontos-de-venda',
                    search: query
                })
            }}
            placeholder="Buscar PDVs pelo nome ou número..."
        />
    )

    return (
        <>
            <PrivatePageHeader
                title="Pontos de Venda"
                leftArea={leftArea()}
                middleArea={openTextfield && searchTextfield}
                rightArea={rightArea()}
            />
        </>
    );
};
