import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useCriptografia } from 'services/app/hooks/criptografia';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { ValeSearchModel } from 'model/api/gestao/troca/vale-response';

export function usePostValeSearch() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { criptografar } = useCriptografia();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getPDV } = usePDV();

    const postValeSearch = useCallback(
        async (search: ValeSearchModel) => {

            const str = getPDV()?.id + '|' + getEmpresaAtual()?.cpfcnpj + '|EMPTY';
            const auth = criptografar(str)
            return invocarApi({
                url: `/vale/search`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': String(auth),
                },
                data: JSON.stringify(search),
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.apiTroca}/api`,
            })
        },
        [criptografar, getEmpresaAtual, getPDV, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postValeSearch,
    };
}