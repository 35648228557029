import { guidEmpty } from 'utils/guid-empty';
import { EnumBalanca } from '../../../../enums/enum-balanca';



export class ProdutoAtualizarVariacaoModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public medidaEntradaId: string = guidEmpty(),
    public medidaSaidaId: string = guidEmpty(),
    public grade: string = "",
    public codigo: string | null = null,
    public qMinimo: number = 0,
    public qMaximo: number = 0,
    public qSaldoTotal: number = 0,
    public tabelaPrecoId: string = guidEmpty(),
    public vPreco: number = 0,
    public vCompra: number = 0,
    public infAdic: string = "",
    public ativo: boolean = true,
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public codigoAnvisa: string | null = null,
  ) { }
}


