import { EnumPagamentosCompostos, TpModMock, tpModMockComposto } from "data/mocks";
import { EnumPagTpMod, EnumPagTpTransacao } from "model";


export function tpPagamentoResolver(
    tpMod: EnumPagTpMod,
    tptransacao: EnumPagTpTransacao
) {
    let value = tpModMockComposto.find(x => {
        return (
            x.Key.tpTransacao === tptransacao &&
            x.Key.tpMod === tpMod
        )
    });

    if (value) {
        return value;
    }

    value = TpModMock.find(x => {
        return x.Key === tpMod;
    })

    return value;
}

export const validarPagamentoComposto = (
    tpMod: EnumPagTpMod,
    tpTransacao: EnumPagTpTransacao,
): EnumPagamentosCompostos | false => {
    const value = tpModMockComposto.find(mod => mod.Key.tpTransacao === tpTransacao && mod.Key.tpMod === tpMod);
    if(!value) return false;

    const valor = value.Value as EnumPagamentosCompostos;

    return valor;
}

