import { useCallback } from 'react';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { UploadCargaPessoa, UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { DialogAdicionarProdutoGenericoProps } from 'views/components/dialog/dialog-adicionar-produto-generico/dialog-adicionar-produto-generico';
import { DialogAdicionarProdutoSemPrecoProps } from 'views/components/dialog/dialog-adicionar-produto-sem-preco/dialog-adicionar-produto-sem-preco';
import { DialogAdicionarProdutoBalancaProps } from 'views/components/dialog/dialog-adicionar-produto-balanca/dialog-adicionar-produto-balanca';
import { DialogAdicionarProdutoFiscalProps } from 'views/components/dialog/dialog-adicionar-produto-fiscal/dialog-adicionar-produto-fiscal';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { DialogCodigoProdutoProps } from 'views/components/render-modais/components/dialog-codigo-produto';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useToastSaurus } from './toast-saurus';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { CredenciamentoSafra, FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { ProdutoNovoModel } from 'model/api/gestao/produto/produto/produto-novo-model';
import { ProdutoCompletoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { DialogWebhookProps } from 'views/components/dialog/dialog-webhook/dialog-webhook';
import { CompartilhamentoDadosWebHookModel } from 'model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model';
import { EmpresaUsuarioModel } from 'model';
import { EnumTpPermissaoUsuario } from 'model/enums/enum-tp-permissao-usuario';
import { EnumDecontoOuAcrescimo } from 'model/enums/enum-desconto-ou-acrescimo';
import { SessaoGerenciamentoModel } from 'model/api/gestao/sessao/sessao-gerenciamento-model';
import { ShowConfirmProps } from './confirm-saurus';
import { EnumAcaoManifesto } from 'model/enums/enum-acao-manifesto';
import { ReceitaMedicaModel, VendaCompletaModel, VolTranspModel } from 'model/api/gestao/venda/venda-completa-model';
import { ManifestoModel } from 'model/api/gestao/manifesto/manifesto-model';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { EnumTpAutenticacaoConvenio } from 'model/enums/enum-tp-autenticacao-convenio';
import { RelatoriosAvancadosModel } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { ModalAdicionarProdutosProps } from 'views/components/modals/adicionar-produto/modal-adicionar-produto';
import { TecladoFuncoesModalProps } from 'views/components/modals/teclado-funcoes/teclado-funcoes-modal';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { TabelaClientes } from 'database/interfaces/interface-tabela-clientes';
import { DialogCheckoutCartaoProps } from 'views/components/dialog/dialog-checkout-cartao/dialog-checkout-cartao-props';
import { MovRegistrarParcelasModel } from 'model/api/gestao/mov-pag/mov-registrar';
import { MovRegistrarResponseModel } from 'model/api/gestao/mov-pag/mov-registrar-response';
import { EnviarEmailDialogProps } from 'views/components/render-modais/components/enviar-email-dialog';
import { ValeModel } from 'model/api/gestao/troca/vale-model';

export const useCadastros = () => {
  const { callEvent } = useEventTools();
  const { showToast } = useToastSaurus();

  //----------------------------------------------------------------------------------------------------------------pessoa

  const abrirCadastroPessoa = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.PessoaModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroPessoa = useCallback((url?: string, atualizarLista?: boolean) => {
    callEvent(AppEventEnum.PessoaModal, {
      id: '',
      aberto: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url),
      atualizarLista
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------finalizadora

  const abrirCadastroFinalizadora = useCallback(
    (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
      atualizarDb: boolean = false
    ) => {
      callEvent(AppEventEnum.FinalizadoraModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        atualizarDb
      });
    },
    [callEvent]
  );

  const fecharCadastroFinalizadora = useCallback(
    (urlRetorno?: string, atualizarLista?: boolean) => {
      callEvent(AppEventEnum.FinalizadoraModal, {
        id: '',
        aberto: false,
        callbackUrl: urlRetorno ?? '',
        trocarUrl: false,
        atualizarLista,
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------conversao em andamento

  const abrirConversaoEmAndamento = useCallback(() => {
    callEvent(AppEventEnum.DialogConversaoEmAndamento, {
      openned: true
    });
  }, [callEvent]);

  const fecharConversaoEmAndamento = useCallback(() => {
    callEvent(AppEventEnum.DialogConversaoEmAndamento, {
      openned: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------uploadCarga

  const abrirCadastroUploadCarga = useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
      tipo: EnumUploadCargaTipo,
      model?: UploadCargaPessoa | UploadCargaProduto,
    ) => {
      callEvent(AppEventEnum.UploadCargaModal, {
        model: model,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        tipo: tipo
      });
    },
    [callEvent]
  );

  const fecharCadastroUploadCarga = useCallback(() => {
    callEvent(AppEventEnum.UploadCargaModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false,
      model: undefined
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------categoria

  const abrirCadastroCategoria = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.CategoriaModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroCategoria = useCallback((atualizarLista?: boolean) => {
    callEvent(AppEventEnum.CategoriaModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false,
      atualizarLista,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Perfil

  const abrirCadastroPerfil = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.PerfilModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroPerfil = useCallback(
    (urlRetorno?: string) => {
      callEvent(AppEventEnum.PerfilModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------Usuario

  const abrirCadastroUsuario = useCallback(
    (model?: EmpresaUsuarioModel, email?: string, pessoaId?: string) => {
      callEvent(AppEventEnum.DialogUsuario, {
        openned: true,
        model,
        email,
        pessoaId,
      });
    },
    [callEvent]
  );

  const fecharCadastroUsuario = useCallback(
    (att?: boolean) => {
      callEvent(AppEventEnum.DialogUsuario, {
        model: undefined,
        aberto: false,
        email: undefined,
        pessoaId: undefined,
        att
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------ncm

  const abrirCadastroNcm = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.NcmModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroNcm = useCallback(
    (urlRetorno?: string) => {
      callEvent(AppEventEnum.NcmModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------limiteAtingido

  const abrirLimiteAtingido = useCallback(() => {
    callEvent(AppEventEnum.ModalLimiteProdutoAtingido, { openned: true });
  }, [callEvent]);

  const fecharLimiteAtingido = useCallback(() => {
    callEvent(AppEventEnum.ModalLimiteProdutoAtingido, { openned: false });
  }, [callEvent]);

  // const { getPlanoUsuario } = useGestaoToken();
  // const { getCotasValidar } = useGetCotasValidar();
  // const plano = useRef(getPlanoUsuario()).current;
  // const isFiscal = isPlanoFiscal(plano?.plano);

  // const searchCotas = useCallback(async () => {
  //   try {
  //     const res = await getCotasValidar(EnumPlanoOpcoes.CadastroProdutos);
  //     if (res.erro) throw res.erro;
  //     return res.resultado?.data;
  //   } catch (e: any) { }
  // }, [getCotasValidar]);

  // const verifyLimitCotaProdutos = useCallback(async () => {
  //   try {
  //     if (!isFiscal) {
  //       const res = await searchCotas();
  //       if (res.error)
  //         throw new Error(
  //           `Não foi possível localizar os Limites de Cadastro de Produto do seu Plano. Detalhe: ${res.error}`
  //         );

  //       if (res) {
  //         abrirLimiteAtingido();
  //         return false;
  //       }
  //     }
  //     return true;
  //   } catch (e: any) {
  //     showToast('error', e.message);
  //   }
  // }, [abrirLimiteAtingido, isFiscal, searchCotas, showToast]);

  const abrirCadastroProduto = useCallback(
    async (
      id: string,
      tipo: EnumTipoProduto,
      callbackUrl: string,
      trocarUrl: boolean
    ) => {
      // if (plano?.plano?.opcoes && id === '') {
      //   const verify = await verifyLimitCotaProdutos();
      //   if (!verify) {
      //     return;
      //   }
      // }
      callEvent(AppEventEnum.ProdutoModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        tipo
      });
    },
    [callEvent]
  );

  const fecharCadastroProduto = useCallback(
    (urlRetorno?: string, atualizarLista?: boolean) => {
      callEvent(AppEventEnum.ProdutoModal, {
        id: '',
        openned: false,
        callbackUrl: urlRetorno ?? '',
        trocarUrl: true,
        tipo: EnumTipoProduto.Produto,
        atualizarLista: atualizarLista
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------limiteAtingido

  const abrirCadastroProdutoFiscal = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.ProdutoFiscalModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroProdutoFiscal = useCallback(
    (urlRetorno?: string, atualizarLista?: boolean) => {
      callEvent(AppEventEnum.ProdutoFiscalModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false,
        atualizarLista
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------pontosVenda

  const abrirCadastroPontosVenda = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.PontosVendaModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroPontosVenda = useCallback(
    (urlRetorno?: string, atualizarLista?: boolean) => {
      callEvent(AppEventEnum.PontosVendaModal, {
        id: '',
        aberto: false,
        callbackUrl: urlRetorno ?? '',
        trocarUrl: false,
        atualizarLista
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------fatura

  const abrirCadastroFaturas = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.FaturasModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroFaturas = useCallback(
    (urlRetorno?: string) => {
      callEvent(AppEventEnum.FaturasModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------saloes

  const abrirCadastroSaloes = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.SaloesModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroSaloes = useCallback(
    (urlRetorno?: string, atualizarLista?: boolean) => {
      callEvent(AppEventEnum.SaloesModal, {
        id: '',
        aberto: false,
        callbackUrl: urlRetorno ?? '',
        trocarUrl: false,
        atualizarLista,
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------saloes
  const abrirReceita = useCallback(
    (fluxoFinalizado: Function) => {
      callEvent(AppEventEnum.Receita, {
        openned: true,
        fluxoFinalizado
      });
    },
    [callEvent]
  );

  const fecharReceita = useCallback(
    () => {
      callEvent(AppEventEnum.Receita, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------DadosPagamento

  const abrirTecladoFuncoes = useCallback(
    (model: TecladoFuncoesModalProps) => {
      callEvent(AppEventEnum.TecladoFuncoes, {
        ...model,
        id: '',
        openned: true
      });
    },
    [callEvent]
  );

  const fecharTecladoFuncoes = useCallback(
    () => {
      callEvent(AppEventEnum.TecladoFuncoes, {
        callback: () => false,
        openned: false,
        label: '',
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------Parcelamento

  const abrirParcelamento = useCallback(
    (callback: (qtdeParcelas: number) => void, qtdePessoas: number, vRestante: number, paymentMethod: FinalizadoraModel) => {
      callEvent(AppEventEnum.Parcelamento, {
        openned: true,
        callback,
        qtdePessoas,
        vRestante,
        paymentMethod
      });
    },
    [callEvent]
  );

  const fecharParcelamento = useCallback(
    () => {
      callEvent(AppEventEnum.Parcelamento, {
        openned: false,
        callback: () => { },
        qtdePessoas: 0,
        vRestante: 0,
        paymentMethod: new FinalizadoraModel()
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------Parcelamento

  const abrirVendaAcrescDesc = useCallback(
    (tipo: 'acresc' | 'desc') => {
      callEvent(AppEventEnum.DialogAcrescimoDesconto, {
        openned: true,
        tipo
      });
    },
    [callEvent]
  );

  const fecharVendaAcrescDesc = useCallback(
    () => {
      callEvent(AppEventEnum.DialogAcrescimoDesconto, {
        openned: false,
        tipo: 'acresc'
      });
    },
    [callEvent]
  );


  //----------------------------------------------------------------------------------------------------------------Mesas

  const abrirCadastroMesas = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.MesasModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroMesas = useCallback(
    (atualizarLista?: boolean) => {
      callEvent(AppEventEnum.MesasModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false,
        atualizarLista,
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------comandas

  const abrirCadastroComandas = useCallback(
    (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
      rfid?: boolean,
      rfidECodigo?: boolean
    ) => {
      callEvent(AppEventEnum.ComandasModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        rfid: rfid,
        rfidECodigo: rfidECodigo
      });
    },
    [callEvent]
  );

  const fecharCadastroComandas = useCallback(
    (atualizarLista?: boolean) => {
      callEvent(AppEventEnum.ComandasModal, {
        id: '',
        aberto: false,
        callbackUrl: '',
        trocarUrl: false,
        rfid: false,
        rfidECodigo: false,
        atualizarLista,
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------calculadora

  const abrirCalculadora = useCallback(
    (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
      value: string,
      setValue: (index: number, value: string) => void
    ) => {
      callEvent(AppEventEnum.CalculadoraModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        value: value,
        setValue: setValue
      });
    },
    [callEvent]
  );

  const fecharCalculadora = useCallback(() => {
    callEvent(AppEventEnum.CalculadoraModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false,
      value: 0,
      setValue: () => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------retirada

  const abrirCadastroRetirada = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.DocumentoFiscalModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroRetirada = useCallback(() => {
    callEvent(AppEventEnum.DocumentoFiscalModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------contratos
  const abrirCadastroContratos = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.DialogVincularContratos, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroContratos = useCallback(() => {
    callEvent(AppEventEnum.DialogVincularContratos, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------depositos
  const abrirCadastroDepositos = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.DepositoModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroDepositos = useCallback(() => {
    callEvent(AppEventEnum.DepositoModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------depositos
  const abrirCadastroBarCodeCam = useCallback(
    (salao: string = '') => {
      callEvent(AppEventEnum.DialogBarCodeCam, {
        openned: true,
        salao: salao
      });
    },
    [callEvent]
  );

  const fecharCadastroBarCodeCam = useCallback(() => {
    callEvent(AppEventEnum.DialogBarCodeCam, {
      aberto: false,
      salao: ''
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------setores
  const abrirCadastroSetores = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.SetoresModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroSetores = useCallback(() => {
    callEvent(AppEventEnum.SetoresModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Equipamento
  const abrirCadastroEquipamentos = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.EquipamentoModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroEquipamentos = useCallback(() => {
    callEvent(AppEventEnum.EquipamentoModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProduto
  const abrirAdicionarProduto = useCallback(
    (produto: MovSimplesProdutoModel, codigo?: ProdutoCodigoModel | null) => {
      callEvent(AppEventEnum.AdicionarProdutoDialog, {
        produto: produto,
        aberto: true,
        codigo
      });
    },
    [callEvent]
  );

  const fecharAdicionarProduto = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false,
      codigo: null,
    } as ModalAdicionarProdutosProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoGenerico
  const abrirAdicionarProdutoGenerico = useCallback(
    (produto: MovSimplesProdutoModel) => {
      callEvent(AppEventEnum.AdicionarProdutoGenericoDialog, {
        produto: produto,
        aberto: true
      } as DialogAdicionarProdutoGenericoProps);
    },
    [callEvent]
  );

  const fecharAdicionarProdutoGenerico = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoGenericoDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false
    } as DialogAdicionarProdutoGenericoProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoSemPreco
  const abrirAdicionarProdutoSemPreco = useCallback(
    (produto: MovSimplesProdutoModel) => {
      callEvent(AppEventEnum.AdicionarProdutoSemPrecoDialog, {
        produto: produto,
        aberto: true
      } as DialogAdicionarProdutoSemPrecoProps);
    },
    [callEvent]
  );

  const fecharAdicionarProdutoSemPreco = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoSemPrecoDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false
    } as DialogAdicionarProdutoSemPrecoProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoBalanca
  const abrirAdicionarProdutoBalanca = useCallback(
    (produto: MovSimplesProdutoModel) => {
      callEvent(AppEventEnum.AdicionarProdutoBalancaDialog, {
        produto: produto,
        aberto: true
      } as DialogAdicionarProdutoBalancaProps);
    },
    [callEvent]
  );

  const fecharAdicionarProdutoBalanca = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoBalancaDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false
    } as DialogAdicionarProdutoBalancaProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoFiscal
  const abrirAdicionarProdutoFiscais = useCallback(
    (produto: MovSimplesProdutoModel) => {
      callEvent(AppEventEnum.AdicionarProdutoFiscaisDialog, {
        produto: produto,
        aberto: true
      } as DialogAdicionarProdutoFiscalProps);
    },
    [callEvent]
  );

  const fecharAdicionarProdutoFiscais = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoFiscaisDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false
    } as DialogAdicionarProdutoFiscalProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------LimiteVendaAtingido
  const abrirModalLimiteVendaAtingido = useCallback(() => {
    callEvent(AppEventEnum.ModalLimiteVendaAtingido, {
      aberto: true
    });
  }, [callEvent]);

  const fecharModalLimiteVendaAtingido = useCallback(() => {
    callEvent(AppEventEnum.ModalLimiteVendaAtingido, {
      aberto: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoSubItem
  const abrirAdicionarProdutoSubItem = useCallback(
    (produto: MovSimplesProdutoModel, edit: boolean = false) => {
      callEvent(AppEventEnum.AdicionarProdutoSubItemDialog, {
        produto: produto,
        aberto: true,
        edit: edit
      });
    },
    [callEvent]
  );

  const fecharAdicionarProdutoSubItem = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoSubItemDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false,
      edit: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------PropagandaPlano
  const abrirModalPropagandaPlano = useCallback(() => {
    callEvent(AppEventEnum.ModalPropagandaPlano, {
      aberto: true
    });
  }, [callEvent]);

  const fecharModalPropagandaPlano = useCallback(() => {
    callEvent(AppEventEnum.ModalPropagandaPlano, {
      aberto: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AvisoCobranca
  const abrirAvisoCobrancaDialog = useCallback(() => {
    callEvent(AppEventEnum.AvisoCobrancaDialog, {
      aberto: true
    });
  }, [callEvent]);

  const fecharAvisoCobrancaDialog = useCallback(() => {
    callEvent(AppEventEnum.AvisoCobrancaDialog, {
      aberto: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------NovoPDV
  const abrirNovoPDVDialog = useCallback((gerarNovoSerial: boolean = false) => {
    callEvent(AppEventEnum.NovoPDVDialog, {
      aberto: true,
      gerarNovoSerial
    });
  }, [callEvent]);

  const fecharNovoPDVDialog = useCallback(() => {
    callEvent(AppEventEnum.NovoPDVDialog, {
      aberto: false,
      gerarNovoSerial: false,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------DialogEstoque
  interface AbrirDialogEstoqueProps {
    atualizarVariacao: () => void;
    variacaoPadraoId: string;
    produtoId: string;
  }

  const abrirDialogEstoque = useCallback(
    ({
      atualizarVariacao,
      produtoId,
      variacaoPadraoId
    }: AbrirDialogEstoqueProps) => {
      callEvent(AppEventEnum.DialogEstoque, {
        aberto: true,
        atualizarVariacao,
        produtoId,
        variacaoPadraoId
      });
    },
    [callEvent]
  );

  const fecharDialogEstoque = useCallback(() => {
    callEvent(AppEventEnum.DialogEstoque, {
      aberto: false,
      atualizarVariacao: () => { },
      produtoId: '',
      variacaoPadraoId: ''
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------DialogCodigoProduto
  interface AbrirDialogCodigoProdutoProps {
    onSubmit: (
      model: ProdutoCodigoModel,
      beforeModel?: ProdutoCodigoModel | undefined
    ) => Promise<boolean>;
    carregando: boolean;
  }

  const abrirDialogCodigoProduto = useCallback(
    ({ carregando, onSubmit }: AbrirDialogCodigoProdutoProps) => {
      callEvent(AppEventEnum.DialogCodigoProduto, {
        aberto: true,
        carregando,
        onSubmit
      } as DialogCodigoProdutoProps);
    },
    [callEvent]
  );

  const fecharDialogCodigoProduto = useCallback(() => {
    callEvent(AppEventEnum.DialogCodigoProduto, {
      aberto: false,
      carregando: false,
      onSubmit: (
        model: ProdutoCodigoModel,
        beforeModel?: ProdutoCodigoModel | undefined
      ) => Promise.resolve(true)
    } as DialogCodigoProdutoProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------SelecaoProdutosCodigo
  const abrirSelecaoProdutosCodigoDialog = useCallback(
    (prod: ProdutoResumidoModel, detailed?: boolean) => {
      callEvent(AppEventEnum.DialogSelecaoProdutosCodigos, {
        aberto: true,
        prod: prod,
        detailed
      });
    },
    [callEvent]
  );

  const fecharSelecaoProdutosCodigoDialog = useCallback(() => {
    callEvent(AppEventEnum.DialogSelecaoProdutosCodigos, {
      aberto: false,
      detailed: false,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------EnviarSms
  const abrirEnviarSmsDialog = useCallback(
    (movId: string, mod: number) => {
      callEvent(AppEventEnum.EnviarSmsDialog, {
        aberto: true,
        id: movId,
        mod
      });
    },
    [callEvent]
  );

  const fecharEnviarSmsDialog = useCallback(() => {
    callEvent(AppEventEnum.EnviarSmsDialog, {
      aberto: false
    });
  }, [callEvent]);

  type EnviarEmailProps = Omit<EnviarEmailDialogProps, 'openned'>
  //----------------------------------------------------------------------------------------------------------------EnviarEmail
  const abrirEnviarEmailDialog = useCallback(
    ({ ...props }: EnviarEmailProps) => {
      callEvent(AppEventEnum.EnviarEmailDialog, {
        ...props,
        openned: true
      });
    },
    [callEvent]
  );

  const fecharEnviarEmailDialog = useCallback(() => {
    callEvent(AppEventEnum.EnviarEmailDialog, {
      openned: false,
      id: '',
      emails: undefined,
      enviarEmail: undefined,
      mod: undefined
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Pagamentos
  const abrirPagamentosDialog = useCallback(
    (payments: FinalizadoraModel[]) => {
      callEvent(AppEventEnum.DialogFormasDePagamento, {
        open: true,
        payments: payments
      });
    },
    [callEvent]
  );

  const fecharPagamentosDialog = useCallback(() => {
    callEvent(AppEventEnum.DialogFormasDePagamento, {
      open: false,
      payments: []
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------SolicitarPermissao

  const abrirSolicitarPermissao = useCallback(
    (acao: (...args: any[]) => Promise<any>, cod: number, label?: string, tipoPermissao?: EnumTpPermissaoUsuario, valor?: any, cancelAction?: () => void) => {
      if (navigator.onLine) {
        callEvent(AppEventEnum.SolicitarPermissao, {
          aberto: true,
          acao,
          cod,
          label,
          tipoPermissao,
          valor,
          cancelAction
        });
      } else {
        showToast(
          'info',
          `Você não possui permissão para realizar esta ação. Conecte-se à internet para solicitar permissão.`
        );
      }
    },
    [callEvent, showToast]
  );

  const fecharSolicitarPermissao = useCallback(() => {
    callEvent(AppEventEnum.SolicitarPermissao, {
      aberto: false,
      acao: async () => { },
      cod: 0,
      label: '',
      tipoPermissao: EnumTpPermissaoUsuario.SimNao,
      valor: ''
    });
  }, [callEvent]);

  //---------------------------------------------------------------------------------------------------------------- TrocarClienteDialog
  const abrirTrocarClienteDialog = useCallback(
    (finalizar: () => Promise<void>) => {
      callEvent(AppEventEnum.TrocarClienteDialog, {
        aberto: true,
        finalizar
      });
    },
    [callEvent]
  );

  const fecharTrocarClienteDialog = useCallback(() => {
    callEvent(AppEventEnum.TrocarClienteDialog, {
      aberto: false,
      mov: new MovSimplesModel()
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------CadastroFacilitadoDialog
  const abrirCadastroProdutoFacilitado = useCallback(
    (
      finalizar: (
        produto: ProdutoCompletoPreCadastroFormModel
      ) => Promise<void>,
      produto: ProdutoNovoModel,
      gradeId: string
    ) => {
      callEvent(AppEventEnum.CadastroProdutoFacilitado, {
        aberto: true,
        produto,
        finalizar,
        gradeId
      });
    },
    [callEvent]
  );

  const fecharCadastroProdutoFacilitado = useCallback(() => {
    callEvent(AppEventEnum.CadastroProdutoFacilitado, {
      aberto: false,
      produto: new ProdutoNovoModel(),
      finalizar: async (produto: ProdutoCompletoPreCadastroFormModel) => { },
      gradeId: ''
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------ImportaçãoDePedido
  const abrirImportarPedidorDialog = useCallback(
    (
      pedido: PedidoModel,
      importarPedido: (pedido: PedidoModel) => Promise<void>,
      dispatchEvent: AppEventEnum,
    ) => {
      callEvent(AppEventEnum.ImportarDialog, {
        aberto: true,
        pedido,
        importarPedido,
        dispatchEvent,
      });
    },
    [callEvent]
  );

  const fecharImportarPedidorDialog = useCallback(() => {
    callEvent(AppEventEnum.ImportarDialog, {
      aberto: false,
      pedido: undefined,
      importarPedido: () => Promise.resolve(),
      dispatchEvent: AppEventEnum.RecarregarPedidos,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------FechamentoDePedido
  const abrirFechamentoPedidorDialog = useCallback(
    (pedidos: PedidoModelComanda[]) => {
      callEvent(AppEventEnum.FechamentoDialog, {
        aberto: true,
        pedidos
      });
    },
    [callEvent]
  );

  const fecharFechamentoPedidorDialog = useCallback(() => {
    callEvent(AppEventEnum.FechamentoDialog, {
      aberto: false,
      pedidos: []
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------MovFechamento
  const abrirMovFechamentoDialog = useCallback(
    (
      handleFechamento: () => Promise<void>,
      handleImportar: () => Promise<void>
    ) => {
      callEvent(AppEventEnum.MovFechamento, {
        aberto: true,
        handleFechamento,
        handleImportar
      });
    },
    [callEvent]
  );

  const fecharMovFechamentoDialog = useCallback(() => {
    callEvent(AppEventEnum.MovFechamento, {
      aberto: false,
      handleFechamento: () => Promise.resolve(),
      handleImportar: () => Promise.resolve()
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------VincularComanda
  const abrirVincularComandaDialog = useCallback(
    (mesaId: string) => {
      callEvent(AppEventEnum.VincularComandaDialog, {
        aberto: true,
        mesaId
      });
    },
    [callEvent]
  );

  const fecharVincularComandaDialog = useCallback(
    (aberto: boolean) => {
      callEvent(AppEventEnum.VincularComandaDialog, {
        aberto: false,
        mesaId: ''
      });
    },
    [callEvent]
  );

  //----------------------------------------------------------------------------------------------------------------DialogbarCodeScanComanda
  const abrirDialogBarCodeCamComanda = useCallback(
    (mesaId: string) => {
      callEvent(AppEventEnum.DialogBarCodeCamComanda, {
        aberto: true,
        mesaId
      });
    },
    [callEvent]
  );

  const fecharDialogBarCodeCamComanda = useCallback(() => {
    callEvent(AppEventEnum.DialogBarCodeCamComanda, {
      aberto: false,
      mesaId: ''
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------DialogAlterarQuantidadeClientes
  const abrirDialogAlterarQuantidadeClientes = useCallback(
    (
      alterarQuantidade: (quantidadeClientes: number) => void,
      qtdeInicial: number,
      comanda?: boolean
    ) => {
      callEvent(AppEventEnum.DialogAlterarQuantidadeClientes, {
        aberto: true,
        alterarQuantidade,
        qtdeInicial,
        comanda
      });
    },
    [callEvent]
  );

  const fecharDialogAlterarQuantidadeClientes = useCallback(() => {
    callEvent(AppEventEnum.DialogAlterarQuantidadeClientes, {
      aberto: false,
      alterarQuantidade: (quantidadeClientes: number) => { },
      qtdeInicial: 0
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------DialogAlterarQuantidadeClientesVenda
  const abrirDialogAlterarQuantidadeClientesVenda = useCallback(
    () => {
      callEvent(AppEventEnum.DialogAlterarQuantidadeClientesVenda, {
        aberto: true,
      });
    },
    [callEvent]
  );

  const fecharDialogAlterarQuantidadeClientesVenda = useCallback(() => {
    callEvent(AppEventEnum.DialogAlterarQuantidadeClientesVenda, {
      aberto: false,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------MovEmAndamento
  const abrirDialogMovEmAndamento = useCallback(
    (quantidadeProdutos: number) => {
      callEvent(AppEventEnum.MovEmAndamento, {
        aberto: true,
        quantidadeProdutos
      });
    },
    [callEvent]
  );

  const fecharDialogMovEmAndamento = useCallback(() => {
    callEvent(AppEventEnum.MovEmAndamento, {
      aberto: false,
      quantidadeProdutos: 0
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------IndicacaoDeDesperdicio
  const abrirDialogIndicacaoDeDesperdicio = useCallback(
    (
      handleConfirme: (
        produto: PedidoProdutoModel,
        desperdicio: EnumIndDesperdicio
      ) => void = (
        produto: PedidoProdutoModel,
        desperdicio: EnumIndDesperdicio
      ) => { },
      produto: React.MutableRefObject<PedidoProdutoModel>
    ) => {
      callEvent(AppEventEnum.IndicacaoDeDesperdicio, {
        aberto: true,
        handleConfirme,
        produto
      });
    },
    [callEvent]
  );

  const fecharDialogIndicacaoDeDesperdicio = useCallback(() => {
    callEvent(AppEventEnum.IndicacaoDeDesperdicio, {
      aberto: false,
      handleConfirme: (
        produto: PedidoProdutoModel,
        desperdicio: EnumIndDesperdicio
      ) => { },
      produto: undefined
    });

    callEvent(AppEventEnum.RefIndicacaoDeDesperdicio, false);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------TaxaServico
  const abrirDialogTaxaServico = useCallback(
    (model: PedidoModel, carregarInfoPedido?: () => void) => {
      callEvent(AppEventEnum.DialogTaxaServico, {
        aberto: true,
        model,
        carregarInfoPedido
      });
    },
    [callEvent]
  );

  const fecharDialogTaxaServico = useCallback(() => {
    callEvent(AppEventEnum.DialogTaxaServico, {
      aberto: false,
      model: new PedidoModel(),
      carregarInfoPedido: () => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AcoesPedidos
  const abrirDialogAcoesPedidos = useCallback(
    (
      pedido: PedidoModel,
      carregarInfoPedido: () => Promise<void>,
      titulo?: string
    ) => {
      callEvent(AppEventEnum.DialogPedidoAcoes, {
        aberto: true,
        pedido,
        carregarInfoPedido,
        titulo
      });
    },
    [callEvent]
  );

  const fecharDialogAcoesPedidos = useCallback(() => {
    callEvent(AppEventEnum.DialogPedidoAcoes, {
      aberto: false,
      pedido: new PedidoModel(),
      atualizarPedido: () => { },
      carregarInfoPedido: () => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AcoesPedidos
  const abrirDialogAlterarMesaPedido = useCallback(
    (pedido: PedidoModel, carregarInfoPedido: () => Promise<void>) => {
      callEvent(AppEventEnum.DialogAlterarMesa, {
        aberto: true,
        pedido,
        carregarInfoPedido
      });
    },
    [callEvent]
  );

  const fecharDialogAlterarMesaPedido = useCallback(() => {
    callEvent(AppEventEnum.DialogAlterarMesa, {
      aberto: false,
      pedido: new PedidoModel(),
      carregarInfoPedido: () => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------PIX
  const abrirDialogPix = useCallback(
    (
      pagamento: MovSimplesPagamentoModel,
      finalizar: () => Promise<any>,
      cancelar: () => void
    ) => {
      callEvent(AppEventEnum.DialogPix, {
        aberto: true,
        pagamento,
        finalizar,
        cancelar
      });
    },
    [callEvent]
  );

  const fecharDialogPix = useCallback(() => {
    callEvent(AppEventEnum.DialogPix, {
      aberto: false,
      finalizar: async () => { },
      cancelar: () => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------CREDENCIAR PIX
  const abrirDialogCredenciarPix = useCallback(
    (
      finalizadoraId: string,
      finalizar: (finalizadora: FinalizadoraModel) => Promise<any>,
      cancelar: (message: string) => void
    ) => {
      callEvent(AppEventEnum.DialogCredenciarPix, {
        aberto: true,
        finalizadoraId,
        finalizar,
        cancelar
      });
    },
    [callEvent]
  );

  const fecharDialogCredenciarPix = useCallback(() => {
    callEvent(AppEventEnum.DialogCredenciarPix, {
      aberto: false,
      finalizadoraId: '',
      finalizar: async (finalizadora: FinalizadoraModel) => { },
      cancelar: async (message: string) => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------QRCODE
  const abrirDialogQrCode = useCallback(
    (text: string) => {
      callEvent(AppEventEnum.DialogQrCode, {
        aberto: true,
        texto: text
      });
    },
    [callEvent]
  );

  const fecharDialogQrCode = useCallback(() => {
    callEvent(AppEventEnum.DialogQrCode, {
      aberto: false,
      texto: ''
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------OPÇÕES PRODUTO CARRINHO
  const abrirDialogOpcoesProdutoCarrinho = useCallback(
    (
      model: MovSimplesProdutoModel,
      inativarProduto: (model: MovSimplesProdutoModel) => Promise<void>
    ) => {
      callEvent(AppEventEnum.DialogOpcoesProdutoCarrinho, {
        aberto: true,
        model,
        inativarProduto
      });
    },
    [callEvent]
  );

  const fecharDialogOpcoesProdutoCarrinho = useCallback(() => {
    callEvent(AppEventEnum.DialogOpcoesProdutoCarrinho, {
      aberto: false,
      model: new MovSimplesProdutoModel(),
      inativarProduto: async (model: MovSimplesProdutoModel) => { }
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------modificador
  const abrirCadastroModificador = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.ModificadorModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroModificador = useCallback(() => {
    callEvent(AppEventEnum.ModificadorModal, {
      id: '',
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);

  const abrirDialogAdicionarAcrescDesc = useCallback(
    (
      tipo: EnumDecontoOuAcrescimo,
      model?: MovSimplesProdutoModel,
      valorTotal?: number,
    ) => {
      callEvent(AppEventEnum.DialogAdicionarAcrescDesc, {
        aberto: true,
        model: model,
        tipo: tipo,
        valorTotal: valorTotal,
      });
    },
    [callEvent]
  );

  const fecharDialogAdicionarAcrescDesc = useCallback(() => {
    callEvent(AppEventEnum.DialogAdicionarAcrescDesc, {
      aberto: false,
      tipo: EnumDecontoOuAcrescimo.DescontoItem,
      model: new MovSimplesModel(),
      valorTotal: 0,
    });
  }, [callEvent]);

  //-----------------------------------------------------------------------------------------------------------PLANO CUSTOMIZADO
  const abrirDialogPlanoCustomizado = useCallback(
    (pathToRedirect: string) => {
      callEvent(AppEventEnum.DialogPlanoCustomizado, {
        aberto: true,
        pathToRedirect,
      });
    },
    [callEvent]
  );

  const fecharDialogPlanoCustomizado = useCallback(() => {
    callEvent(AppEventEnum.DialogPlanoCustomizado, {
      aberto: false,
      pathToRedirect: '',
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Config Resumo de Sessão
  const abrirConfigResumoSessao = useCallback(
    (sessaoId: string) => {
      callEvent(AppEventEnum.DialogConfigResumoSessao, {
        aberto: true,
        sessaoId: sessaoId,
      });
    },
    [callEvent]
  );

  const fecharConfigResumoSessao = useCallback((sessaoId: string) => {
    callEvent(AppEventEnum.DialogConfigResumoSessao, {
      aberto: false,
      sessaoId: sessaoId,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Configurar Webhook
  const abrirConfigWebhook = useCallback(
    (modelo: CompartilhamentoDadosWebHookModel) => {
      callEvent(AppEventEnum.AdicionarWebhookDialog, {
        aberto: true,
        modelo: modelo,
      } as DialogWebhookProps);
    },
    [callEvent]
  );

  const fecharConfigWebhook = useCallback(() => {
    callEvent(AppEventEnum.AdicionarWebhookDialog, {

      aberto: false
    } as DialogWebhookProps);
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------AdicionarProdutoGenericoPesavel
  const abrirAdicionarProdutoGenericoPesavel = useCallback(
    (produto: MovSimplesProdutoModel) => {
      callEvent(AppEventEnum.AdicionarProdutoGenericoPesavelDialog, {
        produto: produto,
        aberto: true
      } as DialogAdicionarProdutoGenericoProps);
    },
    [callEvent]
  );

  const fecharAdicionarProdutoGenericoPesavel = useCallback(() => {
    callEvent(AppEventEnum.AdicionarProdutoGenericoPesavelDialog, {
      produto: new MovSimplesProdutoModel(),
      aberto: false
    } as DialogAdicionarProdutoGenericoProps);
  }, [callEvent]);
  //----------------------------------------------------------------------------------------------------------------Gerenciamento Sessao
  const abrirDialogEdicaoGerenciamentoSessao = useCallback(
    (
      model: SessaoGerenciamentoModel,
    ) => {
      callEvent(AppEventEnum.DialogEditarGerenciamentoSessao, {
        aberto: true,
        model: model,
      });
    },
    [callEvent]
  );

  const fecharDialogEditarGerenciamentoSessao = useCallback(() => {
    callEvent(AppEventEnum.DialogEditarGerenciamentoSessao, {
      aberto: false,
      model: new SessaoGerenciamentoModel(),
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Gerenciamento Sessao
  const abrirDialogConfirmarConferenciaSessao = useCallback(
    (sessao: SessaoGerenciamentoModel) => {
      callEvent(AppEventEnum.DialogConfirmarConferenciaSessao, {
        aberto: true,
        sessao: sessao,
      });
    },
    [callEvent]
  );

  const fecharDialogConfirmarConferenciaSessao = useCallback(() => {
    callEvent(AppEventEnum.DialogConfirmarConferenciaSessao, {
      aberto: false,
      sessao: new SessaoGerenciamentoModel(),
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------PedidoFrete
  const abrirDialogInformacaoAdicional = useCallback(
    (callbackUrl: string, trocarUrl: boolean, permiteFechar: boolean = true) => {
      callEvent(AppEventEnum.DialogInformacaoAdicional, {
        aberto: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        permiteFechar: permiteFechar
      });
    },
    [callEvent]
  );

  const fecharDialogInformacaoAdicional = useCallback(() => {
    callEvent(AppEventEnum.DialogInformacaoAdicional, {
      aberto: false,
      callbackUrl: '',
      trocarUrl: false
    });
  }, [callEvent]);


  //----------------------------------------------------------------------------------------------------------------Volume Transportado
  const abrirVolumeTransportadoCadastro = useCallback(
    (volume: VolTranspModel, mov: VendaCompletaModel) => {
      callEvent(AppEventEnum.VolumeTransportadoModal, {
        volume: volume,
        openned: true,
        mov: mov,
        att: false
      });
    },
    [callEvent]
  );

  const fecharVolumeTransportadoCadastro = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.VolumeTransportadoModal, {
      volume: new VolTranspModel(),
      openned: false,
      mov: new VendaCompletaModel(),
      att: att
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Documento Referenciado
  const abrirDocReferenciadoCadastro = useCallback(
    (mov: VendaCompletaModel, id) => {
      callEvent(AppEventEnum.DocReferenciadoModal, {
        openned: true,
        mov,
        att: false,
        id
      });
    },
    [callEvent]
  );

  const fecharDocReferenciadoCadastro = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.DocReferenciadoModal, {
      openned: false,
      att: att,
      id: ''
    });
  }, [callEvent]);


  //----------------------------------------------------------------------------------------------------------------Dialog Marca
  const abrirDialogMarca = useCallback(
    (marca: MarcaModel,
      finalizar: (
        produto: MarcaModel
      ) => Promise<void>,
      isMarca?: boolean
    ) => {
      callEvent(AppEventEnum.DialogMarca, {
        aberto: true,
        marca,
        finalizar,
        isMarca
      });
    },
    [callEvent]
  );

  const fecharDialogMarca = useCallback(() => {
    callEvent(AppEventEnum.DialogMarca, {
      marca: new MarcaModel(),
      aberto: false,
      finalizar: async (marca: MarcaModel) => { },
    });
  }, [callEvent]);

  //Confirmar Acao Manifesto

  const abrirConfirmarSituacaoManifesto = useCallback(
    (cnpj: string, chave: string, acao: EnumAcaoManifesto) => {
      callEvent(AppEventEnum.ConfirmarSituacaoManifesto, {
        openned: true,
        cnpj,
        chave,
        acao
      });
    }, [callEvent]);

  const fecharConfirmarSituacaoManifesto = useCallback(() => {
    callEvent(AppEventEnum.ConfirmarSituacaoManifesto, {
      openned: false,
      cnpj: '',
      chave: '',
      acao: EnumAcaoManifesto.EventosDeConfirmacaoDeOperacao
    });
  }, [callEvent]);
  //----------------------------------------------------------------------------------------------------------------Dialgo Pagamento Edit
  const abrirPagamentoEditModal = useCallback(
    (id: string, mov: VendaCompletaModel) => {
      callEvent(AppEventEnum.PagamentoEditModal, {
        aberto: true,
        id,
        mov
      });
    },
    [callEvent]
  );


  const fecharPagamentoEditModal = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.PagamentoEditModal, {
      aberto: false,
      id: '',
      mov: new VendaCompletaModel(),
      att
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Dialgo Controlados
  const abrirDialogControlado = useCallback(
    (quantidade: number,
      handleClickNaoInformar: Function,
      handleClickInformar: Function) => {
      callEvent(AppEventEnum.Controlados, {
        aberto: true,
        quantidade,
        handleClickNaoInformar,
        handleClickInformar
      });
    },
    [callEvent]
  );

  const fecharDialogControlado = useCallback(() => {
    callEvent(AppEventEnum.Controlados, {
      aberto: false,
      quantidade: 0,
      fecharDialogControlado: () => { },
      callback: () => { }
    });
  }, [callEvent]);

  //---------------------------------------------------------------------------------------------------------------- Rastro Medicamento
  const abrirRastroMedicamentoModal = useCallback(
    (medicamentos: MovSimplesProdutoModel[], receitaMed: ReceitaMedicaModel, fluxoFinalizado: () => Promise<void>) => {
      callEvent(AppEventEnum.RastroMedicamentoModal, {
        openned: true,
        medicamentos,
        receitaMed,
        fluxoFinalizado
      });
    },
    [callEvent]
  );

  const fecharRastroMedicamentoModal = useCallback(() => {
    callEvent(AppEventEnum.RastroMedicamentoModal, {
      aberto: false,
      medicamento: [],
      receitaMed: new ReceitaMedicaModel()
    });
  }, [callEvent]);

  //Dialog Confirm
  const abrirDialogConfirm = useCallback(
    (
      {
        primaryButtonText,
        secondaryButtonText,
        title,
        description,
        primaryButtonColor,
        secondaryButtonColor,
        showIcon,
        confirmIcon,
        secondaryIcon
      }: ShowConfirmProps
    ) => {
      callEvent(AppEventEnum.DialogConfirm, {
        aberto: true,
        primaryButtonText,
        secondaryButtonText,
        title,
        description,
        primaryButtonColor,
        secondaryButtonColor,
        showIcon,
        confirmIcon,
        secondaryIcon
      })
    },
    [callEvent]
  )

  const fecharDialogConfirm = useCallback(
    () => {
      callEvent(AppEventEnum.DialogConfirm, {
        aberto: false,
        primaryButtonText: '',
        secondaryButtonText: '',
        title: '',
        description: '',
        primaryButtonColor: '',
        secondaryButtonColor: '',
        showIcon: false,
      })
    },
    [callEvent]
  )

  //Modal Manifesto
  const abrirModalSituacaoManifesto = useCallback(
    (model: ManifestoModel) => {
      callEvent(AppEventEnum.SituacaoManifestoModal, {
        aberto: true,
        model
      })
    },
    [callEvent]
  )

  const fecharModalSituacaoManifesto = useCallback(
    () => {
      callEvent(AppEventEnum.SituacaoManifestoModal, {
        aberto: false,
        model: {},
      })
    },
    [callEvent]
  )

  //----------------------------------------------------------------------------------------------------------------Entrada
  const abrirEntradaModal = useCallback(
    () => {
      callEvent(AppEventEnum.EntradaModal, {
        openned: true,
      })
    }, [callEvent])

  const fecharEntradaModal = useCallback(() => {
    callEvent(AppEventEnum.EntradaModal, {
      openned: false,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Dialgo Controlados
  const abrirDialogTaxaServicoCarrinho = useCallback(
    () => {
      callEvent(AppEventEnum.DialogTaxaServicoCarrinho, {
        aberto: true,
      });
    },
    [callEvent]
  );

  const fecharDialogTaxaServicoCarrinho = useCallback(() => {
    callEvent(AppEventEnum.DialogTaxaServicoCarrinho, {
      aberto: false
    });
  }, [callEvent]);
  //----------------------------------------------------------------------------------------------------------------Dialgo Controlados

  interface DialogImpressaoNfe {
    movId: string
    emails: string[],
    senhaChamada?: number,
  }
  const abrirDialogImpressaoNfe = useCallback(
    ({ emails, movId, senhaChamada }: DialogImpressaoNfe) => {
      callEvent(AppEventEnum.DialogImpressaoNfe, {
        aberto: true,
        movId,
        emails,
        senhaChamada,
      });
    },
    [callEvent]
  );

  const fecharDialogImpressaoNfe = useCallback(() => {
    callEvent(AppEventEnum.DialogImpressaoNfe, {
      aberto: false,
      emails: undefined,
      movId: '',
    });
  }, [callEvent]);
  //----------------------------------------------------------------------------------------------------------------Configurações Iniciais
  const abrirConfiguracoesIniciais = useCallback(
    () => {
      callEvent(AppEventEnum.DialogConfigIniciais, {
        aberto: true,

      });
    },
    [callEvent]
  );

  const fecharConfiguracoesIniciais = useCallback(() => {
    callEvent(AppEventEnum.DialogConfigIniciais, {
      aberto: false,

    });
  }, [callEvent]);


  //----------------------------------------------------------------------------------------------------------------Dialgo Definicoes

  const abrirDialogDefinicoes = useCallback(
    () => {
      callEvent(AppEventEnum.DefinicoesVenda, {
        aberto: true,
      });
    },
    [callEvent]
  );

  const fecharDialogDefinicoes = useCallback(() => {
    callEvent(AppEventEnum.DefinicoesVenda, {
      aberto: false
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Dialgo Definicoes

  const abrirCadastroMarca = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.MarcaModal, {
        aberto: true,
        id,
        callbackUrl,
        trocarUrl,
      });
    },
    [callEvent]
  );

  const fecharCadastroMarca = useCallback(
    (url?: string) => {
      callEvent(AppEventEnum.MarcaModal, {
        id: '',
        aberto: false,
        callbackUrl: url ?? '',
        trocarUrl: ''
      });
    }, [callEvent]);

  //-------------------------------------------------------------------------Dialog Aviso/Toast
  const abrirDialogAviso = useCallback(
    (
      mode: 'success' | 'error' | 'info' | 'warning',
      text: string,
      showCloseButton?: boolean,
      permitirFechar: boolean = true
    ) => {
      callEvent(AppEventEnum.DialogAviso, {
        aberto: true,
        mode,
        text,
        showCloseButton,
        permitirFechar
      })
    },
    [callEvent]
  )

  const fecharDialogAviso = useCallback(
    () => {
      callEvent(AppEventEnum.DialogAviso, {
        aberto: false,
        mode: 'success',
        text: '',
        showCloseButton: false
      })
    },
    [callEvent]
  )
  //----------------------------------------------------------------------------------------------------------------Convênio

  interface CadastroConvenioProps {
    id: string
    pessoa?: PessoaModel
    callbackUrl: string
    trocarUrl: boolean
  }

  const abrirCadastroConvenio = useCallback(
    ({ callbackUrl, id, trocarUrl, pessoa }: CadastroConvenioProps) => {
      callEvent(AppEventEnum.ConvenioModal, {
        openned: true,
        callbackUrl,
        id,
        trocarUrl,
        pessoa
      });
    }, [callEvent]);

  const fecharCadastroConvenio = useCallback((url?: string) => {
    callEvent(AppEventEnum.ConvenioModal, {
      id: '',
      openned: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url),
      pessoa: undefined,
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Cartão Convênio

  const abrirCadastroCartaoConvenio = useCallback(
    (id: string, convenioId: string, tipo?: EnumTpAutenticacaoConvenio) => {
      callEvent(AppEventEnum.CartaoConvenioModal, {
        id: id,
        openned: true,
        tipo,
        convenioId
      });
    },
    [callEvent]
  );

  const fecharCadastroCartaoConvenio = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.CartaoConvenioModal, {
      aberto: false,
      att
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Convênio Resumo Faturas

  const abrirConvenioResumoFaturas = useCallback(
    (model: {
      pessoaId: string
      tipo?: EnumTpAutenticacaoConvenio
      anoMesSelecionado?: number
    }) => {
      callEvent(AppEventEnum.ConvenioResumoFaturasModal, {
        openned: true,
        ...model,
      });
    },
    [callEvent]
  );

  const fecharConvenioResumoFaturas = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.ConvenioResumoFaturasModal, {
      aberto: false,
      att
    });
  }, [callEvent]);


  //----------------------------------------------------------------------------------------------------------------comissao

  const abrirCadastroComissao = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean, veioDoCadastro: boolean = false) => {
      callEvent(AppEventEnum.ComissaoModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
        veioDoCadastro
      });
    },
    [callEvent]
  );

  const fecharCadastroComissao = useCallback((url?: string) => {
    callEvent(AppEventEnum.ComissaoModal, {
      id: '',
      aberto: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url)
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------promocao
  const abrirCadastroPromocao = useCallback(
    (id: string, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.PromocaoModal, {
        id: id,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroPromocao = useCallback((url?: string) => {
    callEvent(AppEventEnum.PromocaoModal, {
      id: '',
      aberto: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url)
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------promocao
  const abrirCadastroPromocaoRegra = useCallback(
    (id: string, promocaoId: string, tipoRegra: EnumPromocaoTipoRegra, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.PromocaoRegraModal, {
        id: id,
        promocaoId: promocaoId,
        tipoRegra: tipoRegra,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl
      });
    },
    [callEvent]
  );

  const fecharCadastroPromocaoRegra = useCallback((url?: string) => {
    callEvent(AppEventEnum.PromocaoRegraModal, {
      id: '',
      promocaoId: '',
      openned: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url)
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------Termos De Uso
  const abrirTermosDeUso = useCallback(
    (finalizarCadastro: () => void) => {
      callEvent(AppEventEnum.DialogTermosDeUso, {
        openned: true,
        finalizarCadastro,
      });
    },
    [callEvent]
  );


  const fecharTermosDeUso = useCallback(() => {
    callEvent(AppEventEnum.DialogTermosDeUso, {
      aberto: false,

    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------------------------Convênio Autenticar

  const abrirConvenioAutenticar = useCallback(
    (modo: 'consulta' | 'venda', dadosPagamento?: {
      pagamento: MovSimplesPagamentoModel,
      credenciais: any
    }) => {
      callEvent(AppEventEnum.DialogConvenioAutenticar, {
        aberto: true,
        modo,
        dadosPagamento
      });
    }, [callEvent]);


  const fecharConvenioAutenticar = useCallback(() => {
    callEvent(AppEventEnum.DialogConvenioAutenticar, {
      aberto: false,
    });
  }, [callEvent]);

  const abrirConvenioCartaoQrCode = useCallback(
    (model: {
      numeroCartao: string
      senha: string
      nomeCartao: string
    }) => {
      callEvent(AppEventEnum.DialogConvenioCartaoQrCode, {
        aberto: true,
        model
      });
    },
    [callEvent]
  );

  const fecharConvenioCartaoQrCode = useCallback(() => {
    callEvent(AppEventEnum.DialogConvenioCartaoQrCode, {
      aberto: false,
    });
  }, [callEvent]);

  const abrirRelatoriosAvancados = useCallback(
    (relatorio: RelatoriosAvancadosModel, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.RelatoriosAvancadosModal, {
        relatorio,
        openned: true,
        callbackUrl: callbackUrl,
        trocarUrl: trocarUrl,
      });
    },
    [callEvent]
  );

  const fecharRelatoriosAvancados = useCallback((att?: boolean) => {
    callEvent(AppEventEnum.RelatoriosAvancadosModal, {
      relatorio: new RelatoriosAvancadosModel(),
      openned: false,
      callbackUrl: '',
      trocarUrl: false,
      att
    });
  }, [callEvent]);

  const abrirComissaoComissionados = useCallback(
    (comissaoId: string, tpComissionado: EnumTpPessoaComissionamento, callbackUrl: string, trocarUrl: boolean) => {
      callEvent(AppEventEnum.ComissaoComissionadosModal, {
        comissaoId,
        openned: true,
        callbackUrl,
        trocarUrl,
        tpComissionado,
      });
    },
    [callEvent]
  );

  const fecharComissaoComissionados = useCallback(() => {
    callEvent(AppEventEnum.ComissaoComissionadosModal, {
      comissaoId: '',
      openned: false,
      callbackUrl: '',
      trocarUrl: false,
    });
  }, [callEvent]);

  const abrirComissaoApuracao = useCallback((comissaoId: string, callbackUrl: string, trocarUrl: boolean) => {
    callEvent(AppEventEnum.ComissaoApuracaoModal, {
      comissaoId: comissaoId,
      openned: true,
      callbackUrl: callbackUrl,
      trocarUrl: trocarUrl,
    });
  }, [callEvent])

  const fecharComissaoApuracao = useCallback((url?: string) => {
    callEvent(AppEventEnum.ComissaoApuracaoModal, {
      comissaoId: '',
      openned: false,
      callbackUrl: url ?? '',
      trocarUrl: Boolean(url),
    });
  }, [callEvent])

  //----------------------------------------------------------------------------------------------Pedido Pagamento Antecipado
  const abrirPedidoPagamentoAntecipado = useCallback(
    (pedido: PedidoModel) => {
      callEvent(AppEventEnum.PedidoPagamentoAntecipado, {
        openned: true,
        pedido,
      });
    },
    [callEvent]
  );


  const fecharPedidoPagamentoAntecipado = useCallback(() => {
    callEvent(AppEventEnum.PedidoPagamentoAntecipado, {
      openned: false,
      pedido: new PedidoModel(),
    });
  }, [callEvent]);

  //----------------------------------------------------------------------------------------------Produto Motivo Cancelamento
  const abrirProdutoMotivoCancelamento = useCallback((callback: (motivo: string) => void) => {
    callEvent(AppEventEnum.ProdutoMotivoCancelamentoDialog, {
      aberto: true,
      callback,
    });
  }, [callEvent])

  const fecharProdutoMotivoCancelamento = useCallback(() => {
    callEvent(AppEventEnum.ProdutoMotivoCancelamentoDialog, {
      aberto: false,
      callback: () => { },
    });
  }, [callEvent])

  //----------------------------------------------------------------------------------------------Produto Motivo Cancelamento
  const abrirPagamentoRealizado = useCallback(() => {
    callEvent(AppEventEnum.PagamentoRealizado, {
      aberto: true,
    });
  }, [callEvent])

  const fecharPagamentoRealizado = useCallback(() => {
    callEvent(AppEventEnum.PagamentoRealizado, {
      aberto: false,
    });
  }, [callEvent])

  const abrirIdentificarComanda = useCallback((callback: (comanda: ComandasModel) => void) => {
    callEvent(AppEventEnum.IdentificarComanda, {
      openned: true,
      callback,
    });
  }, [callEvent])

  const fecharIdentificarComanda = useCallback(() => {
    callEvent(AppEventEnum.IdentificarComanda, {
      openned: false,
      callback: () => { },
    });
  }, [callEvent])

  const abrirInformacaoOperador = useCallback(
    (
      cliente: PessoaModel | TabelaClientes,
      confirmar: () => void,
      cancelar: () => void
    ) => {
      callEvent(AppEventEnum.InformacaoOperador, {
        openned: true,
        cliente,
        confirmar,
        cancelar
      });
    }, [callEvent])

  const fecharInformacaoOperador = useCallback(
    (
    ) => {
      callEvent(AppEventEnum.InformacaoOperador, {
        openned: false,
        confirmar: () => { },
        cancelar: () => { }
      });
    }, [callEvent])

  //----------------------------------------------------------------------------------------------------------------Checkout Cartao
  const abrirDialogCheckoutCartao = useCallback(
    (
      pagamento: MovSimplesPagamentoModel,
      credenciais: CredenciamentoSafra,
      resolve: (pagamento: MovSimplesPagamentoModel) => void,
      reject: () => void,
    ) => {
      callEvent(AppEventEnum.DialogCheckoutCartao, {
        openned: true,
        pagamento,
        credenciamento: credenciais,
        resolve,
        reject
      } as DialogCheckoutCartaoProps);
    },
    [callEvent]
  );

  const fecharDialogCheckoutCartao = useCallback(() => {
    callEvent(AppEventEnum.DialogCheckoutCartao, {
      openned: false,
      pagamento: new MovSimplesPagamentoModel(),
      credenciamento: new CredenciamentoSafra(),
      resolve: () => { },
      reject: () => { },
    } as DialogCheckoutCartaoProps);
  }, [callEvent]);

  const abrirDialogVencimentoPagamento = useCallback(
    (
      pagamento: MovSimplesPagamentoModel,
      parcelas: MovRegistrarParcelasModel[],
      callback: (parcelas: MovRegistrarParcelasModel[]) => void,
      cancelar: () => void,
    ) => {
      callEvent(AppEventEnum.VencimentoPagamentoDialog, {
        aberto: true,
        pagamento,
        parcelas,
        callback,
        cancelar
      });
    }, [callEvent])

  const fecharDialogVencimentoPagamento = useCallback(
    () => {
      callEvent(AppEventEnum.VencimentoPagamentoDialog, {
        aberto: false,
        pagamento: new MovSimplesPagamentoModel(),
        parcelas: [],
        callback: () => { },
        cancelar: () => { },
      });
    }, [callEvent])

  const abrirDialogDownloadBoletos = useCallback(
    (
      boletos: MovRegistrarResponseModel,
      callback: (parcelas: MovRegistrarParcelasModel[]) => void,
    ) => {
      callEvent(AppEventEnum.DownloadBoletoDialog, {
        aberto: true,
        boletos,
        callback,
      });
    }, [callEvent])

  const fecharDialogDownloadBoletos = useCallback(
    () => {
      callEvent(AppEventEnum.DownloadBoletoDialog, {
        aberto: false,
        boletos: new MovRegistrarResponseModel(),
        callback: () => { },
      });
    }, [callEvent])

  const abrirValeTrocoModal = useCallback(
    (
      callbackSuccess: (vale?: ValeModel) => void,
      callbackCancel: () => void,
    ) => {
      callEvent(AppEventEnum.ValeTrocoModal, {
        aberto: true,
        finalizar: callbackSuccess,
        cancelar: callbackCancel,
      });
    }, [callEvent])

  const fecharValeTrocoModal = useCallback(
    () => {
      callEvent(AppEventEnum.ValeTrocoModal, {
        aberto: false,
        finalizar: () => { },
        cancelar: () => { },
      });
    }, [callEvent])

  const abrirAutenticarVale = useCallback(
    (
      pagamento: MovSimplesPagamentoModel,
      callbackSuccess: (valorVale: number) => void,
      callbackCancel: () => void,
    ) => {
      callEvent(AppEventEnum.DialogAutenticarVale, {
        aberto: true,
        pagamento,
        sucesso: callbackSuccess,
        cancelar: callbackCancel,
      });
    }, [callEvent])

  const fecharAutenticarVale = useCallback(
    () => {
      callEvent(AppEventEnum.DialogAutenticarVale, {
        aberto: false,
        pagamento: new MovSimplesPagamentoModel(),
        sucesso: (valorVale: number) => { },
        cancelar: () => { },
      });
    }, [callEvent])

  const abrirReimprimirVale = useCallback(
    () => {
      callEvent(AppEventEnum.ReiprimirValeDialog, {
        aberto: true,
      });
    }, [callEvent])

  const fecharReimprimirVale = useCallback(
    () => {
      callEvent(AppEventEnum.ReiprimirValeDialog, {
        aberto: false,
      });
    }, [callEvent])


  return {
    abrirCadastroPessoa,
    fecharCadastroPessoa,

    abrirCadastroFinalizadora,
    fecharCadastroFinalizadora,

    abrirCadastroUploadCarga,
    fecharCadastroUploadCarga,

    abrirCadastroCategoria,
    fecharCadastroCategoria,

    abrirCadastroPerfil,
    fecharCadastroPerfil,

    abrirCadastroUsuario,
    fecharCadastroUsuario,

    abrirCadastroNcm,
    fecharCadastroNcm,

    abrirCadastroProduto,
    fecharCadastroProduto,

    abrirCadastroProdutoFiscal,
    fecharCadastroProdutoFiscal,

    abrirCadastroPontosVenda,
    fecharCadastroPontosVenda,

    abrirLimiteAtingido,
    fecharLimiteAtingido,

    abrirConversaoEmAndamento,
    fecharConversaoEmAndamento,

    abrirCadastroFaturas,
    fecharCadastroFaturas,

    abrirCadastroSaloes,
    fecharCadastroSaloes,

    abrirReceita,
    fecharReceita,

    abrirTecladoFuncoes,
    fecharTecladoFuncoes,

    abrirCadastroComandas,
    fecharCadastroComandas,

    abrirCalculadora,
    fecharCalculadora,

    abrirCadastroRetirada,
    fecharCadastroRetirada,

    abrirCadastroContratos,
    fecharCadastroContratos,

    abrirCadastroDepositos,
    fecharCadastroDepositos,

    abrirCadastroBarCodeCam,
    fecharCadastroBarCodeCam,

    abrirCadastroSetores,
    fecharCadastroSetores,

    abrirCadastroEquipamentos,
    fecharCadastroEquipamentos,

    abrirAdicionarProduto,
    fecharAdicionarProduto,

    abrirAdicionarProdutoGenerico,
    fecharAdicionarProdutoGenerico,

    abrirAdicionarProdutoSemPreco,
    fecharAdicionarProdutoSemPreco,

    abrirAdicionarProdutoBalanca,
    fecharAdicionarProdutoBalanca,

    abrirAdicionarProdutoFiscais,
    fecharAdicionarProdutoFiscais,

    abrirModalLimiteVendaAtingido,
    fecharModalLimiteVendaAtingido,

    abrirAdicionarProdutoSubItem,
    fecharAdicionarProdutoSubItem,

    abrirModalPropagandaPlano,
    fecharModalPropagandaPlano,

    abrirAvisoCobrancaDialog,
    fecharAvisoCobrancaDialog,

    abrirNovoPDVDialog,
    fecharNovoPDVDialog,

    abrirCadastroMesas,
    fecharCadastroMesas,

    abrirDialogCodigoProduto,
    fecharDialogCodigoProduto,

    abrirSelecaoProdutosCodigoDialog,
    fecharSelecaoProdutosCodigoDialog,

    abrirEnviarSmsDialog,
    fecharEnviarSmsDialog,

    abrirEnviarEmailDialog,
    fecharEnviarEmailDialog,

    abrirTrocarClienteDialog,
    fecharTrocarClienteDialog,

    abrirCadastroProdutoFacilitado,
    fecharCadastroProdutoFacilitado,

    abrirImportarPedidorDialog,
    fecharImportarPedidorDialog,

    abrirFechamentoPedidorDialog,
    fecharFechamentoPedidorDialog,

    abrirMovFechamentoDialog,
    fecharMovFechamentoDialog,

    abrirVincularComandaDialog,
    fecharVincularComandaDialog,

    abrirDialogBarCodeCamComanda,
    fecharDialogBarCodeCamComanda,

    abrirDialogAlterarQuantidadeClientes,
    fecharDialogAlterarQuantidadeClientes,

    abrirDialogMovEmAndamento,
    fecharDialogMovEmAndamento,

    abrirDialogIndicacaoDeDesperdicio,
    fecharDialogIndicacaoDeDesperdicio,

    abrirDialogTaxaServico,
    fecharDialogTaxaServico,

    abrirDialogAcoesPedidos,
    fecharDialogAcoesPedidos,

    abrirDialogAlterarMesaPedido,
    fecharDialogAlterarMesaPedido,

    abrirDialogPix,
    fecharDialogPix,

    abrirDialogCredenciarPix,
    fecharDialogCredenciarPix,

    abrirDialogQrCode,
    fecharDialogQrCode,

    abrirDialogOpcoesProdutoCarrinho,
    fecharDialogOpcoesProdutoCarrinho,

    abrirDialogPlanoCustomizado,
    fecharDialogPlanoCustomizado,

    abrirCadastroModificador,
    fecharCadastroModificador,

    abrirConfigWebhook,
    fecharConfigWebhook,

    abrirConfigResumoSessao,
    fecharConfigResumoSessao,

    abrirDialogAdicionarAcrescDesc,
    fecharDialogAdicionarAcrescDesc,

    abrirDialogEdicaoGerenciamentoSessao,
    fecharDialogEditarGerenciamentoSessao,

    abrirDialogConfirmarConferenciaSessao,
    fecharDialogConfirmarConferenciaSessao,

    abrirEntradaModal,
    fecharEntradaModal,

    abrirVolumeTransportadoCadastro,
    fecharVolumeTransportadoCadastro,

    abrirDocReferenciadoCadastro,
    fecharDocReferenciadoCadastro,

    abrirDialogConfirm,
    fecharDialogConfirm,

    abrirModalSituacaoManifesto,
    fecharModalSituacaoManifesto,

    abrirPagamentosDialog,
    fecharPagamentosDialog,

    abrirParcelamento,
    fecharParcelamento,


    abrirVendaAcrescDesc,
    fecharVendaAcrescDesc,

    abrirDialogMarca,
    fecharDialogMarca,

    abrirDialogEstoque,
    fecharDialogEstoque,

    abrirConfirmarSituacaoManifesto,
    fecharConfirmarSituacaoManifesto,

    abrirPagamentoEditModal,
    fecharPagamentoEditModal,

    abrirDialogControlado,
    fecharDialogControlado,

    abrirRastroMedicamentoModal,
    fecharRastroMedicamentoModal,

    abrirDialogInformacaoAdicional,
    fecharDialogInformacaoAdicional,

    abrirAdicionarProdutoGenericoPesavel,
    fecharAdicionarProdutoGenericoPesavel,

    abrirSolicitarPermissao,
    fecharSolicitarPermissao,

    abrirDialogAlterarQuantidadeClientesVenda,
    fecharDialogAlterarQuantidadeClientesVenda,

    abrirDialogTaxaServicoCarrinho,
    fecharDialogTaxaServicoCarrinho,

    abrirDialogImpressaoNfe,
    fecharDialogImpressaoNfe,

    abrirConfiguracoesIniciais,
    fecharConfiguracoesIniciais,

    abrirDialogDefinicoes,
    fecharDialogDefinicoes,

    abrirCadastroMarca,
    fecharCadastroMarca,

    abrirDialogAviso,
    fecharDialogAviso,

    abrirCadastroComissao,
    fecharCadastroComissao,

    abrirCadastroConvenio,
    fecharCadastroConvenio,

    abrirCadastroCartaoConvenio,
    fecharCadastroCartaoConvenio,

    abrirConvenioResumoFaturas,
    fecharConvenioResumoFaturas,

    abrirCadastroPromocao,
    fecharCadastroPromocao,

    abrirCadastroPromocaoRegra,
    fecharCadastroPromocaoRegra,

    abrirConvenioAutenticar,
    fecharConvenioAutenticar,

    abrirConvenioCartaoQrCode,
    fecharConvenioCartaoQrCode,

    abrirRelatoriosAvancados,
    fecharRelatoriosAvancados,

    abrirTermosDeUso,
    fecharTermosDeUso,

    abrirComissaoComissionados,
    fecharComissaoComissionados,

    abrirComissaoApuracao,
    fecharComissaoApuracao,

    abrirPedidoPagamentoAntecipado,
    fecharPedidoPagamentoAntecipado,

    abrirProdutoMotivoCancelamento,
    fecharProdutoMotivoCancelamento,

    abrirPagamentoRealizado,
    fecharPagamentoRealizado,

    abrirIdentificarComanda,
    fecharIdentificarComanda,

    abrirInformacaoOperador,
    fecharInformacaoOperador,

    abrirDialogCheckoutCartao,
    fecharDialogCheckoutCartao,

    abrirDialogVencimentoPagamento,
    fecharDialogVencimentoPagamento,

    abrirDialogDownloadBoletos,
    fecharDialogDownloadBoletos,

    abrirValeTrocoModal,
    fecharValeTrocoModal,

    abrirAutenticarVale,
    fecharAutenticarVale,

    abrirReimprimirVale,
    fecharReimprimirVale,
  };
};
