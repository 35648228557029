import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';


export const useFormPessoaValeValidation = () => {

  const { documentoYup } = useValidationYupDocumento(false);

  const FormPessoaValeValidation = useMemo(() => {

    return (
      Yup.object().shape({
        clienteDocumento: documentoYup(),
      })
    )
  }, [documentoYup])
  return {
    FormPessoaValeValidation,
  }
}

