import { useCallback, FocusEvent, useState, useEffect, useRef, ReactElement } from "react";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";
import { ProdutoCategoriaModel } from "model/api/gestao/produto/produto-categoria/produto-categoria-model";

import { guidEmpty } from "utils/guid-empty";
import { useGetProdutoCategorias, usePostProdutoCategoria } from "data/api/gestao/produto/produto-categoria";
import { EnumTipoExibicao } from "model/enums/enum-tipo-exibicao";
import { DropCacheToUseProps, EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";

export interface AutocompleteCategoriasProps extends DropCacheToUseProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete | null) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  idCategoria?: string;
  allowSubmit?: boolean;
  permiteAdicionar?: boolean;
  modo?: "categoria" | "categoriaPai";
  disabled?: boolean;
  setCarregandoExterno?: (carregando: boolean) => void
  carregarInicialmente?: boolean;
  endAdornment?: ReactElement | null;
  fullWidth?: boolean;
}

const semCategoriaPai = new ProdutoCategoriaModel(
  guidEmpty(),
  guidEmpty(),
  "Sem Categoria Pai",
  "Sem Categoria Pai"
);

export const AutocompleteProdutoCategorias = ({
  onChange,
  loadingExterno,
  setCarregandoExterno,
  carregarInicialmente = true,
  useCache = true,
  ...props
}: AutocompleteCategoriasProps) => {
  const { getProdutoCategorias, carregando: carregandoGetCategorias } = useGetProdutoCategorias();
  const { postProdutoCategoria, carregando: carregandoPostCategoria } = usePostProdutoCategoria();
  const { fetchData, removeCacheItem } = useDropCache<ApiListModel<ProdutoCategoriaModel>>(getProdutoCategorias)

  const [categoriaState, setCategoriaState] = useState<Array<ProdutoCategoriaModel>>(
    new Array<ProdutoCategoriaModel>()
  );
  const allowReq = useRef<boolean>(true);

  const getCategoriasWrapper = useCallback(
    async (termo: string) => {
      try {
        const params = [`Nome=${termo}&Ativo=true`]
        const ret = await fetchData({
          params,
          category: EnumDropCache.Categoria,
          propId: props.propId ?? '',
          useCache,
          searchTerm: termo,
        });
        const list = ret?.data.list ?? []
        let categorias: Array<ProdutoCategoriaModel> = new Array<ProdutoCategoriaModel>();
        if (list?.length > 0) {
          categorias = list as Array<ProdutoCategoriaModel>;
        }
        if (props.modo === "categoriaPai") {
          categorias.push(semCategoriaPai);
        }

        const semSemCategoria: ProdutoCategoriaModel = {
          ativo: false,
          breadCrumbs: 'Sem Categoria',
          categoriaPaiId: null,
          cor: '#fff',
          descricao: 'Sem Categoria',
          foto: '',
          id: guidEmpty(),
          nome: 'Sem Categoria',
          tipoExibicao: EnumTipoExibicao.Todos,
          ordem: 0
        }

        categorias.push(semSemCategoria);
        setCategoriaState(categorias);
      } catch (e: any) { }
    },
    [fetchData, props.modo, props.propId, useCache]
  );

  const addNovaCategoria = useCallback(
    async (nome: string) => {
      const novaCategoria = new ProdutoCategoriaModel()
      novaCategoria.nome = nome;
      novaCategoria.descricao = nome;

      if (setCarregandoExterno) {
        setCarregandoExterno(true);
      }

      const ret = await postProdutoCategoria(novaCategoria);
      if (ret.erro) throw ret.erro
      removeCacheItem(props.propId ?? '', EnumDropCache.Categoria)

      const categoriaRet = ret.resultado?.data as ProdutoCategoriaModel;
      return categoriaRet
    },
    [postProdutoCategoria, props.propId, removeCacheItem, setCarregandoExterno],
  );

  const onChangeWrapper = useCallback(
    async (value: retornoAutoComplete | null) => {
      try {
        if (!value) return
        allowReq.current = true
        if (value.isNewVal) {
          if (value.isString) {
            const novaCategoria = await addNovaCategoria(value.value)
            if (novaCategoria) {
              value = {
                isNewVal: false,
                isString: false,
                value: novaCategoria
              }
            } else {
              value = null
            }
          }
        }
        if (onChange) onChange(value);
      } catch (error: any) {
      } finally {
        setCarregandoExterno && setCarregandoExterno(false)
      }
    }, [addNovaCategoria, onChange, setCarregandoExterno]
  );

  const onPesquisa = useCallback(
    (termo: string) => {
      getCategoriasWrapper(termo);
    },
    [getCategoriasWrapper]
  );

  useEffect(() => {
    if (carregarInicialmente && props.value && allowReq.current) {
      getCategoriasWrapper(props.value)
      allowReq.current = false;
    }
  }, [carregarInicialmente, getCategoriasWrapper, props.permiteAdicionar, props.value])

  const carregando = [carregandoGetCategorias, carregandoPostCategoria].includes(true)

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      loading={carregando}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={categoriaState}
      optionLabelKey="breadCrumbs"
      optionValueKey="breadCrumbs"
      disabledValueKey="id"
      disabledValues={[props.idCategoria]}
      noOptionsText={"Nenhuma Categoria Localizada"}
      onBlur={props.onBlur}
      value={props.value}
      name={props.name}
      label={props.label}
      allowSubmit={props.allowSubmit}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={props.permiteAdicionar}
      textoNovoItem="Adicionar: "
      endAdornment={props.endAdornment}
      fullWidth={props.fullWidth}
    />
  );
};
