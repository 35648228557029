import { useGetHistoricoVenda } from 'data/api/gestao/historico-venda/get-historico-venda';
import { AppEventEnum } from 'model/enums/enum-app-event';
import React, { useRef } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MenuPrincipalModelo } from 'services/app/hooks/menu-principal/menu-principal-types';
import { usePDV } from 'services/app/hooks/pdv';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { LoadingButton } from 'views/components';
import { useStyles } from './sessao-pdv-styles';
import { consoleDev } from 'utils/console-dev';
import { toDateString } from 'utils/to-date';
import {
  SincronizacaoDados,
  useSincronizacaoCadastros
} from 'services/app/hooks/sincronizacao-cadastros';
import { SessaoModel } from 'model/api/gestao/sessao/sessao-model';
import { PontosVendaCompletoVendaModel } from 'model/app/ponto-venda/ponto-venda-completo-model';
import { useSessaoAtual } from 'services/app';
import { PermissaoIcon, UserIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { PdvIcon } from 'views/components/icons/pdv-icon';
import { Typography, Grid } from 'views/design-system';

interface Props {
  modeloAtual: MenuPrincipalModelo;
  carregando: boolean;
  tiraBorderTop?: boolean;
}

export const SessaoPDV = ({ modeloAtual, tiraBorderTop }: Props) => {

  consoleDev('SessaoPDV');

  // HOOKS
  const { isMobile } = useThemeQueries();
  const { getSessao } = useSessaoPDV();
  const { addHandler, removeHandler } = useEventTools();
  const { getPDV } = usePDV();
  const { carregando } = useGetHistoricoVenda();
  const { getUltimaSincronizacao } = useSincronizacaoCadastros();
  const {
    usuario, getEmpresaSelecionada
  } = useSessaoAtual();
  const nomeUsuario = usuario?.saudacao || 'Visitante';
  const perfilUsuario = getEmpresaSelecionada()?.Perfil.Nome || 'Administrador';

  // STATES E REFS
  const refSessaoAtual = useRef<HTMLHeadingElement>(null);
  const refPdv = useRef<HTMLHeadingElement>(null);
  const refTypographUltimaSinc = useRef<HTMLHeadingElement>(null);

  const refPdvValue = useRef<PontosVendaCompletoVendaModel | undefined>(getPDV());
  const refAbertura = useRef<SessaoModel | undefined>(undefined);
  const refUltimaSinc = useRef<SincronizacaoDados | undefined>(
    getUltimaSincronizacao()
  );

  // AUX
  const classes = useStyles();

  const sessaoPdv = React.useCallback(async () => {
    const sessao = await getSessao();
    refAbertura.current = sessao;

    if (refSessaoAtual.current) {
      const text = toDateString(
        sessao?.dataAbertura ? new Date(sessao.dataAbertura) : undefined
        , 'HH:mm');
      refSessaoAtual.current.textContent = text ? text : '-';
    }
  }, [getSessao]);

  const reloadPdv = React.useCallback(async () => {
    refPdvValue.current = getPDV();

    if (refPdv.current) {
      const text = refPdvValue.current
        ? ('000' + refPdvValue.current?.numCaixa.toString()).substr(-3)
        : '-'
      refPdv.current.textContent = text ? text : '-';
    }
  }, [getPDV]);

  const sincronizacaoStatus = React.useCallback(
    (status: number) => {
      if (refTypographUltimaSinc.current) {
        const text = getUltimaSincronizacao()?.dataSucesso
          ? toDateString(
            getUltimaSincronizacao()?.dataSucesso,
            'DD/MM/YYYY HH:mm'
          )
          : toDateString(
            getUltimaSincronizacao()?.dataUltima,
            'DD/MM/YYYY HH:mm'
          );

        refTypographUltimaSinc.current.textContent = text
          ? text
          : 'Não Realizada';
      }
    },
    [getUltimaSincronizacao]
  );

  React.useEffect(() => {
    addHandler(AppEventEnum.SessaoPDV, sessaoPdv);
    addHandler(AppEventEnum.PDV, reloadPdv);
    addHandler(AppEventEnum.Sincronizacao, sincronizacaoStatus);
    return () => {
      removeHandler(AppEventEnum.SessaoPDV, sessaoPdv);
      removeHandler(AppEventEnum.PDV, reloadPdv);
      removeHandler(AppEventEnum.Sincronizacao, sincronizacaoStatus);
    };
  }, [addHandler, reloadPdv, removeHandler, sessaoPdv, sincronizacaoStatus]);

  React.useEffect(() => {
    sessaoPdv();
  }, [addHandler, removeHandler, sessaoPdv]);

  return (
    <>
      <>
        <Grid container className={tiraBorderTop ? classes.containerSemBorderTop : classes.container}>
          {modeloAtual !== 'Mini' ? (
            <>
              {carregando ? (
                <Grid className={classes.carregando}>
                  <LoadingButton tipo="AZUL" />
                </Grid>
              ) : (
                <>
                  <Grid item xs={6} sm={6} md={3} className={classes.content}>
                    <Typography className={classes.label}>Usuário</Typography>
                    <Typography ref={refPdv} className={classes.value}>
                      <UserIcon tipo="BUTTON" />
                      {nomeUsuario}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} className={isMobile ? classes.final : classes.content}>
                    <Typography className={classes.label}>Perfil</Typography>
                    <Typography ref={refPdv} className={classes.value}>
                      <PermissaoIcon
                        tipo="BUTTON"
                      />
                      {perfilUsuario}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={4} md={1} className={classes.content}>
                    <Typography variant='body1' className={classes.label}>Número PDV</Typography>
                    <Typography ref={refPdv} className={classes.value}>
                      <PdvIcon tipo="BUTTON" />
                      {refPdvValue.current
                        ? ('000' + refPdvValue.current?.numCaixa.toString()).substr(-3)
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={4} md={1} className={classes.meio}>
                    <Typography className={classes.label}>Abertura</Typography>
                    <Typography ref={refSessaoAtual} className={classes.value}>
                      {toDateString(
                        refAbertura.current?.dataAbertura
                          ? new Date(refAbertura.current?.dataAbertura)
                          : undefined
                        , 'HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} className={classes.final}>
                    <Typography className={classes.label}>
                      Última Sincronização
                    </Typography>
                    <Typography
                      ref={refTypographUltimaSinc}
                      className={classes.value}
                    >
                      {refUltimaSinc.current?.dataSucesso
                        ? toDateString(
                          refUltimaSinc.current?.dataSucesso,
                          'DD/MM HH:mm'
                        )
                        : refUltimaSinc.current?.dataUltima
                          ? toDateString(
                            refUltimaSinc.current?.dataUltima,
                            'DD/MM HH:mm'
                          )
                          : 'Não Realizada'}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <Grid item xs={12} className={classes.content}>
              <Typography className={classes.title}>PDV</Typography>
              <Typography ref={refPdv} className={classes.value}>
                {getPDV()
                  ? ('000' + refPdvValue.current?.numCaixa.toString()).substr(-3)
                  : '-'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    </>
  );
};
