import { useMemo } from 'react';
import { useValidationYupEmail } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useEnviarCupomVendaPorEmailValidation = () => {

    const { emailYup } = useValidationYupEmail()
    const formEnviarCupomVendaPorEmailValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                email: emailYup(false)
            })
        )
    }, [emailYup])
    return {
        formEnviarCupomVendaPorEmailValidationYup,
    }
}

