import { useCallback, useEffect, useState, useRef } from 'react';
import { NovoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { isEqual } from 'utils/is-equal';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';

import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';

import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useGetProdutoCategoriaById } from 'data/api/gestao/produto/produto-categoria/get-produto-categoria-by-id';
import { usePutProdutoCategoria } from 'data/api/gestao/produto/produto-categoria/put-produto-categoria';
import { CategoriaEditFormModel } from 'model/app/forms/categoria/categoria-edit-form-model';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { FormCategoriaEdit } from '../../../../form/categoria/form-categoria-edit/form-categoria-edit';
import { guidEmpty } from '../../../../../../utils/guid-empty';
import classNames from 'classnames';
import { useGetProdutoCategorias, usePostProdutoCategoria } from 'data/api/gestao/produto/produto-categoria';
import { useLocation } from 'react-router-dom';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { Button, Grid } from 'views/design-system';
import { EnumDropCache, useDropCache } from 'services/app/hooks/drop-cache';

interface CategoriaEditProps {
  id: string
  atualizarLista: boolean
  handleAtualizarLista: (value: boolean) => void
}

export const CategoriaEdit = ({ atualizarLista, handleAtualizarLista, ...props }: CategoriaEditProps) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);

  const location = useLocation()
  const pathName = location.pathname;
  const classes = useModalStyles();

  const { fecharCadastroCategoria, abrirCadastroCategoria } = useCadastros();
  const { removeCategoryCache } = useDropCache()
  const { getProdutoCategoriaById, carregando: carregandoGet } =
    useGetProdutoCategoriaById();
  const { putProdutoCategoria, carregando: carregandoPut } =
    usePutProdutoCategoria();
  const { postProdutoCategoria, carregando: carregandoPost } =
    usePostProdutoCategoria();
  const { postImagemBase64, carregando: carregandoPostImagem } = usePostImagemBase64()
  const { getPessoa } = useSessaoAtual()

  const { getProdutoCategorias, carregando: carregandoCategorias } = useGetProdutoCategorias()
  const carregando =
    carregandoPut ||
    carregandoGet ||
    carregandoPost ||
    preenchendoTela ||
    carregandoPostImagem ||
    carregandoCategorias;

  const [categoriaFormState, setCategoriaForm] =
    useState<CategoriaEditFormModel>(new CategoriaEditFormModel());
  const [ultimaOrdem, setUltimaOrdem] = useState<number>(0)
  const refEditForm = useRef<DefaultFormRefs<CategoriaEditFormModel>>(null);
  const refProdutoCategoriaModel = useRef<ProdutoCategoriaModel>(
    new ProdutoCategoriaModel(),
  );
  const redirect = useRef<boolean>(true);

  const recarregarForm = useCallback((model: CategoriaEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(categoriaFormState);
  }, [categoriaFormState, recarregarForm]);

  const formatCategoria = useCallback((categoria: CategoriaEditFormModel) => {
    //doideiras malucas abaixo
    //aqui eu trato no primeiro get lá para preencher o input
    categoria.categoriaPaiId =
      categoria.categoriaPaiId === null
        ? guidEmpty()
        : categoria.categoriaPaiId;

    categoria.breadCrumbs = categoria.breadCrumbs.substring(
      0,
      categoria.breadCrumbs.lastIndexOf(categoria.nome),
    );
    const lastIndex = categoria.breadCrumbs.lastIndexOf('>');
    if (lastIndex === categoria.breadCrumbs.length - 2) {
      categoria.breadCrumbs = categoria.breadCrumbs.substring(0, lastIndex);
    }

    categoria.breadCrumbs = categoria.breadCrumbs.trimEnd();

    if (categoria.breadCrumbs.length === 0) {
      categoria.breadCrumbs = 'Sem Categoria Pai';
    }
    return categoria;
  }, []);

  const getCategoriaByIdWrapper = useCallback(async () => {
    const res = await getProdutoCategoriaById(props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as ProdutoCategoriaModel;
    ret.foto = (ret.foto !== null && ret.foto !== '') ? atob(ret.foto) : ''
    refProdutoCategoriaModel.current = ret;
    let categoria = picker<CategoriaEditFormModel>(
      ret,
      new CategoriaEditFormModel(),
    );

    return formatCategoria(categoria);
  }, [getProdutoCategoriaById, props.id, formatCategoria]);

  const getCategoriasWrapper = useCallback(async () => {
    const res = await getProdutoCategorias('')
    if (res.erro) {
      throw res.erro
    }
    const categorias = res.resultado?.data.totalResults
    setUltimaOrdem(categorias)
  }, [getProdutoCategorias])


  useEffect(() => {
    (async () => {
      try {
        getCategoriasWrapper()
        setPreenchendoTela(true);
        if (props.id) {
          const categoria = await getCategoriaByIdWrapper();
          setCategoriaForm(categoria);
        }
      } catch (e: any) {
        showToast('error', e.message);
      } finally {
        setPreenchendoTela(false)
      }
    })();
    return () => {
      //funcao de limpeza
      setCategoriaForm(new CategoriaEditFormModel());
    };
  }, [showToast, getCategoriaByIdWrapper, props.id, getCategoriasWrapper]);

  const enviarImagem = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `produtos/categorias/${getPessoa()?.pessoa?.contratoId}/`,
          newGuid(),
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno,
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [getPessoa, postImagemBase64],
  );

  const saveNewCategoria = useCallback(
    async (categoriaModel: CategoriaEditFormModel) => {
      let categoria: ProdutoCategoriaModel = categoriaModel;

      if (categoria.foto.length > 0) {
        categoria.foto = await enviarImagem(categoriaModel.foto)
      }

      categoria.categoriaPaiId = null;
      if (categoria.ordem === 0) {
        categoria.ordem = ultimaOrdem + 1
      }
      const ret = await postProdutoCategoria(categoria);
      if (ret.erro) throw ret.erro;
      removeCategoryCache(EnumDropCache.Categoria)
      handleAtualizarLista(true)

      if (redirect.current) {
        abrirCadastroCategoria(ret.resultado?.data.id, pathName, true);
      } else {
        refEditForm.current!.resetForm();
      }

      showToast('success', 'Categoria adicionada com sucesso!');

      return categoria
    },
    [abrirCadastroCategoria, enviarImagem, handleAtualizarLista, pathName, postProdutoCategoria, removeCategoryCache, showToast, ultimaOrdem],
  );

  const saveChangesCategoria = useCallback(
    async (categoriaModelForm: CategoriaEditFormModel) => {
      let categoria = picker<ProdutoCategoriaModel>(
        categoriaModelForm,
        refProdutoCategoriaModel.current,
      );

      const imagemIgual =
        categoria.foto === refProdutoCategoriaModel.current.foto

      if (categoria.foto !== "" && !imagemIgual) {
        categoria.foto = await enviarImagem(categoria.foto)
      } else if (categoria.foto !== '') {
        categoria.foto = btoa(categoria.foto)
      }

      //achei que estava mudando isso, mas deixei para previnir
      categoria.breadCrumbs = refProdutoCategoriaModel.current.breadCrumbs;

      categoria.categoriaPaiId =
        categoria.categoriaPaiId === guidEmpty()
          ? null
          : categoria.categoriaPaiId;
      const ret = await putProdutoCategoria(categoria);
      if (ret.erro) throw ret.erro;
      removeCategoryCache(EnumDropCache.Categoria)
      handleAtualizarLista(true)

      showToast('success', 'Registro de Categoria atualizado com sucesso!');
      setCategoriaForm(categoriaModelForm);
    },
    [enviarImagem, handleAtualizarLista, putProdutoCategoria, removeCategoryCache, showToast],
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroCategoria('', '', true);
  }, [abrirCadastroCategoria]);

  const handleSubmit = useCallback(
    async (
      model: CategoriaEditFormModel,
      beforeModel: CategoriaEditFormModel,
    ) => {
      try {
        const categoriaEqual = isEqual(model, beforeModel);

        if (props.id === '') {
          await saveNewCategoria(model);
          return
        }

        if (categoriaEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
        }

        await saveChangesCategoria(model);

      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [props.id, saveChangesCategoria, saveNewCategoria, showToast],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    refEditForm.current?.submitForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroCategoria(atualizarLista);
    handleAtualizarLista(false)
  }, [atualizarLista, fecharCadastroCategoria, handleAtualizarLista]);

  const changeStatus = useCallback(async () => {
    try {
      let model = await getCategoriaByIdWrapper();
      model.ativo = !model.ativo;

      await saveChangesCategoria(model);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getCategoriaByIdWrapper, saveChangesCategoria, showToast]);

  const isEdicao = props.id

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={
          props.id !== '' ? 'Edição de Categoria' : 'Cadastro de Categoria'
        }
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          <MenuOptions
            options={[
              new MenuOptionsModel(
                `${categoriaFormState.ativo ? 'Desabilitar' : 'Habilitar'
                } categoria`,
                <></>,
                changeStatus,
              ),
            ]}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormCategoriaEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            categoriasList={[] as Array<ProdutoCategoriaModel>}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={isEdicao ? 6 : 12} md={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={() => {
                  if (props.id === '') {
                    submitForm(false);
                  } else {
                    redirectToCadastro();
                  }
                }}
              >
                <NovoIcon tipo="BUTTON" />
                {isEdicao ? 'Novo' : 'Salvar e Adicionar Outro'}
              </Button>
            </Grid>

            <Grid item xs={isEdicao ? 6 : 12} md={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  if (props.id === '') {
                    submitForm(true);
                  } else {
                    refEditForm.current?.submitForm();
                  }
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                {isEdicao ? 'Salvar' : 'Salvar e Editar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CategoriaEdit;
