import { FinalizarVendaModel } from "model/app/mov-simples/finalizar-venda-model";
import { EnumIdeStatus } from "model/enums/enum-ide-status";
import { EnumMovModelo } from "model/enums/enum-mov-modelo";
import { EnumTpEmis } from "model/enums/enum-tp-emis";
import { guidEmpty } from "utils/guid-empty";
import { EnumTpAmb } from "../../../../enums/enum-tpamb";
import { PedidoModel } from "../../pedido/pedido-model";
import { PessoaModel } from "../../pessoa";
import { MovSimplesPagamentoModel } from "./mov-simples-pagamento-model";
import { MovSimplesProdutoModel } from "./mov-simples-produto-model";
import { toDateStringApi } from 'utils/to-date';
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";
import { CobrModel, ReceitaMedicaModel } from "../../venda/venda-completa-model";
import { MovSimplesPessoaModel } from "./mov-simples-pessoa-model";

export class PagAntecipadoModel {
    constructor(
        public vTotal: number = 0,
        public vPago: number = 0,
    ) { }
}

export class InformacoesGeraisPedidoModel {
    constructor(
        public salaoId: string = '',
        public mesaId: string | null = '',
        public balcaoIdentificado: boolean = false,
        public comandaId: string = '',
        public codigoMesa: string = '',
        public codigoComanda: string = '',
        public codigoPedido: string = '',
        public pedidos: PedidoModel[] = [],
        public identificador?: string,
        public isDelivery?: boolean,
        public pagAntecipado?: PagAntecipadoModel,
    ) { }
}

export class MovDescAcrecInfoModel {
    constructor(
        public tipo: EnumTpDescontoAcrescimo = EnumTpDescontoAcrescimo.Porcentagem,
        public vPorcentagem: number = 0,
        public vValorFixo: number = 0,
    ) { }
}

export class MovSimplesModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public operadorId: string = guidEmpty(),
        public sessaoId: any = null,
        public status: EnumIdeStatus = EnumIdeStatus.EMDIGITACAO,
        public tpAmb: EnumTpAmb = EnumTpAmb.Homologacao,
        public dEmi: Date | string = toDateStringApi(new Date()),
        public serie: number = 0,
        public nnf: number = 0,
        public mod: EnumMovModelo = EnumMovModelo.ORCAMENTO,
        public documentoNaNota: boolean = false,
        public pessoaId: string = guidEmpty(),
        public documento: string = "",
        public infAdicional: string | undefined = undefined,
        public regimeTributarioId: string = guidEmpty(),
        public produtos: MovSimplesProdutoModel[] = [],
        public cobr: CobrModel | undefined = undefined,
        public pags: MovSimplesPagamentoModel[] = [],
        public cliente: MovSimplesPessoaModel | undefined = undefined,
        public transportadora: PessoaModel | undefined = undefined,
        public vProd: number = 0,
        public vNF: number = 0,
        public vProdMax: number = 0,
        public vDesc: number = 0,
        public vDescEmbutido: number = 0,
        public vSeg: number = 0,
        public vOutro: number = 0,
        public vOutroEmbutido: number = 0,
        public vFrete: number = 0,
        public vTaxaEntrega: number = 0,
        public vServicos: number = 0,
        public obsPagDelivery: string = '',
        public vPago: number = 0,
        public vTroco: number = 0,
        public qtdeItens: number = 0,
        public qComItens: number = 0,
        public qCom: number = 0,
        public pessoas: number = 0,
        public idNFe: string = guidEmpty(),
        public tpEmis: EnumTpEmis = EnumTpEmis.OFFLINE,

        // vTotTrib soma de todos vtrib dos produtos na venda
        public vTotTrib: number = 0,

        public clienteIdentificado: boolean = false,
        public retornoFinalizacao: FinalizarVendaModel | undefined = undefined,

        // DADOS PEDIDO
        public informacoesGeraisPedido: InformacoesGeraisPedidoModel = new InformacoesGeraisPedidoModel(),
        //VARIAVEL PARA CONTROLE DE PROMOÇÕES PARA CONSEGUIRMOS APLICAR NO FLUXO DA VENDA O METODO DE FATURAR NOTA
        public isFaturada = false,

        public telefone: string = '',

        public receitasMedicas: ReceitaMedicaModel[] = [],

        public senhaChamada: number = 0,

        //pra saber se a taxa de serviço foi alterada manualmente e não recalcular
        public taxaManual: boolean = false,
    ) { }
}