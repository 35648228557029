import { Button } from 'views/design-system';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useFormStepper } from '../../../components/form-stepper';
import { ContratoUsuarioFormModel } from '../../../../model/app/forms/master/contrato-usuario-form';
import { DefaultFormRefs } from '../../../components/form/utils/form-default-props';
import { AvancarIcon } from '../../../components/icons/avancar-icon';
import { FormStep } from '../../../../model/app/form-stepper';
import { NovaPessoaIcon } from '../../../components/icons/nova-pessoa-icon';
import { VoltarIcon } from '../../../components/icons/voltar-icon';
import { useGetUsuarioValidarCadastro } from '../../../../data/api/gestao/usuario/get-validar-cadastro';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { LojaIcon } from '../../../components/icons/loja-icon';
import { FormContratoDocumento } from '../../../components/form/master/contrato-documento/form-contrato-documento';
import { ContratoDocumentoFormModel } from '../../../../model/app/forms/master/contrato-documento-form';
import { useGetEmpresaValidarExistente } from '../../../../data/api/gestao/empresa/get-empresa-validar';
import { useConsultaCnpj } from '../../../../data/api/wsmaster/consultar-cnpj';
import { EmpresaFormModel } from '../../../../model/app/forms/master/empresa-form';
import { stringNumeros } from "utils/string-numeros"
import { OkIcon } from '../../../components/icons/ok-icon';
import { useGetSegmentoCnae } from '../../../../data/api/gestao/segmentos';
import { SegmentoModel } from '../../../../model/api/gestao/master/segmento';
import { IdentidadeNovoContratoProps } from '../../../../data/api/gestao/identidade';
import { EmpresaModel } from '../../../../model/api/gestao/master/empresa';
import { usePostIdentidadeNovoContrato } from '../../../../data/api/gestao/identidade/post-novo-contrato';
import { picker } from '../../../../utils/picker';
import { LocalizacaoModel } from 'model/api/gestao/localizacao/localizacao-model';
import { GestaoStorageKeys, useGestaoStorage, useGestaoToken, useSessaoAtual, useToastSaurus } from 'services/app';
import { useDevice } from 'services/app/hooks/device';
import { VariaveisAmbiente, sistemaId } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { isEmpty } from 'lodash';
import { retornaLabelCPFCNPJ } from 'utils/cpfcnpj-validate';
import { FormContratoUsuario } from 'views/components/form/master/contrato-usuario/form-contrato-usuario';
import { FormEmpresa } from 'views/components/form/master/empresa/form-empresa';
import { useTermosDeUsoStore } from 'views/states/termos-de-uso-store';

export const useNovoContratoPageLogic = () => {
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();
    const history = useHistory();
    const { currentStep, nextStep, prevStep } = useFormStepper(4);
    const contratoUsuarioRefs = useRef<DefaultFormRefs<ContratoUsuarioFormModel>>(null);
    const contratoDocumentoRefs = useRef<DefaultFormRefs<ContratoDocumentoFormModel>>(null);
    const empresaFormRefs = useRef<DefaultFormRefs<EmpresaFormModel>>(null);
    const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>(undefined)
    const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
    const { postIdentidadeNovoContrato, carregando: carregandoNovoContrato } = usePostIdentidadeNovoContrato()
    const { delRegistro, getRegistro } = useGestaoStorage()
    const { getDadosTerminal, carregando: carregandoAndroid } = useDevice()
    const termosAceitos = useTermosDeUsoStore(store => store.state.termosAceitos)
    const { carregando: carregandoGetUsuarioValidarCadastro, getUsuarioValidarCadastro } = useGetUsuarioValidarCadastro();
    const { carregando: carregandoGetEmpresaValidarExitente, getEmpresaValidarExitente } = useGetEmpresaValidarExistente();
    const [carregandoFromForms, setCarregandoFromForms] = useState(false);
    const { carregando: carregandoGetSegmentos, getSegmentoCnae } = useGetSegmentoCnae();
    const { carregando: carregandoLogin, setarUser, usuario, validaUsuarioConectado } = useSessaoAtual();
    const { isTokenExpired } = useGestaoToken();
    const [carregandoConsultaCnpj, setCarregandoConsultaCnpj] = useState<boolean>(false)
    const [exibirCampoApelido, setExibirCampoApelido] = useState<boolean>(false)
    const loading: boolean =
        carregandoGetSegmentos ||
        carregandoGetUsuarioValidarCadastro ||
        carregandoGetEmpresaValidarExitente ||
        carregandoCNPJ ||
        carregandoFromForms ||
        carregandoNovoContrato ||
        carregandoLogin ||
        carregandoAndroid ||
        carregandoConsultaCnpj;

    const usuarioModel = useRef<ContratoUsuarioFormModel>(new ContratoUsuarioFormModel());
    const documentoModel = useRef<ContratoDocumentoFormModel>(new ContratoDocumentoFormModel());
    const empresaModel = useRef<EmpresaFormModel>(new EmpresaFormModel());

    const finalizarCadastro = useCallback(async () => {
        try {
            const planoId = new URLSearchParams(history.location.search).get('planoId');

            const novoContrato = new IdentidadeNovoContratoProps();
            novoContrato.nome = usuarioModel.current.saudacao;
            novoContrato.email = usuarioModel.current.email;
            novoContrato.senha = usuarioModel.current.senha;
            novoContrato.senhaConfirmacao = usuarioModel.current.confirmar;
            novoContrato.apelido = usuarioModel.current.apelido;
            novoContrato.telefone = stringNumeros(empresaModel.current.fone);
            novoContrato.sistemaId = sistemaId;

            if(!isEmpty(planoId)){
                novoContrato.planoId = planoId;
            }

            novoContrato.empresa = picker<EmpresaModel>(empresaModel.current, novoContrato.empresa);

            const ret = await postIdentidadeNovoContrato(novoContrato, getRegistro(GestaoStorageKeys.ChaveAtivacao));
            if (ret.erro) {
                throw ret.erro;
            }

            try {
                await setarUser(ret.resultado?.data.accessToken, false)
                showToast("success", "Bem vindo! Seu cadastro foi finalizado e agora fique à vontade para utilizar o sistema.");
            } catch (e: Error | any) {
                showToast("error", "Erro ao realizar o Login após o cadastramento. Tente realizar o login manualmente. Detalhe: " + e.message);
            }
            finally {
                delRegistro(GestaoStorageKeys.ChaveAtivacao)
            }
        } catch (e: Error | any) {
            showToast("error", "Erro ao Inicializar seu Contrato. Tente novamente em alguns instantes. Detalhe: " + e.message);
        }
    }, [history.location.search, postIdentidadeNovoContrato, getRegistro, setarUser, showToast, delRegistro]);

    useEffect(() => {
        // EFFECT PARA DIRECIONAR APOS FINALIZAR O CONTRATO
        if (usuario) {
            let allowRedirect = isTokenExpired(usuario) ? validaUsuarioConectado() : true;
            if(allowRedirect){
                history.push("/");
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, usuario]);

    const getCnpjMaquininha = useCallback(async () => {
        try {
            if ((VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) && isEmpty(documentoModel.current.documento)) {
                const result = await getDadosTerminal()
                if (result === null) {
                    setCarregandoConsultaCnpj(false)
                    return
                }
                const ret = await getEmpresaValidarExitente(stringNumeros(result.cnpj));
                if (ret.erro) {
                    throw ret.erro;
                }
                const cpfCnpjLabel = retornaLabelCPFCNPJ(result.cnpj)
                if (ret?.resultado?.data === true) {
                    confirm({
                        title: `${cpfCnpjLabel} Detectado`,
                        description: `Detectamos um ${cpfCnpjLabel} em seu dispositivo. Já existe uma conta vinculada com o ${cpfCnpjLabel} ${formatarCPFCNPJ(result.cnpj)} , deseja acessá-la ou cadastrar outro CPF/CNPJ?`,
                        cancellationText: 'Cadastrar outro CPF/CNPJ',
                        confirmationText: 'Voltar ao Login',
                        confirmationButtonProps: {
                            style: {
                                padding: 8
                            }
                        },
                        cancellationButtonProps: {
                            style: {
                                padding: 8
                            }
                        }
                    }).then(() => {
                        setCarregandoConsultaCnpj(false)
                        history.push('/login')
                    }).catch(() => setCarregandoConsultaCnpj(false))
                    return
                }

                confirm({
                    title: `${cpfCnpjLabel} Detectado`,
                    description: `Detectamos um ${cpfCnpjLabel} em seu dispositivo. Desejar utiliza o ${cpfCnpjLabel} ${formatarCPFCNPJ(result.cnpj)} no cadastro?`,
                    cancellationText: 'Cadastrar outro CPF/CNPJ',
                    confirmationText: `Utilizar ${cpfCnpjLabel}`,
                    confirmationButtonProps: {
                        style: {
                            padding: 8
                        }
                    },
                    cancellationButtonProps: {
                        style: {
                            padding: 8
                        }
                    }
                }).then(() => {
                    contratoDocumentoRefs.current?.fillForm(new ContratoDocumentoFormModel(result.cnpj))
                    contratoDocumentoRefs.current?.submitForm()
                    setCarregandoConsultaCnpj(false)
                }).catch(() => setCarregandoConsultaCnpj(false))
            } else {
                setCarregandoConsultaCnpj(false)
            }
        } catch (e: any) {
            setCarregandoConsultaCnpj(false)
        }
    }, [confirm, getDadosTerminal, getEmpresaValidarExitente, history])

    const voltarStep = useCallback(() => {
        switch (currentStep) {
            case 0:
                if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) {
                    history.push('/novo-contrato-redirecionar');
                    break;
                }
                history.push('/login');
                break;
            case 1:
            case 2:
                prevStep();
                break;
        }
    }, [currentStep, history, prevStep]);

    const avancarStep = useCallback(() => {
        switch (currentStep) {
            case 0:
                contratoUsuarioRefs.current?.submitForm();
                break;
            case 1:
                contratoDocumentoRefs.current?.submitForm();
                break;
            case 2:
                empresaFormRefs.current?.submitForm();
                break;
        }
    }, [currentStep]);

    const voltarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="outlined"
                fullWidth={true}
                disabled={loading}
                onClick={voltarStep} >
                <VoltarIcon tipo="BUTTON" />
                Voltar
            </Button>
        )
    }, [voltarStep, loading]);

    const avancarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={loading}
                onClick={avancarStep} >
                <AvancarIcon tipo="BUTTON_PRIMARY" />
                Avançar
            </Button>
        )
    }, [loading, avancarStep]);

    const finalizarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={loading || !termosAceitos}
                onClick={avancarStep} >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Finalizar Cadastro
            </Button>
        )
    }, [loading, termosAceitos, avancarStep]);

    useEffect(() => {
        switch (currentStep) {
            case 0:
                contratoUsuarioRefs.current?.fillForm(usuarioModel.current);
                break;
            case 1:
                getCnpjMaquininha();
                contratoDocumentoRefs.current?.fillForm(documentoModel.current);
                break;
            case 2:
                empresaFormRefs.current?.fillForm(empresaModel.current);
                break;
        }
    }, [confirm, currentStep, getCnpjMaquininha, getDadosTerminal, getEmpresaValidarExitente, history, showToast]);

    const getFormUsuario = useCallback((): JSX.Element => {
        const handleSubmitFormUsuario = async (usuario: ContratoUsuarioFormModel) => {
            try {
                const retEmail = await getUsuarioValidarCadastro('email', usuario.email);
                if (retEmail.statusCode === 400 || retEmail.resultado?.data === true) {
                    confirm({
                        title: 'Já está cadastrado?',
                        description: 'O e-mail "' + usuario.email + ' já consta em nossa base de usuários. Não será possível prosseguir com este e-mail. Selecione uma ação abaixo: ',
                        cancellationText: 'Trocar o e-mail',
                        confirmationText: 'Recuperação de Senha',
                    })
                        .then(() => {
                            history.push('/recuperar-senha');
                        })
                        .catch(() => {
                            contratoUsuarioRefs.current?.fillForm(usuario);
                        });
                    return;
                } else if (retEmail.erro) {
                    throw retEmail.erro;
                }

                const retApelido = await getUsuarioValidarCadastro('apelido', usuario.apelido);
                if (retApelido.statusCode === 400 || retApelido.resultado?.data === true) {
                    confirm({
                        title: 'Já está cadastrado?',
                        description: 'O Apelido "' + usuario.apelido + '" já consta em nossa base de usuários. Não será possível prosseguir com este apelido. Selecione uma ação abaixo: ',
                        cancellationText: 'Trocar o Apelido',
                        confirmationText: 'Recuperação de Senha',
                    })
                        .then(() => {
                            history.push('/recuperar-senha');
                        })
                        .catch(() => {
                            setExibirCampoApelido(true)
                            contratoUsuarioRefs.current?.fillForm(usuario);
                        });
                    return;
                } else if (retApelido.erro) {
                    throw retApelido.erro;
                }

                usuarioModel.current = usuario;
                setCarregandoConsultaCnpj(true)
                nextStep();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };
        return (
            <FormContratoUsuario habilitarCampoApelido={setExibirCampoApelido} campoApelido={exibirCampoApelido} showLoading={false} ref={contratoUsuarioRefs} loading={loading} onSubmit={handleSubmitFormUsuario} />
        );
    }, [exibirCampoApelido, loading, getUsuarioValidarCadastro, nextStep, confirm, history, showToast]);

    const getFormDocumento = useCallback((): JSX.Element => {

        if ('geolocation' in navigator) {
            var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setLocalizacao(
                        new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
                    );
                },
                function (error) {

                },
                options,
            );
        }

        const handleSubmitFormDocumento = async (modelo: ContratoDocumentoFormModel) => {
            try {
                empresaModel.current = new EmpresaFormModel(modelo.documento);
                documentoModel.current = modelo

                const ret = await getEmpresaValidarExitente(stringNumeros(modelo.documento));

                if (ret.erro) {
                    throw ret.erro;
                }
                if (ret?.resultado?.data === true) {
                    throw new Error(`O CNPJ ${modelo.documento} já está em uso, favor entrar em contato com o administrador da empresa.`);
                }
                if (stringNumeros(modelo.documento).length === 14) {
                    //SE FOR CNPJ
                    try {
                        let empresa = await consultarCNPJ(modelo.documento as string, localizacao);
                        empresaModel.current.bairro = empresa.bairro;
                        empresaModel.current.cep = empresa.cep;
                        empresaModel.current.cMunicipio = empresa.cMunicipio;
                        empresaModel.current.complemento = empresa.complemento;
                        empresaModel.current.uf = empresa.cMunicipio.substring(0, 2);
                        empresaModel.current.logradouro = empresa.logradouro;
                        empresaModel.current.municipio = empresa.municipio;
                        empresaModel.current.nomeFantasia = empresa.nomeFantasia;
                        empresaModel.current.regime = empresa.regime;
                        empresaModel.current.numero = empresa.numero;
                        empresaModel.current.cnae = empresa.cnae;
                        empresaModel.current.razaoSocial = empresa.razaoSocial;
                        empresaModel.current.uf = empresa.uf;
                        empresaModel.current.ierg = empresa.ie
                        if (empresa.cnae && empresa.cnae !== '0000000') {
                            const resSegmento = await getSegmentoCnae(empresa.cnae);

                            if (resSegmento.erro) throw resSegmento.erro;

                            const data = resSegmento.resultado?.data as SegmentoModel[];

                            empresaModel.current.segmento = data[0].descricao;
                            empresaModel.current.segmentoId = data[0].id;
                        }
                    } catch (e: Error | any) {
                        console.error(e.message);
                    }
                } else if (stringNumeros(modelo.documento).length === 11) {
                    empresaModel.current.razaoSocial = usuarioModel.current.saudacao
                    empresaModel.current.nomeFantasia = usuarioModel.current.saudacao
                }
                nextStep();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };

        return (
            <FormContratoDocumento showLoading={false} ref={contratoDocumentoRefs} loading={loading} onSubmit={handleSubmitFormDocumento} />
        );
    }, [loading, getEmpresaValidarExitente, nextStep, consultarCNPJ, localizacao, getSegmentoCnae, showToast]);

    const getFormEmpresa = useCallback((): JSX.Element => {
        const handleSubmitFormEmpresa = async (modelo: EmpresaFormModel) => {
            try {
                empresaModel.current = modelo;

                const res = await getUsuarioValidarCadastro('telefone', stringNumeros(modelo.fone))

                if (res.statusCode === 400 || res.resultado?.data === true) {
                    confirm({
                        title: 'Já está cadastrado?',
                        description: 'O telefone "' + modelo.fone + '" já está cadastrado. Se deseja utilizá-lo, desvincule o número da conta cadastrada e tente novamente.',
                        cancellationText: 'Trocar o telefone',
                        confirmationText: 'Fazer login em outra conta',
                    })
                        .then(() => {
                            history.push('/login');
                        })
                    return
                } else if (res.erro) {
                    throw res.erro;
                }


                finalizarCadastro();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };
        return (
            <FormEmpresa showLoading={false} ref={empresaFormRefs} setCarregandoExterno={setCarregandoFromForms} loading={loading} onSubmit={handleSubmitFormEmpresa} />
        );
    }, [loading, getUsuarioValidarCadastro, finalizarCadastro, confirm, history, showToast]);

    const getFormArray = useMemo(() => {
        return [
            new FormStep(
                "Criar Conta",
                "Legal! Para ingressar, preencha os campos abaixo para iniciarmos seu cadastro. São apenas três passos para finalizarmos.",
                <NovaPessoaIcon tipo="GERAL" />,
                "Usuário",
                <NovaPessoaIcon tipo="GERAL" />,
                getFormUsuario(),
                voltarButton,
                avancarButton
            ),
            new FormStep(
                "Sua Empresa",
                "Agora informe o CNPJ da sua empresa para podermos personalizar seu ambiente corretamente.",
                <LojaIcon tipo="GERAL" />,
                "Empresa",
                <LojaIcon tipo="GERAL" />,
                getFormDocumento(),
                voltarButton,
                avancarButton
            ),
            new FormStep(
                stringNumeros(empresaModel.current.cpfcnpj).length === 11 ?
                    "Último Passo"
                    :
                    stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0 ?
                        "Último Passo"
                        :
                        "Último Passo",
                stringNumeros(empresaModel.current.cpfcnpj).length === 11 ?
                    `Seu cadastro está quase no final, ${usuarioModel.current.saudacao.split(' ')[0]}. Informe seu contato abaixo e clique em Finalizar Cadastro.`
                    :
                    stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0 ?
                        `Seu cadastro está quase no final, ${usuarioModel.current.saudacao.split(' ')[0]}. Informe seu contato abaixo e clique em Finalizar Cadastro.`
                        :
                        "Não identificamos os dados da sua empresa, informe os dados abaixo para finalizar seu cadastro.",
                <OkIcon tipo="GERAL" />,
                "Conclusão",
                <OkIcon tipo="GERAL" />,
                getFormEmpresa(),
                voltarButton,
                finalizarButton
            )
        ];
    }, [getFormUsuario, voltarButton, avancarButton, getFormDocumento, getFormEmpresa, finalizarButton]);

    return {
        formStepper: {
            currentStep,
            nextStep,
            prevStep
        },
        formArray: getFormArray,
        carregando: loading
    };


}