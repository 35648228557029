import { Typography, Grid, Box } from 'views/design-system';
import { CardProdutoProps } from './card-produto-busca-props';
import { memo, useRef } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { toDecimalString } from 'utils/to-decimal';
import useOnPress from 'utils/useOnPress';
import { usePromocoes } from 'services/app/hooks/promocoes';
import { useStyles } from './card-produto-busca-styles';
import classNames from 'classnames';
import { BarcodeIcon, CategoriaIcon } from 'views/components/icons';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { LaboratorioIcon } from 'views/components/icons/laboratorio-icon';
import { useCaptureCardNavigation } from '../components/default-card/utils/capture-card-navigation';
import { isEmpty } from 'lodash';

const CardProdutoBusca = ({ model, onClick, onHold, index }: CardProdutoProps) => {
  const cardClasses = useDefaultCardStyles();
  const { retornarPromocaoDePor } = usePromocoes();
  const classes = useStyles();
  const carrinhoBtn = useRef<HTMLDivElement | null>(null);

  const handlePress = () => {
    onHold(model);
  };

  const handleClick = async () => {
    carrinhoBtn.current?.classList.add(classes.fePulse);
    setTimeout(
      () => carrinhoBtn.current?.classList.remove(classes.fePulse),
      350,
    );
    onClick(model);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700
  };

  const EventPressAndClick = useOnPress(
    handlePress,
    handleClick,
    defaultOptions
  );

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={cardClasses.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={cardClasses.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={cardClasses.cardImg} alt={model.nome} />
      );
    }
  };


  const valorProduto = model.vPreco ?? 0;
  const valorPMC = model.medicamento?.precoMaximoConsumidor ?? 0;
  const valorPromocao = retornarPromocaoDePor(model)?.valorPromocao ?? 0;

  const valorDe = valorProduto > valorPMC ? valorProduto : valorPMC;
  const valorPor = valorPromocao < valorProduto && valorPromocao > 0 ? valorPromocao : valorProduto;

  const { cardRef } = useCaptureCardNavigation(index);

  return (
    <>
      <div ref={carrinhoBtn} {...EventPressAndClick}>
        <DefaultCard cardRef={cardRef}>
          <Grid
            item
            xs={12}
            className={classes.containerInternoCard}
          >
            <Grid container>
              <Grid item xs={12} container spacing={1}>
                <Grid className={classes.containerInfo}>
                  {img(model?.imagemUrl ?? '')}
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Grid container xs={12} flex alignItems='center'>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        style={{ lineHeight: '16px' }}
                      >
                        <Box style={{ fontWeight: 600 }}>{model.nome}</Box>
                      </Typography>

                      <Grid className={classes.tags} flex>
                        {model.codigos.map(cod =>
                          <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                            <BarcodeIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {cod.codigo}
                            </Typography>
                          </Grid>
                        )}

                        {model?.medicamento?.tarja !== undefined && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagTarja)}>
                            <MedicamentoIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {TarjaMedicamentoMock.find((tarja) => tarja.Key === model?.medicamento?.tarja)?.Value ?? ''}
                            </Typography>
                          </Grid>
                        )}
                        {model?.medicamento?.tipoMedicamento !== undefined && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagTipoMedicamento)}>
                            <MedicamentoIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {TipoMedicamentoMock.find((tipo) => tipo.Key === model?.medicamento?.tipoMedicamento)?.Value ?? ''}
                            </Typography>
                          </Grid>
                        )}
                        {!isEmpty(model?.medicamento?.laboratorioNome) && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagLaboratorio, (model.medicamento?.laboratorioNome ?? '').toUpperCase().indexOf("CIMED") > -1 ? classes.tagLaboratorioDestaque : undefined)}>
                            <LaboratorioIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {model?.medicamento?.laboratorioNome}
                            </Typography>
                          </Grid>
                        )}
                        {model?.medicamento?.classeTerapeutica !== undefined && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagClasse)}>
                            <CategoriaIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {model?.medicamento?.classeTerapeutica}
                            </Typography>
                          </Grid>
                        )}
                        {model?.medicamento?.composicao !== undefined && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagComposicao)}>
                            <MedicamentoIcon tipo="GERAL" />
                            <Typography variant="caption">
                              {model?.medicamento?.composicao}
                            </Typography>
                          </Grid>
                        )}
                        {valorDe - valorPor !== 0 && (
                          <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                            <PromocaoIcon tipo="GERAL" />
                            <Typography variant="caption">
                              <i>{valorDe - valorPor > 0 ? 'Desconto' : 'Acréscimo'}: </i>
                              <strong>R${' '}{toDecimalString(valorDe - valorPor, 2)}</strong>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.containerInfo} flex flexDirection='column' alignItems='flex-end' justifyContent='center'>
                  <Typography color="textPrimary" variant="caption" style={{ marginBottom: -4 }}>
                    Valor de Venda
                  </Typography>
                  {(valorDe > 0 && valorDe !== valorPor) && (
                    <Grid>
                      <Typography className={classes.textPriceRiscado} align='right' variant="body1">
                        <strong>
                          R$ {toDecimalString(valorDe, 2)} {model.medida}{valorDe === valorPMC ? '*' : ''}
                        </strong>
                      </Typography>
                    </Grid>
                  )}
                  <Typography className={classes.textPrice} align='right' variant="body1">
                    <strong>
                      R$ {toDecimalString(valorPor, 2)} {model.medida}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DefaultCard>
      </div >
    </>
  );
};

export default memo(CardProdutoBusca, (prev, next) => {
  if (prev !== next) return false;
  return true;
});
