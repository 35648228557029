import { guidEmpty } from 'utils/guid-empty';

export class ProdutoImpostoModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public regimeTributarioId: string = guidEmpty(),
    public cfop: string = "",
    public cBenef: string = "",
    public icmsCst: number = 0,
    public icmsModBc: number = 0,
    public icmsPRedBc: number = 0,
    public icmsPIcms: number = 0,
    public icmsModBcSt: number = 0,
    public icmsPMvaVUnidSt: number = 0,
    public icmsPredBcSt: number = 0,
    public icmsPIcmsSt: number = 0,
    public icmsMotDesIcms: number = 1,
    public icmsPBcOp: number = 0,
    public icmsUfSt: string = "",
    public icmsPCredSn: number = 0,
    public icmsPredSn: number = 0,
    public icmsPFcp: number = 0,
    public icmsPFcpSt: number = 0,
    public pisCst: number = 1,
    public pisPercVUnidPis: number = 0,
    public cofinsCst: number = 1,
    public cofinsPercVUnidCofins: number = 0,
    public ecfAliq: number = 0,
    public grupoImpostoId: string | null = null,
    public descricao: string = '',
  ) { }
}


