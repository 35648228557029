import { useGetSaloes } from "data/api/gestao/saloes/get-saloes"
import { SaloesModel } from "model/api/gestao/saloes/saloes-model"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app"
import { CircularLoading } from "views/components"
import { ButtonFab } from "views/components/controles"
import { useStyles } from "./saloes-list-styles"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"
import { SaloesListData } from "./saloes-list-data"
import { SalaoAddIcon } from "views/components/icons/salao-add-icon"
import { Grid } from "views/design-system"

export const SaloesList = () => {

    // AUX
    const { location } = useHistory();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { addHandler, removeHandler } = useEventTools()

    // STATES
    const [queryStatusSaloes, setQueryStatusSaloes] = useState({
        listSaloes: Array<SaloesModel>(),
    });

    // chamadas API
    const { getSaloes, carregando: carregandoSaloes } = useGetSaloes();

    // providers
    const {
        abrirCadastroSaloes,
    } = useCadastros();

    const { getEmpresaSelecionada } = useSessaoAtual();

    const carregando = carregandoSaloes;

    // Saloes
    const fillResultSaloes = useCallback(
        async (listSaloes: Array<SaloesModel>) => {
            setQueryStatusSaloes({
                listSaloes: listSaloes,
            });
        },
        [],
    );

    const handleSaloes = useCallback(
        async () => {
            const query = 'pageSize=0';
            try {
                const res = await getSaloes(
                    query,
                    getEmpresaSelecionada()!.Id,
                );
                if (res.erro) throw res.erro;

                fillResultSaloes(res.resultado?.data.list);
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [getSaloes, getEmpresaSelecionada, fillResultSaloes, showToast],
    );

    const onCardSalaoSelected = (id: string) => {
        abrirCadastroSaloes(id, location.pathname, true);
    };

    const onCardSalaoChecked = (id: string) => { };

    const onClickAdd = useCallback(() => {
        abrirCadastroSaloes('', '', true);
    }, [abrirCadastroSaloes]);

    useEffect(() => {
        handleSaloes();
    }, [handleSaloes]);

    useEffect(() => {
        const modalSaloesEdit = ({ openned, atualizarLista }: any) => {
            if (!openned && atualizarLista) handleSaloes()
        }
        addHandler(AppEventEnum.SaloesModal, modalSaloesEdit)
        return () => removeHandler(AppEventEnum.SaloesModal, modalSaloesEdit)
    }, [addHandler, handleSaloes, removeHandler])

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                    <Grid item xs={12} className={classes.listContainer}>
                        <SaloesListData
                            carregando={carregando}
                            list={queryStatusSaloes.listSaloes}
                            onCardChecked={onCardSalaoChecked}
                            onCardSelected={onCardSalaoSelected}
                            selectedList={[]}
                        ></SaloesListData>
                    </Grid>
                </Grid>
            </div>
            <ButtonFab
                tooltip="Adicionar Salão"
                icon={<SalaoAddIcon fill={'#fff'} tipo="BUTTON_FAB" />}
                onClick={onClickAdd}
            />
        </>
    )
}