import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    trocoContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column', flexGrow: 1 },
    container: {
        height: '100%',
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '100%',
    },
    height100: { height: 100 },
    pagamentosContainer: {
        overflowY: 'auto',
        flexGrow: 1
    }
}));