import { Fade, useTheme } from '@material-ui/core';
import { CotaModel } from 'model/api/gestao/cota/cota-model';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { getPlanoLimite } from 'utils/plano-utils';
import { CircularLoading, useThemeQueries } from 'views';
import { ConsumptionChart } from 'views/components/consumption-chart/consumption-chart';
import { CloseIcon } from 'views/components/icons/close-icon';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { styles } from './card-controle-cota-mesas-com-qrcode-styles';
import { toDecimal } from 'utils/to-decimal';
import { Button, Grid, Typography } from 'views/design-system';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';
import { useGetCotas } from 'data/api/gestao/cota/get-cota';

interface CardControleCotaMesasComQrCodeProps {
  atualizarCotas: React.MutableRefObject<boolean>
}

export const CardControleCotaMesasComQrcode = ({ atualizarCotas }: CardControleCotaMesasComQrCodeProps) => {
  const [showCard, setShowCard] = useState(true);
  const classes = styles();
  const history = useHistory();
  const { isMobile } = useThemeQueries();
  const theme = useTheme();
  const handleViewPlans = () => {
    history.push('/alterar-plano');
  };
  const { plano } = useSessaoAtual();
  const { getCotas, carregando: carregandoCotas } = useGetCotas();
  const carregando = carregandoCotas;
  const { showToast } = useToastSaurus();
  const [cotas, setCotas] = useState<CotaModel[]>([])

  const handleGetCotas = useCallback(
    async () => {
      const query = "codigo=" + EnumPlanoOpcoes.MesaComQrCode;
      try {
        const res = await getCotas(query);
        if (res.erro) throw res.erro;

        const resultado = res.resultado?.data
        setCotas(resultado)
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getCotas, setCotas, showToast],
  );

  useEffect(() => {
    if (history.location.pathname === RetaguardaRotasMock.gerenciarMesaComandaRota.path + '/mesas' && atualizarCotas.current) {
      handleGetCotas();
      atualizarCotas.current = false
    }
  }, [atualizarCotas, handleGetCotas, history.location.pathname]);

  const total = getPlanoLimite(plano?.plano, EnumPlanoOpcoes.MesaComQrCode);
  const consumed = cotas.filter(
    (user) => user.codigo === EnumPlanoOpcoes.MesaComQrCode && user.quant > 0,
  );
  const qtdConsumed = consumed.reduce((acc, cur) => {
    const res = acc + cur.quant
    return res
  }, 0)

  const percent = () => {
    return toDecimal((((qtdConsumed || 0) * 100) / total))
  };
  if (percent() < 80) return null

  return (
    <>
      {total >= 0 && (
        <Fade in={showCard} mountOnEnter unmountOnExit>
          <Grid className={classes.container}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <Grid className={classes.content}>
              <Grid container spacing={2} className={classes.containerInterno}>
                <Grid item className={classes.header}>
                  <Typography
                    style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
                    className={classes.textTitle}
                  >
                    Olá, fique atento aos limites do plano{' '}
                    <strong>{plano?.plano?.nome}</strong>!
                  </Typography>

                  <Button
                    color="secondary"
                    className={classes.buttonClose}
                    onClick={() => setShowCard(!showCard)}
                  >
                    <CloseIcon tipo="BUTTON_PRIMARY"></CloseIcon>
                  </Button>
                </Grid>

                <Grid item className={classes.contentSubtitle}>
                  <Grid className={classes.textInfoAndButton}>
                    {isMobile ? (
                      <Grid className={classes.chart}>
                        <Typography variant="caption" className={classes.text}>
                          Seu plano possui um limite de{' '}
                          <strong>{total} Mesas que geram pedido por QRcode </strong>

                        </Typography>
                      </Grid>
                    ) : (
                      <Grid className={classes.chart}>
                        <Typography variant="caption" className={classes.text} >
                          Seu plano possui um limite de{' '}
                          <strong>{total} Mesas que geram pedido por QRcode </strong>.
                        </Typography>
                        <br></br>
                        <Typography variant="caption" className={classes.text}>
                          Para aumentar a capacidade faça um
                          UPGRADE agora mesmo!
                        </Typography>
                      </Grid>
                    )}
                    <Grid>
                      {!carregando && (
                        <ConsumptionChart
                          quantidade
                          total={total}
                          consumed={
                            qtdConsumed || 0
                          }
                        />
                      )}
                    </Grid>

                    <Grid xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: theme.spacing(1),
                      }}
                    >
                      <Button
                        onClick={handleViewPlans}
                        variant="contained"
                        fullWidth
                        size="small"
                        className={classes.button}
                      >
                        <UpgradeIcon tipo="BUTTON" />
                        FAZER UPGRADE
                      </Button>

                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  );
};
