import { useStyles } from './card-comanda-atendimento-styles';
import { useThemeQueries } from 'views';
import { CardComandaAtendimentoProps } from './card-comanda-atendimento-props';
import { PessoasMesaIcon } from 'views/components/icons/pessoas-mesa-icon';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { toDecimalString } from 'utils/to-decimal';
import { useCallback } from 'react';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import useOnPress from 'utils/useOnPress';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { isEmpty } from 'lodash';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import classNames from 'classnames';
import { Box, Grid, Typography } from 'views/design-system';
import { MouseLeftClick } from 'views/components/icons/mouse-left-click';
import { TemTouch } from 'utils/tem-touch';
import { HoldPressIcon } from 'views/components/icons/hold-press-icon';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { DefaultCard } from '../components';

export const CardComandaAtendimento = ({
  model,
  onClick,
  onHold,
  modeloTrabalho
}: CardComandaAtendimentoProps) => {
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const auxActionOnClick = useCallback(() => ({
    [EnumModeloDeTrabalho.APENAS_CAIXA]: () => onHold(model),
    [EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS]: () => onHold(model),
    [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS]: () => onClick(model),
  }), [model, onClick, onHold])

  const auxActionOnHold = useCallback(() => ({
    [EnumModeloDeTrabalho.APENAS_CAIXA]: () => onClick(model),
    [EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS]: () => onClick(model),
    [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS]: () => onHold(model),
  }), [model, onClick, onHold])

  const handlePress = useCallback(() => {
    auxActionOnHold()[modeloTrabalho]();
  }, [auxActionOnHold, modeloTrabalho]);

  const handleClick = useCallback(() => {
    auxActionOnClick()[modeloTrabalho]();
  }, [auxActionOnClick, modeloTrabalho]);

  const EventPressAndClick = useOnPress(handlePress, handleClick, {
    shouldPreventDefault: true,
    delay: 700,
  });
  const ComandaOcupada = useCallback(
    (balcaoPedido: PedidoModelComanda) => {
      // DATA CRIACAO PEDIDO
      const horaCriacaoPedido = new Date(balcaoPedido?.dataCriacao).getHours();
      const minutosCriacaoPedido = new Date(
        balcaoPedido?.dataCriacao
      ).getMinutes();
      const pedidoDate = `${horaCriacaoPedido}:${minutosCriacaoPedido >= 10
        ? minutosCriacaoPedido
        : '0' + minutosCriacaoPedido
        }`;

      const hora = new Date(balcaoPedido?.systemUpdateDate ?? '').getHours();
      const minutos = new Date(
        balcaoPedido?.systemUpdateDate ?? ''
      ).getMinutes();
      const horaPedido = `${hora >= 10 ? hora : `0${hora}`}:${minutos >= 10 ? minutos : '0' + minutos
        }`;

      const fillColor = balcaoPedido.statusPedido.codigo === EnumStatusPedido.FECHADO
        ? theme.palette.warning.main
        : balcaoPedido.statusPedido.codigo ===
          EnumStatusPedido.IMPORTADO
          ? theme.palette.grey[500]
          : balcaoPedido.isOciosa
            ? theme.palette.error.main
            : ''

      return (
        <DefaultCard
          hasTagStatus={false}
          {...EventPressAndClick}
          className={classes.cardContainer}
          style={{
            borderColor: fillColor,
            boxShadow: balcaoPedido.isOciosa
              ? ''
              : '0px 3px 4px rgba(0, 0, 0, 0.09)'
          }}
        >
          <Grid container className={classes.cardContent}>
            <Box className={classes.longPressIcon}>
              {!TemTouch() ? (
                <MouseLeftClick
                  tipo="INPUT"
                  fill={fillColor}
                />
              ) : (
                <HoldPressIcon
                  tipo="INPUT"
                  fill={fillColor}
                />
              )}
            </Box>
            {!isEmpty(balcaoPedido.identificador) &&

              <Grid item xs={12}>
                <Box className={classes.identificador} flex alignItems='center' justifyContent='flex-end'>
                  <ComandasIcon
                    tipo="INPUT"
                    fill={fillColor}
                  />
                  <Typography
                    className={classes.codigoComanda}>
                    {balcaoPedido.identificador}
                  </Typography>
                </Box>
              </Grid>
            }
            {!isEmpty(model.codigoComanda) && isEmpty(balcaoPedido.identificador) &&
              <Grid item xs={12} >
                <Box flex alignItems='center' justifyContent='flex-end'>
                  <ComandasIcon
                    tipo="INPUT"
                    fill={fillColor}
                  />
                  <h4
                    className={classes.codigoComanda}>
                    {balcaoPedido?.codigoComanda}
                  </h4>
                </Box>
              </Grid>
            }

            {(!isEmpty(model.identificador) ? !model.identificador.includes(balcaoPedido.cliente.nomeFantasia.split(' ')[0]) : true) && <Grid flex item xs={12} alignItems='flex-end' justifyContent='flex-end' mb={1}>
              <PessoaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: isMobile ? '20px' : undefined }}
              />
              <Typography className={classNames(classes.textInfo, classes.handleOverflow)}>
                {balcaoPedido.cliente.nomeFantasia.split(' ')[0]}
              </Typography>
            </Grid>}
            <Grid item xs={12} className={classes.pessoasContainer}>
              <PessoasMesaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                {balcaoPedido.quantidadePessoas}{' '}
                {balcaoPedido.quantidadePessoas !== 1 ? 'Pessoas' : 'Pessoa'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.totalContainer}>
              <SacoDinheiroIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                R${' '}
                {toDecimalString(
                  balcaoPedido?.produtos
                    .filter(
                      (p) =>
                        (p.status.codigo ===
                          EnumStatusProdutoPedido.DISPONIVEL ||
                          p.status.codigo ===
                          EnumStatusProdutoPedido.TROCADO) &&
                        p.indFin
                    )
                    .reduce((acc, current) => acc + current.valorTotal, 0),
                  2
                )}
              </Typography>
            </Grid>
            {balcaoPedido.mesaCodigo && <Grid item xs={12} className={classes.totalContainer}>
              <MesaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                Mesa {balcaoPedido.mesaCodigo}
              </Typography>
            </Grid>}
            <Grid item xs={12} className={classes.footer}>
              <div className={classes.hourContent}>
                <Typography variant="body1">{pedidoDate}</Typography>
              </div>
              {balcaoPedido.statusPedido.codigo === EnumStatusPedido.FECHADO ? (
                <Grid className={classes.fechamentoContainer}>
                  <Typography variant="body1">Fechada</Typography>
                </Grid>
              ) : balcaoPedido.statusPedido.codigo ===
                EnumStatusPedido.IMPORTADO ? (
                <Grid className={classes.importadoContainer}>
                  <Typography variant="body1">Importado</Typography>
                </Grid>
              ) : (
                <>
                  <Typography
                    className={classes.textBottomInfo}
                    variant="body2"
                  >
                    Último produto às {horaPedido}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </DefaultCard >
      );
    },
    [theme.palette.warning.main, theme.palette.grey, theme.palette.error.main, EventPressAndClick, classes.cardContainer, classes.cardContent, classes.longPressIcon, classes.identificador, classes.codigoComanda, classes.textInfo, classes.handleOverflow, classes.pessoasContainer, classes.totalContainer, classes.footer, classes.hourContent, classes.fechamentoContainer, classes.importadoContainer, classes.textBottomInfo, model.codigoComanda, model.identificador, isMobile]
  );

  return <>{ComandaOcupada(model)}</>;
};
