import { useState } from "react";
import { Box } from "views/design-system";
import { DefaultModal, ModalHeader } from "../components";
import { useModalStyles } from "../utils/modal-styles";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { VoltarIcon } from "views/components/icons";
import { SwitchValeTroco } from "./components/switch-vale-troco";
import { ValeTroco } from "./components/vale-troco";
import { CircularLoading } from "views/components/utils";
import { useVale } from "services/app/hooks/vale";
import { useCadastroPadrao } from "services/app/hooks/cadastro-padrao";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useThemeQueries } from "views/theme";
import classNames from "classnames";
import { PessoaValeFormModel } from "model/app/forms/pessoa/pessoa-edit-form-model";
import { ValeModel } from "model/api/gestao/troca/vale-model";
import { useToastSaurus } from "services/app";
import { EnumStatusVale } from "model/enums/enum-vale";

enum EnumTelaModalValeTroco {
    SWITCH = 1,
    FORM = 2,
}

interface Props {
    openned: boolean;
    onSubmit: (vale?: ValeModel) => void;
    handleCancel: () => void;
}

export const ValeTrocoModal = (props: Props) => {

    const { fillValeModel, criarValeTroco, inserirRepique, atualizarStatusVale, carregando, mov } = useVale();
    const { getConsumidor } = useCadastroPadrao();
    const { showConfirm } = useConfirmSaurus();
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();

    const modalClasses = useModalStyles();

    const [tela, setTela] = useState<EnumTelaModalValeTroco>(EnumTelaModalValeTroco.SWITCH)
    const [valeState, setValeState] = useState<ValeModel | undefined>(undefined);

    const submitVale = async (vale: PessoaValeFormModel) => {
        try {
            const ret = await criarValeTroco(vale);
            if (ret) {
                await atualizarStatusVale(ret, EnumStatusVale.Confirmado);
                setValeState(ret);
                setTela(EnumTelaModalValeTroco.SWITCH);
                showToast('success', 'Vale Troco criado (Finalize a venda para imprimí-lo)!');
            }
        } catch {

        }
    }

    const confirmarOpcao = (
        callback: () => any,
        description: string,
    ) => {
        showConfirm({
            primaryButtonText: 'Confirmar',
            secondaryButtonText: 'Voltar',
            secondaryButtonColor: theme.palette.primary.main,
            title: 'Confirmar Opção',
            showIcon: false,
            description,
        }).then(() => callback())
    }

    const inserirVale = async () => {
        const consumidorPadrao = await getConsumidor();
        if (mov.clienteIdentificado && mov.cliente?.id !== consumidorPadrao?.id) {
            const vale = fillValeModel();
            await submitVale(vale);
            return
        }
        setTela(EnumTelaModalValeTroco.FORM)
    }


    const retornarTela = () => {
        switch (tela) {
            case EnumTelaModalValeTroco.SWITCH:
                return <SwitchValeTroco
                    finalizarVenda={props.onSubmit}
                    inserirRepique={() => {
                        confirmarOpcao(inserirRepique, 'Deseja confirmar o troco dessa venda como "repique"?')
                    }}
                    switchValeTroco={() => {
                        confirmarOpcao(inserirVale, 'Deseja confirmar o troco dessa venda como "Vale Troco"?')
                    }}
                    mov={mov}
                    vale={valeState}
                />
            default:
                return <ValeTroco
                    handleSubmit={submitVale}
                    voltar={() => setTela(EnumTelaModalValeTroco.SWITCH)}
                />
        }
    }

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned}
            variant='temporary'
            anchor='right'
        >
            <Box className={classNames('modal-id', modalClasses.root)}>
                <ModalHeader
                    title={'Validar Troco'}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={() => {
                                showConfirm({
                                    title: 'Cancelar Pagamento',
                                    description: 'Deseja cancelar o pagamento realizado?',
                                    primaryButtonText: 'Cancelar Pagamento',
                                    secondaryButtonText: 'Prosseguir',
                                    secondaryButtonColor: theme.palette.primary.main,
                                }).then(async () => {
                                    try {
                                        if (valeState) {
                                            await atualizarStatusVale(valeState, EnumStatusVale.Cancelado);
                                        }
                                        props.handleCancel()
                                    } catch {

                                    }
                                })
                                    .catch(() => { })
                            }}
                        />
                    }
                />
                <Box className={modalClasses.content} p={2}>
                    {carregando && <CircularLoading tipo='FULLSIZED' />}
                    {retornarTela()}
                </Box>
            </Box>
        </DefaultModal>
    )
}