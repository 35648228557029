import { useCallback, FocusEvent, useState } from "react";
import { useGetProdutos } from "data/api/gestao/produto/produto/get-produtos";
import { ProdutoResumidoModel } from "model/api/gestao/produto/produto/produto-resumido-model";

import {
  retornoAutoComplete,
  AutocompleteSaurus,
  NovoItemModel,
} from "../autocomplete-saurus/autocomplete-saurus";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { TpProdutoCapsLockMock } from "data/mocks/tp-produto-mock";
import { produtoPageNome } from "views/pages/private/cadastros/produto/produto-page";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { DropCacheToUseProps, EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";

export enum EnumNomeCodigo {
  Nome,
  Codigo,
  Todos
}

export interface AutocompleteMProdutosProps extends DropCacheToUseProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  permiteAdicionar?: boolean;
  textNovoItem?: string | NovoItemModel[];
  tpProduto?: EnumTipoProduto[];
  exibirTipo?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => Promise<any> | any;
  optSemProduto?: boolean;
  tipo?: EnumTipoProduto;
  nomeCodigo?: EnumNomeCodigo
  isCodigoNome?: boolean
}

export const AutocompleteProdutos = ({
  onChange,
  loadingExterno,
  useCache = true,
  ...props
}: AutocompleteMProdutosProps) => {
  const [produtosState, setProdutosState] = useState<Array<ProdutoResumidoModel>>(
    new Array<ProdutoResumidoModel>()
  );

  const [permiteNovo, setPermiteNovo] = useState<boolean>(true)

  const { getProdutos, carregando } = useGetProdutos();
  const { fetchData } = useDropCache<ApiListModel<ProdutoResumidoModel>>(getProdutos)
  const { getEmpresaAtual } = useEmpresaAtual()

  const getProdutosWrapper = useCallback(
    async (termo: string, nomeProduto: string) => {
      try {
        const params = [termo, getEmpresaAtual()?.id || ""]
        const ret = await fetchData({
          params,
          useCache,
          category: EnumDropCache.Produto,
          propId: props.propId ?? '',
          searchTerm: termo,
        });
        let produtos: Array<ProdutoResumidoModel> = new Array<ProdutoResumidoModel>();
        const list = ret?.data?.list ?? []
        if (list?.length > 0) {
          produtos = list as Array<ProdutoResumidoModel>;
          produtos = produtos.filter(x => props.nomeCodigo === EnumNomeCodigo.Codigo ? x.codigo : x).map(x => ({
            ...x,
            estendido: `${x.codigoAnvisa ? x.codigoAnvisa + '(COD. Anvisa) - ' : ''}${x.codigo ? x.codigo + ' - ' : ''}${x.nome}`
          }))
        }

        if (props.optSemProduto) {
          setProdutosState([
            {
              ...new ProdutoResumidoModel(),
              tipo: props.tipo ? props.tipo : EnumTipoProduto.Produto,
              nome: `Sem ${produtoPageNome(props.tipo ?? EnumTipoProduto.Produto, false)}`,
            },
            ...produtos
          ])
          return
        }

        if (props.permiteAdicionar) {
          const permiteNovo = produtos.reduce<boolean>((prev, curr) => curr.nome.trim().toUpperCase() === nomeProduto.trim().toUpperCase() ? false : prev, true)
          setPermiteNovo(permiteNovo)
        }
        setProdutosState(produtos);

      } catch (e: any) { }
    },
    [fetchData, getEmpresaAtual, props.nomeCodigo, props.optSemProduto, props.permiteAdicionar, props.propId, props.tipo, useCache]
  );

  const onPesquisa = useCallback((termo: string) => {
    let filtroTp = ''
    if (props.tpProduto && props.tpProduto.length === 1) {
      filtroTp = `&Tipo=${props.tpProduto[0]}`
    }

    getProdutosWrapper(`Generico=${termo}${filtroTp}&ativo=true`, termo);
  }, [getProdutosWrapper, props.tpProduto]);

  const onChangeWrapper = useCallback(
    async (value: retornoAutoComplete) => {
      if (onChange) await onChange(value);
      if (value.isNewVal) {
        onPesquisa(props.value ?? '')
      }
    }, [onChange, onPesquisa, props.value]);

  const optionKey = (value: EnumNomeCodigo | undefined) => ({
    [EnumNomeCodigo.Nome]: 'nome',
    [EnumNomeCodigo.Codigo]: 'codigo',
    [EnumNomeCodigo.Todos]: 'estendido',
  })[value || EnumNomeCodigo.Nome]

  return (
    <AutocompleteSaurus
      inputRef={props.inputRef}
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      opcoes={produtosState}
      optionLabelKey={optionKey(props.nomeCodigo)}
      optionValueKey={optionKey(props.nomeCodigo)}
      optionCategoryKey={props.exibirTipo ? "tipo" : undefined}
      categoryMock={props.exibirTipo ? TpProdutoCapsLockMock : undefined}
      onBlur={props.onBlur}
      onPesquisa={onPesquisa}
      value={props.value}
      allowSubmit={props.allowSubmit}
      tipoComplete={props.isCodigoNome ? 'PRODUTO' : undefined}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={!permiteNovo ? permiteNovo : props.permiteAdicionar}
      textoNovoItem={props.textNovoItem ? props.textNovoItem : "Adicionar: "}
    />
  );
};
