import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ValeModel } from 'model/api/gestao/troca/vale-model';
import { useCriptografia } from 'services/app/hooks/criptografia';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePDV } from 'services/app/hooks/pdv';

export function usePostVale() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { criptografar } = useCriptografia();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getPDV } = usePDV();

    const postVale = useCallback(
        async (troca: ValeModel) => {

            troca.referenceId = getEmpresaAtual()?.id || '';
            const str = getPDV()?.id + '|' + getEmpresaAtual()?.cpfcnpj + '|EMPTY';
            const auth = criptografar(str);
            
            return invocarApi({
                url: `/vale`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': String(auth),
                },
                data: JSON.stringify(troca),
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.apiTroca}/api`,
            })
        },
        [criptografar, getEmpresaAtual, getPDV, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postVale,
    };
}
