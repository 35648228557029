import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerInternoCard: {
        position: 'relative',
        padding: theme.spacing(1),
    },
    containerInfo: {
        display: 'flex',
    },
    tags: {
        flexWrap: 'wrap',
        gap: 4,
        marginTop: 4
    },
    tagProdutos: {
        cursor: 'default',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagTarja: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
        // [theme.breakpoints.down('xs')]: {
        //     display: 'none'
        // }
    },
    tagTipoMedicamento: {
        background: "#f7e0f3",
        color: theme.palette.text.primary
    },
    tagComposicao: {
        background: "#e2dbf7",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorio: {
        background: "#efefef",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorioDestaque: {
        background: "#fdf6d0",
        "& span": {
            fontWeight: 600,
        }
    },
    tagClasse: {
        background: "#e5f3ee",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagDesconto: {
        background: "#e0ebff",
        color: theme.palette.text.primary
    },
    textPriceRiscado: {
        lineHeight: '16px',
        textDecoration: 'line-through',
        color: theme.palette.text.secondary,
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    textPrice: {
        lineHeight: '16px',
        color: theme.palette.primary.main,
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    selected: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    "@keyframes pulse": {
        from: { transform: "scale(1)" },
        "50%": { transform: "scale(1.03)" },
        to: { transform: "scale(1)" }
    },
    fePulse: {
        animationName: "$pulse",
        animationDuration: ".3s",
        animationIterationCount: "1",
    }
}));

