import { Box, Button } from 'views/design-system';
import { useStyles } from './cliente-delivery-cadastro-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useRef } from 'react';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { PedidoDadosEnderecoModel, PedidoDadosModelPost, PedidoDadosModelPut } from 'model/api/gestao/pedido/pedido-dados-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { PedidoDadosIntegracaoModel } from 'model/api/gestao/pedido/pedido-integracao-model';
import { PessoaCadastroFormModel } from 'model/app/forms/pessoa/pessoa-cadastro-form-model';
import { PessoaContatosModel, PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { picker } from 'utils/picker';
import { usePostEnderecoPessoa, usePostPessoa, usePutEnderecoPessoa, usePutPessoa } from 'data/api/gestao/pessoa';
import { EnumCadastroTipo, EnumMovModelo } from 'model';
import { isEmpty, isEqual } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { newGuid } from 'utils/new-guid';
import { FormAtendimentoDelivery } from 'views/components/form/atendimento-delivery/form-cliente-delivery/form-cliente-delivery';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MovSimplesPessoaEnderecoModel, MovSimplesPessoaModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pessoa-model';
import { useConfirm } from 'material-ui-confirm';
import { useHistory, useLocation } from 'react-router-dom';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoDados } from 'data/api/gestao/pedido-dados/put-pedido-dados';
import { useGetPedidoSalaoById } from 'data/api/gestao/pedido/get-pedido-salao-by-id';
import { PedidoClienteModel } from 'model/api/gestao/pedido/pedido-cliente-model';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface ClienteDeliveryCadastroProps {
}

interface PedidoLocationProps {
    pedido: PedidoModel
}

export const ClienteDeliveryCadastro = ({ ...props }: ClienteDeliveryCadastroProps) => {
    const classes = useStyles();

    const { postPessoa, carregando: carregandoPessoa } = usePostPessoa();
    const { putPessoa, carregando: carregandoPutPessoa } = usePutPessoa();
    const { postEnderecoPessoa, carregando: carregandoEnderecoPessoa } = usePostEnderecoPessoa();
    const { putEnderecoPessoa, carregando: carregandoPutEnderecoPessoa } = usePutEnderecoPessoa();
    const { putPedidoDados, carregando: carregandoPutPedidoDados } = usePutPedidoDados()
    const { getPedidoSalaoById, carregando: carregandoGetPedidoId } = useGetPedidoSalaoById()
    const { setClientePessoa } = useMovAtual();
    const { avancarFluxoMov } = useMovRota();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getEmpresaSelecionada, getPessoa } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const { getMov } = useMovAtual()
    const confirm = useConfirm()
    const history = useHistory()
    const location = useLocation<PedidoLocationProps>()
    const { abrirDialogInformacaoAdicional } = useCadastros()
    const { redirectLanding } = useMovRota();
    const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

    const carregando = [
        carregandoPessoa,
        carregandoEnderecoPessoa,
        carregandoPutPessoa,
        carregandoPutEnderecoPessoa,
        carregandoPutPedidoDados,
        carregandoGetPedidoId,
    ].includes(true)

    const formRef = useRef<DefaultFormRefs<PedidoDadosModelPost>>(null);
    const pedidoState = location.state?.pedido

    useEffect(() => {
        setHeaderProps({
            title: 'Identificar Cliente',
            showDefinicoes: false,
            showMenuPdv: true,
            showFiltro: false
        })
        if (getMov()?.mod !== EnumMovModelo.DELIVERY && isEmpty(pedidoState)) {
            redirectLanding().catch((e) => {
                showToast('error', e.message);
            })
            return;
        }
    }, [getMov, history, pedidoState, redirectLanding, setHeaderProps, showToast])

    const compararObjetos = useCallback((pedidoDados: PedidoDadosModelPost, pessoa: PessoaModel, endereco?: PessoaEnderecoModel) => {
        const pessoaPedido = pedidoDados.cliente;
        const enderecoPedido = pedidoDados.enderecoEntrega;

        const pessoaTelefone = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || ''
        const pessoaEmail = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)?.valor || ''

        const isPessoalEqual = (
            pessoaPedido.cpfCnpj === pessoa.cpfcnpj &&
            pessoaPedido.email === pessoaEmail &&
            pessoaPedido.telefone === pessoaTelefone &&
            pessoaPedido.nomeFantasia === pessoa.nome
        )

        const isEnderecoEqual = endereco ? (
            enderecoPedido.bairro === endereco.bairro &&
            enderecoPedido.cep === endereco.cep &&
            enderecoPedido.codigoMunicipio === endereco.cMun &&
            enderecoPedido.complemento === endereco.complemento &&
            enderecoPedido.numero === endereco.numero &&
            enderecoPedido.uf === endereco.uf &&
            enderecoPedido.municipio === endereco.xMun &&
            enderecoPedido.logradouro === endereco.logradouro
        ) : false

        return {
            isEnderecoEqual,
            isPessoalEqual
        }
    }, [])

    const pedidoIntegradorWrapper = useCallback(() => {
        const integrador = new PedidoDadosIntegracaoModel();

        integrador.nomeIntegrador = "TouchOne";
        integrador.cnpjIntegrador = "11914993000123";
        integrador.credencialCliente = getEmpresaSelecionada()?.Id ?? '';
        integrador.codigoAplicacao = 990009859;

        return integrador;
    }, [getEmpresaSelecionada])

    const handlePostPessoa = useCallback(
        async (modelP: PessoaCadastroFormModel, contatos: PessoaContatosModel[]) => {
            const pessoaToCreate = picker<PessoaModel>(modelP, new PessoaModel());
            pessoaToCreate.contatos = contatos
            pessoaToCreate.contratoId = getPessoa().pessoa?.contratoId || ''
            pessoaToCreate.documentos = [];
            pessoaToCreate.enderecos = [];

            const ret = await postPessoa(pessoaToCreate);


            if (ret.erro) throw ret.erro

            return ret.resultado?.data
        },
        [getPessoa, postPessoa],
    );

    const handlePutPessoa = useCallback(async (model: PessoaModel) => {
        const ret = await putPessoa(model);

        if (ret.erro) throw ret.erro

        return ret.resultado?.data
    }, [putPessoa])

    const handleSubmit = useCallback(async (model: PedidoDadosModelPost, enderecoBeforeModel?: PessoaEnderecoModel, pessoaBeforeModel?: PessoaModel) => {
        let contatos: PessoaContatosModel[] = []
        let pessoaCompleto = new MovSimplesPessoaModel()
        let alterouCepTelefone = {
            cep: false,
            telefone: false
        }
        let atualizar = true
        if (!isEmpty(model.cliente.telefone)) {
            contatos.push(new PessoaContatosModel(
                newGuid(),
                guidEmpty(),
                EnumTipoPessoaContato.TELEFONE,
                model.cliente.telefone
            ))
        }

        if (!isEmpty(model.cliente.email)) {
            contatos.push(new PessoaContatosModel(
                newGuid(),
                guidEmpty(),
                EnumTipoPessoaContato.EMAIL,
                model.cliente.email
            ))
        }
        try {
            //Verificando se tem algum campo de endereço preenchido
            const isEnderecoEmpty = Object.values(model.enderecoEntrega).reduce<boolean>((prev, curr) => {
                if (!isEmpty(curr) || !prev) {
                    return false
                }

                return true
            }, true)

            //IF CASO A PESSOA JÁ EXISTA
            const { isEnderecoEqual, isPessoalEqual } = compararObjetos(model, pessoaBeforeModel || new PessoaModel(), enderecoBeforeModel)
            if (pessoaBeforeModel && pessoaBeforeModel?.id !== guidEmpty()) {
                if (pessoaBeforeModel.contatos.find(x => x.tipo === EnumTipoPessoaContato.TELEFONE) || enderecoBeforeModel?.cep) {
                    alterouCepTelefone = {
                        cep: !isEnderecoEqual && !isEqual(model.enderecoEntrega.cep, enderecoBeforeModel?.cep),
                        telefone: !isPessoalEqual && !isEqual(model.cliente.telefone, pessoaBeforeModel.contatos.find(x => x.tipo === EnumTipoPessoaContato.TELEFONE)?.valor)
                    }
                    if (
                        (Object.values(alterouCepTelefone).includes(true))
                    ) {
                        await confirm({
                            title: 'Mudança de cadastro',
                            description: 'Teve uma mudança entre os campos de telefone ou CEP, deseja atualizar o cadastro do cliente com os novos valores ou apenas alterar para esta venda?',
                            confirmationText: 'Atualizar cadastro',
                            cancellationText: 'Esta venda',
                            cancellationButtonProps: {
                                variant: 'outlined',
                                color: 'primary'
                            }
                        })
                            .then(() => atualizar = true)
                            .catch(() => atualizar = false)
                    }
                }
                contatos = contatos.map(contato => ({
                    ...contato,
                    cadastroId: pessoaBeforeModel.id
                }))

                if (!isPessoalEqual) {
                    const pessoaPutModel = {
                        ...pessoaBeforeModel,
                        contatos,
                        cpfcnpj: model.cliente.cpfCnpj,
                        nome: model.cliente.nomeFantasia,
                    }
                    if (atualizar) {
                        await handlePutPessoa(pessoaPutModel)
                    }
                    pessoaCompleto = {
                        ...pessoaPutModel,
                        tpCadastro: pessoaPutModel.tpCadastro ?? EnumCadastroTipo.CLIENTE,
                        endereco: null
                    }
                }

                if (!isEnderecoEqual && enderecoBeforeModel) {
                    const enderecoPutModel: MovSimplesPessoaEnderecoModel = {
                        ...enderecoBeforeModel,
                        ...new MovSimplesPessoaEnderecoModel(),
                        cep: model.enderecoEntrega.cep,
                        logradouro: model.enderecoEntrega.logradouro,
                        numero: model.enderecoEntrega.numero,
                        complemento: model.enderecoEntrega.complemento,
                        referencia: model.enderecoEntrega.pontoReferencia,
                        bairro: model.enderecoEntrega.bairro,
                        cMun: model.enderecoEntrega.codigoMunicipio,
                        xMun: model.enderecoEntrega.municipio,
                        uf: model.enderecoEntrega.uf,
                        cadastroId: pessoaBeforeModel.id,
                        pessoaId: pessoaBeforeModel.id,
                        ierg: enderecoBeforeModel.ierg,
                        im: enderecoBeforeModel.im,
                        indIEDest: enderecoBeforeModel.indIEDest,
                        pessoaTransportadoraPadraoId: enderecoBeforeModel.pessoaTransportadoraPadraoId
                    }
                    if (atualizar) {
                        const res = await putEnderecoPessoa(enderecoPutModel)

                        if (res.erro) throw res.erro
                    }

                    pessoaCompleto = {
                        ...pessoaCompleto,
                        endereco: enderecoPutModel
                    }

                } else if (!isEnderecoEmpty && !isEnderecoEqual) {
                    const endereco = new PessoaEnderecoModel(
                        guidEmpty(),
                        pessoaBeforeModel.id,
                        model.enderecoEntrega.cep,
                        model.enderecoEntrega.logradouro,
                        model.enderecoEntrega.numero,
                        model.enderecoEntrega.complemento,
                        model.enderecoEntrega.pontoReferencia,
                        model.enderecoEntrega.bairro,
                        model.enderecoEntrega.codigoMunicipio,
                        model.enderecoEntrega.municipio,
                        model.enderecoEntrega.uf,
                    )
                    const res = await postEnderecoPessoa(endereco)

                    if (res.erro) throw res.erro
                    pessoaCompleto = {
                        ...pessoaCompleto,
                        endereco: res.resultado?.data
                    }
                }
            } else { //ELSE CASO VÁ CRIAR UMA PESSOA NOVA

                const retPessoa = await handlePostPessoa(new PessoaCadastroFormModel(
                    EnumCadastroTipo.CLIENTE,
                    model.cliente.cpfCnpj,
                    model.cliente.nomeFantasia,
                ), contatos) as PessoaModel

                if (!isEnderecoEmpty) {
                    const endereco = new PessoaEnderecoModel(
                        guidEmpty(),
                        retPessoa.id,
                        model.enderecoEntrega.cep,
                        model.enderecoEntrega.logradouro,
                        model.enderecoEntrega.numero,
                        model.enderecoEntrega.complemento,
                        model.enderecoEntrega.pontoReferencia,
                        model.enderecoEntrega.bairro,
                        model.enderecoEntrega.codigoMunicipio,
                        model.enderecoEntrega.municipio,
                        model.enderecoEntrega.uf,
                    )

                    const res = await postEnderecoPessoa(endereco)
                    if (res.erro) throw res.erro
                    pessoaCompleto = {
                        ...retPessoa,
                        tpCadastro: retPessoa.tpCadastro ?? EnumCadastroTipo.CLIENTE,
                        endereco: res.resultado?.data
                    }
                }
            }

            model.documentoLoja = getEmpresaAtual()!.cpfcnpj;
            model.dadosIntegracao = pedidoIntegradorWrapper();

            if (pedidoState) {
                const pedidoPut = picker<PedidoDadosModelPut>(pedidoState, new PedidoDadosModelPut())
                pedidoPut.cliente = picker<PedidoClienteModel>(model.cliente, pedidoState.cliente)
                pedidoPut.enderecoEntrega = picker<PedidoDadosEnderecoModel>(model.enderecoEntrega, pedidoState.enderecoEntrega)
                pedidoPut.pagamentos = [...pedidoState.pagamentos.map(pag => {
                    const pagamento = pag as any
                    return {
                        ...pag,
                        situacao: pagamento.situacao.codigo,
                        tpMod: pagamento.tpMod.codigo
                    }
                })]
                pedidoPut.statusAutomacao = pedidoState.statusAutomacao.codigo
                pedidoPut.statusPedido = pedidoState.statusPedido.codigo
                pedidoPut.tipoPedido = pedidoState.tipoPedido.codigo

                const res = await putPedidoDados(getEmpresaAtual()?.id ?? '', pedidoPut)

                if (res.erro) throw res.erro

                const resGet = await getPedidoSalaoById(getEmpresaAtual()?.id ?? '', pedidoState.id)
                showToast('success', 'Dados atualizados!')

                if (resGet.erro) {
                    history.push({
                        pathname: '/venda-simples/entrega-frete',
                        state: {
                            pedido: pedidoState
                        }
                    })
                    return
                }

                history.push({
                    pathname: '/venda-simples/entrega-frete',
                    state: {
                        pedido: resGet.resultado?.data
                    }
                })
                return
            }

            const objCompleto = {
                cliente: isPessoalEqual ? pessoaBeforeModel : pessoaCompleto,
                endereco: isEnderecoEqual ? enderecoBeforeModel : pessoaCompleto.endereco
            }
            await setClientePessoa({
                ...new MovSimplesPessoaModel(),
                ...objCompleto.cliente,
                tpCadastro: objCompleto.cliente?.tpCadastro ?? EnumCadastroTipo.CLIENTE,
                endereco: { ...objCompleto.endereco as MovSimplesPessoaEnderecoModel }
            })

            if (getMov()?.infAdicional === undefined) {
                abrirDialogInformacaoAdicional('', false, false)
                showToast('success', 'Cliente selecionado!')
                return;
            }
            showToast('success', 'Cliente selecionado!')
            await avancarFluxoMov();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [abrirDialogInformacaoAdicional, avancarFluxoMov, compararObjetos, confirm, getEmpresaAtual, getMov, getPedidoSalaoById, handlePostPessoa, handlePutPessoa, history, pedidoIntegradorWrapper, pedidoState, postEnderecoPessoa, putEnderecoPessoa, putPedidoDados, setClientePessoa, showToast])

    return (
        <Box className={classes.container}>
            <FormAtendimentoDelivery
                loading={carregando}
                onSubmit={async () => { }}
                pedidoState={pedidoState}
                handleSubmit={handleSubmit}
                showLoading={carregando}
                ref={formRef}
            />
            <Button
                fullWidth
                rounded
                color='primary'
                variant='contained'
                onClick={() => {
                    formRef.current?.submitForm()
                }}
                className={'round'}
            >
                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                Confirmar Dados
            </Button>
        </Box>
    );
};