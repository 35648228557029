import { CardCatalogoProps } from './card-catalogo-props';
import { useStyles } from './card-catalogo-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import useOnPress from 'utils/useOnPress';
import { EstrelaFavoritoIcon } from 'views/components/icons/estrela-favorito-icon';
import { useToastSaurus } from 'services/app';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { memo, useCallback, useMemo, useReducer, useRef } from 'react';
import { usePutProdutoFavorito } from 'data/api/gestao/produto/produto/put-produto-favorito';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { imagemBase64 } from 'utils/tratamento-de-imagem';
import { handleClickFeedbackTatil } from 'utils/feedback-tatil-fn';
import { Badge, Grid, Typography } from 'views/design-system';
import { usePromocoes } from 'services/app/hooks/promocoes';

const CardCatalogo = ({
  model,
  onClick,
  onHold,
  exibirFavorito,
  qtdeCarrinho,
  cor,
}: CardCatalogoProps) => {
  const { putProdutoFavorito } = usePutProdutoFavorito();
  const classes = useStyles();
  const { retornarPromocaoDePor } = usePromocoes();

  const { showToast } = useToastSaurus();
  const carrinhoBtn = useRef<HTMLDivElement | null>(null);
  const handlePress = useCallback(() => {
    onHold(model);
  }, [model, onHold]);

  const valorProduto = model.vPreco ?? 0;
  const valorPMC = model.medicamento?.precoMaximoConsumidor ?? 0;
  const valorPromocao = retornarPromocaoDePor(model)?.valorPromocao ?? 0;

  const valorDe = valorProduto > valorPMC ? valorProduto : valorPMC;
  const valorPor = valorPromocao < valorProduto && valorPromocao > 0 ? valorPromocao : valorProduto;
  const possuiDe = (valorDe > 0 && valorDe !== valorPor);

  const handleClick = useCallback(async () => {
    carrinhoBtn.current?.classList.add(classes.fePulse);
    setTimeout(
      () => carrinhoBtn.current?.classList.remove(classes.fePulse),
      350,
    );
    onClick(model);
  }, [classes.fePulse, model, onClick]);


  const EventPressAndClick = useOnPress(handlePress, handleClick, {
    shouldPreventDefault: true,
    delay: 700,
  });

  const preventCardClick = (event: any) => {
    try {
      event.stopPropagation();
      event.preventDefault();
    } catch (e: any) { }
  };

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const refreshFavoritos = () => {
    forceUpdate();
  };

  const handleClickAddFavorite = useCallback(
    async (event: any) => {
      try {
        await handleClickFeedbackTatil();
        preventCardClick(event);

        if (model.favorito) {
          const res = await putProdutoFavorito(
            { favorito: false },
            model.produtoId,
          );
          if (res.erro) throw res.erro;
          await TouchoneDBPrimary.produtos.put({ ...model, favorito: false, promocoes: [] });
          model.favorito = !model.favorito;
        } else {
          const res = await putProdutoFavorito(
            { favorito: true },
            model.produtoId,
          );
          if (res.erro) throw res.erro;
          await TouchoneDBPrimary.produtos.put({ ...model, favorito: true, promocoes: [] });
          model.favorito = !model.favorito;
        }

        refreshFavoritos();
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [model, putProdutoFavorito, showToast],
  );

  const img = useCallback(
    (imagemUrl: string) => {
      try {
        if (imagemUrl.length > 0) {
          const url = imagemBase64(model.imagemUrl);
          return (
            <img
              src={url}
              className={classes.cardImg}
              alt={model.nome}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={model.nome}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
        );
      }
    },
    [classes.cardImg, model.imagemUrl, model.nome],
  );

  const gridContainerInsideComp = useMemo(
    () => (
      <>
        <div className={classes.divider} />
        <Grid className={classes.gridTextos}>
          <Typography className={classes.textName}>
            {model.nome}
          </Typography>

          {possuiDe && (
            <Grid>
              <Typography noWrap className={classes.textPriceRiscado} align="right">
                <strong>R$ {toDecimalString(valorDe, 2)} {model.medida}{valorDe === valorPMC ? '*' : ''}</strong>
              </Typography>
            </Grid>
          )}
          <Typography noWrap className={classes.textPrice} align="right">
            <strong>R$ {toDecimalString(valorPor, 2)} {model.medida}</strong>
          </Typography>

        </Grid>
      </>
    ),
    [classes.divider, classes.gridTextos, classes.textName, classes.textPrice, classes.textPriceRiscado, model.medida, model.nome, possuiDe, valorDe, valorPMC, valorPor],
  );

  const gridInsideComp = useMemo(
    () => (
      <Grid className={classes.containerInfo}>
        {(qtdeCarrinho || 0) > 0 && (
          <Badge
            className={classes.badgeQtde}
            badgeContent={(qtdeCarrinho || 0) > 99 ? '99+' : formatDecimalInteger(qtdeCarrinho || 0, 3)}
            color="error"
          >
          </Badge>
        )}
        {gridContainerInsideComp}
      </Grid>
    ),
    [
      classes.badgeQtde,
      classes.containerInfo,
      gridContainerInsideComp,
      qtdeCarrinho,
    ],
  );

  const insideComp = useMemo(
    () => (
      <>
        <Grid className={classes.cardContainer} ref={carrinhoBtn}>
          <Grid
            style={{
              background: `${cor}`,
            }}
            className={classes.containerColorCategoria}
          />
          {exibirFavorito && (
            <Grid
              className={classes.containerFavorito}
              onClick={handleClickAddFavorite}
              onTouchMove={preventCardClick}
              onMouseDown={preventCardClick}
              onTouchStart={handleClickAddFavorite}
              onMouseUp={preventCardClick}
              onTouchEnd={preventCardClick}
            >
              <EstrelaFavoritoIcon
                fill={model.favorito ? '#FFBF00' : '#B7B7B7'}
              />
            </Grid>
          )}
          <div className={classes.cardContent}>
            <div className={classes.containerImg}>
              <div className={classes.celulaGrid}>{img(model.imagemUrl)}</div>
            </div>
            {gridInsideComp}
          </div>
        </Grid>
      </>
    ),
    [classes.cardContainer, exibirFavorito, classes.cardContent, classes.celulaGrid, classes.containerColorCategoria, classes.containerFavorito, classes.containerImg, cor, gridInsideComp, handleClickAddFavorite, img, model.favorito, model.imagemUrl],
  );

  const component = useMemo(
    () => (
      <>
        <Grid
          item
          style={{ display: 'flex' }}
          {...EventPressAndClick}
        >
          {insideComp}
        </Grid>
      </>
    ),
    [EventPressAndClick, insideComp],
  );
  return component;
};
export default memo(CardCatalogo);