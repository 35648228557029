import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useStyles } from './convenio-list-styles';
import { ConvenioListData } from './convenio-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useGetConvenio } from 'data/api/gestao/conta-pessoa/get-conta-pessoa';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ConvenioModel } from 'model/api/gestao/convenio/convenio-model';
import { Grid } from 'views/design-system';

export const ConvenioList = () => {
  const classes = useStyles();
  const { abrirCadastroConvenio } = useCadastros();
  const { getConvenio, carregando } = useGetConvenio();
  const { addHandler, removeHandler } = useEventTools();
  const { getEmpresaAtual } = useEmpresaAtual()

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const location = useLocation()
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<ConvenioModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const filtros = useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    return {
      status: isEmpty(urlParams.get('status')) ? 0 : Number(urlParams.get('status')),
      termo: urlParams.get('termo') || '',
    }
  }, [location.search])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ConvenioModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo)
          ? 'generico=' + filtros.termo
          : '') +
        (filtros.status > -1
          ? '&status=' + filtros.status
          : '')

      try {
        const res = await getConvenio(getEmpresaAtual()?.id ?? '', query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.termo, filtros.status, getConvenio, getEmpresaAtual, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }

    addHandler(AppEventEnum.ConvenioModal, modalEdit);

    return () => removeHandler(AppEventEnum.ConvenioModal, modalEdit);
  }, [
    addHandler,
    modalEdit,
    modalEditAberto,
    queryStatus.page,
    removeHandler,
    search,
  ]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    }, [search, queryStatus.totalPages]);

  const onCardSelected = (id: string) => {
    abrirCadastroConvenio({
      id,
      callbackUrl: history.location.pathname,
      trocarUrl: true
    });
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <ConvenioListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
