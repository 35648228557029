import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupEmail } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormAtendimentoDeliveryValidation = () => {

    const { emailYup } = useValidationYupEmail();
    const { documentoYup } = useValidationYupDocumento(false)

    const formAtendimentoDeliveryValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                cliente: Yup.object({
                    nomeFantasia: Yup.string().required('Nome Completo é obrigatório.'),
                    email: emailYup(false).notRequired(),
                    cpfCnpj: documentoYup(),
                    telefone: Yup.string().required('O telefone é obrigatório'),
                }),
                enderecoEntrega: Yup.object({
                    logradouro: Yup.string().required('O endereço é obrigatório.'),
                    cep: Yup.string().required('O CEP é obrigatório.'),
                    numero: Yup.string().required('O número é obrigatório.'),
                    municipio: Yup.string().required('O município é obrigatório'),
                    uf: Yup.string().required('O UF é obrigatório.'),
                    bairro: Yup.string().required('O bairro é obrigatório.')
                })
            })
        )
    }, [documentoYup, emailYup])

    return {
        formAtendimentoDeliveryValidationYup,
    }
}

