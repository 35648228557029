import { isEmpty } from "lodash";

import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { useSessaoAtual } from 'services/app';
import { useThemeQueries } from '../../../theme/util-styles';
import ProdutoFiscalCadastro from "./components/produto-fiscal-cadastro/produto-fiscal-cadastro";
import ProdutoFiscalEdit from "./components/produto-fiscal-edit/produto-fiscal-edit";

interface ProdutoFiscalModalProps extends ModalProps {
    atualizarLista: boolean
    handleAtualizarLista: (value: boolean) => void
}

export const ProdutoFiscalModal = (props: ProdutoFiscalModalProps) => {
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { isMobile } = useThemeQueries();
    return (
        <DefaultModal
            minWidth={isMobile ? "0px" : "800px"}
            open={props.openned || false}
            variant={"temporary"}
            anchor="right">
            {props.openned && isEmpty(props.id) &&
                <ProdutoFiscalCadastro
                    empresaId={getEmpresaSelecionada()?.Id || ''}
                    contratoId={getEmpresaSelecionada()?.ContratoId || ''}
                    handleAtualizarLista={props.handleAtualizarLista}
                    atualizarLista={props.atualizarLista}
                />
            }
            {props.openned && !isEmpty(props.id) &&
                <ProdutoFiscalEdit
                    id={props.id}
                    empresaId={getEmpresaSelecionada()?.Id || ''}
                    handleAtualizarLista={props.handleAtualizarLista}
                    atualizarLista={props.atualizarLista}
                />}
        </DefaultModal >
    );
};
