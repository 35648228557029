import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoById = useCallback(
    (empresaId: string, id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/produto/${id}`,
        method: 'GET',
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoById
  };
}
