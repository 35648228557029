import { guidEmpty } from "utils/guid-empty";
import { PontosVendaEditConfiguracoesFormModel } from "./pontos-venda-edit-configuracoes-model";
import { EnumPDVTpCaixa } from "model/enums/enum-pdv-tpcaixa";

export class PontosVendaEditFormModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public depositoId: string = guidEmpty(),
        public numCaixa: number = 0,
        public chave: string = "",
        public descricao: string = "",
        public indStatus: number = 0,
        public dominio: string = '',
        public tpCaixa: EnumPDVTpCaixa = EnumPDVTpCaixa.WEBPDV,
        public configuracoes: Array<PontosVendaEditConfiguracoesFormModel> = [],
        public versao: string = '',
    ) { }
}
