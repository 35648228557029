import { CircularLoading } from "views/components";
import { Paginacao } from "views/components/paginacao";
import { Grid } from "views/design-system";
import { CustumerListData } from "../../../mov-cliente-identificacao/components/custumer-list-data/custumer-list-data";
import { useCallback, useEffect, useRef, useState } from "react";
import { PessoaModel } from "model/api/gestao/pessoa";
import { TouchoneDBPrimary } from "database/touchone-database";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { useVendaHeaderStore } from "views/components/headers/venda-header/stores/venda-header-store";
import { useStyles } from "./mov-pessoas-pesquisa-styles";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useKeyNavigation } from "services/app/hooks/key-navigation";
import { AppEventEnum } from "model/enums/enum-app-event";
import classNames from "classnames";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { EnumCadastroStatus } from "model";

interface Props {
    onCardSelected: (cliente: any) => Promise<void>;
    abertoPermanentemente: boolean;
}

export const MovPessoasPesquisa = (props: Props) => {

    const classes = useStyles();

    const timer = useRef({} as NodeJS.Timeout);
    const ultimoTermoPesquisado = useRef('');
    const { showToast } = useToastSaurus();
    const { getPermissaoBoolean } = useSessaoAtual()
    const searchCustumer = useVendaHeaderStore(state => state.props.search) || '';
    const [aberto, setAberto] = useState<boolean>(props.abertoPermanentemente);
    const { addHandler, removeHandler } = useEventTools();
    const podeClienteDesativado = getPermissaoBoolean(EnumCodigosPermissoes.CLIENTE_DESATIVADO)

    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<PessoaModel>()
    });
    const currentIndex = useRef<number>(-1);
    useKeyNavigation(queryStatus.list.length, currentIndex);

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<PessoaModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            try {
                const res = await TouchoneDBPrimary.clientes
                    .filter((cliente) => {
                        if (
                            (cliente.nome &&
                                cliente.nome
                                    .toLowerCase()
                                    .includes(searchCustumer.toLowerCase())) ||
                            (cliente.cpfcnpj &&
                                cliente.cpfcnpj
                                    .toLowerCase()
                                    .includes(searchCustumer.toLowerCase()))
                        ) {
                            if (cliente.status === EnumCadastroStatus.DESATIVADO) {
                                return podeClienteDesativado
                            }
                            return true;
                        }

                        return false;
                    })
                    .toArray();

                fillResult(1, 1, res.length, res as PessoaModel[]);
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [fillResult, searchCustumer, podeClienteDesativado, showToast]
    );

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    useEffect(() => {
        clearTimeout(timer.current);
        if (aberto) {
            if (
                ultimoTermoPesquisado.current !== searchCustumer ||
                searchCustumer.length === 0
            ) {
                timer.current = setTimeout(() => {
                    search(queryStatus.page);
                }, 500);
            }
        }
        return () => {
            clearTimeout(timer.current);
        };
    }, [aberto, queryStatus.page, search, searchCustumer]);

    const inserirPessoa = useCallback(() => {
        if (currentIndex.current > -1)
            props.onCardSelected(queryStatus.list[currentIndex.current])
    }, [props, queryStatus.list])

    useEffect(() => {
        addHandler(AppEventEnum.SubmitPesquisa, inserirPessoa);
        addHandler(AppEventEnum.TelaPesquisa, (aberto: boolean) => !props.abertoPermanentemente && setAberto(aberto))
        return () => {
            removeHandler(AppEventEnum.SubmitPesquisa, inserirPessoa)
            removeHandler(AppEventEnum.TelaPesquisa, (aberto: boolean) => !props.abertoPermanentemente && setAberto(aberto))
        }
    }, [addHandler, inserirPessoa, props.abertoPermanentemente, removeHandler])

    return (
        <>
            {false && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={classNames(classes.root, aberto ? '' : classes.displayNone)}>
                <Grid container>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryStatus.totalPages}
                        totalRegisters={queryStatus.totalResults}
                        currentPage={queryStatus.page}
                    />
                    <Grid item xs={12} className={classes.listContainer}>
                        <CustumerListData
                            carregando={false}
                            list={queryStatus.list}
                            onCardSelected={props.onCardSelected}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}