import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  label: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  maisIcon: {
    marginLeft: theme.spacing(2),
    '& svg': {
      margin: 0,
      padding: 0,
    }
  },
  emailAdd: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  lixoIcon: {
    background: theme.palette.error.main,
    borderRadius: '100%',
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '& svg': {
      width: 15,
      height: 15,
      margin: 0,
      padding: 0,
    }
  },
}));
