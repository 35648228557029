import { usePostMesa } from "data/api/gestao/mesas/post-mesa";
import { MesasNovaModel } from "model/api/gestao/mesa/mesa-nova-model";
import { useCallback, useEffect, useRef } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { FormMesaNova } from "views/components/form/mesa/form-mesa-nova/form-mesa-nova";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { ModalHeader } from "views/components/modals/components";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { Button, Grid } from "views/design-system";

interface MesasAdicionarProps {
    salaoId: string
    atualizarLista: boolean
    handleAtualizarLista: (value: boolean) => void
}

export const MesasAdicionar = (props: MesasAdicionarProps) => {
    //AUX
    const classes = useModalStyles();
    const { showToast } = useToastSaurus();

    //CHAMADAS DA API
    const { postMesa, carregando: carregandoPost } = usePostMesa();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

    const carregando = carregandoPost || carregandoSessaoAtual;

    //PROVIDERS
    const {
        fecharCadastroMesas,
    } = useCadastros();

    //REFS
    const cadMesasFormRef = useRef<DefaultFormRefs<MesasNovaModel>>(null);
    const redirect = useRef<boolean>(true)
    const model = new MesasNovaModel()

    const onCloseClick = useCallback(() => {
        fecharCadastroMesas(props.atualizarLista);
        props.handleAtualizarLista(false)
    }, [fecharCadastroMesas, props]);

    const saveMesa = useCallback(
        async (model: MesasNovaModel) => {
            try {
                const ret = await postMesa(
                    { ...model, status: 1 },
                    getEmpresaSelecionada()?.Id || '',
                    props.salaoId,
                );
                if (ret.erro) {
                    throw ret.erro;
                }
                props.handleAtualizarLista(true)
                showToast('success', 'Mesas criada com sucesso!');

                if (!redirect.current) {
                    onCloseClick();
                }
            } catch (e: any) {
                showToast('error', e.message);
                cadMesasFormRef.current?.fillForm(model);
            }
        },
        [postMesa, getEmpresaSelecionada, props, showToast, onCloseClick],
    );

    const handleSubmit = useCallback(
        async (model: MesasNovaModel) => {
            return saveMesa(model);
        },
        [saveMesa],
    );

    const submitForm = useCallback((redirectToEdit: boolean) => {
        redirect.current = redirectToEdit;
        cadMesasFormRef.current?.submitForm();
    }, []);

    useEffect(() => {
        cadMesasFormRef.current?.resetForm();
    }, []);

    return (
        <div className={classes.root}>
            <ModalHeader
                title={'Adicionar Mesa'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <div className={classes.contentForms}>
                <FormMesaNova
                    ref={cadMesasFormRef}
                    showLoading={false}
                    loading={carregando ?? false}
                    onSubmit={handleSubmit}
                    model={model}
                />
            </div>
            <div className={classes.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            disabled={carregando}
                            onClick={() => submitForm(false)}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                            Salvar Mesa
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}