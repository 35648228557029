import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    position: 'relative',
    zIndex: 2,
    alignItems: 'center',
    height: 70,
  },
  topContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 2,
    alignItems: 'center',
    minHeight: '65px',
    height: '65px',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      height: '70px',
      minHeight: '70px',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  bottomContainer: {
    zIndex: 1,
    background: theme.palette.background.default,
    position: 'relative',
    borderBottomRightRadius: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginLeft: 100
  },
  title: {
    fontWeight: 600,
    textShadow: '2px 2px 1px rgba(0,0,0,0.05)',
    fontSize: '1.8rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center'
  },
  flexContent: {
    flex: 1,
    overflow: 'hidden'
  },
  flexFixedLeft: {
    flex: 0,
    paddingRight: 16,
    marginRight: 8
  },
  flexFixedRight: {
    flex: 0,
    display: 'flex',
    paddingLeft: 8
  },
  displayNone: {
    display: 'none',
  },
  unsetHeight: {
    height: 'unset',
  },
  textfieldContainer: {
    width: 400
  }
}));