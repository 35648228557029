import { guidEmpty } from "utils/guid-empty";
import { EnumBalanca } from "../../../../enums/enum-balanca";
import { ProdutoCodigoModel } from "../produto-codigo/produto-codigo-model";
import { ProdutoImagemModel } from "../produto-imagem/produto-imagem";
import { ProdutoImpostoModel } from "../produto-imposto/produto-imposto-model";
import { ProdutoPrecoModel } from "../produto-preco/produto-preco-model";

export class ProdutoVariacaoModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produto: string = '',
    public medidaEntradaId: string = guidEmpty(),
    public medidaEntrada: string = '',
    public medidaSaidaId: string = guidEmpty(),
    public medidaSaida: string = '',
    public grade: string = "",
    public qMinimo: number = 0,
    public qMaximo: number = 0,
    public qSaldoTotal: number = 0,
    public vCompra: number = 0,
    public infAdic: string = "",
    public ativo: boolean = true,
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public codigos: Array<ProdutoCodigoModel> = new Array<ProdutoCodigoModel>(),
    public impostos: Array<ProdutoImpostoModel> = new Array<ProdutoImpostoModel>(),
    public precos: Array<ProdutoPrecoModel> = new Array<ProdutoPrecoModel>(),
    public imagens: Array<ProdutoImagemModel> = new Array<ProdutoImagemModel>(),
    public codigoAnvisa: string | null = null
  ) { }
}
