import { useCallback, useEffect, useState, useRef } from 'react';
import { NovoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useGetComandaById } from 'data/api/gestao/comandas/get-comanda-by-id';
import { usePutComanda } from 'data/api/gestao/comandas/put-comanda';
import { ComandasEditModel } from 'model/api/gestao/comanda/comanda-edit-model';
import { FormComandaEdit, FormComandaEditRefs } from 'views/components/form/comandas/form-comanda-edit/form-comanda-edit';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { EscaneandoComanda } from 'views/pages/private/movimentacao/mov-leitor-comanda/components/escaneando-comanda/escaneando-comanda';
import { Button, Grid } from 'views/design-system';

interface ComandasEditProps {
  id: string
  atualizarLista: boolean
  handleAtualizarLista: (value: boolean) => void
}

export const ComandasEdit = ({ atualizarLista, handleAtualizarLista, ...props }: ComandasEditProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [escaneando, setEscaneando] = useState<boolean>(false);
  const classes = useModalStyles();
  const { fecharCadastroComandas, abrirCadastroComandas } = useCadastros();
  const { getComandaById, carregando: carregandoComandas } =
    useGetComandaById();
  const { putComanda, carregando: carregandoPut } = usePutComanda();

  const carregando = carregandoPut || carregandoComandas || preenchendoTela;
  const [comandaFormState, setComandaForm] = useState<ComandasEditModel>(
    new ComandasEditModel(),
  );

  const refEditForm = useRef<FormComandaEditRefs>(null);
  const refComandasModel = useRef<ComandasModel>(new ComandasModel());

  const recarregarForm = useCallback((model: ComandasEditModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(comandaFormState);
  }, [comandaFormState, recarregarForm]);

  const getComandaByIdWapper = useCallback(async () => {
    const res = await getComandaById(props.id, getEmpresaSelecionada()!.Id);
    if (res.erro) {
      throw res.erro;
    }
    return res.resultado?.data;
  }, [getComandaById, getEmpresaSelecionada, props.id]);

  const preencherTela = useCallback(async () => {
    try {
      const comanda = await getComandaByIdWapper();
      setComandaForm({ ...comanda, status: comanda.status.codigo });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getComandaByIdWapper, showToast]);

  useEffect(() => {
    (async () => {
      setPreenchendoTela(true);
      preencherTela();
      setPreenchendoTela(false);
    })();
    return () => {
      //funcao de limpeza
      setComandaForm(new ComandasEditModel());
    };
  }, [preencherTela]);

  const saveChangesComanda = useCallback(
    async (comandaModelForm: ComandasEditModel) => {
      let comanda = picker<ComandasModel>(
        comandaModelForm,
        refComandasModel.current,
      );
      const ret = await putComanda(comanda, getEmpresaSelecionada()!.Id);
      if (ret.erro) {
        throw ret.erro;
      }
      handleAtualizarLista(true)

    },
    [getEmpresaSelecionada, handleAtualizarLista, putComanda],
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroComandas('', '', true);
  }, [abrirCadastroComandas]);

  const handleSubmit = useCallback(
    async (model: ComandasEditModel, beforeModel: ComandasEditModel) => {
      try {
        const comandaEqual = isEqual(model, beforeModel);
        if (comandaEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
          return
        }

        await saveChangesComanda(model);
        showToast('success', 'Comanda atualizada com Sucesso!');
        preencherTela();
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    }, [saveChangesComanda, showToast, preencherTela]);

  const onCloseClick = useCallback(() => {
    fecharCadastroComandas(atualizarLista);
    handleAtualizarLista(false)
  }, [atualizarLista, fecharCadastroComandas, handleAtualizarLista]);

  const setarRfid = useCallback(async (rfid: string) => {
    setEscaneando(false)
    refEditForm.current?.setRfid(rfid)
  }, [])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Editar Comanda'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      {escaneando && <EscaneandoComanda labelVoltar='Voltar' closeEscaneando={() => setEscaneando(false)} confirmarComanda={setarRfid} />}
      <div className={classes.content} style={escaneando ? { display: 'none' } : undefined}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormComandaEdit
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
            setEscaneando={setEscaneando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Gerar Comanda
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar Comanda
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
