import { useEffect, useState, useCallback } from "react";
import { useStyles } from "./relatorios-avancados-list-styles";
import { RelatoriosAvancadosListData } from "./relatorios-avancados-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { RelatoriosAvancadosModel } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';
import { Grid } from 'views/design-system';
import { useGetRelatoriosAvancados } from 'data/api/gestao/relatorios/relatorios-avancados/get-relatorios-avancados';

export const RelatoriosAvancadosList = () => {
  const classes = useStyles();
  const { abrirRelatoriosAvancados } = useCadastros();
  const { getRelatoriosAvancados, carregando } = useGetRelatoriosAvancados();
  const { showToast } = useToastSaurus();
  const location = useLocation()

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<RelatoriosAvancadosModel>(),
  });
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<RelatoriosAvancadosModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const urlParams = new URLSearchParams(location.search)
  const filtros = {
    status: isEmpty(urlParams.get('status')) ? 1 : Number(urlParams.get('status')),
    termo: urlParams.get('termo') || '',
    codigo: urlParams.get('codigo') || ''
  }

  const search = useCallback(
    async (newPage: number) => {
      const query =
        "" +
        (!isEmpty(filtros.termo) ? "&termo=" + filtros.termo : "") +
        (filtros.status > -1 ? "&ativo=" + Boolean(filtros.status) : "") +
        (!isEmpty(filtros.codigo) ? "&codigo=" + filtros.codigo : "");

      try {
        const res = await getRelatoriosAvancados(query, newPage);
        if (res.erro) throw res.erro;

        fillResult(
          res.resultado?.data?.pageIndex,
          1,
          res.resultado?.data?.length,
          res.resultado?.data
        );
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [filtros.termo, filtros.status, filtros.codigo, getRelatoriosAvancados, fillResult, showToast]
  );

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (relatorio: RelatoriosAvancadosModel) => {
    abrirRelatoriosAvancados(relatorio, location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <RelatoriosAvancadosListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

