import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useCriptografia } from 'services/app/hooks/criptografia';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePDV } from 'services/app/hooks/pdv';

export function useGetValeByCod() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { criptografar } = useCriptografia();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getPDV } = usePDV();

    const getValeByCod = useCallback(
        async (codigo: string) => {

            const str = getPDV()?.id + '|' + getEmpresaAtual()?.cpfcnpj + '|EMPTY';
            const auth = criptografar(str);

            return invocarApi({
                url: `/vale/${codigo}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': String(auth),
                },
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.apiTroca}/api`,
            })
        },
        [criptografar, getEmpresaAtual, getPDV, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getValeByCod,
    };
}
