import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useCriptografia } from 'services/app/hooks/criptografia';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { ValePutModel } from 'model/api/gestao/troca/vale-put-model';

export function usePutVale() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { criptografar } = useCriptografia();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getPDV } = usePDV();

    const putVale = useCallback(
        async (cod: string, obj: ValePutModel) => {
            const str = getPDV()?.id + '|' + getEmpresaAtual()?.cpfcnpj + '|EMPTY';
            const auth = criptografar(str)
            return invocarApi({
                url: `/vale`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': String(auth),
                },
                data: JSON.stringify({
                    ...obj,
                    nroVale: cod,
                }),
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.apiTroca}/api`,
            })
        },
        [criptografar, getEmpresaAtual, getPDV, invocarApi],
    );
    return {
        ...outrasPropriedades,
        putVale,
    };
}