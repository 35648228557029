import { useCallback, useEffect, useState, useRef } from "react";
import { NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { isEqual } from "utils/is-equal";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DefaultFormRefs } from "views/components/form/utils";
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import classNames from "classnames";
import { usePutMarca } from "data/api/gestao/marca/put-marca";
import { useGetMarcaById } from "data/api/gestao/marca/get-marca-by-id";
import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { FormMarcaEdit } from "views/components/form/marca/form-marca-edit/form-marca-edit";
import { nomeMarcaAmbiente } from "utils/nome-marca-ambiente";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import { useDeleteMarca } from "data/api/gestao/marca/delete-marca-by-id";
import { Button, Grid } from "views/design-system";
import { EnumDropCache, useDropCache } from "services/app/hooks/drop-cache";

export const MarcaEdit = (props: { id: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();
  const { abrirCadastroMarca, fecharCadastroMarca } = useCadastros();
  const { getMarcaById, carregando: carregandoGet } = useGetMarcaById();
  const { putMarca, carregando: carregandoPut } = usePutMarca();
  const { deleteMarca, carregando: carregandoDelete } = useDeleteMarca()
  const { removeCategoryCache } = useDropCache()
  const { plano } = useSessaoAtual()
  const [formInSubmit, setFormInSubmit] = useState(false);
  const carregando = [carregandoPut, carregandoGet, preenchendoTela, formInSubmit, carregandoDelete].includes(true);
  const [marcaFormState, setMarcaForm] = useState<MarcaModel>(new MarcaModel());

  const refEditForm = useRef<DefaultFormRefs<MarcaModel>>(null);
  const refMarcaModel = useRef<MarcaModel>(new MarcaModel());

  const recarregarForm = useCallback((model: MarcaModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(marcaFormState);
  }, [marcaFormState, recarregarForm]);

  const getMarcaByIdWrapper = useCallback(async () => {
    const res = await getMarcaById(props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as MarcaModel;
    refMarcaModel.current = ret;
    const ncm = picker<MarcaModel>(ret, new MarcaModel());

    return ncm;
  }, [getMarcaById, props.id]);

  useEffect(() => {
    (async () => {
      try {
        setPreenchendoTela(true);
        let ncm = await getMarcaByIdWrapper();
        setMarcaForm(ncm);
        setPreenchendoTela(false);
      } catch (e: any) {
        showToast("error", e.message);
        setPreenchendoTela(false);
      }
    })();
    return () => {
      //funcao de limpeza
      setMarcaForm(new MarcaModel());
    };
  }, [showToast, getMarcaByIdWrapper, setMarcaForm]);

  const saveChangesMarca = useCallback(
    async (model: MarcaModel) => {
      const equipamento = picker<MarcaModel>(model, refMarcaModel.current);
      try {
        const ret = await putMarca(equipamento);

        if (ret.erro) {
          throw ret.erro;
        }
        removeCategoryCache(EnumDropCache.Marca)
        showToast("success", `${nomeMarcaAmbiente(plano?.plano, true)} atualizado com sucesso!`,);
        setMarcaForm(model);
      } catch (e: any) {
        showToast("error", e.message);
        refEditForm.current?.resetForm();
      }
    },
    [plano?.plano, putMarca, removeCategoryCache, showToast]
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroMarca("", "", true);
  }, [abrirCadastroMarca]);

  const handleSubmit = useCallback(
    async (model: MarcaModel, beforeModel: MarcaModel) => {
      const marcaEqual = isEqual(model, beforeModel);

      if (marcaEqual) {
        showToast("info", "Nenhuma informação foi alterada");
        return
      }

      await saveChangesMarca(model);

    },
    [saveChangesMarca, showToast]
  );
  const onCloseClick = useCallback(() => {
    fecharCadastroMarca();
  }, [fecharCadastroMarca]);

  const removerMarca = async () => {
    try {
      const res = await deleteMarca(props.id)
      if (res.erro) throw res.erro
      fecharCadastroMarca()
      removeCategoryCache(EnumDropCache.Marca)

      showToast('success', `${nomeMarcaAmbiente(plano?.plano, true)} removido com sucesso!`)
    } catch (error: any) {
      showToast('error', `Erro ao remover a marca. Detalhe: ${error.message}`)
    }
  }

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={`Edição de ${nomeMarcaAmbiente(plano?.plano, true)}`}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }

        rightArea={
          <MenuOptions
            options={[
              new MenuOptionsModel(
                `Remover ${nomeMarcaAmbiente(plano?.plano)}`,
                <></>,
                removerMarca,
              ),
            ]}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormMarcaEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
