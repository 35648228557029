import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PessoaModal } from "views/components/modals/pessoa/pessoa-modal/pessoa-modal";

export const CadastroPessoaModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: '',
        atualizarLista: false
    });

    const pessoaRoute = `/pessoas/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroPessoaModal')

    useEffect(() => {
        if (location.pathname.indexOf(pessoaRoute) === 0) {
            const posrota = location.pathname.replace(pessoaRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    ...prev,
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: pessoaRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, pessoaRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? pessoaRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;

        if (!openned && !trocarUrl) {
            push({
                pathname: pessoaRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(pessoaRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }

        setModalState(prev => ({
            ...prev,
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        }));
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, pessoaRoute, push])

    const handleAtualizarLista = useCallback((value: boolean) => {
        setModalState(prev => ({
            ...prev,
            atualizarLista: value
        }))
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PessoaModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PessoaModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <PessoaModal
            id={modalState.id}
            key="mdlPessoa"
            openned={modalState.aberto}
            atualizarLista={modalState.atualizarLista}
            handleAtualizarLista={handleAtualizarLista}
        />
    )
}