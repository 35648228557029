import { ValeReceberFormModel } from "model/app/forms/vale/vale-receber-form-model";
import { EnumStatusVale } from "model/enums/enum-vale";
import { useEffect, useRef } from "react";
import { useToastSaurus } from "services/app";
import { useVale } from "services/app/hooks/vale";
import { DialogSaurus } from "views/components/dialog/dialog-saurus/dialog-saurus"
import { DefaultFormRefs } from "views/components/form/utils";
import { FormReceberVale } from "views/components/form/vale/form-receber-vale/form-receber-vale";
import { VoltarIcon } from "views/components/icons";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { AutenticarValeDialogProps } from "views/components/render-modais/components/autenticar-vale-dialog";
import { Box, Button, Grid } from "views/design-system";

export const DialogAutenticarVale = ({ aberto, pagamento, cancelar, sucesso }: AutenticarValeDialogProps) => {

    const { retValeStatus, utilizarVale, getVale, carregando, mov } = useVale();
    const { showToast } = useToastSaurus();

    const formRef = useRef<DefaultFormRefs<ValeReceberFormModel>>(null);

    const handleSubmit = async (model: ValeReceberFormModel) => {
        try {
            const arr = await retValeStatus(model.chave);

            if (!arr) {
                throw new Error('Vale Inválido para uso!');
            }

            const data = arr[arr.length - 1];

            if (!data || data.status !== EnumStatusVale.Confirmado) {
                throw new Error('Vale Inválido para uso!');
            }

            const vale = await getVale(model.chave);

            if (vale.valor < pagamento.vPag) {
                throw new Error('Valor do Vale é inferior ao informado no pagamento.');
            }

            const utilizado = await utilizarVale(vale, model);

            if (utilizado) sucesso(vale.valor);
        } catch (e: any) {
            showToast('error', e.message);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (mov.cliente?.cpfcnpj) {
                formRef.current?.fillForm({
                    chave: '',
                    documento: mov.cliente.cpfcnpj,
                })
            }
        }, 500)
    }, [mov.cliente])

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo='Receber Vale'
                tamanho='xs'
            >
                <Box mt={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormReceberVale
                                loading={carregando}
                                showLoading
                                ref={formRef}
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='outlined' onClick={() => cancelar()}>
                                <VoltarIcon tipo='BUTTON' />
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='contained' onClick={() => formRef.current?.submitForm()}>
                                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogSaurus>
        </>
    )
}