import { useCallback, FocusEvent, useState, useEffect, useRef } from 'react';
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { useGetSetores } from 'data/api/gestao/setores';
import { useSessaoAtual } from 'services/app';
import { DropCacheToUseProps, EnumDropCache, useDropCache } from 'services/app/hooks/drop-cache';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';

export interface AutocompleteSetorProps extends DropCacheToUseProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string | null;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  idSetor?: string | null;
  allowSubmit?: boolean;
  permiteAdicionar?: boolean;
  modo?: 'setor';
  disabled?: boolean;
}

export const AutocompleteProdutoSetor = ({
  onChange,
  loadingExterno,
  useCache = true,
  ...props
}: AutocompleteSetorProps) => {
  // STATES E REFS
  const [setorState, setSetorState] = useState<Array<SetoresModel>>(
    new Array<SetoresModel>(),
  );
  const allowReq = useRef<boolean>(true);

  // CHAMADAS API
  const { getSetores, carregando: carregandoSetores } = useGetSetores();
  const { fetchData, removeCacheItem } = useDropCache<ApiListModel<SetoresModel>>(getSetores)

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();

  const getSetoresWrapper = useCallback(
    async (termo: string) => {
      try {
        const params = [getEmpresaSelecionada()?.Id ?? '', `?pageSize=0&descricao=${termo ?? ''}`]
        const ret = await fetchData({
          category: EnumDropCache.Setor,
          params,
          propId: props.propId ?? '',
          useCache: useCache,
          searchTerm: termo,
        });

        let setores: Array<SetoresModel> = new Array<SetoresModel>();

        const setoresFilter = ((ret?.data?.list ?? []) as SetoresModel[]).filter(
          (setor) =>
            setor.descricao
              .toLocaleLowerCase()
              .includes(termo.toLocaleLowerCase()),
        );

        if (setoresFilter.length > 0) {
          setores = setoresFilter as Array<SetoresModel>;
        }

        setores.push({
          id: '1',
          descricao: 'Sem Setor',
          uriImage: '',
        });

        setSetorState(setores);
      } catch (e: any) { }
    }, [fetchData, getEmpresaSelecionada, props.propId, useCache]);

  useEffect(() => {
    if (props.value && allowReq.current) {
      getSetoresWrapper(props.value)
      allowReq.current = false
    }
  }, [getSetoresWrapper, props.value])

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (value.isNewVal) removeCacheItem(props.propId ?? '', EnumDropCache.Setor)
      if (onChange) onChange(value);
    }, [onChange, props.propId, removeCacheItem]);

  const onPesquisa = useCallback(
    (termo: string) => {
      getSetoresWrapper(termo);
    },
    [getSetoresWrapper],
  );

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      loading={carregandoSetores}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={setorState}
      optionLabelKey={'descricao'}
      optionValueKey={'descricao'}
      disabledValueKey="id"
      disabledValues={[props.idSetor]}
      noOptionsText={'Nenhum setor localizado'}
      onBlur={props.onBlur}
      value={props.value}
      name={props.name}
      label={props.label}
      allowSubmit={props.allowSubmit}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={props.permiteAdicionar}
    />
  );
};
