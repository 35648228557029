import { TpTrocoMock } from "data/mocks/tp-troco-mock"
import { EnumPagTpMod } from "model"
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model"
import { EnumTpTroco } from "model/enums/enum-tp-troco"
import { toCurrency } from "utils/to-decimal"
import { IconCard } from "views/components/cards/components"
import { PagamentosListData } from "views/components/dialog/dialog-historico-pagamento/pagamentos-list-data/pagamentos-list-data"
import { ConfirmarIcon } from "views/components/icons/confirmar-icon"
import { DinheiroMaosIcon } from "views/components/icons/dinheiro-maos-icon"
import { EtiquetaIcon } from "views/components/icons/etiqueta-icon"
import { Box, Grid, Typography } from "views/design-system"
import { useStyles } from '../vale-troco-modal-styles'
import { Button } from "@material-ui/core"
import { ValeModel } from "model/api/gestao/troca/vale-model"
import { EnumPagamentosCompostos } from "data/mocks"
import { validarPagamentoComposto } from "utils/tp-pagamento-resolver"

interface SwitchValeTrocoProps {
    finalizarVenda: (vale?: ValeModel) => void;
    switchValeTroco: () => void;
    inserirRepique: () => void;
    mov: MovSimplesModel;
    vale?: ValeModel;
}

export const SwitchValeTroco = ({
    switchValeTroco,
    finalizarVenda,
    inserirRepique,
    mov,
    vale,
}: SwitchValeTrocoProps) => {

    const classes = useStyles();

    const valorNF = mov.vNF;
    const vPago = mov.pags.reduce((prev, curr) => prev + curr.vPag, 0);

    const pags = mov.pags
        .filter(
            (x) =>
                x.modPagamento === EnumPagTpMod.DINHEIRO ||
                validarPagamentoComposto(x.modPagamento, x.tpTransacao) === EnumPagamentosCompostos.ValeTroco ||
                x.modPagamento === EnumPagTpMod.OUTRO
        )
        .sort(function (a, b) {
            return b.vPag - a.vPag;
        });

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.pagamentosContainer}>
                    <PagamentosListData list={mov?.pags} />
                </Box>
                <Box className={classes.trocoContainer}>
                    <Typography weight={600} variant='h6' color='primary'>
                        {TpTrocoMock.find(x => pags[0]?.tpTroco === x.Key)?.Value || 'Troco'}
                    </Typography>
                    <Typography weight={600} variant='h4' color='primary'>{toCurrency(vPago - valorNF)}</Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <IconCard
                                icon={<EtiquetaIcon tipo='BUTTON' />}
                                model={undefined}
                                onClick={switchValeTroco}
                                title='Vale Troco'
                                disabled={mov.pags.some(pag => pag.tpTroco === EnumTpTroco.Repique) || mov.pags.some(pag => pag.tpTroco === EnumTpTroco.Vale)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <IconCard
                                icon={<DinheiroMaosIcon tipo='BUTTON' />}
                                model={undefined}
                                onClick={inserirRepique}
                                title='Repique'
                                description="Ficar com Troco"
                                disabled={mov.pags.some(pag => pag.tpTroco === EnumTpTroco.Repique) || mov.pags.some(pag => pag.tpTroco === EnumTpTroco.Vale)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color='primary' variant='contained' fullWidth onClick={() => finalizarVenda(vale)}>
                                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                                Finalizar Venda
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}