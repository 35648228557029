import { EnumStatusVale } from "model/enums/enum-vale";
import { ValeModel } from "./vale-model";

export class ValePutModel {
    constructor(
      public id: string = '',
      public valeId: string = '',
      public dtStatus: string = '',
      public usuario: string = '',
      public status: EnumStatusVale = EnumStatusVale.Pendente,
      public referenceID: string = '',
      public referenceJSON: string = '',
      public vale: ValeModel = new ValeModel(),
    ) {}
  }