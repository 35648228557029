import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';


export const useFormReceberValeValidation = () => {

    const { documentoYup } = useValidationYupDocumento(true);

  const FormReceberValeValidation = useMemo(() => {
    return (
      Yup.object().shape({
        chave: Yup.string().length(20, 'Chave Inválida'),
        cpfCnpj: documentoYup(),
      })
    )
  }, [documentoYup])
  
  return {
    FormReceberValeValidation,
  }
}