import { PontosVendaVersaoModel } from "./ponto-venda-versao-model";
import { PontosVendaConfiguracoesModel } from "./pontos-venda-configuracoes-model";
import { PontosVendaModel } from "./pontos-venda-model";

export class PontosVendaCompletoModel {
    constructor(
        public caixa: PontosVendaModel = new PontosVendaModel(),
        public configuracoes: PontosVendaConfiguracoesModel[] = [],
        public versao: PontosVendaVersaoModel = new PontosVendaVersaoModel(),
    ) { }
}