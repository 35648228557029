import { yupResolver } from "@hookform/resolvers/yup";
import { ValeModel } from "model/api/gestao/troca/vale-model";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCadastros, useToastSaurus } from "services/app";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { useVale } from "services/app/hooks/vale";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { DialogSaurus } from "views/components/dialog/dialog-saurus/dialog-saurus"
import { useValidationYupDocumento } from "views/components/form-validations";
import { VoltarIcon } from "views/components/icons";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { CircularLoading } from "views/components/utils";
import { Button, Grid } from "views/design-system";
import * as Yup from 'yup';

interface ReiprimirValeFormModel {
    clienteDocumento: string;
}

interface DialogReimprimirProps {
    openned: boolean;
    imprimirVales: (vales: ValeModel[]) => any;
}

export const useReiprimirValeValidation = () => {

    const { documentoYup } = useValidationYupDocumento(false);

    const FormPessoaValeValidation = useMemo(() => {

        return (
            Yup.object().shape({
                clienteDocumento: documentoYup(),
            })
        )
    }, [documentoYup])
    return {
        FormPessoaValeValidation,
    }
}

export const DialogReiprimirVale = ({ openned, imprimirVales }: DialogReimprimirProps) => {

    const { getValesByDoc, carregando } = useVale();
    const { fecharReimprimirVale } = useCadastros();
    const { showToast } = useToastSaurus();
    const { getMov } = useMovAtual();
    const clienteIdentificado = getMov()?.cliente;

    const { FormPessoaValeValidation } = useReiprimirValeValidation();

    const onSubmit = async (model: ReiprimirValeFormModel) => {
        try {
            const vales = await getValesByDoc(model.clienteDocumento);
            if (vales.length === 0) {
                showToast('info', 'Esse cliente não possuí vales disponíveis.');
            }
            imprimirVales(vales);
        } catch {

        }
    }

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ReiprimirValeFormModel>({
        defaultValues: {
            clienteDocumento: clienteIdentificado?.cpfcnpj || '',
        },
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FormPessoaValeValidation),
    });

    return (
        <>
            <DialogSaurus
                aberto={openned}
                titulo='Reimprimir Vale'
                tamanho='xs'
            >
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 8 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="clienteDocumento"
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='CPF/CNPJ'
                                        placeholder="Informe o CPF/CNPJ do cliente para re-imprimir os vales disponíveis"
                                        tipo='CNPJ_CPF'
                                        autoFocus
                                        {...field}
                                        error={Boolean(errors.clienteDocumento)}
                                        helperText={Boolean(errors.clienteDocumento) ? errors.clienteDocumento?.message : null}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='outlined' onClick={() => {
                                fecharReimprimirVale();
                            }}>
                                <VoltarIcon tipo='BUTTON' />
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='contained' onClick={() => {
                                handleSubmit(onSubmit)();
                            }}>
                                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogSaurus>
        </>
    )
}