import { isEmpty } from "lodash";
import { DefaultModal } from '../../components/default-modal/default-modal';
import { ModalProps } from '../../utils/modal-props';
import { PessoaEdicao } from "./pessoa-edicao/pessoa-edicao";
import { PessoaCadastroSteps } from "./pessoa-cadastro-steps/pessoa-cadastro-steps";

export interface PessoalModalProps extends ModalProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const PessoaModal = (props: PessoalModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (
        <PessoaCadastroSteps handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      )}
      {props.openned && !isEmpty(props.id) && (
        <PessoaEdicao id={props.id} handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      )}
    </DefaultModal>
  );
};
