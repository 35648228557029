import { EnumIndIEDest } from "model/enums/enum-indiedest";
import { EnumCadastroStatus } from "model/enums/enum-cadastro-status";
import { EnumCadastroTipo } from "model/enums/enum-cadastro-tipo";

import { guidEmpty } from "utils/guid-empty";
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";
import { EnumSexo } from "model/enums/enum-sexo";
import { RepresentanteCliente } from 'model/api/gestao/pessoa/pessoa-model';

export class PessoaEditDadosFormModel {
  constructor(
    public id: string = guidEmpty(),
    public tpCadastro: EnumCadastroTipo = EnumCadastroTipo.FUNCIONARIO,
    public cpfcnpj: string = "",
    public ierg: string = "",
    public nome: string = "",
    public infOperador: string = "",
    public infInterno: string = "",
    public status: EnumCadastroStatus = EnumCadastroStatus.LIBERADO,
    public contatos: PessoaEditContatoFormModel[] = [],
    public dtNasc: string | Date | null = null,
    public sexo: EnumSexo = EnumSexo.NaoInformado,
    public representanteId: string = '',
    public representante: RepresentanteCliente | null = null,
    public usuarioId: string = '',
    public enderecos: PessoaEditEnderecoFormModel[] = []
  ) { }
}
export class PessoaEditEnderecoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(), //IdPessoa
    public cep: string = '',
    public logradouro: string = '',
    public numero: string = '',
    public complemento: string = '',
    public referencia: string = '',
    public bairro: string = '',
    public cMun: string = '',
    public xMun: string = '',
    public uf: string = '',
    public cuf: number = 0,
    public ierg: string = '',
    public im: string = '',
    public indIEDest: EnumIndIEDest = EnumIndIEDest.NAO_CONTRIBUINTE,
    public pessoaTransportadoraPadraoId: string = '',
  ) { }
}

export class PessoaValeFormModel {
  constructor(
    public clienteDocumento: string = '',
    public clienteNome: string = '',
    public clienteTelefone: string = '',
    public clienteEmail: string = '',
    public clienteCEP: string = '',
    public clienteXLgr: string = '',
    public clienteNro: string = '',
    public clienteXCpl: string = '',
    public clienteXBairro: string = '',
    public clienteCMun: number = 0,
    public clienteXMun: string = '',
    public clienteUF: string = '',
  ) { }
}

export class PessoaEditUsuarioLoginFormModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public usuarioId: string = '',
    public loginPdv: string = '',
    public senhaPdv: string | null = null,
  ) { }
}

export class PessoaEditContatoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public tipo: EnumTipoPessoaContato = EnumTipoPessoaContato.EMAIL,
    public valor: string = "",
    public responsavel: string = "",
  ) { }
}

export class PessoaEditDocumentoModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public tipo: EnumTipoDocumento = EnumTipoDocumento.CarteiraIdentidade,
    public documento: string = '',
    public dataExpiracao: string = '',
    public ufExpedicao: string = '',
    public orgaoExpedicao: string = '',
    public dataValidade: string = '',
    public informacoes: string = '',
    public indIEDest: EnumIndIEDest = EnumIndIEDest.NAO_CONTRIBUINTE,
  ) { }
}
