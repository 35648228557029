import { picker } from 'utils/picker';
import { usePostFinalizadora } from 'data/api/gestao/finalizadora';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { FinalizadoraCadastroFormModel } from 'model/app/forms/finalizadora/finalizadora-cadastro-form-model';
import { FormFinalizadoraCadastro } from 'views/components/form/finalizadora/form-finalizadora-cadastro/form-finalizadora-cadastro';
import { NovoIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { FinalizadoraCadastroTipo } from '../finalizadora-cadastro-tipo/finalizadora-cadastro-tipo';
import { stringNumeros } from 'utils/string-numeros';
import { guidEmpty } from 'utils/guid-empty';
import { Button, Grid } from 'views/design-system';
import { TypeTpModTransacao } from 'data/mocks';

enum EnumFinalizadoraCadastroStep {
  TipoFinalizadora,
  Formulario
}

export interface FinalizadoraCadastroProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const FinalizadoraCadastro = ({ atualizarLista, handleAtualizarLista }: FinalizadoraCadastroProps) => {
  const reload = useRef<boolean>(false);
  const { postFinalizadora, carregando } = usePostFinalizadora();
  const { fecharCadastroFinalizadora, abrirCadastroFinalizadora } =
    useCadastros();
  const cadFinalizadoraFormRef =
    useRef<DefaultFormRefs<FinalizadoraCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);
  const tipoPagTpModRef = useRef<TypeTpModTransacao>(EnumPagTpMod.DINHEIRO);
  const classes = useModalStyles();
  const { getEmpresaSelecionada } = useSessaoAtual();

  const [step, setStep] = useState<EnumFinalizadoraCadastroStep>(
    EnumFinalizadoraCadastroStep.TipoFinalizadora
  );

  const history = useHistory();
  const pathName = history.location.pathname;

  const { showToast } = useToastSaurus();
  const saveNewFinalizadora = useCallback(
    async (model: FinalizadoraModel) => {
      try {
        const ret = await postFinalizadora(model);
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          abrirCadastroFinalizadora(ret.resultado?.data.id, pathName, true);
        }
        if (!redirect.current) {
          cadFinalizadoraFormRef.current!.resetForm();
          showToast(
            'success',
            'Forma de pagamento adicionada com sucesso!',
            2000
          );
          setStep(EnumFinalizadoraCadastroStep.TipoFinalizadora);
        }
        handleAtualizarLista(true)
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [postFinalizadora, handleAtualizarLista, abrirCadastroFinalizadora, pathName, showToast]
  );
  const handleSubmit = useCallback(
    async (modelF: FinalizadoraCadastroFormModel) => {
      const FinalizadoraToCreate = picker<FinalizadoraModel>(
        modelF,
        new FinalizadoraModel()
      );
      FinalizadoraToCreate.resumido = FinalizadoraToCreate.descricao;

      //Setando valor do TpTransacao caso seja PIX
      FinalizadoraToCreate.tpTransacao =
        modelF.tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO
          ? EnumPagTpTransacao.SAFRA_PIX
          : FinalizadoraToCreate.tpTransacao;

      //Setando valor do TpTransacao caso seja BOLETO SAFRA    
      FinalizadoraToCreate.tpTransacao = modelF.tpMod === EnumPagTpMod.BOLETO_BANCARIO && modelF.credenciamentoBoleto
        ? EnumPagTpTransacao.BOLETO
        : FinalizadoraToCreate.tpTransacao;


      if (FinalizadoraToCreate.tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO) {
        FinalizadoraToCreate.credenciais = JSON.stringify(modelF.credenciais);
      } else if (FinalizadoraToCreate.tpMod === EnumPagTpMod.BOLETO_BANCARIO && modelF.credenciamentoBoleto) {
        FinalizadoraToCreate.credenciais = JSON.stringify(modelF.credenciamentoBoleto)

        FinalizadoraToCreate.cnpjCred = stringNumeros(getEmpresaSelecionada()?.Documento || '');

        FinalizadoraToCreate.empresaId = getEmpresaSelecionada()?.Id || null;
      } else if (FinalizadoraToCreate.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET) {
        FinalizadoraToCreate.credenciais = JSON.stringify(modelF.credenciais);
      }
      else {
        FinalizadoraToCreate.empresaId =
          modelF.empresaId === guidEmpty() ||
            modelF.empresaId === null
            ? null
            : modelF.empresaId;
      }
      return saveNewFinalizadora(FinalizadoraToCreate);
    },
    [getEmpresaSelecionada, saveNewFinalizadora]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadFinalizadoraFormRef.current?.submitForm();
    reload.current = true;
  }, []);

  const submitTipo = useCallback((tipo: TypeTpModTransacao) => {
    tipoPagTpModRef.current = tipo;
    setStep(EnumFinalizadoraCadastroStep.Formulario);
  }, []);

  const onCloseClick = useCallback(() => {
    switch (step) {
      case EnumFinalizadoraCadastroStep.Formulario:
        setStep(EnumFinalizadoraCadastroStep.TipoFinalizadora);
        break;
      default:
        fecharCadastroFinalizadora('', atualizarLista);
        handleAtualizarLista(false)
    }
  }, [atualizarLista, fecharCadastroFinalizadora, handleAtualizarLista, step]);

  useEffect(() => {
    if (step === EnumFinalizadoraCadastroStep.Formulario) {
      let tpMod;
      let tpTransacao;
      if (typeof tipoPagTpModRef.current === 'object') {
        tpMod = tipoPagTpModRef.current.tpMod;
        tpTransacao = tipoPagTpModRef.current.tpTransacao;
      } else {
        tpMod = tipoPagTpModRef.current;
      }
      cadFinalizadoraFormRef.current?.fillForm(
        {
          ...new FinalizadoraCadastroFormModel(),
          tpMod,
          tpTransacao,
        }
      );
    }
  }, [step]);

  const switchStep = useCallback(() => {
    switch (step) {
      case EnumFinalizadoraCadastroStep.Formulario:
        return (
          <div className={classes.content}>
            <div
              className={classNames(
                classes.contentForms,
                carregando ? classes.contentFormsLoading : undefined
              )}
            >
              <FormFinalizadoraCadastro
                ref={cadFinalizadoraFormRef}
                loading={carregando}
                onSubmit={handleSubmit}
                tpMod={tipoPagTpModRef.current}
                showLoading
              />
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    disabled={carregando}
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      submitForm(false);
                    }}
                  >
                    <NovoIcon tipo="BUTTON" />
                    Salvar e Adicionar Outro
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    disabled={carregando}
                    onClick={() => {
                      submitForm(true);
                      reload.current = true;
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                  carregando ? classes.contentFormsLoading : undefined
                )}
              >
                <FinalizadoraCadastroTipo onConfirm={submitTipo} />
              </div>
            </div>
          </>
        );
    }
  }, [
    carregando,
    classes.acoes,
    classes.content,
    classes.contentForms,
    classes.contentFormsLoading,
    handleSubmit,
    step,
    submitForm,
    submitTipo
  ]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Nova Forma de Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      {switchStep()}
    </div>
  );
};
export default FinalizadoraCadastro;
