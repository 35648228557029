import { PessoaCadastroTipo } from './components/pessoa-cadastro-tipo';
import { useStyles } from './pessoa-cadastro-steps-styles';
import { useCallback, useMemo, useRef, useState } from 'react';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { ConsultaCNPJModel, EnumCadastroTipo } from 'model';
import { TemTouch } from 'utils/tem-touch';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { stringNumeros } from 'utils/string-numeros';
import { DefaultFormRefs } from 'views/components/form/utils';
import { PessoaCadastroFormModel } from 'model/app/forms/pessoa/pessoa-cadastro-form-model';
import { FormPessoaCadastro } from 'views/components/form/pessoa/form-pessoa-cadastro';
import { usePostEnderecoPessoa, usePostPessoa } from 'data/api/gestao/pessoa';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { CircularLoading } from 'views/components';
import { useCadastros, useToastSaurus } from 'services/app';
import { TpCadastroFarmaciaMock, UFMock } from 'data/mocks';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import classNames from 'classnames';
import { validarCNPJ, validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { PessoaDocumentoModel } from 'model/api/gestao/pessoa/pessoa-documento-model';
import { usePostDocumentoPessoa } from 'data/api/gestao/pessoa/post-documento-pessoa';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { guidEmpty } from 'utils/guid-empty';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { useGetPessoaByDoc } from 'data/api/gestao/pessoa/get-pessoa-by-doc';
import { useConfirm } from 'material-ui-confirm';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { PessoaCadastroTecladoCPFCNPJ } from './components/pessoa-cadastro-teclado-cpfcnpj';
import { PessoaCadastroTecladoDocumento } from './components/pessoa-cadastro-teclado-documento';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { Box, Button } from 'views/design-system';

const tiposComDocProfissional = [
  EnumCadastroTipo.PRESCRITOR,
  EnumCadastroTipo.RESPONSAVEL_TECNICO
];

export const tiposComDocProfissionalObrigatorio = [
  EnumCadastroTipo.PRESCRITOR,
  EnumCadastroTipo.RESPONSAVEL_TECNICO
];

export const tiposQuePedemAmbosDocumentos = [EnumCadastroTipo.PRESCRITOR];

export const tiposComCPFCNPJObrigatorio = [
  EnumCadastroTipo.FORNECEDOR,
  EnumCadastroTipo.REPRESENTANTE,
];

export interface InfoDocTeclado {
  orgaoExpedicao: TipoDocumentoOrgaoExpedidorEnum | null;
  ufExpedicao: string;
  dataValidade: string;
  dataExpedicao: string;
}

export enum EnumStepCadastroPessoa {
  TipoPessoa,
  TecladoCPFCNPJ,
  TecladoDocumentoProfissional,
  Formulario
}

interface PessoaCadastroStepsProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const PessoaCadastroSteps = ({ atualizarLista, handleAtualizarLista }: PessoaCadastroStepsProps) => {
  const [step, setStep] = useState<EnumStepCadastroPessoa>(
    EnumStepCadastroPessoa.TipoPessoa
  );
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const { abrirCadastroPessoa, fecharCadastroPessoa } = useCadastros();
  const confirm = useConfirm();
  const { pathname } = useLocation();
  const { getEmpresaAtual } = useEmpresaAtual();

  const { postPessoa, carregando: carregandoPost } = usePostPessoa();
  const { postEnderecoPessoa, carregando: carregandoEndereco } =
    usePostEnderecoPessoa();
  const { postDocumentoPessoa, carregando: carregandoDocumento } =
    usePostDocumentoPessoa();
  const { getPessoaByDoc, carregando: carregandoDoc } = useGetPessoaByDoc();
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();

  const carregando =
    carregandoPost ||
    carregandoCNPJ ||
    carregandoEndereco ||
    carregandoDocumento ||
    carregandoDoc;

  const pessoaModel = useRef<PessoaModel>(new PessoaModel());
  const consultaModel = useRef<ConsultaCNPJModel | null>(null);
  const documentoModel = useRef<PessoaDocumentoModel | null>(null);
  const cadPessoaFormRef =
    useRef<DefaultFormRefs<PessoaCadastroFormModel>>(null);
  const docValidado = useRef<boolean>(false);

  const validarDocumento = useCallback(
    async (documento: string) => {
      try {
        const res = await getPessoaByDoc(stringNumeros(documento));

        if (res.erro) throw res.erro;

        if (res.resultado && res.resultado?.data.list.length > 0) {
          const pessoaCadastrada = res.resultado.data.list[0] as PessoaModel;
          await confirm({
            title: 'Documento já cadastrado!',
            description: `O documento ${formatarCPFCNPJ(
              documento
            )} já está cadastrado no usuário ${pessoaCadastrada.nome
              }. Deseja ir ao cadastro ou alterar o documento?`,
            confirmationText: 'Ir ao cadastro',
            cancellationText: 'Voltar'
          }).then(() => {
            abrirCadastroPessoa(pessoaCadastrada.id, pathname, true);
          });
          return false;
        }
        return true;
      } catch (e: any) {
        if (e) showToast('error', e.message);
        return false;
      }
    },
    [abrirCadastroPessoa, confirm, getPessoaByDoc, pathname, showToast]
  );

  const consultaCNPJWrapper = useCallback(
    async (documento: string, setarNome: boolean, perguntarSetarNome?: {
      accept: boolean,
      nome: string
    }) => {
      try {
        let permitirTrocaNome = false
        const res = await consultarCNPJ(documento);

        if (
          res
          && perguntarSetarNome
          && res.razaoSocial.normalize('NFD').toLowerCase() !==
          perguntarSetarNome.nome.normalize('NFD').toLowerCase()
        ) {
          await confirm({
            title: 'CNPJ Encontrado',
            description: 'Os dados do seu CNPJ foram encontrados e verificamos que a Razão Social está diferente. Deseja substituir?',
            confirmationText: 'Substituir',
            cancellationText: 'Rejeitar'
          }).then(() => permitirTrocaNome = true)
        }

        if (setarNome || permitirTrocaNome) {
          pessoaModel.current.nome = res.razaoSocial;
        }

        consultaModel.current = res;

        return;
      } catch (e: any) { }
    },
    [confirm, consultarCNPJ]
  );

  const submitPessoa = useCallback(
    async (model: PessoaModel) => {
      try {
        let erro = false;

        if (!docValidado.current && !isEmpty(model.cpfcnpj)) {
          const validacao = await validarDocumento(model.cpfcnpj);

          if (!validacao) return;
        }

        const res = await postPessoa(model);
        if (res.erro) throw res.erro;

        const dadosConsulta = consultaModel.current;

        if (dadosConsulta) {
          const enderecoModel = new PessoaEnderecoModel();
          enderecoModel.pessoaId = res.resultado?.data.id;
          enderecoModel.bairro = dadosConsulta.bairro;
          enderecoModel.cMun = dadosConsulta.cMunicipio;
          enderecoModel.xMun = dadosConsulta.municipio;
          enderecoModel.cep = stringNumeros(dadosConsulta.cep);
          enderecoModel.complemento = dadosConsulta.complemento;
          enderecoModel.logradouro = dadosConsulta.logradouro;
          enderecoModel.numero = dadosConsulta.numero;
          enderecoModel.ierg = dadosConsulta.ie
          enderecoModel.uf = dadosConsulta.uf;
          enderecoModel.cuf = UFMock.find(
            (uf) => uf.Value === dadosConsulta.uf
          )?.Key;
          enderecoModel.ierg = dadosConsulta.ie;

          try {
            const retEndereco = await postEnderecoPessoa(enderecoModel);
            if (retEndereco.erro) {
              throw retEndereco.erro;
            }
          } catch { }
        }

        if (documentoModel.current) {
          documentoModel.current.pessoaId = res.resultado?.data.id;
          try {
            if (documentoModel.current.documento) {
              const resDocumento = await postDocumentoPessoa(
                documentoModel.current
              );
              if (resDocumento.erro) throw resDocumento.erro;
            }
          } catch (e: any) {
            erro = true;
            showToast(
              'error',
              'Cadastrado realizado, porém houve um erro ao cadastrar o documento. Tente novamente.'
            );
          }
        }

        if (!erro) {
          showToast(
            'success',
            `${TpCadastroFarmaciaMock.find((x) => x.Key === model.tpCadastro)
              ?.Value || 'Pessoa'
            } cadastrado com sucesso!`
          );
        }

        history.push('/pessoas');
        abrirCadastroPessoa(res.resultado?.data.id, '', true);
        handleAtualizarLista(true)
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      abrirCadastroPessoa,
      history,
      postDocumentoPessoa,
      postEnderecoPessoa,
      postPessoa,
      showToast,
      validarDocumento,
      handleAtualizarLista,
    ]
  );

  const handleTipo = useCallback((tipo: EnumCadastroTipo) => {
    documentoModel.current = null;
    pessoaModel.current.tpCadastro = tipo;
    pessoaModel.current.cpfcnpj = '';

    if (TemTouch()) {
      if (tiposComDocProfissional.includes(tipo)) {
        setStep(EnumStepCadastroPessoa.TecladoDocumentoProfissional);
        return;
      }
      setStep(EnumStepCadastroPessoa.TecladoCPFCNPJ);
      return;
    }

    setStep(EnumStepCadastroPessoa.Formulario);
  }, []);

  const handleDocumento = useCallback(
    async (
      documento: string,
      isObrigatorio: boolean,
      info?: InfoDocTeclado
    ) => {
      if (isEmpty(documento) && isObrigatorio) {
        showToast('error', 'Documento obrigatório.');
        return;
      }
      if (pessoaModel.current?.tpCadastro && tiposComCPFCNPJObrigatorio.includes(pessoaModel.current.tpCadastro)) {
        if (!validarCPFCNPJ(documento))
          return showToast('error', 'Documento Inválido.');

        const validacao = await validarDocumento(documento);

        if (!validacao) return;
      }

      docValidado.current = true;

      //faço essa validação pra saber que não é um CPF/CNPJ, já que não vem info  dele
      if (!info) {
        pessoaModel.current.cpfcnpj = stringNumeros(documento);

        await consultaCNPJWrapper(documento, true);

        if (consultaModel.current) {
          await submitPessoa(pessoaModel.current);
        }
      } else {
        documentoModel.current = new PessoaDocumentoModel(
          guidEmpty(),
          '',
          EnumTipoDocumento.CarteiraRegistroProfissional,
          documento,
          info.dataExpedicao,
          info?.ufExpedicao || '',
          info?.orgaoExpedicao || undefined,
          info.dataValidade
        );
      }
      if (
        step === EnumStepCadastroPessoa.TecladoDocumentoProfissional &&
        pessoaModel.current.tpCadastro &&
        tiposQuePedemAmbosDocumentos.includes(pessoaModel.current.tpCadastro)
      ) {
        setStep(EnumStepCadastroPessoa.TecladoCPFCNPJ);
        return;
      }
      setStep(EnumStepCadastroPessoa.Formulario);
    },
    [consultaCNPJWrapper, showToast, step, submitPessoa, validarDocumento]
  );

  const handleFormulario = useCallback(
    async (model: PessoaCadastroFormModel) => {
      pessoaModel.current.nome = model.nome;
      if (!TemTouch()) {
        documentoModel.current = new PessoaDocumentoModel(
          guidEmpty(),
          '',
          model.documento.tipo,
          model.documento.documento,
          model.documento.dataExpedicao,
          model.documento.ufExpedicao,
          model.documento.orgaoExpedicao,
          model.documento.dataValidade
        );
      }
      if (!isEmpty(model.cpfcnpj)) {
        pessoaModel.current.cpfcnpj = stringNumeros(model.cpfcnpj);
      }

      if (model.tpCadastro === EnumCadastroTipo.CLIENTE) {
        pessoaModel.current.representanteId = model.representanteId;
        pessoaModel.current.representante = model.representante;
      }
      if (validarCNPJ(model.cpfcnpj) && !consultaModel.current) {
        await consultaCNPJWrapper(model.cpfcnpj, false, {
          accept: true,
          nome: model.nome
        });
      }
      submitPessoa(pessoaModel.current);

    },
    [consultaCNPJWrapper, submitPessoa]
  );

  const handleVoltar = useCallback(() => {
    switch (step) {
      case EnumStepCadastroPessoa.TecladoCPFCNPJ:
        if (
          pessoaModel.current.tpCadastro &&
          tiposQuePedemAmbosDocumentos.includes(pessoaModel.current.tpCadastro)
        ) {
          setStep(EnumStepCadastroPessoa.TecladoDocumentoProfissional);
          return;
        }
        setStep(EnumStepCadastroPessoa.TipoPessoa);
        break;
      case EnumStepCadastroPessoa.Formulario:
        if (TemTouch()) {
          if (
            pessoaModel.current.tpCadastro &&
            tiposQuePedemAmbosDocumentos.includes(
              pessoaModel.current.tpCadastro
            )
          ) {
            setStep(EnumStepCadastroPessoa.TecladoCPFCNPJ);
            return;
          }
          if (
            pessoaModel.current.tpCadastro &&
            tiposComDocProfissional.includes(pessoaModel.current.tpCadastro)
          ) {
            setStep(EnumStepCadastroPessoa.TecladoDocumentoProfissional);
            return;
          }
          setStep(EnumStepCadastroPessoa.TecladoCPFCNPJ);
          break;
        }
        setStep(EnumStepCadastroPessoa.TipoPessoa);
        break;
      case EnumStepCadastroPessoa.TipoPessoa:
        fecharCadastroPessoa('', atualizarLista);
        handleAtualizarLista(false)
        break;
      case EnumStepCadastroPessoa.TecladoDocumentoProfissional:
        setStep(EnumStepCadastroPessoa.TipoPessoa);
        break;
    }
  }, [atualizarLista, fecharCadastroPessoa, handleAtualizarLista, step]);

  const skipTeclado = useCallback(() => {
    switch (step) {
      case EnumStepCadastroPessoa.TecladoCPFCNPJ:
        setStep(EnumStepCadastroPessoa.Formulario);
        break;
      case EnumStepCadastroPessoa.TecladoDocumentoProfissional:
        if (
          pessoaModel.current.tpCadastro &&
          tiposQuePedemAmbosDocumentos.includes(pessoaModel.current.tpCadastro)
        ) {
          setStep(EnumStepCadastroPessoa.TecladoCPFCNPJ);
          return;
        }
        setStep(EnumStepCadastroPessoa.Formulario);
    }
  }, [step]);

  const switchStep = useMemo(() => {
    switch (step) {
      case EnumStepCadastroPessoa.TipoPessoa:
        return <PessoaCadastroTipo onClick={handleTipo} />;

      case EnumStepCadastroPessoa.TecladoCPFCNPJ:
        return (
          <PessoaCadastroTecladoCPFCNPJ
            submitDocumento={handleDocumento}
            handleVoltar={handleVoltar}
            tipo={pessoaModel.current.tpCadastro ?? EnumCadastroTipo.CLIENTE}
            valorPadrao={pessoaModel.current.cpfcnpj}
            handleAvancar={skipTeclado}
          />
        );

      case EnumStepCadastroPessoa.TecladoDocumentoProfissional:
        return (
          <PessoaCadastroTecladoDocumento
            submitDocumento={handleDocumento}
            handleVoltar={handleVoltar}
            tipo={pessoaModel.current.tpCadastro ?? EnumCadastroTipo.CLIENTE}
            valorPadrao={
              documentoModel.current
                ? documentoModel.current.documento
                : undefined
            }
            handleAvancar={skipTeclado}
            infoPadrao={
              documentoModel.current
                ? {
                  orgaoExpedicao: documentoModel.current.orgaoExpedicao,
                  ufExpedicao: documentoModel.current.ufExpedicao,
                  dataValidade: documentoModel.current.dataValidade,
                  dataExpedicao: documentoModel.current.dataExpedicao
                }
                : undefined
            }
            ufPadrao={
              pessoaModel.current.tpCadastro ===
                EnumCadastroTipo.RESPONSAVEL_TECNICO
                ? getEmpresaAtual()?.uf
                : ''
            }
          />
        );

      case EnumStepCadastroPessoa.Formulario:
        return (
          <Box className={classes.formContainer}>
            <FormPessoaCadastro
              showLoading={false}
              loading={false}
              tipo={pessoaModel.current.tpCadastro ?? EnumCadastroTipo.CLIENTE}
              ref={cadPessoaFormRef}
              onSubmit={handleFormulario}
              showCpf={!TemTouch()}
              cpfObrigatorio={
                tiposComCPFCNPJObrigatorio.includes(
                  pessoaModel.current.tpCadastro ?? EnumCadastroTipo.CLIENTE
                ) && isEmpty(pessoaModel.current.cpfcnpj)
              }
              showDocs={
                tiposComDocProfissionalObrigatorio.includes(
                  pessoaModel.current.tpCadastro ?? EnumCadastroTipo.CLIENTE
                ) && isEmpty(documentoModel.current)
              }
            />
            <Box
              className={classNames(
                classes.buttonContainer,
                classes.buttonFlexWrap
              )}
            >
              <Button variant="outlined" color="primary" onClick={handleVoltar}>
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => cadPessoaFormRef.current?.submitForm()}
                id="tour-form-pessoas-botao-avancar"
              >
                <AvancarIcon tipo="BUTTON_PRIMARY" />
                Avançar
              </Button>
            </Box>
          </Box>
        );
    }
  }, [
    classes.buttonContainer,
    classes.buttonFlexWrap,
    classes.formContainer,
    getEmpresaAtual,
    handleDocumento,
    handleFormulario,
    handleTipo,
    handleVoltar,
    skipTeclado,
    step
  ]);

  const retornarTituloModal = useCallback(() => {
    switch (step) {
      case EnumStepCadastroPessoa.TecladoCPFCNPJ:
        switch (pessoaModel.current.tpCadastro) {
          case EnumCadastroTipo.PRESCRITOR:
            return 'Identificação do Prescritor';
          case EnumCadastroTipo.CLIENTE:
            return 'Identificação do Cliente';
          case EnumCadastroTipo.FORNECEDOR:
            return 'Identificação do Fornecedor';
          case EnumCadastroTipo.FUNCIONARIO:
            return 'Identificação do Funcionário';
          case EnumCadastroTipo.REPRESENTANTE:
            return 'Identificação do Representante';
          case EnumCadastroTipo.RESPONSAVEL_TECNICO:
            return 'Identificação do Resp. Técnico';
          default:
            return 'Identificação da Transportadora';
        }
      case EnumStepCadastroPessoa.TecladoDocumentoProfissional:
        switch (pessoaModel.current.tpCadastro) {
          case EnumCadastroTipo.PRESCRITOR:
            return 'Identificação do Prescritor';
          case EnumCadastroTipo.CLIENTE:
            return 'Identificação do Cliente';
          case EnumCadastroTipo.FORNECEDOR:
            return 'Identificação do Fornecedor';
          case EnumCadastroTipo.FUNCIONARIO:
            return 'Identificação do Funcionário';
          case EnumCadastroTipo.REPRESENTANTE:
            return 'Identificação do Representante';
          case EnumCadastroTipo.RESPONSAVEL_TECNICO:
            return 'Identificação do Resp. Técnico';
          default:
            return 'Identificação da Transportadora';
        }
      default:
        switch (pessoaModel.current.tpCadastro) {
          case undefined:
            return 'Cadastro de Pessoa';
          case EnumCadastroTipo.PRESCRITOR:
            return 'Cadastro de Prescritor';
          case EnumCadastroTipo.CLIENTE:
            return 'Cadastro de Cliente';
          case EnumCadastroTipo.FORNECEDOR:
            return 'Cadastro de Fornecedor';
          case EnumCadastroTipo.FUNCIONARIO:
            return 'Cadastro de Funcionário';
          case EnumCadastroTipo.REPRESENTANTE:
            return 'Cadastro de Representante';
          case EnumCadastroTipo.RESPONSAVEL_TECNICO:
            return 'Cadastro de Responsável Técnico';
          default:
            return 'Cadastro de Transportadora';
        }
    }
  }, [step]);

  return (
    <Box className={modalClasses.root}>
      <ModalHeader
        title={retornarTituloModal()}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              fecharCadastroPessoa('', atualizarLista);
              handleAtualizarLista(false)
            }}
          />
        }
      />
      <Box className={classes.container}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        {switchStep}
      </Box>
    </Box>
  );
};
