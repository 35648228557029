import { EnumStatusVale, EnumTipoVale } from "model/enums/enum-vale";
import { guidEmpty } from "utils/guid-empty";

export class ValeModel {
    constructor(
      public id: string = guidEmpty(),
      public referenceId: string = '',
      public nroVale: string = '',
      public emitenteDocumento: string = '',
      public emitente: string = '',
      public clienteDocumento: string = '',
      public clienteNome: string = '',
      public clienteTelefone: string = '',
      public clienteEmail: string = '',
      public clienteCEP: string = '',
      public clienteXLgr: string = '',
      public clienteNro: string = '',
      public clienteXCpl: string = '',
      public clienteXBairro: string = '',
      public clienteCMun: number = 0,
      public clienteXMun: string = '',
      public clienteUF: string = '',
      public clienteCPais: number = 0,
      public clienteXPais: string = '',
      public tipo: EnumTipoVale = EnumTipoVale.ValeTroco,
      public valor: number = 0,
      public status: EnumStatusVale = EnumStatusVale.Pendente,
      public dtAtivacao: string = '',
      public dtExpiracao: string = '',
      public dtCriacao: string = '',
      public usuarioCriacao: string = '',
      public dtUtilizacao: string = '',
      public usuarioUtilizacao: string = '',
      public dtCancelamento: string = '',
      public usuarioCancelamento: string = '',
      public logStatus: LogStatusModel[] = [],
      public trocaItens: TrocaItensModel[] = [],
    ) {}
  }
  
  export class LogStatusModel {
    constructor(
      public id: string = '',
      public valeId: string = '',
      public dtStatus: string = '',
      public usuario: string = '',
      public status: EnumStatusVale = EnumStatusVale.Pendente,
      public referenceID: string = '',
      public referenceJSON: string = '',
    ) {}
  }
  
  export class TrocaItensModel {
    constructor(
      public id: string = '',
      public valeId: string = '',
      public cupomDocumentoLoja: string = '',
      public cupomNro: number = 0,
      public cupomSerie: number = 0,
      public cupomTpMov: number = 0,
      public cupomDtEmissao: number = 0,
      public nItem: number = 0,
      public idProduto: number = 0,
      public codProduto: string = '',
      public descProduto: string = '',
      public qCom: number = 0,
      public vProd: number = 0,
      public vale: string = '',
    ) {}
  }