import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { guidEmpty } from "../../../../../utils/guid-empty";
import { EnumIndEscala } from "../../../../enums/enum-ind-escala";
import { EnumOrigemMercadoria } from "../../../../enums/enum-origem-mercadoria";
import { ProdutoVariacaoModel } from "../produto-variacao/produto-variacao";


export class ProdutoCompletoModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public infAdic: string = "",
    public categoriaId: string = guidEmpty(),
    public categoria: string = '',
    public ncmId: string = guidEmpty(),
    public ncm: string = '',
    public marcaId: string = "",
    public marca: string = '',
    public cnpjFab: string = "",
    public orig: EnumOrigemMercadoria = EnumOrigemMercadoria.OM_NACIONAL,
    public indEscala: EnumIndEscala = EnumIndEscala.N,
    public grades: Array<ProdutoVariacaoModel> = new Array<ProdutoVariacaoModel>(),
    public codigoNcm: string = "",
    public impostoId: null = null,
    public imposto: string = "",
    public setorId: string | null = null,
    public setor: string = '',
    public cobraTaxaServico: boolean = true,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto
  ) { }
}
