import { TpValeMock } from "data/mocks/tp-vale-mock";
import { ValeModel } from "model/api/gestao/troca/vale-model";
import { EnumStatusVale, EnumTipoVale } from "model/enums/enum-vale";
import { Alinhamento, Orientacao } from "utils/alinhamento";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { StringBuilder } from "utils/stringBuilder";
import { toDateString } from "utils/to-date";
import { toCurrency } from "utils/to-decimal";

export const useImpressaoVale = () => {

    const impressaoVale = (
        xVale: ValeModel,
        qtd_colunas: number,
    ): string => {
        try {
            let xRetorno = new StringBuilder();

            // Cabeçalho
            xRetorno.append("<cond><centro>");
            xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));

            xRetorno.appendWithln(`<centro><grande>${TpValeMock.find(vale => vale.Key === xVale.tipo)?.Value || 'Vale'}</grande>`);

            xRetorno.append("<cond><centro>");
            xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));
            xRetorno.append("</n>");

            // Informações do Vale
            xRetorno.appendWithln(
                Alinhamento.AlinhaCentro(
                    `Data de Criação: ${toDateString(new Date(xVale.dtCriacao), 'DD/MM/yyyy HH:mm')}`,
                    " ",
                    qtd_colunas
                )
            );
            xRetorno.appendWithln(
                Alinhamento.AlinhaCentro(
                    `Empresa Emitente: ${formatarCPFCNPJ(xVale.emitenteDocumento)}`,
                    " ",
                    qtd_colunas
                )
            );

            // Validade
            if (xVale.tipo !== EnumTipoVale.ValeVasilhame) {
                xRetorno.append("<n>");
                xRetorno.appendWithln(
                    Alinhamento.AlinhaCentro(
                        `Válido de ${toDateString(new Date(xVale.dtAtivacao), 'DD/MM/yyyy HH:mm')} até ${toDateString(new Date(xVale.dtExpiracao), 'DD/MM/yyyy HH:mm')}`,
                        " ",
                        qtd_colunas
                    )
                );
                xRetorno.append("</n>");
            }

            // Status do Vale
            if (xVale.status === EnumStatusVale.Cancelado) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));
                xRetorno.appendWithln("<centro><grande>CANCELADO</grande>");
                xRetorno.append("<cond><n>");
                xRetorno.appendWithln(
                    Alinhamento.AlinhaCentro(
                        `Cancelado em ${toDateString(new Date(xVale.dtCancelamento), 'DD/MM/yyyy HH:mm')} por ${xVale.usuarioCancelamento}`,
                        " ",
                        qtd_colunas
                    )
                );
                xRetorno.append("</n>");
            } else if (xVale.status === EnumStatusVale.Utilizado) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));
                xRetorno.appendWithln("<centro><grande>UTILIZADO</grande>");
                xRetorno.append("<cond><n>");
                xRetorno.appendWithln(
                    Alinhamento.AlinhaCentro(
                        `Utilizado em ${toDateString(new Date(xVale.dtUtilizacao), 'DD/MM/yyyy HH:mm')} por ${xVale.usuarioUtilizacao}`,
                        " ",
                        qtd_colunas
                    )
                );
                xRetorno.append("</n>");
            }

            // Expiração
            const now = new Date();
            if (now > new Date(xVale.dtExpiracao)) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));
                xRetorno.appendWithln("<centro><grande>EXPIRADO</grande>");
            } else if (now < new Date(xVale.dtAtivacao)) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas));
                xRetorno.appendWithln("<centro><grande>NÃO ATIVO</grande>");
            }

            xRetorno.append("<cond>");

            // Informações do Cliente
            if (xVale.clienteNome || xVale.clienteTelefone || xVale.clienteEmail) {
                xRetorno.appendWithln(Alinhamento.AlinhaCentro("-", "-", qtd_colunas));
            }
            if (xVale.clienteNome) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda(`Nome: ${xVale.clienteNome}`, " ", qtd_colunas));
            }
            if (xVale.clienteTelefone) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda(`Telefone: ${xVale.clienteTelefone}`, " ", qtd_colunas));
            }
            if (xVale.clienteEmail) {
                xRetorno.appendWithln(Alinhamento.AlinhaEsquerda(`Email: ${xVale.clienteEmail}`, " ", qtd_colunas));
            }

            // Endereço
            if (xVale.clienteXLgr) {
                xRetorno.appendWithln(
                    Alinhamento.AlinhamentoMultiLinhas(
                        Orientacao.Esquerda,
                        `Endereço: ${xVale.clienteXLgr}, ${xVale.clienteNro} ${xVale.clienteXCpl} - ${xVale.clienteXBairro} - ${xVale.clienteCEP} - ${xVale.clienteXMun} - ${xVale.clienteUF}`,
                        qtd_colunas,
                        " "
                    )
                );
            }

            xRetorno.appendWithln(Alinhamento.AlinhaCentro("-", "-", qtd_colunas));

            // Valor do Vale
            xRetorno.appendWithln(Alinhamento.AlinhaCentro("Valor do Vale", " ", qtd_colunas));
            xRetorno.appendWithln(`<centro><gigante>${toCurrency(xVale.valor)}</gigante>`);
            xRetorno.appendWithln("<cond>");
            xRetorno.appendWithln(Alinhamento.AlinhaCentro("-", "-", qtd_colunas));

            // Código de Barras

            xRetorno.append("<centro>");
            xRetorno.appendWithln("<expandido>CHAVE ÚNICA</expandido>");
            xRetorno.append(`<barcode>${xVale.nroVale}</barcode>`);
            xRetorno.appendWithln(Alinhamento.AlinhaCentro(xVale.nroVale.match(/.{1,5}/g)?.join(" ") ?? "", " ", qtd_colunas));
            xRetorno.appendWithln(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Esquerda, "A apresentacao deste vale e obrigatorio no momento da venda.", qtd_colunas, " "));

            xRetorno.append("<guilhotina>");

            return xRetorno.toString();
        } catch (error) {
            throw new Error(`Erro ao gerar o modelo de Impressão: ${error}`);
        }
    };



    return {
        impressaoVale
    }
}