import { ConvenioResumoFaturasProps } from "./convenio-resumo-faturas-props"
import { ModalHeader } from "views/components/modals/components";
import { CircularLoading } from "views/components/utils";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { DinheiroIcon, VoltarIcon } from "views/components/icons";
import classNames from "classnames";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useCadastros, useToastSaurus } from "services/app";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { useStyles } from "./convenio-resumo-faturas-styles";
import { ConvenioModel } from "model/api/gestao/convenio/convenio-model";
import { ConvenioFaturaModel } from "model/api/gestao/convenio/convenio-fatura-model";
import { InformacoesConvenio } from "./components/informacoes-convenio/informacoes-convenio";
import { AnoMesList } from "./components/ano-mes-list/ano-mes-list";
import { useGetConvenioByPessoa, useGetFaturaPessoaAnoMes } from "data/api/gestao/conta-pessoa";
import { LancamentosList } from "./components/lancamentos-list/lancamentos-list";
import { toDecimalString } from "utils/to-decimal";
import { EnumConvenioTpFaturaStatus } from "model/enums/enum-convenio-tp-fatura-status";
import { TpStatusFaturaConvenioMock } from "data/mocks/status-tp-fatura-convenio-mock";
import { isEqual } from "lodash";
import tinycolor from 'tinycolor2';
import { Button, Typography, Grid } from "views/design-system";
import { EnumTpAutenticacaoConvenio } from "model/enums/enum-tp-autenticacao-convenio";
import { useHistory } from "react-router-dom";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import { CredenciamentoSafra } from "model/api/gestao/finalizadora/finalizadora-model";
import { ConvenioCartaoAutenticarModel } from "model/api/gestao/convenio";
import { usePDV } from "services/app/hooks/pdv";
import { useConfirm } from "material-ui-confirm";
import { MovRotasMock } from "data/mocks/mov-rotas-mock";
import { toDateString } from "utils/to-date";
import { useMovRota } from "services/app/hooks/mov-rota";
import { EnumTpTecladoFuncoes, RetornoTecladoProps } from "views/components/modals/teclado-funcoes/components/teclado-funcoes/teclado-funcoes-props";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { EnumMenuPrincipalModo } from "model/enums/enum-menu-principal-modo";

interface HistoryStateProps {
    modoPagamento: MovSimplesPagamentoModel;
    credenciais: CredenciamentoSafra
    loginConvenio?: ConvenioCartaoAutenticarModel
    pagFatura?: {
        faturaId: string
        anoMesCompetencia: number
    }
}

export const ConvenioResumoFaturas = ({ tipo, anoMesSelecionado, ...props }: ConvenioResumoFaturasProps) => {

    // STATES & REFS
    const [fatura, setFatura] = useState<ConvenioFaturaModel>(new ConvenioFaturaModel())
    const [anoMes, setAnoMes] = useState<number | undefined>(anoMesSelecionado)
    const [convenio, setConvenio] = useState<ConvenioModel>(new ConvenioModel())
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const [historicoCompleto, setHistoricoCompleto] = useState<boolean>(false)
    const attRef = useRef<boolean>(false)
    const { lancamentos } = fatura

    // HOOKS && PROVIDERS 
    const { showToast } = useToastSaurus();
    const modalClasses = useModalStyles()
    const classes = useStyles()
    const {
        fecharConvenioResumoFaturas,
        abrirCadastroConvenio,
        abrirTecladoFuncoes,
        abrirCadastroCartaoConvenio,
    } = useCadastros()
    const { alterarModo } = useMenuPrincipal()
    const { getEmpresaAtual } = useEmpresaAtual()
    const history = useHistory()
    const { getPDV } = usePDV()
    const confirm = useConfirm()
    const { redirectProcessarPagamento } = useMovRota()
    const [carregandoInterno, setCarregandoInterno] = useState<boolean>(true)

    //REQS
    const { getConvenioByPessoa, carregando: carregandoGetConvenioId } = useGetConvenioByPessoa()
    const { getFaturaPessoaAnoMes, carregando: carregandoGet } = useGetFaturaPessoaAnoMes()

    const carregando = [carregandoGet, carregandoGetConvenioId, carregandoInterno].includes(true)

    const getFaturaAnoMesWrapper = useCallback(async (pessoaId: string) => {
        if (!anoMes) return
        const params = {
            anoMes,
            empresaId: getEmpresaAtual()?.id ?? '',
            pessoaId: pessoaId ?? props.pessoaId,
        }
        const res = await getFaturaPessoaAnoMes(params);
        if (res.erro) {
            throw res.erro;
        }
        const ret = res.resultado?.data as ConvenioFaturaModel;
        return ret
    }, [getFaturaPessoaAnoMes, anoMes, getEmpresaAtual, props.pessoaId]);

    const handleFaturas = useCallback(async () => {
        try {
            const fatura = await getFaturaAnoMesWrapper(props.pessoaId)
            if (fatura) setFatura(fatura)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getFaturaAnoMesWrapper, props.pessoaId, showToast])

    const getConvenioByPessoaWrapper = useCallback(async () => {
        const res = await getConvenioByPessoa(props.pessoaId, getEmpresaAtual()?.id ?? '')
        if (res.erro) throw res.erro

        const convenioRes = res.resultado?.data as ConvenioModel
        setConvenio(convenioRes)

    }, [getConvenioByPessoa, getEmpresaAtual, props.pessoaId])

    const preencherTela = useCallback(async () => {
        try {
            setPreenchendoTela(true);
            await getConvenioByPessoaWrapper()
        } catch (err: any) {
            showToast('error', err.message);
        }
        finally {
            setPreenchendoTela(false)
        }
    }, [getConvenioByPessoaWrapper, showToast])

    useEffect(() => {
        preencherTela()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCloseClick = () => {
        historicoCompleto
            ? setHistoricoCompleto(false)
            : fecharConvenioResumoFaturas(attRef.current)
    }

    const cartaoPrincipal = convenio.cartoes?.find(cartao => cartao.pessoaId === convenio.pessoaId)

    const selecionarAnoMes = useCallback(async (anoMes: number) => {
        try {
            setAnoMes(anoMes)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [showToast])

    useEffect(() => {
        if (!isEqual(convenio, new ConvenioModel())) {
            handleFaturas()
        }
    }, [convenio, handleFaturas])

    const returnColorStatus = useCallback(() => ({
        [EnumConvenioTpFaturaStatus.Aberta]: '#FFBC00',
        [EnumConvenioTpFaturaStatus.Fechada]: '#607D8B',
        [EnumConvenioTpFaturaStatus.NaoPaga]: '#F44336',
        [EnumConvenioTpFaturaStatus.Paga]: '#10C822',
    })[fatura?.fatura?.status ?? EnumConvenioTpFaturaStatus.Aberta] || '#FFBC00', [fatura?.fatura?.status])

    const returnColorText = useCallback(() => {
        return tinycolor(returnColorStatus()).getBrightness() > 200 ? tinycolor(returnColorStatus()).darken(50).toString() : '#fff'
    }, [returnColorStatus])

    const editarConvenio = () => {
        fecharConvenioResumoFaturas()

        setTimeout(() => {
            if (tipo === EnumTpAutenticacaoConvenio.Dependente || convenio.pessoaId !== props.pessoaId) {
                abrirCadastroCartaoConvenio(convenio.cartoes?.[0]?.id ?? '', convenio.id, tipo)
                return
            }
            abrirCadastroConvenio({
                callbackUrl: '',
                id: convenio.id,
                trocarUrl: true
            })
        }, 50)
    }

    const valorTotal = useMemo(() => {
        if (fatura.fatura) return fatura.fatura.valorFechamento

        return (lancamentos ?? []).reduce((prev, curr) => prev + (curr.valorCredito || curr.valorDebito), 0)
    }, [fatura.fatura, lancamentos])

    const handlePagamento = async (model: RetornoTecladoProps) => {
        const { credenciado, credenciais: credenciamento, pag } = model
        const credenciais = credenciamento
            ? JSON.parse(credenciamento ?? '')
            : new CredenciamentoSafra();
        if (!pag) return false
        await alterarModo(EnumMenuPrincipalModo.PDV)
        await redirectProcessarPagamento(
            {
                credenciais,
                credenciado,
                modoPagamento: {
                    ...new MovSimplesPagamentoModel(),
                    tpTransacao: pag.tpTransacao,
                    nParcelas: pag.qtdeParcela,
                    dhTransacao: new Date(),
                    vPag: pag.vPag,
                    vTroco: pag.vTroco,
                    pagamentoId: pag.pagamentoId,
                    cnpj: pag.card.cnpj ?? '',
                    modPagamento: pag.tPag,
                },
                pagFatura: {
                    anoMesCompetencia: anoMes,
                    faturaId: fatura?.id ?? '',
                    pessoaId: props.pessoaId,
                }
            } as HistoryStateProps,
            false
        )
        fecharConvenioResumoFaturas()
        return true
    }

    const pagarFatura = () => {
        const pdv = getPDV()
        if (!pdv) {
            confirm({
                title: 'PDV não localizado',
                description: 'Para poder pagar esta fatura, precisará iniciar o módulo de venda para podermos identificar o PDV.',
                confirmationText: 'Iniciar Módulo de Venda',
                cancellationText: 'Voltar'
            }).then(() => {
                fecharConvenioResumoFaturas()
                history.push({
                    pathname: MovRotasMock.landingRota
                })
            })
            return
        }
        abrirTecladoFuncoes({ id: fatura?.id ?? '', valor: valorTotal, callback: handlePagamento, isPagFatura: true, tipo: EnumTpTecladoFuncoes.Pagamento })
    }

    const verMaisHistorico = useCallback(() => {
        setHistoricoCompleto(true)
    }, [])

    const lancamentosList = useMemo(() => {
        return (lancamentos ?? []).filter(lanc => fatura.fatura ? lanc : lanc.pessoaId === props.pessoaId)
    }, [fatura.fatura, lancamentos, props.pessoaId])

    const isFechado = fatura.fatura?.status === EnumConvenioTpFaturaStatus.Fechada
    return (
        <div className={modalClasses.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <ModalHeader
                title={
                    carregando ? 'Carregando...' : historicoCompleto ? 'Histórico' : `Convênio ${cartaoPrincipal?.nomeCartao ?? ''}`
                }
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <div className={classNames(modalClasses.content, classes.content)}>
                {!historicoCompleto && (
                    <InformacoesConvenio model={convenio} editarConvenio={editarConvenio} />
                )}
                <AnoMesList
                    historicoCompleto={historicoCompleto}
                    onClick={selecionarAnoMes}
                    anoMesAtual={anoMes}
                    pessoaId={props.pessoaId}
                    empresaId={getEmpresaAtual()?.id ?? ''}
                    setCarregandoInterno={setCarregandoInterno}
                />
                <div
                    className={classNames(
                        modalClasses.contentForms,
                        preenchendoTela ? modalClasses.contentFormsLoading : undefined,
                        classes.sombraBaixo
                    )}
                >
                    <LancamentosList carregando={carregando} verMaisHistorico={verMaisHistorico} limitar={!historicoCompleto} model={lancamentosList} />
                </div>
                {(!historicoCompleto && fatura.fatura) && (
                    <div className={classNames(modalClasses.acoes, classes.acoes)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.contentStatus}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isFechado ? 4 : true}>
                                            <div className={classes.status}>
                                                <Typography className={classes.title}>
                                                    Status
                                                </Typography>
                                                <div className={classes.boxStatus} style={{
                                                    backgroundColor: returnColorStatus(),
                                                    color: returnColorText()
                                                }}>
                                                    {TpStatusFaturaConvenioMock.find(status => status.Key === fatura?.fatura?.status)?.Value ?? 'Sem status'}
                                                </div>
                                            </div>
                                        </Grid>
                                        {isFechado && (
                                            <Grid item xs={4}>
                                                <Typography className={classNames(classes.title, classes.titleRight)}>
                                                    Vencimento
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {toDateString(fatura.fatura.dataVencimento, 'DD')}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={isFechado ? 4 : 'auto'}>
                                            <Typography className={classNames(classes.title, classes.titleRight)}>
                                                Valor
                                            </Typography>
                                            <Typography className={classes.value}>
                                                R$ {toDecimalString(valorTotal)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            {fatura.fatura && [EnumConvenioTpFaturaStatus.Aberta, EnumConvenioTpFaturaStatus.Fechada].includes(fatura?.fatura?.status) && (
                                <Grid item xs={12}>
                                    <Button
                                        disabled={carregando || valorTotal === 0}
                                        onClick={pagarFatura}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        <DinheiroIcon tipo="BUTTON_PRIMARY" />
                                        Pagar Fatura
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                )}
            </div >
        </div >
    )
}