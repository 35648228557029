import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";
import { ProdutoVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-variacao";

export function useGetProdutoVariacao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoVariacao = useCallback(
    (produtoId: string, variacaoId: string) =>
      invocarApi<ProdutoVariacaoModel>({
        url: `/${VariaveisAmbiente.gatewayVersion}/Produto/${produtoId}/variacao/${variacaoId}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoVariacao,
  };
}
