import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormProdutoNomeMedidaPreCadastroValidation } from './form-produto-nome-medida-pre-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { ProdutoNomeMedidaPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-nome-medida-form-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { AutocompleteMedidas } from '../../../../controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { guidEmpty } from '../../../../../../utils/guid-empty';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { usePostProdutoMedida } from 'data/api/gestao/produto/produto-medida/post-produto-medida';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { retornoAutoComplete } from '../../../../controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { BalancaMock } from 'data/mocks/balanca-mock';
import { isPlanoFarmaceutico, isPlanoFiscal } from 'utils/plano-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid } from 'views/design-system';


export interface FormProdutoNomeMedidaPreCadastroProps
  extends DefaultFormProps<ProdutoNomeMedidaPreCadastroFormModel> {
  setCarregandoExterno(carregando: boolean): void;
  contratoId: string;
  empresaId: string;
}

export const FormProdutoNomeMedidaPreCadastro = forwardRef<
  DefaultFormRefs<ProdutoNomeMedidaPreCadastroFormModel>,
  FormProdutoNomeMedidaPreCadastroProps
>(
  (
    {
      loading,
      setCarregandoExterno,
      ...props
    }: FormProdutoNomeMedidaPreCadastroProps,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const [initialValues, setInitialValues] =
      useState<ProdutoNomeMedidaPreCadastroFormModel>(
        new ProdutoNomeMedidaPreCadastroFormModel(),
      );
    const { isMobile } = useThemeQueries();
    const { plano } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const isFiscal = isPlanoFiscal(plano?.plano);
    const isFarmacia = isPlanoFarmaceutico(plano?.plano);
    const { postProdutoMedida, carregando: carregandoPost } =
      usePostProdutoMedida();
    const { FormProdutoNomeMedidaPreCadastroYupValidation } =
      useFormProdutoNomeMedidaPreCadastroValidation();
    const carregando = carregandoPost || loading;

    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      setValue,
      reset,
      setError
    } = useForm<ProdutoNomeMedidaPreCadastroFormModel>({
      defaultValues: { ...initialValues },
      resolver: yupResolver(FormProdutoNomeMedidaPreCadastroYupValidation),
      criteriaMode: 'all',
      mode: 'onChange',
    });

    const validateCarc = useCallback((value) => {
      const descricao: string = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
      if (regexCaracEspeciais.test(descricao)) {
        return true
      }
      return false
    }, [])

    const onSubmit = (values: ProdutoNomeMedidaPreCadastroFormModel) => {
      const isValidName = validateCarc(values.nome);
      const isValidInfAdc = validateCarc(values.infAdic);
      if (!isFarmacia) {
        if (isValidName) {
          setError('nome', { type: "error", message: 'Não pode conter caracteres especiais.' })
          return
        }

        if (isValidInfAdc) {
          setError('infAdic', { type: "error", message: 'Não pode conter caracteres especiais.' })
          return
        }
      }

      const model = picker<ProdutoNomeMedidaPreCadastroFormModel>(
        values,
        new ProdutoNomeMedidaPreCadastroFormModel(),
      );

      props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        setInitialValues(new ProdutoNomeMedidaPreCadastroFormModel());
        if (!isMobile) refInputNome.current?.focus();
        reset();
      },
      fillForm: (model: ProdutoNomeMedidaPreCadastroFormModel) => {
        if (!isFiscal) {
          model.medida = 'UN'
        }
        setInitialValues(model);
        reset({ ...model });
        setTimeout(() => {
          if (!isMobile) refInputNome.current?.focus();
        }, 500);
      },
    }));

    const addNovaMedida = useCallback(
      async (value: string) => {
        setCarregandoExterno(true);

        const novaMedida = new ProdutoMedidaModel(
          guidEmpty(),
          props.contratoId,
          props.empresaId,
          value,
          value,
        );
        const ret = await postProdutoMedida(novaMedida);
        if (ret.erro) {
          setCarregandoExterno(false)
          throw ret.erro;
        }
        const medidaRet = ret.resultado?.data as ProdutoMedidaModel;
        setValue('medida', medidaRet.sigla);
        setValue('medidaId', medidaRet.id);
      },
      [
        setCarregandoExterno,
        props.contratoId,
        props.empresaId,
        postProdutoMedida,
        setValue,
      ],
    );

    const onChangeMedidaWrapper = useCallback(
      async (retorno: retornoAutoComplete) => {
        if (retorno.isNewVal) {
          const ultimaMedida = getValues('medida');
          try {
            setValue('medida', retorno.value);
            await addNovaMedida(retorno.value);
          } catch (e: any) {
            setValue('medida', ultimaMedida);
            showToast(
              'error',
              'Erro ao cadastrar a medida. Tente novamente em alguns instantes. Detalhe: ' +
              e.message,
            );
          } finally {
            setCarregandoExterno(false)
          }
        } else if (!retorno.isString) {
          const medida = picker<ProdutoMedidaModel>(
            retorno.value,
            new ProdutoMedidaModel(),
          );

          setValue('medida', medida.sigla);
          setValue('medidaId', medida.id);
        }
      },
      [addNovaMedida, getValues, setCarregandoExterno, setValue, showToast],
    );

    return (
      <>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputNome}
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label={'Nome Comercial'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: Agua Mineral 600ml"
                      error={Boolean(errors.nome && errors.nome.message)}
                      helperText={
                        errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="infAdic"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      id="outlined-textarea"
                      allowSubmit
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label="Informações Adicionais"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={400}
                      placeholder='Ex: "Consumir em até 7 dias após aberto". (Limite 400 caracteres)'
                      error={Boolean(errors.infAdic && errors.infAdic.message)}
                      helperText={
                        errors.infAdic
                          ? errors.infAdic?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              {isFiscal && (
                <Grid item xs={isFarmacia ? 12 : 6}>
                  <Controller
                    name="medida"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteMedidas
                        loadingExterno={carregando}
                        useCache={false}
                        label="Medida de Venda"
                        error={Boolean(errors.medida && errors.medida.message)}
                        helperText={
                          errors.medida
                            ? errors.medida?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('medida')}
                        primeiroValor
                        onChange={onChangeMedidaWrapper}
                        permiteAdicionar
                      />
                    )}
                  />
                </Grid>
              )}
              {(isFiscal && !isFarmacia) && (
                <Grid item xs={6}>
                  <Controller
                    name="balanca"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        allowSubmit
                        label="Modo de Balança"
                        variant="outlined"
                        fullWidth
                        disabled={loading}
                        conteudo={BalancaMock}
                        {...field}
                        onChange={(event) => {
                          const item = BalancaMock.filter(
                            (item) => item.Key === event.target.value,
                          )[0]?.Key;
                          if (item !== undefined) {
                            setValue('balanca', item);
                          }
                        }}
                        value={getValues('balanca')}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </>
    );
  },
);
