import { isEmpty } from 'lodash';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { SaloesCadastro } from './saloes-modal/saloes-cadastro/saloes-cadastro';
import { SaloesEdit } from './saloes-modal/saloes-edit/saloes-edit';

export interface SaloesModalProps extends ModalProps {
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const SaloesModal = (props: SaloesModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) &&
        <SaloesCadastro handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      }
      {props.openned && !isEmpty(props.id) &&
        <SaloesEdit id={props.id} handleAtualizarLista={props.handleAtualizarLista} atualizarLista={props.atualizarLista} />
      }
    </DefaultModal>
  );
};
