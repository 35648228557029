import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useStyles } from './pessoa-list-styles';
import { PessoaListData } from './pessoa-list-data';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { Grid } from 'views/design-system';
import { EnumCadastroStatus } from 'model';
import { useGetPessoasResumido } from 'data/api/gestao/pessoa/get-pessoas-resumido';
import { PessoaResumidoModel } from 'model/api/gestao/pessoa/pessoa-resumido-model';

export const PessoaList = () => {
  const classes = useStyles();
  const { abrirCadastroPessoa } = useCadastros();
  const { addHandler, removeHandler } = useEventTools();
  const { getPessoasResumido, carregando } = useGetPessoasResumido()

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const location = useLocation()
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaResumidoModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const filtros = useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    return {
      status: !isEmpty(urlParams.getAll('status')) ? urlParams.getAll('status').map(tp => Number(tp)) : [EnumCadastroStatus.LIBERADO, EnumCadastroStatus.BLOQUEADO],
      termo: urlParams.get('termo') || '',
      tpCadastro: !isEmpty(urlParams.getAll('tpCadastro')) ? urlParams.getAll('tpCadastro').map(tp => Number(tp)) : [-1],
    }
  }, [location.search])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo)
          ? 'generico=' + filtros.termo
          : '') +
        (filtros.status.length > 0
          ? filtros.status.some(t => t === -1) ? '' : filtros.status.reduce((acc, current) => acc + `&status=${current}`, '')
          : '') +
        (filtros.tpCadastro.length > 0
          ? filtros.tpCadastro.some(t => t === -1) ? '' : filtros.tpCadastro.reduce((acc, current) => acc + `&TpCadastros=${current}`, '')
          : '');

      try {
        const res = await getPessoasResumido(query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.termo, filtros.status, filtros.tpCadastro, getPessoasResumido, fillResult, showToast],
  );

  useEffect(() => {
    search(queryStatus.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const modalEdit = ({ openned, atualizarLista }: any) => {
      if (!openned && atualizarLista) {
        search(queryStatus.page);
      }
    };
    addHandler(AppEventEnum.PessoaModal, modalEdit);
    return () => {
      removeHandler(AppEventEnum.PessoaModal, modalEdit);
    }
  }, [addHandler, queryStatus.page, removeHandler, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    abrirCadastroPessoa(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <PessoaListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
