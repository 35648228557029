import { EnumIndIEDest } from "model/enums/enum-indiedest";
import { EnumCadastroStatus } from "model/enums/enum-cadastro-status";
import { EnumCadastroTipo } from "model/enums/enum-cadastro-tipo";
import { PessoaContatosModel } from "./pessoa-contatos-model";
import { guidEmpty } from "utils/guid-empty";
import { PessoaDocumentoModel } from "./pessoa-documento-model";
import { PessoaEnderecoModel } from "./pessoa-endereco-model";

export class RepresentanteCliente {
    constructor(
        public id: string = '',
        public tpCadastro: number = 0,
        public cpfcnpj: string = "",
        public nome: string = "",
        public fantasia: string = "",
        public status: number = 0,
        public dtNasc: string | Date | undefined | null = null,
        public sexo: number = 0
    ) { }
}
export class PessoaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public regimeTributarioId: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public tpCadastro: EnumCadastroTipo | undefined = undefined,
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public indIEDest: EnumIndIEDest = EnumIndIEDest.NAO_CONTRIBUINTE,
        public nome: string = '',
        public infOperador: string = '',
        public status: EnumCadastroStatus = EnumCadastroStatus.LIBERADO,
        public contatos: Array<PessoaContatosModel> = new Array<PessoaContatosModel>(),
        public documentos: Array<PessoaDocumentoModel> = new Array<PessoaDocumentoModel>(),
        public fantasia: string = '',
        public usuarioId: string = '',
        public infInterno: string = '',
        public representanteId: string = '',
        public dtNasc: Date | string | null = null,
        public sexo: number = 0,
        public representante: RepresentanteCliente | null = null,
        public enderecos: Array<PessoaEnderecoModel> = []
    ) { }
}