import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { makeUtilClasses, useThemeQueries } from 'views';

import {
    DefaultFormProps,
    DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { useConsultaCEP, useConsultaCnpj } from 'data/api/wsmaster';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { stringNumeros } from 'utils/string-numeros';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from 'views/design-system';
import { useFormPessoaValeValidation } from './form-pessoa-vale-validation';
import { PessoaValeFormModel } from 'model/app/forms/pessoa/pessoa-edit-form-model';

export const FormPessoaVale = forwardRef<
    DefaultFormRefs<PessoaValeFormModel>,
    DefaultFormProps<PessoaValeFormModel>
>((props: DefaultFormProps<PessoaValeFormModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { showToast } = useToastSaurus();

    const originalModel = useRef<PessoaValeFormModel>(new PessoaValeFormModel());

    const { isMobile } = useThemeQueries();
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
    const { FormPessoaValeValidation } = useFormPessoaValeValidation();

    const carregando = carregandoCEP || carregandoCNPJ;

    //REFS
    const refInputCnpjCpf = useRef<HTMLInputElement>(null);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<PessoaValeFormModel>({
        defaultValues: props.model || new PessoaValeFormModel(),
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FormPessoaValeValidation)
    });

    const { getEmpresaAtual } = useEmpresaAtual();

    const onSubmit = (values: PessoaValeFormModel) => {
        props.onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputCnpjCpf.current?.focus();
        },
        fillForm: (model: PessoaValeFormModel) => {
            reset({ ...model, clienteUF: model.clienteUF || getEmpresaAtual()?.uf || '' });
            originalModel.current = {
                ...model
            };
            setTimeout(() => {
                if (!isMobile) refInputCnpjCpf.current?.focus();
            }, 500);
        },
    }));

    const loading = carregando || props.loading;

    useEffect(() => {
        if (props.model) {
            reset({ ...props.model });
        }
    }, [props.model, reset]);

    return (
        <>
            <div className={utilClasses.formContainer}>
                {loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo={'NORMAL'} />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={
                        loading && props.showLoading ? utilClasses.controlLoading : ''
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteDocumento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CNPJ_CPF"
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label="CPF/CNPJ"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        searchable
                                        onSearch={async () => {
                                            try {
                                                const dadosConsulta = await consultarCNPJ(getValues('clienteDocumento') || '');

                                                const enderecoModel = new PessoaValeFormModel();
                                                enderecoModel.clienteXBairro = dadosConsulta.bairro;
                                                enderecoModel.clienteCMun = Number(dadosConsulta.cMunicipio);
                                                enderecoModel.clienteXMun = dadosConsulta.municipio;
                                                enderecoModel.clienteCEP = dadosConsulta.cep;
                                                enderecoModel.clienteXCpl = dadosConsulta.complemento;
                                                enderecoModel.clienteXLgr = dadosConsulta.logradouro;
                                                enderecoModel.clienteNro = dadosConsulta.numero;
                                                enderecoModel.clienteUF = dadosConsulta.uf;

                                                reset({ ...enderecoModel })

                                            } catch (e: any) {
                                                showToast('error', e.message);
                                            }
                                        }}
                                        autoComplete="new-password"
                                        {...field}
                                        error={Boolean(errors?.clienteDocumento)}
                                        helperText={
                                            errors?.clienteDocumento ? errors?.clienteDocumento?.message : undefined
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteNome"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Nome Completo / Razão Social"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        autoComplete="new-password"
                                        placeholder="Ex: João da Silva"
                                        {...field}
                                        error={Boolean(errors?.clienteNome)}
                                        helperText={
                                            errors?.clienteNome ? errors?.clienteNome?.message : undefined
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteCEP"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CEP"
                                        disabled={props.loading}
                                        fullWidth
                                        searchable
                                        autoComplete={'off'}
                                        label="CEP"
                                        placeholder=""
                                        onSearch={async (value: string) => {
                                            try {
                                                let res = await consultaCEP(value);
                                                setValue('clienteCEP', stringNumeros(res.CEP));
                                                setValue('clienteXBairro', res.Bairro);
                                                setValue('clienteXLgr', res.Logradouro);
                                                setValue('clienteUF', res.UF);
                                                setValue('clienteCMun', Number(res.CMun));
                                                setValue('clienteXMun', res.Municipio);
                                            } catch (e: any) {
                                                showToast('error', e.message);
                                            }
                                        }}
                                        error={Boolean(errors.clienteCEP && errors.clienteCEP.message)}
                                        helperText={errors.clienteCEP ? errors.clienteCEP?.message : undefined}
                                        {...field}
                                        value={getValues('clienteCEP')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteXLgr"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Logradouro"
                                        placeholder="Ex: Av. Paulista"
                                        error={Boolean(
                                            errors.clienteXLgr && errors.clienteXLgr.message
                                        )}
                                        helperText={
                                            errors.clienteXLgr ? errors.clienteXLgr?.message : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="clienteNro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Número"
                                        placeholder="Ex: 112"
                                        error={Boolean(errors.clienteNro && errors.clienteNro.message)}
                                        helperText={
                                            errors.clienteNro ? errors.clienteNro?.message : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="clienteXCpl"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Complemento"
                                        placeholder="Ex: Apartamento 11"
                                        error={Boolean(
                                            errors.clienteXCpl && errors.clienteXCpl.message
                                        )}
                                        helperText={
                                            errors.clienteXCpl
                                                ? errors.clienteXCpl?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteXBairro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Bairro"
                                        placeholder="Ex: Bela Vista"
                                        error={Boolean(errors.clienteXBairro && errors.clienteXBairro.message)}
                                        helperText={
                                            errors.clienteXBairro ? errors.clienteXBairro?.message : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="clienteXMun"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Município"
                                        placeholder="Ex: São Paulo"
                                        error={Boolean(errors.clienteXMun && errors.clienteXMun.message)}
                                        helperText={errors.clienteXMun ? errors.clienteXMun?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="clienteCMun"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="NUMERO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete={'off'}
                                        label="Código do Município"
                                        placeholder="Ex: 3550308"
                                        error={Boolean(errors.clienteCMun && errors.clienteCMun.message)}
                                        helperText={errors.clienteCMun ? errors.clienteCMun?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="clienteUF"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        disabled={props.loading}
                                        conteudo={UFMock}
                                        fullWidth
                                        variant="outlined"
                                        label={'UF'}
                                        allowSubmit
                                        select
                                        {...field}
                                        value={
                                            UFMock.find((uf) => uf.Value === getValues('clienteUF'))?.Key
                                        }
                                        onChange={(event) => {
                                            if (event) {
                                                const item = UFMock.filter(
                                                    (item) => item.Key === event.target.value
                                                )[0];
                                                if (item) {
                                                    setValue('clienteUF', item.Value);
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
