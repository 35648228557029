import { stringNumeros } from "utils/string-numeros";
import { ValeModel } from "./vale-model";

export class ValeResponseModel<T = ValeModel> {
  constructor(
    public retTexto: string = '',
    public response: T,
    public validacoes: ValidacaoValeModel[] = [],
  ) { }
}

export class ValidacaoValeModel {
  constructor(
    public propriedade: string,
    public mensagem: string
  ) { }
}

export class ValeSearchModel {
  public Where: string = '';
  public OrderBy: string = 'DtCriacao ASC';
  public Top: number = 100;

  constructor(
    cpfCnpj: string,
  ) {
    this.Where = " AND Vale.ClienteDocumento = '" + stringNumeros(cpfCnpj) + "' AND Vale.Status = 1 ";
  }
}