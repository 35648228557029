import { EmissorModel } from "model/api/gestao/master/emissor";
import { PontosVendaVersaoModel } from "model/api/gestao/pontos-venda/ponto-venda-versao-model";
import { PontosVendaConfiguracoesModel } from "model/api/gestao/pontos-venda/pontos-venda-configuracoes-model";
import { EnumIndstatusStatus } from "model/enums/enum-indstatus-status";
import { guidEmpty } from "utils/guid-empty";

export class PontosVendaCompletoVendaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public depositoId: string = guidEmpty(),
        public numCaixa: number = 1,
        public chave: string = '',
        public descricao: string = '',
        public indStatus: EnumIndstatusStatus = EnumIndstatusStatus.ATIVADO,
        public dominio: string = '',
        public tpCaixa: number = 0,
        public configuracoes: Array<PontosVendaConfiguracoesModel> = [],
        public versao: PontosVendaVersaoModel | undefined = undefined,
        public emissor: EmissorModel | undefined = undefined,
        public abertura: Date = new Date(),
        public ultConsulta: Date = new Date(2001, 1, 1),
    ) { }
}