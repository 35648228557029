import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    zIndex: 0,
    width: '100%',
    minHeight: '160px',
    padding: 5,
    flex: 1,
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    '-webkit-user-select': 'none',
  },
  containerColorCategoria: {
    position: 'relative',
    height: 4,
    marginLeft: -4,
    marginRight: -4,
    marginTop: -5,
    marginBottom: 4,
    borderRadius: '5px 5px 0 0',
    width: 'calc(100% + 9px)',
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
  },
  containerFavorito: {
    position: 'absolute',
    right: 4,
    top: 10,
    zIndex: 1,
    width: '30px',
    height: '30px',
    "& svg": {
      width: 30,
      height: 30,
    }
  },
  containerImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px',
    marginRight: '8px',
    height: 100,
  },
  celulaGrid: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  badgeQtde: {
    position: 'absolute',
    left: 20,
    top: 23,
    "& span": {
      fontSize: 10,
    }
  },
  cardImg: {
    display: "flex",
    objectFit: "contain",
    zIndex: 0,
    position: 'relative',
    background: theme.palette.common.white,
    width: "70px",
    height: "70px ",
    borderRadius: theme.shape.borderRadius,
    transition: "0.2s ease-in-out transform",
    "&:hover": {
      transform: "scale(1.1)",
    }
  },
  containerInfo: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  divider: {
    borderTop: '1px solid #cdcdcd',
    width: '100%'
  },
  gridTextos: {

  },
  textName: {
    color: '#797878',
    fontSize: '10px',
    fontWeight: 'bold',
    marginBottom: 5,
    lineHeight: '14px',
  },
  textPriceRiscado: {
    lineHeight: '14px',
    fontWeight: 600,
    textDecoration: 'line-through',
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  textPrice: {
    lineHeight: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    fontSize: '14px',
  },
  "@keyframes pulse": {
    from: { transform: "scale(1)" },
    "50%": { transform: "scale(1.02)" },
    to: { transform: "scale(1)" }
  },
  fePulse: {
    animationName: "$pulse",
    animationDuration: ".3s",
    animationIterationCount: "1",
  }
}));

