import { forwardRef, useImperativeHandle } from "react";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import { Controller, useForm } from "react-hook-form";
import { ValeReceberFormModel } from "model/app/forms/vale/vale-receber-form-model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormReceberValeValidation } from "./form-receber-vale-validation";
import { Button, Grid } from "views/design-system";
import { makeUtilClasses } from "views/theme";
import { CircularLoading } from "views/components/utils";
import { TextFieldSaurus } from "views/components/controles/inputs";

export const FormReceberVale = forwardRef<DefaultFormRefs<ValeReceberFormModel>, DefaultFormProps<ValeReceberFormModel>>(
    (props: DefaultFormProps<ValeReceberFormModel>, ref) => {

        const utilClasses = makeUtilClasses();
        const { FormReceberValeValidation } = useFormReceberValeValidation();

        const {
            handleSubmit,
            control,
            reset,
            formState: { errors },
        } = useForm<ValeReceberFormModel>({
            defaultValues: props.model || new ValeReceberFormModel(),
            criteriaMode: 'all',
            mode: 'onChange',
            resolver: yupResolver(FormReceberValeValidation)
        });

        function onSubmit(values: ValeReceberFormModel) {
            props.onSubmit(values);
        }

        useImperativeHandle(ref, () => ({
            resetForm() { },
            async submitForm() {
                handleSubmit(onSubmit)();
            },
            fillForm(inputModel) {
                reset({ ...inputModel })
            },
        }))

        return (
            <>
                <div className={utilClasses.formContainer}>
                    {props.loading && props.showLoading ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo={'FULLSIZED'} />
                        </div>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={
                            props.loading && props.showLoading ? utilClasses.controlLoading : ''
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="chave"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="NUMERO"
                                            disabled={props.loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Chave Única do Vale (20 Dígitos)"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoComplete="new-password"
                                            {...field}
                                            error={Boolean(errors?.chave)}
                                            helperText={
                                                errors?.chave ? errors?.chave?.message : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="documento"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="CNPJ_CPF"
                                            disabled={props.loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Documento do Cliente Vinculado (CPF/CNPJ)"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoComplete="new-password"
                                            {...field}
                                            error={Boolean(errors?.documento)}
                                            helperText={
                                                errors?.documento ? errors?.documento?.message : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </>
        )
    }
)