import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogAutenticarVale } from 'views/components/modals/dialog-autenticar-vale/dialog-autenticar-vale';

export class AutenticarValeDialogProps {
    constructor(
        public aberto: boolean = false,
        public pagamento: MovSimplesPagamentoModel = new MovSimplesPagamentoModel(),
        public sucesso: (valorVale: number) => void = () => {},
        public cancelar: () => void = () => {},
    ) { }
}

export const AutenticarValeDialog = () => {

    const { addHandler, removeHandler } = useEventTools();

    const [modalState, setModalState] = useState<AutenticarValeDialogProps>(new AutenticarValeDialogProps());

    const modalAlterado = useCallback(({ aberto, pagamento, sucesso , cancelar }: AutenticarValeDialogProps) => {
        setModalState({
            aberto: aberto,
            pagamento,
            sucesso,
            cancelar
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.DialogAutenticarVale, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.DialogAutenticarVale, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogAutenticarVale
                    cancelar={modalState.cancelar}
                    aberto={modalState.aberto}
                    pagamento={modalState.pagamento}
                    sucesso={modalState.sucesso}
                />
            )}
        </>
    );
};