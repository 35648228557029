import { useCallback, useMemo, useState } from "react";
import { useMovAtual } from "./mov-atual";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { useToastSaurus } from "./toast-saurus";
import { usePostVale } from "data/api/gestao/vale/post-vale";
import { PessoaValeFormModel } from "model/app/forms/pessoa/pessoa-edit-form-model";
import { LogStatusModel, ValeModel } from "model/api/gestao/troca/vale-model";
import { retClienteContato } from "utils/cliente-contato";
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import { EnumPagTpMod } from "model";
import { EnumTpTroco } from "model/enums/enum-tp-troco";
import { toDateStringApi } from "utils/to-date";
import { incMonth } from "utils/get-date";
import { EnumStatusVale, EnumTipoVale } from "model/enums/enum-vale";
import { useGetValeStatus } from "data/api/gestao/vale/get-vale-status";
import { ValeReceberFormModel } from "model/app/forms/vale/vale-receber-form-model";
import { stringNumeros } from "utils/string-numeros";
import { roundTo } from "utils/round-to";
import { ValeResponseModel, ValeSearchModel } from "model/api/gestao/troca/vale-response";
import { usePostValeSearch } from "data/api/gestao/vale/post-vale-search";
import { useEmpresaAtual } from "./empresa-atual";
import { usePutVale } from "data/api/gestao/vale/patch-vale";
import { ValePutModel } from "model/api/gestao/troca/vale-put-model";
import { guidEmpty } from "utils/guid-empty";
import { useSessaoAtual } from "../providers";
import { useGetValeByCod } from "data/api/gestao/vale/get-vale-by-cod";
import { EnumPagamentosCompostos } from "data/mocks";
import { validarPagamentoComposto } from "utils/tp-pagamento-resolver";

export const useVale = () => {

    const { getMov, alterarPagamento } = useMovAtual();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { usuario } = useSessaoAtual();

    const [update, setUpdate] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mov = useMemo(() => getMov() || new MovSimplesModel(), [getMov, update]);

    const { showToast } = useToastSaurus();
    const { postVale, carregando: carregandoPost } = usePostVale();
    const { postValeSearch, carregando: carregandoSearch } = usePostValeSearch();
    const { getValeByCod, carregando: carregandoGet } = useGetValeByCod();
    const { getValeStatus, carregando: carregandoGetStatus } = useGetValeStatus();
    const { putVale, carregando: carregandoPatch } = usePutVale();

    const carregando = carregandoPost || carregandoPatch || carregandoGetStatus || carregandoSearch || carregandoGet;

    const getValesByDoc = useCallback(async (cpfCnpj: string): Promise<ValeModel[]> => {
        try {
            const objConsulta = new ValeSearchModel(cpfCnpj);

            const res = await postValeSearch(objConsulta);

            if (res.erro) throw res.erro;

            const data = res.resultado?.data as ValeResponseModel<ValeModel[]>;

            return data.response;

        } catch (e: any) {
            showToast('error', e.message)
            throw e;
        }


    }, [postValeSearch, showToast])

    const getVale = useCallback(async (cod: string) => {
        try {
            const res = await getValeByCod(cod);
            if (res.erro) throw res.erro

            const data = res.resultado?.data as ValeResponseModel<ValeModel>;

            return data.response;
        } catch (e: any) {
            showToast('error', e.message)
            throw e;
        }
    }, [getValeByCod, showToast])

    const fillValeModel = useCallback(() => {
        const troco = new ValeModel();

        const cliente = mov.cliente;

        if (mov.clienteIdentificado && cliente) {
            troco.clienteCEP = cliente.endereco?.cep || '';
            troco.clienteCMun = Number(cliente.endereco?.cMun) || 0;
            troco.clienteCPais = 1058;
            troco.clienteDocumento = stringNumeros(cliente.cpfcnpj);
            troco.clienteEmail = retClienteContato(cliente.contatos);
            troco.clienteNome = cliente.nome;
            troco.clienteNro = cliente.endereco?.numero || '';
            troco.clienteTelefone = retClienteContato(cliente.contatos, EnumTipoPessoaContato.TELEFONE);
            troco.clienteUF = cliente.endereco?.uf || '';
            troco.clienteXBairro = cliente.endereco?.bairro || '';
            troco.clienteXCpl = cliente.endereco?.complemento || '';
            troco.clienteXLgr = cliente.endereco?.logradouro || '';
            troco.clienteXMun = cliente.endereco?.xMun || '';
        }

        const valorNF = mov.vNF;
        const vPago = mov.pags.reduce((prev, curr) => prev + curr.vPag, 0);
        troco.valor = roundTo(vPago - valorNF);

        troco.usuarioCriacao = usuario?.apelido || '';

        troco.emitente = getEmpresaAtual()?.razaoSocial || '';
        troco.emitenteDocumento = getEmpresaAtual()?.cpfcnpj || '';

        return troco;
    }, [getEmpresaAtual, mov.cliente, mov.clienteIdentificado, mov.pags, mov.vNF, usuario?.apelido])

    const criarValeTroco = useCallback(async (form: PessoaValeFormModel) => {
        try {
            const fillValeTroco = fillValeModel();
            const valeTroco = { ...fillValeTroco, ...form }
            const dtAtual = toDateStringApi(new Date()) || '';
            valeTroco.dtAtivacao = dtAtual;
            valeTroco.dtCriacao = dtAtual;
            valeTroco.dtExpiracao = toDateStringApi(new Date(incMonth(new Date(), 1, String(new Date().getDate())))) || '';
            valeTroco.tipo = EnumTipoVale.ValeTroco;
            valeTroco.status = EnumStatusVale.Pendente;

            const valorNF = mov.vNF;
            const vPago = mov.pags.reduce((prev, curr) => prev + curr.vPag, 0);
            valeTroco.valor = roundTo(vPago - valorNF);

            const res = await postVale(valeTroco);

            if (res.erro) throw res.erro

            const data = res.resultado?.data as ValeResponseModel<ValeModel>;

            const pags = mov.pags
                .filter(
                    (x) =>
                        x.modPagamento === EnumPagTpMod.DINHEIRO ||
                        x.modPagamento === EnumPagTpMod.OUTRO
                )
                .sort(function (a, b) {
                    return b.vPag - a.vPag;
                });

            if (pags[0]) {
                pags[0].tpTroco = EnumTpTroco.Vale;
                await alterarPagamento(pags[0]);
                setUpdate(prev => !prev);
            }

            return data.response;

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [alterarPagamento, fillValeModel, mov.pags, mov.vNF, postVale, showToast])

    const retValeStatus = useCallback(async (codigo: string) => {
        try {
            const res = await getValeStatus(codigo);

            if (res.erro) throw res.erro;

            const data = res.resultado?.data as ValeResponseModel<LogStatusModel[]>;

            return data.response;
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getValeStatus, showToast])

    const utilizarVale = useCallback(async (
        vale: ValeModel,
        model: ValeReceberFormModel
    ) => {
        try {
            if (new Date() > new Date(vale.dtExpiracao)) {
                throw new Error('Vale Expirado')
            }
            const putModel = new ValePutModel(
                guidEmpty(),
                vale.id,
                toDateStringApi(new Date()),
                usuario?.apelido || '',
                EnumStatusVale.Utilizado,
                '',
                '',
                {
                    ...vale,
                    dtUtilizacao: toDateStringApi(new Date()),
                    status: EnumStatusVale.Utilizado,
                },
            );

            const res = await putVale(model.chave, putModel);

            if (res.erro) {
                if(res.resultado?.data?.retTexto){
                    throw res.resultado.data.retTexto
                }
                throw res.erro
            }

            return true;
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [putVale, showToast, usuario?.apelido])

    const atualizarStatusVale = useCallback(async (model: ValeModel, status: EnumStatusVale) => {
        try {
            const putModel = new ValePutModel(
                guidEmpty(),
                model.id,
                toDateStringApi(new Date()),
                model.usuarioCriacao,
                status,
                '',
                '',
                {
                    ...model,
                    status,
                },
            );
            const res = await putVale(model.nroVale, putModel);
            if (res.erro) throw res.erro;

        } catch (e: any) {
            showToast('error', e.message)
            throw e;
        }
    }, [putVale, showToast])


    const inserirRepique = useCallback(async () => {
        const pags = mov.pags
            .filter(
                (x) =>
                    x.modPagamento === EnumPagTpMod.DINHEIRO ||
                    validarPagamentoComposto(x.modPagamento, x.tpTransacao) === EnumPagamentosCompostos.ValeTroco ||
                    x.modPagamento === EnumPagTpMod.OUTRO
            )
            .sort(function (a, b) {
                return b.vPag - a.vPag;
            });

        if (pags[0]) {
            pags[0].tpTroco = EnumTpTroco.Repique;
            await alterarPagamento(pags[0]);
            setUpdate(prev => !prev);
        }
    }, [alterarPagamento, mov.pags])

    return {
        carregando,
        mov,
        criarValeTroco,
        fillValeModel,
        inserirRepique,
        retValeStatus,
        utilizarVale,
        getValesByDoc,
        getVale,
        atualizarStatusVale,
    }
}