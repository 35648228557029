import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardDragging: {
    display: 'none',
    opacity: 0,
    '& p': {
      userSelect: 'none'
    },
    '& .MuiTypography-caption': {
      userSelect: 'none'
    }
  },
  cardFlexStart: {
    justifyContent: 'flex-start'
  },
  cardSmall: {
    width: '90px !important',
    minHeight: '79px !important',
    '& svg': {
      height: 28,
      width: 26
    },
    '& p': {
      fontSize: '13px',
      whiteSpace: 'break-spaces',
      overflow: 'unset'
    }
  },

  card: {
    position: 'relative',
    display: 'flex',
    opacity: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    width: '100%',
    padding: theme.spacing(1),
    minHeight: 155,
    height: '100%',
    boxShadow: '0px 2.5px 4px 0px #28293D40',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    cursor: 'pointer',
    '& svg': {
      fill: theme.palette.primary.main,
      height: 38,
      width: 36,
      margin: 0,
      userSelect: 'none'
    },
    '& p': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      userSelect: 'unset'
    },
  },
  changedBackground: {
    '& p': {
      color: `${theme.palette.common.white} !important`,
      fontWeight: 500,
    },
    '& svg': {
      fill: `${theme.palette.common.white} !important`,
    },
  },
  backgroundSuccess: {
    background: `${theme.palette.success.light} !important`,
  },
  backgroundPrimary: {
    background: `${theme.palette.primary.light} !important`,
  },
  backgroundSecondary: {
    background: `${theme.palette.secondary.light} !important`,
  },
  backgroundError: {
    background: `${theme.palette.error.light} !important`,
  },
  disabledLayer: {
    background: `rgba(255, 255, 255, .6)`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  caption: {
    userSelect: 'unset'
  },
  captionBackground: {
    userSelect: 'unset',
    color: `${theme.palette.common.white} !important`,
  },
  childrenItem: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'flex-end'
  },
  dragInfo: {
    '& svg': {
      width: '20px !important',
      height: '20px !important',
      fill: theme.palette.divider,
      userSelect: 'none'
    },
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    pointer: 'move !important'
  },
  draggableFlexCard: {
    justifyContent: 'flex-start'
  },
  draggableCardDragging: {
    display: 'none',
    opacity: 0,
    '& p': {
      userSelect: 'none'
    },
    '& .MuiTypography-caption': {
      userSelect: 'none'
    }
  },
  draggableCardFlexStart: {
    justifyContent: 'flex-start'
  },
  draggableCardSmall: {
    width: 90,
    minHeight: 79,
    '& svg': {
      height: 28,
      width: 26
    },
    '& p': {
      fontSize: '13px',
      whiteSpace: 'break-spaces',
      overflow: 'unset'
    }
  },

  draggableCard: {
    position: 'fixed',
    display: 'flex',
    opacity: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    width: '100%',
    padding: theme.spacing(1),
    minHeight: 155,
    height: '100%',
    boxShadow: '0px 2.5px 4px 0px #28293D40',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    cursor: 'pointer',
    zIndex: 101,
    userSelect: 'unset',
    top: 0,
    left: 0,
    animation: `$fadeIn 300ms`,
    '& svg': {
      fill: theme.palette.primary.main,
      height: 38,
      width: 36,
      margin: 0
    },
    '& p': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      userSelect: 'unset'
    }
  },
  dragOverlay: {
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    whiteSpace: 'break-spaces',
    '& p': {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      whiteSpace: 'break-spaces !important'
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '30%': {
      opacity: 0
    },
    '80%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 1
    }
  }
}));
