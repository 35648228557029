import { PessoaValeFormModel } from "model/app/forms/pessoa/pessoa-edit-form-model"
import { useRef } from "react"
import { FormPessoaVale } from "views/components/form/vale/form-pessoa-vale/form-pessoa-vale"
import { DefaultFormRefs } from "views/components/form/utils"
import { VoltarIcon } from "views/components/icons"
import { ConfirmarIcon } from "views/components/icons/confirmar-icon"
import { Box, Button } from "views/design-system"

interface ValeTrocoProps{
    handleSubmit: (form: PessoaValeFormModel) => Promise<any>;
    voltar: () => void;
}

export const ValeTroco = ({ handleSubmit, voltar }: ValeTrocoProps) => {

    const formRef = useRef<DefaultFormRefs<PessoaValeFormModel>>(null);

    return <>
        <Box flex flexDirection="column" gap={2}>
            <FormPessoaVale
                loading={false}
                showLoading={false}
                onSubmit={handleSubmit}
                ref={formRef}
            />
        </Box>
        <Box flex gap={2} mt={2}>
            <Button color="primary" variant='outlined' fullWidth onClick={voltar}>
                <VoltarIcon tipo='BUTTON' />
                Voltar
            </Button>
            <Button color='primary' variant='contained' onClick={() => formRef.current?.submitForm()} fullWidth>
                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                Confirmar
            </Button>
        </Box>
    </>

}